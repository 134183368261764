import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import {
  Close as CloseIcon,
  Download as DownloadIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';

import { ButtonGroup, IconButton } from '@mui/material';

import Spinner from './Spinner';
import { withErrorBoundary } from '~/ui/atoms';
import Log from '~/utils/Log';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function PdfViewer(props) {
  const [page, setPage] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);

  const onLoadSuccess = (pdf) => {
    setPageNumber(pdf._pdfInfo.numPages);
  };

  const onRenderSuccess = () => {
    setLoaded(true);
    props.onLoadSuccess();
  };

  const previousPage = () => {
    if (page === 1) {
      return;
    }

    Log.productAnalyticsEvent('Go to previous page', Log.FEATURE.PDF_VIEWER);

    setPage(page - 1);
  };

  const nextPage = () => {
    if (page === pageNumber) {
      return;
    }

    Log.productAnalyticsEvent('Go to next page', Log.FEATURE.PDF_VIEWER);

    setPage(page + 1);
  };

  const error = (
    <span className="w-full">PDF konnte nicht geladen werden.</span>
  );

  return (
    <div
      className="min-h-200px h-full w-full"
      onMouseOver={() => setMouseOver(true)}
      onMouseOut={() => setMouseOver(false)}
    >
      <Document
        file={props.pdf}
        onLoadSuccess={onLoadSuccess}
        error={error}
        loading=""
        noData=""
      >
        <Page
          pageNumber={page}
          width={(props.width ?? 0) * 0.9}
          error={error}
          loading=""
          noData=""
          onRenderSuccess={onRenderSuccess}
        />
      </Document>
      {pageNumber && loaded ? (
        <div
          className={
            'flex-sb-c pt-20px b-30px t-30px sticky w-full ' +
            (mouseOver ? 'fade-in-300ms' : 'fade-out-300ms')
          }
        >
          <div className="circle w-40px box-shadow flex-c-c bg-white">
            <IconButton
              color="primary"
              onClick={() => props.download()}
              size="large"
            >
              <DownloadIcon />
            </IconButton>
          </div>
          <div className="w-full text-center">
            <ButtonGroup className="w-100px flex-sb-c">
              <div className="circle w-40px box-shadow flex-c-c bg-white">
                <IconButton color="primary" onClick={previousPage} size="large">
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </div>
              <div className="circle w-40px box-shadow flex-c-c bg-white">
                <IconButton color="primary" onClick={nextPage} size="large">
                  <KeyboardArrowRightIcon />
                </IconButton>
              </div>
            </ButtonGroup>
          </div>
          <div className="circle w-40px box-shadow flex-c-c bg-white">
            <IconButton
              color="primary"
              onClick={() => props.close()}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      ) : (
        <Spinner title="PDF wird geladen..." />
      )}
    </div>
  );
}

export default withErrorBoundary(PdfViewer, 'PDF konnte nicht geladen werden.');
