import { Error as ErrorIcon } from '@mui/icons-material';

import { LOADING_STATE } from '~/constants/LoadingState';
import Spinner from './Spinner';

export default function LoadingPage(props) {
  if (props.loading === LOADING_STATE.LOADING) {
    return <Spinner title={props.loadingTitle} />;
  }

  if (props.loading === LOADING_STATE.FAILED) {
    return (
      <div className="flex-c-c h-full w-full">
        <ErrorIcon className="mr-10px" />
        <div>{props.errorTitle}</div>
      </div>
    );
  }
}
