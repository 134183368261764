import React from 'react';

import { PersonAdd as PersonAddIcon } from '@mui/icons-material';

import { connect } from 'react-redux';
import BasicForm from '../../BasicForm';
import { withErrorBoundary } from '~/ui/atoms';
import ArrayUtils from '~/utils/arrayUtils';
import UserService from '~/services/user.service';
import { ROUTE } from '~/constants/Route';
import { LOADING_STATE } from '~/constants/LoadingState';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';
import DeliveriesService from '~/services/deliveries.service';
import Spinner from '../../Spinner';
import FunctionUtils from '~/utils/functionUtils';
import ToastService from '~/services/toast.service';
import { InputAdornment } from '@mui/material';
import UserEmailNamePair from '../../UserEmailNamePair';
import MapperService from '~/services/mapper.service';
import GenericMultiPicker from '../../baseComponents/inputs/select/GenericMultiPicker';
import PromiseUtils from '~/utils/promiseUtils';
import UserAction from '~/models/userActions/UserAction';
import { saveUserActions } from '~/redux/userinfoSlice';
import { updateDeliveryNotes } from '~/redux/deliveryNotesSlice';
import DeliveryNoteActionMetaData from './DeliveryNoteActionMetaData';

const mapStateToProps = ({ deliveryNotes, userinfo, users }) => ({
  deliveryNotes,
  userinfo,
  users,
});
const mapDispatchToProps = () => ({
  saveUserActions,
  updateDeliveryNotes,
});

class ShareDeliveryNoteForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initializing: LOADING_STATE.NOT_LOADED,
      pickedUsers: [],
      sharedUsers: [],
      sortedUsers: [],
      submittingForm: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // We don't want to load the shared users if
    // - the modal isn't open or
    // - the initializing is already running or already done or
    // - the users haven't been loaded successfully
    if (
      !this.props.open ||
      this.props.users.usersLoading !== LOADING_STATE.SUCCEEDED
    ) {
      return;
    }

    // We want to load the shared users if
    // - the modal has been opened or
    // - the users have been loaded successfully
    if (
      !prevProps.open ||
      prevProps.users.usersLoading === LOADING_STATE.LOADING
    ) {
      this.init();
    }
  }

  async init() {
    if (
      !UserService.userIsAuthorizedForPage(
        this.props.userinfo.userPermissions,
        ROUTE.SETTINGS_USER.ROUTE,
      )
    ) {
      return;
    }

    this.setState({
      initializing: LOADING_STATE.LOADING,
    });

    let sharedUserIds = [];

    for (const deliveryNoteId of this.props.deliveryNoteIds) {
      const [userIds, error] = await promiseHandler(
        DeliveriesService.getUsersOfSharedDeliveryNote(deliveryNoteId),
      );

      if (error) {
        Log.error('Failed to get users of shared delivery note.', error);
        Log.productAnalyticsEvent(
          'Failed to load shared users',
          Log.FEATURE.SHARE_DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );
        this.setState({
          initializing: LOADING_STATE.FAILED,
        });
        continue;
      }

      sharedUserIds.push(...userIds);
    }

    sharedUserIds = ArrayUtils.removeDuplicates(sharedUserIds);

    const users = this.props.users.users
      .filter(({ active }) => active)
      .map((user) => {
        const newUser = {
          id: user.id,
          active: user.active,
          email: user.email,
          name: user.getName(),
          nameComponent: <UserEmailNamePair user={user} />,
        };

        return MapperService.addSearchStringWithValues(newUser, [
          user.email,
          user.getName(),
        ]);
      });

    const sortedUsers = ArrayUtils.sortByKey(users, 'email').filter(
      ({ active }) => active,
    );
    const sharedUsers = ArrayUtils.sortByKey(users, 'email').filter(
      ({ active, id }) => active && sharedUserIds.includes(id),
    );

    this.setState({
      initializing: LOADING_STATE.SUCCEEDED,
      pickedUsers: [],
      sharedUsers,
      sortedUsers,
    });
  }

  formSuccess = async (event) => {
    event.preventDefault();

    this.setState({
      submittingForm: true,
    });

    Log.info(
      'Submit share delivery note form',
      { value: this.state.pickedUsers },
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent(
      'Submit share delivery note form',
      Log.FEATURE.SHARE_DELIVERY_NOTE,
    );

    const promises = [];

    if (this.props.deliveryNoteIds.length === 0) {
      Log.error('Failed to share delivery note: Missing delivery note.');
      ToastService.error([
        'Die Lieferung(en) konnte(n) nicht den Benutzern geteilt werden.',
      ]);
      Log.productAnalyticsEvent(
        'Failed to share delivery note',
        Log.FEATURE.SHARE_DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      this.setState({
        submittingForm: false,
      });
    }

    for (const pickedUser of this.state.pickedUsers) {
      for (const deliveryNoteId of this.props.deliveryNoteIds) {
        promises.push(
          DeliveriesService.shareDeliveryNote(deliveryNoteId, pickedUser.id),
        );
      }
    }

    const [, error] = await promiseHandler(PromiseUtils.allResolved(promises));

    if (error) {
      Log.error('Failed to share delivery note.', error);
      ToastService.error([
        'Die Lieferung(en) konnte(n) nicht mit allen Benutzern geteilt werden.',
      ]);
      Log.productAnalyticsEvent(
        'Failed to share delivery note',
        Log.FEATURE.SHARE_DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      this.setState({
        submittingForm: false,
      });
      await FunctionUtils.timer(300);
      await this.init();
      return;
    }

    ToastService.success(['Die Lieferung(en) wurde(n) erfolgreich geteilt.']);

    const [newUserActions, updatedDeliveryNotes] =
      UserAction.getEnhanceUserActionsAndDeliveryNotes(
        this.props.userinfo.userActions,
        this.state.pickedUsers,
        this.props.deliveryNotes.deliveryNotes,
        this.props.deliveryNoteIds,
        UserAction.TYPE.SHARE_DELIVERY_NOTE,
      );

    this.props.saveUserActions(newUserActions);
    this.props.updateDeliveryNotes(updatedDeliveryNotes);

    const [, error2] = await promiseHandler(
      UserService.updateUserActions(newUserActions),
    );

    if (error2) {
      Log.error('Failed to update user actions.', error2);
    }

    this.closeForm();

    // Storing the shared user in the backend is not synchronous. Thus, we need to wait for some time before refreshing.
    await FunctionUtils.timer(300);
    this.init();
  };
  formAbort = () => {
    Log.productAnalyticsEvent(
      'Abort share delivery note form',
      Log.FEATURE.SHARE_DELIVERY_NOTE,
    );

    this.closeForm();
  };

  closeForm() {
    this.setState({
      pickedUsers: [],
      sharedUsers: [],
      submittingForm: false,
    });
    this.props.closeForm();
  }

  handleChange = (pickedUsers) => {
    Log.productAnalyticsEvent(
      'Change users of share delivery note form',
      Log.FEATURE.SHARE_DELIVERY_NOTE,
    );

    this.setState({
      pickedUsers,
    });
  };

  getFormBody() {
    if (this.state.initializing === LOADING_STATE.LOADING) {
      return <Spinner />;
    }

    if (this.state.initializing === LOADING_STATE.FAILED) {
      return <div>Daten konnten nicht geladen werden.</div>;
    }

    return (
      <div className="w-500px">
        <GenericMultiPicker
          startAdornment={
            <InputAdornment position="start">
              <PersonAddIcon />
            </InputAdornment>
          }
          textfieldLabel="Mit welchen Benutzern soll diese Lieferung geteilt werden?"
          pickedItems={this.state.pickedUsers}
          allItems={this.state.sortedUsers}
          callbackPickedItems={this.handleChange}
          fieldName="email"
          loading={this.props.users.usersLoading}
        />
        {this.state.sharedUsers.length > 0 ? (
          <>
            <div className="h-20px" />
            <DeliveryNoteActionMetaData
              title="Bereits geteilt mit:"
              users={this.state.sharedUsers}
            />
          </>
        ) : null}
      </div>
    );
  }

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        title="Lieferung mit einem Benutzer teilen"
        submitButtonTitle="Teilen"
        submittingForm={this.state.submittingForm}
      >
        {this.getFormBody()}
      </BasicForm>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(ShareDeliveryNoteForm),
  null,
);
