import React from 'react';

import { InputLabel, MenuItem, Select, Button } from '@mui/material';

import { connect } from 'react-redux';
import Log from '~/utils/Log';
import PermissionForm from './PermissionForm';
import Permissions from '~/models/masterdata/Permissions';
import PermissionGrantService from '~/services/permissionGrant.service';
import ToastService from '~/services/toast.service';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import { LightTooltip } from '~/utils/componentUtils';
import PermissionGrantMultiPicker from './PermissionGrantMultiPicker';
import PromiseUtils from '~/utils/promiseUtils';
import { promiseHandler } from '~/utils/promiseHandler';

const mapStateToProps = (state) => ({});

class PermissionGrantPickerItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pickedSubjectType: this.props.defaultSubjectType
        ? this.props.defaultSubjectType
        : PermissionGrant.SUBJECT_TYPE.USER.KEY,
      pickedSubjects: this.props.defaultSubjects
        ? this.props.defaultSubjects
        : [],
      pickedEntityType: this.props.defaultEntityType
        ? this.props.defaultEntityType
        : PermissionGrant.ENTITY_TYPE.SITE.KEY,
      pickedEntities: this.props.defaultEntities
        ? this.props.defaultEntities
        : [],
      permissionFormOpen: false,
      permissions: new Permissions(),
    };
  }

  handleChangeSubjectType = (event) => {
    Log.info(
      'Change form value of subject type',
      { from: this.state.pickedSubjectType, to: event.target.value },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change subject type', Log.FEATURE.PERMISSIONS);

    this.setState({
      pickedSubjectType: event.target.value,
      pickedSubjects: [],
    });
  };
  handleChangeSubjects = (event) => {
    const newPickedSubjects = event.map((item) => item.id);

    Log.info(
      'Change form value of subjects',
      { from: this.state.pickedSubjects, to: newPickedSubjects },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change subjects', Log.FEATURE.PERMISSIONS);

    this.setState({
      pickedSubjects: newPickedSubjects,
    });
  };
  handleChangeEntityType = (event) => {
    Log.info(
      'Change form value of entity type',
      { from: this.state.pickedEntityType, to: event.target.value },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change entity type', Log.FEATURE.PERMISSIONS);

    this.setState({
      pickedEntityType: event.target.value,
      pickedEntities: [],
    });
  };
  handleChangeEntities = (event) => {
    const newPickedEntities = event.map((item) => item.id);

    Log.info(
      'Change form value of entities',
      { from: this.state.pickedEntities, to: newPickedEntities },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change entities', Log.FEATURE.PERMISSIONS);

    this.setState({
      pickedEntities: newPickedEntities,
    });
  };
  handleChangeRole = (event) => {
    Log.info(
      'Change form value of role',
      {
        from: this.state.permissions.getDefaultRoleName(),
        to: event.target.value,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change role (' + event.target.value + ')',
      Log.FEATURE.PERMISSIONS,
    );

    const newPermissions = new Permissions();

    if (event.target.value === Permissions.INDIVIDUAL_ROLE) {
      this.setState({
        permissions: new Permissions(),
        permissionFormOpen: true,
      });
      return;
    }

    newPermissions.initWithDefaultRole(event.target.value);

    this.setState({
      permissions: newPermissions,
    });
  };

  async submit() {
    const promises = [];

    if (!this.state.permissions.permissionGranted()) {
      ToastService.warning([
        'Du hast für mindestens einen Benutzer oder eine Benutzer-Gruppe keine Berechtigungen vergeben.',
        'Bitte vergib Berechtigungen, um diese zu speichern.',
      ]);
      Log.productAnalyticsEvent(
        'No user or user group selected',
        Log.FEATURE.PERMISSIONS,
      );
      return;
    }

    for (const subject of this.state.pickedSubjects) {
      for (const entity of this.state.pickedEntities) {
        const body = {
          permissions: this.state.permissions.getBackendPermissions(),
        };

        Log.info(
          'Submit permission grant form',
          body,
          Log.BREADCRUMB.FORM_SUBMIT.KEY,
        );

        const [isDuplicate, error] = await promiseHandler(
          PermissionGrantService.isDuplicatePermissionGrant(
            this.props.fixedPicker ?? PermissionGrant.TYPE.SUBJECT,
            this.state.pickedSubjectType,
            subject,
            this.state.pickedEntityType,
            entity,
            this.state.permissions,
          ),
        );

        if (error) {
          Log.error('Failed to detect duplicate permission grant.', error);
          Log.productAnalyticsEvent(
            'Failed to detect duplicate permission grant',
            Log.FEATURE.PERMISSIONS,
            Log.TYPE.ERROR,
          );
        }

        if (isDuplicate) {
          continue;
        }

        const promise = PermissionGrantService.createNewPermissionGrant(
          this.state.pickedSubjectType,
          subject,
          this.state.pickedEntityType,
          entity,
          body,
        );

        promises.push(promise);
      }
    }

    return PromiseUtils.allResolved(promises);
  }

  openPermissionForm = () => {
    Log.productAnalyticsEvent('Open form', Log.FEATURE.PERMISSIONS);
    this.setState({
      permissionFormOpen: true,
    });
  };
  permissionFormSuccess = (permissions) => {
    this.setState({
      permissionFormOpen: false,
      permissions,
    });
  };
  permissionFormAbort = () => {
    this.setState({
      permissionFormOpen: false,
    });
  };

  render() {
    return (
      <div>
        <div className="flex-s-c gap-20px">
          <div>
            <InputLabel className="text-13px">Berechtigung für</InputLabel>
            <Select
              value={this.state.pickedSubjectType}
              key={0}
              disabled={
                this.props.fixedPicker === PermissionGrant.TYPE.SUBJECT &&
                this.props.defaultSubjects.length > 0
              }
              onChange={this.handleChangeSubjectType}
              className="w-300px"
              size="small"
            >
              {PermissionGrant.getPickableSubjectTypes().map((entity) => (
                <MenuItem value={entity.value} key={entity.value}>
                  {entity.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <PermissionGrantMultiPicker
            type="permission_for"
            subjectType={this.state.pickedSubjectType}
            pickedIds={this.state.pickedSubjects}
            callbackPickedItems={this.handleChangeSubjects}
            disabled={
              this.props.fixedPicker === PermissionGrant.TYPE.SUBJECT &&
              this.props.defaultSubjects.length > 0
            }
            fullWidth
          />
        </div>
        <div className="flex-s-c gap-20px mt-20px">
          <div>
            <InputLabel className="text-13px">Berechtigung auf</InputLabel>
            <Select
              value={this.state.pickedEntityType}
              key={0}
              disabled={
                this.props.fixedPicker === PermissionGrant.TYPE.ENTITY &&
                this.props.defaultEntities.length > 0
              }
              onChange={this.handleChangeEntityType}
              className="w-300px"
              size="small"
            >
              {PermissionGrant.getPickableEntityTypes().map((entity) => (
                <MenuItem value={entity.value} key={entity.value}>
                  {entity.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <PermissionGrantMultiPicker
            type="permission_to"
            entityType={this.state.pickedEntityType}
            pickedIds={this.state.pickedEntities}
            callbackPickedItems={this.handleChangeEntities}
            disabled={
              this.props.fixedPicker === PermissionGrant.TYPE.ENTITY &&
              this.props.defaultEntities.length > 0
            }
            fullWidth
            // Needed for the enhanced label
            subjectType={this.state.pickedSubjectType}
            subjects={this.state.pickedSubjects}
          />
        </div>
        <div className="flex-s-e gap-20px mt-20px">
          <div>
            <InputLabel className="text-13px">Berechtigung als</InputLabel>
            <Select
              value={this.state.permissions.getDefaultRoleName() ?? 'None'} // A random String has to be chosen as fallback value because otherwise renderValue wouldn't catch the case if(!this.state.permissions.permissionGranted())
              key={0}
              onChange={this.handleChangeRole}
              className="w-300px"
              size="small"
              renderValue={(id) => {
                if (!this.state.permissions.permissionGranted()) {
                  return (
                    <span className="text-mui-not-selected-grey">
                      Bitte Berechtigungen vergeben
                    </span>
                  );
                }

                return Permissions.getPickableRoles().find(
                  (option) => option.id === id,
                ).name;
              }}
            >
              {Permissions.getPickableRoles().map((role) => (
                <MenuItem value={role.id} key={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="mb-2px">
            <LightTooltip title="Detaillierte Berechtigungen einsehen">
              <Button
                variant="outlined"
                color="primary"
                onClick={this.openPermissionForm}
              >
                Details
              </Button>
            </LightTooltip>
          </div>
        </div>
        <PermissionForm
          open={this.state.permissionFormOpen}
          formSuccess={this.permissionFormSuccess}
          formAbort={this.permissionFormAbort}
          permissions={this.state.permissions}
        />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  PermissionGrantPickerItem,
);
