import React from 'react';

import { Error as ErrorIcon } from '@mui/icons-material';

import { connect } from 'react-redux';
import Spinner from '../Spinner';
import { LOADING_STATE } from '~/constants/LoadingState';

const mapStateToProps = (state) => ({
  userinfo: state.userinfo,
});

class UnauthorizedPage extends React.Component {
  getMessage() {
    if (
      this.props.userinfo.userPermissionsLoading === LOADING_STATE.NOT_LOADED
    ) {
      return <Spinner title="Berechtigungen werden geladen..." />;
    }

    if (this.props.userinfo.userPermissionsLoading === LOADING_STATE.LOADING) {
      return <Spinner title="Berechtigungen werden geladen..." />;
    }

    if (this.props.userinfo.userPermissionsLoading === LOADING_STATE.FAILED) {
      return (
        <>
          <ErrorIcon className="mr-10px" />
          Berechtigungen konnten nicht geladen werden.
        </>
      );
    }

    return (
      <>
        <ErrorIcon className="mr-10px" />
        Du bist nicht berechtigt, auf diesen Bereich zuzugreifen.
      </>
    );
  }

  render() {
    return <div className="flex-c-c h-full w-full">{this.getMessage()}</div>;
  }
}

export default connect(mapStateToProps)(UnauthorizedPage);
