import React from 'react';
import Spinner from '../../components/Spinner';
import IdsService from '~/services/ids.service';
import BrowserUtils from '~/utils/browserUtils';
import Log from '~/utils/Log';
import { withErrorBoundary } from '~/ui/atoms';

class IdsRedirectPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
    };
  }

  componentDidMount() {
    const queryParams = BrowserUtils.getQueryParamsFromBrowserUrl([
      'company_id',
      'token',
      'action',
    ]);
    const companyId = queryParams.company_id;
    const accessToken = queryParams.token;
    const action = queryParams.action;

    if (!companyId || !accessToken || !action) {
      this.setState({
        errorMessage:
          'Ungültige URL: ids des IDS Shops ("company_id"), access token ("token") und action ("action") werden als query parameter benötigt.',
      });
    }

    IdsService.redirectWithIdsCredentials(companyId, accessToken, action).catch(
      (error) => {
        Log.productAnalyticsEvent(
          'Failed to redirect to shop',
          Log.FEATURE.IDS,
          Log.TYPE.ERROR,
        );
        this.setState({
          errorMessage:
            this.state.errorMessage ??
            'Weiterleitung konnte nicht durchgeführt werden.',
        });
      },
    );
  }

  render() {
    if (this.state.errorMessage) {
      return <div className="h-100vh flex-c-c">{this.state.errorMessage}</div>;
    }

    return (
      <div className="h-100vh">
        <Spinner title="Du wirst weitergeleitet..." />
      </div>
    );
  }
}

export default withErrorBoundary(
  IdsRedirectPage,
  'Weiterleitung konnte nicht durchgeführt werden.',
);
