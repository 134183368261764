import * as React from 'react';

import {
  Archive as ArchiveIcon,
  Clear as ClearIcon,
  DateRange as DateRangeIcon,
} from '@mui/icons-material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { dateUtils } from '~/utils/dateUtils';

import DateRangePicker from './DateRangePicker';
import { useSelector } from 'react-redux';
import ArchivedTableNoticeModal from '~/components/ArchivedTableNoticeModal';
import moment from 'moment';
import DeliveriesService from '~/services/deliveries.service';
import FilterNew from '~/models/filters/FilterNew';
import Log from '~/utils/Log';
import FilterContext from '~/models/filters/FilterContext';
import { TAB } from '~/constants/Tab';
import UserUtils from '~/utils/userUtils';
import { LightTooltipWide } from '~/utils/componentUtils';
import Spinner from '~/components/Spinner';
import { LOADING_STATE } from '~/constants/LoadingState';

const INDIVIDUAL = 'individual';
const PREDEFINED_DATE_RANGE_OPTIONS = [
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.DAY.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.DAY.STRING,
  },
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.YESTERDAY.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.YESTERDAY.STRING,
  },
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.WEEK.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.WEEK.STRING,
  },
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.LAST_WEEK.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.LAST_WEEK.STRING,
  },
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.MONTH.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.MONTH.STRING,
  },
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.LAST_MONTH.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.LAST_MONTH.STRING,
  },
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.YEAR.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS.YEAR.STRING,
  },
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS._7DAYS.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS._7DAYS.STRING,
  },
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS._30DAYS.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS._30DAYS.STRING,
  },
  {
    id: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS._365DAYS.ID,
    name: dateUtils.PREDEFINED_DATE_RANGE_OPTIONS._365DAYS.STRING,
  },
  { id: INDIVIDUAL, name: null },
];

const DateRangeSelect = (props) => {
  const {
    archiveMode,
    dateRange,
    disabled,
    displayArchiveModeIcon,
    displayLabel,
    individualDateRange,
    onDateRangeChange,
    onPredefinedDateRangeChange,
    page,
    predefinedDateRange,
    suppressArchiveModal,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = React.useState(false);
  const [nonApplicableFilters, setNonApplicableFilters] = React.useState([]);
  const [temporaryDateChange, setTemporaryDateChange] = React.useState(null);
  const [isDateRangeClicked, setIsDateRangeClicked] = React.useState(false);
  const [localDateRange, setLocalDateRange] = React.useState(null);
  const oldestFilteredDlnDate = useSelector(
    (state) => state.filters.oldestFilteredDlnDate,
  );
  const oldestFilteredDlnDateLoading = useSelector(
    (state) => state.filters.oldestFilteredDlnDateLoading,
  );
  const selectedDeliveryTab = useSelector(
    (state) => state.filters.delivery_tab,
  );

  React.useEffect(() => {
    setLocalDateRange(dateRange);
  }, [dateRange]);

  // Check if the archive mode is already active.
  // In this case, we don't need to display the ArchivedTableNoticeModal again.
  const isArchiveMode = () => {
    return DeliveriesService.isArchiveMode(dateRange);
  };

  const handlePredefinedDateRangeChange = (entityName) => {
    const entityId = PREDEFINED_DATE_RANGE_OPTIONS.find(
      (option) => option.id === entityName,
    )?.id;
    const isDateBeforeOldestDlnDate = dateUtils.isDlnDateOlderThanDateRange(
      entityId,
      new Date(oldestFilteredDlnDate),
    );
    // Only display the ArchivedTableNoticeModal if the following conditions are fulfilled:
    // archiveMode: The parent component implements the archive function at all.
    // isDateBeforeOldestDlnDate: The selected date range is old enough to "access the archive".
    // !isArchiveMode(): The archive mode is not already active as in this case the user is already loading data from the archive.
    // !shouldSuppressArchiveModal: E.g. the home screen doesn't want the ArchivedTableNoticeModal to be displayed.
    if (
      archiveMode &&
      isDateBeforeOldestDlnDate &&
      !isArchiveMode() &&
      !shouldSuppressArchiveModal()
    ) {
      triggerModal(entityName);
      return;
    }

    onPredefinedDateRangeChange(entityId);
  };

  const handleLeaveArchiveMode = () => {
    const defaultDateRange = dateUtils.PREDEFINED_DATE_RANGE_OPTIONS._7DAYS.ID;
    handlePredefinedDateRangeChange(defaultDateRange);
  };

  const handleIndividualDateRangeChange = (date) => {
    const oldestDlnDate = new Date(oldestFilteredDlnDate);
    const oldestDlnDateWithinSelectedDateRange = moment(date[0]).isBefore(
      oldestDlnDate,
    );

    // We only want to change the actual date range in case of the archive mode, if the user submits the ArchivedTableNoticeModal.
    // Thus, we need a date range only inside the DateRangeSelect that is displayed in the picker but not applied to the parent component.
    setLocalDateRange(date);

    if (
      archiveMode &&
      oldestDlnDateWithinSelectedDateRange &&
      !isArchiveMode() &&
      !shouldSuppressArchiveModal()
    ) {
      setIsDateRangeClicked(true);
      triggerModal(date);
      return;
    }

    onDateRangeChange(date);
  };

  const triggerModal = (dateValue) => {
    const activeFilters = FilterNew.getActiveFiltersForPage(page);
    const nonApplicableFilters =
      FilterNew.getNonApplicableFilters(activeFilters);
    const filterStrings = nonApplicableFilters.map(
      (filter) => filter.filterProps.filterString,
    );
    setTemporaryDateChange(dateValue);
    setNonApplicableFilters(filterStrings);
    setArchiveModalOpen(true);
  };

  const handleOnArchivedModalReject = () => {
    Log.info('Reject archive modal', null, Log.BREADCRUMB.USER_ACTION.KEY);
    Log.productAnalyticsEvent('Reject archive modal', Log.FEATURE.ARCHIVE_MODE);

    if (isDateRangeClicked) {
      setLocalDateRange(dateRange);
      onDateRangeChange(dateRange);
      setIsDateRangeClicked(false);
    }

    setArchiveModalOpen(false);
  };

  const handleOnArchivedModalAccept = () => {
    if (getIsUserDisabled() || getIsFeatureDisabled()) {
      setLocalDateRange(dateRange);
      onDateRangeChange(dateRange);
      setIsDateRangeClicked(false);
      setArchiveModalOpen(false);
      return;
    }

    Log.info('Accept archive modal', null, Log.BREADCRUMB.USER_ACTION.KEY);
    Log.productAnalyticsEvent('Accept archive modal', Log.FEATURE.ARCHIVE_MODE);

    if (isDateRangeClicked) {
      onDateRangeChange(temporaryDateChange);
    } else {
      onPredefinedDateRangeChange(temporaryDateChange);
    }

    setArchiveModalOpen(false);
    setIsDateRangeClicked(false);
    FilterNew.removeNonApplicableBackendFilters(page);
  };

  const getIsFeatureDisabled = () => {
    const isUserMetricsPage = page === FilterContext.PAGE.USER_METRICS;
    const isDeliveryPage = page === FilterContext.PAGE.DELIVERY;
    const isUnassignedDeliveryNotesTab =
      selectedDeliveryTab === TAB.DELIVERY.UNASSIGNED_DELIVERY_NOTES.INDEX;

    return (
      isUserMetricsPage || (isDeliveryPage && isUnassignedDeliveryNotesTab)
    );
  };

  // Determine if the user is not allowed to access the archive mode.
  const getIsUserDisabled = () => {
    return !UserUtils.isArchiveModeAllowedUser();
  };

  // If the user is not allowed to access the archive mode, we want to disable the ArchivedTableNoticeModal with
  // the specific hint that the archive is not accessible for now.
  const shouldSuppressArchiveModal = () => {
    return suppressArchiveModal && !getIsUserDisabled();
  };

  const isLoading = () => {
    return (
      archiveMode && oldestFilteredDlnDateLoading === LOADING_STATE.LOADING
    );
  };

  const renderValue = (id) => {
    let component = null;

    if (id === INDIVIDUAL) {
      component = (
        <div>
          {dateUtils.getFormattedDate(
            dateRange[0],
            dateUtils.DATE_FORMAT.DD_MM_YYYY,
          ) +
            ' - ' +
            dateUtils.getFormattedDate(
              dateRange[1],
              dateUtils.DATE_FORMAT.DD_MM_YYYY,
            )}
        </div>
      );
    } else {
      component = (
        <div>
          {
            PREDEFINED_DATE_RANGE_OPTIONS.find((option) => option.id === id)
              ?.name
          }
        </div>
      );
    }

    if (isLoading()) {
      return (
        <div className="text-10px mt--10px mb--10px">
          <div className="mt-3px bold text-center">{component}</div>
          <div className="mt--10px">
            <Spinner size={5} title="Archiv wird initialisiert..." />
          </div>
        </div>
      );
    }

    return component;
  };

  return (
    <FormControl>
      {displayLabel ? (
        <InputLabel id="date-range-label">Zeitraum</InputLabel>
      ) : null}
      <ArchivedTableNoticeModal
        onReject={handleOnArchivedModalReject}
        onAccept={handleOnArchivedModalAccept}
        open={archiveModalOpen}
        // Manually disabled the "Nicht zugeordnet" feature because it leads to some bugs. Should be fixed in the future.
        isFeatureDisabled={getIsFeatureDisabled()}
        // Only allow some accounts to access the archive mode due to migration.
        isUserDisabled={getIsUserDisabled()}
        nonApplicableFilters={nonApplicableFilters}
      />
      <div className="flex-s-c gap-20px">
        <Select
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          className="w-260px bg-white"
          size="small"
          labelId="date-range-label"
          label={displayLabel ? 'Zeitraum' : null}
          id="date-range-select"
          value={individualDateRange ? INDIVIDUAL : predefinedDateRange}
          renderValue={renderValue}
          key="date-range-select-0"
          onChange={(event) => {
            handlePredefinedDateRangeChange(event.target.value);
          }}
          disabled={disabled || isLoading()}
          startAdornment={
            disabled || isLoading() ? null : (
              <DateRangeIcon
                className="mr-10px text-grey600 cursor-pointer"
                onClick={() => setOpen(true)}
                disabled={disabled || isLoading()}
              />
            )
          }
        >
          {PREDEFINED_DATE_RANGE_OPTIONS.map((entity) => {
            if (entity.id === INDIVIDUAL) {
              return (
                <div className="pl-10px pr-10px pt-10px" key={entity.id}>
                  <DateRangePicker
                    onDateRangeChange={handleIndividualDateRangeChange}
                    dateRange={localDateRange ?? dateRange}
                    disabled={disabled}
                    archiveMode={archiveMode}
                    oldestFilteredDlnDate={oldestFilteredDlnDate}
                  />
                </div>
              );
            }

            const accessesArchiveMode =
              archiveMode &&
              oldestFilteredDlnDate &&
              dateUtils.isDlnDateOlderThanDateRange(
                entity.id,
                new Date(oldestFilteredDlnDate),
              );

            return (
              <MenuItem
                value={entity.id}
                key={entity.id}
                className="flex-sb-c border-bottom-grey900 w-95-percent ml-6px pb-8px pl-8px pr-0 pt-0"
              >
                <p style={{ margin: '10px 0 6px' }}>{entity.name}</p>
                {accessesArchiveMode && (
                  <span className="flex-sb-c px-7px py-6px secondary-background self-baseline rounded-b-sm">
                    <ArchiveIcon
                      style={{ color: 'var(--grey700)', marginRight: '5px' }}
                      fontSize="small"
                    />
                    <div className="text-12px">Archiv</div>
                  </span>
                )}
              </MenuItem>
            );
          })}
        </Select>
        {displayArchiveModeIcon && isArchiveMode() && (
          <LightTooltipWide title="Du greifst auf das Archiv zu. Eventuell stehen nicht alle Funktionen zur Verfügung. Klicke hier zum Verlassen des Archivmodus.">
            <div
              className="p-8px rounded-8px secondary-background flex-s-c cursor-pointer"
              onClick={handleLeaveArchiveMode}
            >
              <ArchiveIcon
                style={{ color: 'var(--grey700)', marginRight: '5px' }}
              />
              Archiv
              <ClearIcon style={{ marginLeft: '8px' }} />
            </div>
          </LightTooltipWide>
        )}
      </div>
    </FormControl>
  );
};

export default DateRangeSelect;
