import Address from './Address';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import TradeContact from './TradeContact';
import PermissionGrant from './PermissionGrant';
import ArrayUtils from '~/utils/arrayUtils';
import SiteReference from './SiteReference';
import Coordinates from './Coordinates';
import CSVValidator from './CSVValidator';

export default class Site {
  constructor(site, additionalDataInitiated) {
    this.id = site?.id ?? null;
    this.issuerAssignedId = site?.issuer_assigned_id ?? null;
    this.name = !site?.name || site?.name === 'null' ? '' : site?.name;
    this.type = site?.type ?? Site.TYPE.CONSTRUCTION_SITE.KEY;
    this.active = site?.is_active ?? true;
    this.address = new Address(site?.address);
    this.coords = new Coordinates(site?.coords);
    this.costCenters = site?.accounting_reference_list ?? [];
    this.company = site?.company_id ?? '';
    this.tradeContact = new TradeContact(site?.trade_contact);
    this.references = site?.identifier_list
      ? site.identifier_list.map((item) => new SiteReference(item))
      : [];
    this.start = site?.start || null;
    this.end = site?.end || null;

    this.organisationalGroups = site?.org_units ?? [];
    this.organisationalGroupPaths = site?.org_unit_paths ?? [];

    this.permissionGrantsFrom =
      site?.permissions_from?.map(
        (permissionGrant) =>
          new PermissionGrant({
            ...permissionGrant,
            target_type: PermissionGrant.ENTITY_TYPE.SITE.KEY,
            target_id: this.id,
          }),
      ) ?? [];

    // This variable is used to track whether additional data such as permissions and paths are already initiated.
    // In the future, this data will not be provided anymore by the bulk endpoint and must be loaded separately when the user opens the respective master data form.
    this.additionalDataInitiated = additionalDataInitiated;

    // Workaround: Prevent the paths to be loaded when the GET /all endpoint is called as the paths are currently still provided and cause visual bugs.
    if (!this.additionalDataInitiated) {
      this.organisationalGroupPaths = [];
    }
  }

  static getSiteType(typeKey) {
    const siteType = Object.keys(Site.TYPE).find(
      (x) => Site.TYPE[x].KEY === typeKey,
    );

    if (!siteType) {
      throw new EnumValueNotFoundException('invalid site type: ' + typeKey);
    }

    return Site.TYPE[siteType].STRING;
  }

  static getSiteTypes() {
    return Object.keys(Site.TYPE).map((x) => {
      return {
        id: Site.TYPE[x].KEY,
        name: Site.TYPE[x].STRING,
      };
    });
  }

  static getDifferentValues(siteA, siteB) {
    const differentValues = [];

    if (siteA?.id !== siteB?.id) {
      differentValues.push('ID');
    }

    if (siteA?.name !== siteB?.name) {
      differentValues.push('Name');
    }

    if (siteA?.type !== siteB?.type) {
      differentValues.push('Art');
    }

    if (siteA?.active !== siteB?.active) {
      differentValues.push('Aktiv');
    }

    if (siteA?.company !== siteB?.company) {
      differentValues.push('Firma');
    }

    if (Address.getDifferentValues(siteA?.address, siteB?.address).length > 0) {
      differentValues.push('Adresse');
    }

    if (
      Coordinates.getDifferentValues(siteA?.coords, siteB?.coords).length > 0
    ) {
      differentValues.push('Koordinaten');
    }

    // Difference check for trade contact is not needed currently as it can't be specified in site form.

    const differentCostCenters = ArrayUtils.getDifference(
      siteA?.costCenters,
      siteB?.costCenters,
    );
    if (
      differentCostCenters[0].length > 0 ||
      differentCostCenters[1].length > 0
    ) {
      differentValues.push('Kostenstellen');
    }

    const differentOrganisationalGroups = ArrayUtils.getDifference(
      siteA?.organisationalGroups,
      siteB?.organisationalGroups,
    );
    if (
      differentOrganisationalGroups[0].length > 0 ||
      differentOrganisationalGroups[1].length > 0
    ) {
      differentValues.push('Organisations-Gruppen');
    }

    return differentValues;
  }

  static IS_ACTIVE = {
    YES: 'Ja',
    NO: 'Nein',
  };
  static TYPE = {
    CONSTRUCTION_SITE: {
      KEY: 'construction_site',
      STRING: 'Baustelle',
    },
    PLANT: {
      KEY: 'plant',
      STRING: 'Werk',
    },
    WAREHOUSE: {
      KEY: 'warehouse',
      STRING: 'Lager',
    },
    DISPOSAL: {
      KEY: 'disposal',
      STRING: 'Entsorgung',
    },
    FACTORY: {
      KEY: 'factory',
      STRING: 'Fabrik',
    },
  };
  static CSV_ALLOWED_COLUMNS = [
    {
      NAME: 'latitude',
      DATA_TYPE: CSVValidator.DATA_TYPE.NUMBER,
    },
    {
      NAME: 'longitude',
      DATA_TYPE: CSVValidator.DATA_TYPE.NUMBER,
    },
    {
      NAME: 'city',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'country',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'post_code',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'street_name',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'building_number',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'accounting_reference_list',
      DATA_TYPE: CSVValidator.DATA_TYPE.UUID_LIST,
    },
    {
      NAME: 'name',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'type',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'company_id',
      DATA_TYPE: CSVValidator.DATA_TYPE.UUID,
    },
    {
      NAME: 'org_units',
      DATA_TYPE: CSVValidator.DATA_TYPE.UUID_LIST,
    },
  ];
  static CSV_WITH_COST_CENTERS_ALLOWED_COLUMNS = [
    {
      NAME: 'cost_centers',
      DATA_TYPE: CSVValidator.DATA_TYPE.LIST,
    },
    {
      NAME: 'name',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'city',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'country',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'post_code',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'street_name',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'building_number',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'company_id',
      DATA_TYPE: CSVValidator.DATA_TYPE.UUID,
    },
    {
      NAME: 'org_units',
      DATA_TYPE: CSVValidator.DATA_TYPE.UUID_LIST,
    },
  ];
}
