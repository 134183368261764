import React from 'react';

import { InputLabel, MenuItem, Select, Button } from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import Log from '~/utils/Log';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import Permissions from '~/models/masterdata/Permissions';
import PermissionGrantMultiPicker from '../../permissionGrant/PermissionGrantMultiPicker';
import { LightTooltip } from '~/utils/componentUtils';
import PermissionForm from '../../permissionGrant/PermissionForm';
import ToastService from '~/services/toast.service';
import PermissionGrantService from '~/services/permissionGrant.service';
import PromiseUtils from '~/utils/promiseUtils';
import { promiseHandler } from '~/utils/promiseHandler';
import UserService from '~/services/user.service';
import ArrayUtils from '~/utils/arrayUtils';
import SiteService from '~/services/site.service';

class UpdatePermissionsWizardPermissionGrantPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pickedSubjectType: PermissionGrant.SUBJECT_TYPE.USER.KEY,
      pickedSubjects: [],
      pickedEntityType: PermissionGrant.ENTITY_TYPE.SITE.KEY,
      pickedEntities: [],
      permissionFormOpen: false,
      permissions: new Permissions(),
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.initRole();
  }

  // Automatically select a role as default based on the roles that are already assigned to the users whose permissions should be updated.
  async initRole() {
    if (this.state.permissions.permissionGranted()) {
      return;
    } // Don't overwrite if the user has already specified a set of permissions.

    if (!this.state.pickedSubjects[0]) {
      return;
    } // No need to search for the most frequent role if no subject is selected yet.

    const [subject, error] = await promiseHandler(
      UserService.getUser(this.state.pickedSubjects[0], true),
    );

    const roles = subject.permissionGrantsOn.map((permissionGrant) =>
      permissionGrant.getDefaultRoleName(),
    );
    // Ignore Mitarbeiter as we assume that users don't want to assign Mitarbeiter role in the daily usage.
    const filteredRoles = roles.filter(
      (role) => role !== Permissions.DEFAULT_ROLE.EMPLOYEE.NAME,
    );
    const mostFrequentRole = ArrayUtils.getMostFrequentValue(filteredRoles);

    // Ignore Individuell as we assume that users don't want to assign individual permissions in the daily usage.
    if (mostFrequentRole === Permissions.INDIVIDUAL_ROLE) {
      return;
    }

    const newPermissions = new Permissions();
    newPermissions.initWithDefaultRole(mostFrequentRole);

    this.setState({
      permissions: newPermissions,
    });
  }

  handleChangeSubjectType = (event) => {
    Log.info(
      'Change form value of subject type',
      { from: this.state.pickedSubjectType, to: event.target.value },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change subject type in update permissions wizard',
      Log.FEATURE.WIZARD,
    );

    this.setState({
      pickedSubjectType: event.target.value,
      pickedSubjects: [],
    });
  };
  handleChangeSubjects = (event) => {
    const newPickedSubjects = event.map((item) => item.id);

    Log.info(
      'Change form value of subjects',
      { from: this.state.pickedSubjects, to: newPickedSubjects },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change subjects in update permissions wizard',
      Log.FEATURE.WIZARD,
    );

    this.setState({
      pickedSubjects: newPickedSubjects,
    });

    // We want to prefill the user list in the deletion step with the user from the grating step.
    // Currently, commented out though because this seems to be a bit misleading.
    // this.props.setPickedSubjects(ArrayUtils.removeDuplicates([...this.props.pickedSubjects, ...newPickedSubjects]));
  };
  handleChangeEntityType = (event) => {
    Log.info(
      'Change form value of entity type',
      { from: this.state.pickedEntityType, to: event.target.value },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change entity type', Log.FEATURE.WIZARD);

    this.setState({
      pickedEntityType: event.target.value,
      pickedEntities: [],
    });
  };
  handleChangeEntities = (event) => {
    const newPickedEntities = event.map((item) => item.id);

    Log.info(
      'Change form value of entities',
      { from: this.state.pickedEntities, to: newPickedEntities },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change entities', Log.FEATURE.WIZARD);

    this.setState({
      pickedEntities: newPickedEntities,
    });
  };
  handleChangeRole = (event) => {
    Log.info(
      'Change form value of role',
      {
        from: this.state.permissions.getDefaultRoleName(),
        to: event.target.value,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change role (' + event.target.value + ') in update permissions wizard',
      Log.FEATURE.WIZARD,
    );

    const newPermissions = new Permissions();

    if (event.target.value === Permissions.INDIVIDUAL_ROLE) {
      this.setState({
        permissionFormOpen: true,
      });
      return;
    }

    newPermissions.initWithDefaultRole(event.target.value);

    this.setState({
      permissions: newPermissions,
    });
  };
  openPermissionForm = () => {
    Log.productAnalyticsEvent(
      'Open permission form in update permissions wizard',
      Log.FEATURE.WIZARD,
    );
    this.setState({
      permissionFormOpen: true,
    });
  };
  permissionFormSuccess = (permissions) => {
    this.setState({
      permissionFormOpen: false,
      permissions,
    });
  };
  permissionFormAbort = () => {
    this.setState({
      permissionFormOpen: false,
    });
  };

  async submit(grantPermissionsOnCostCenters) {
    const promises = [];

    if (!this.state.permissions.permissionGranted()) {
      return;
    }

    for (const subject of this.state.pickedSubjects) {
      for (const entity of this.state.pickedEntities) {
        const body = {
          permissions: this.state.permissions.getBackendPermissions(),
        };

        Log.info(
          'Submit permission grant form',
          body,
          Log.BREADCRUMB.FORM_SUBMIT.KEY,
        );

        const [isDuplicate, error] = await promiseHandler(
          PermissionGrantService.isDuplicatePermissionGrant(
            PermissionGrant.TYPE.SUBJECT,
            this.state.pickedSubjectType,
            subject,
            this.state.pickedEntityType,
            entity,
            this.state.permissions,
          ),
        );

        if (error) {
          Log.error('Failed to detect duplicate permission grant.', error);
          Log.productAnalyticsEvent(
            'Failed to detect duplicate permission grant',
            Log.FEATURE.PERMISSIONS,
            Log.TYPE.ERROR,
          );
        }

        if (isDuplicate) {
          continue;
        }

        const promise = PermissionGrantService.createNewPermissionGrant(
          this.state.pickedSubjectType,
          subject,
          this.state.pickedEntityType,
          entity,
          body,
        );

        promises.push(promise);
      }
    }

    if (
      grantPermissionsOnCostCenters &&
      this.state.pickedEntityType === PermissionGrant.ENTITY_TYPE.SITE.KEY
    ) {
      for (let index = 0; index < this.state.pickedEntities.length; index++) {
        const [site, error] = await promiseHandler(
          SiteService.getSiteById(this.state.pickedEntities[index]),
        );

        if (error) {
          Log.error(
            'Failed to load cost center. id: ' +
              this.state.pickedEntities[index],
          );
          ToastService.error([
            'Berechtigungen konnten nicht auf alle Kostenstellen vollständig vergeben werden.',
          ]);
          continue;
        }

        for (const subject of this.state.pickedSubjects) {
          for (const costCenterId of site.costCenters) {
            const body = {
              permissions: this.state.permissions.getBackendPermissions(),
            };

            Log.info(
              'Submit permission grant form',
              body,
              Log.BREADCRUMB.FORM_SUBMIT.KEY,
            );

            const [isDuplicate, error] = await promiseHandler(
              PermissionGrantService.isDuplicatePermissionGrant(
                PermissionGrant.TYPE.SUBJECT,
                this.state.pickedSubjectType,
                subject,
                PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY,
                costCenterId,
                this.state.permissions,
              ),
            );

            if (error) {
              Log.error('Failed to detect duplicate permission grant.', error);
              Log.productAnalyticsEvent(
                'Failed to detect duplicate permission grant',
                Log.FEATURE.PERMISSIONS,
                Log.TYPE.ERROR,
              );
            }

            if (isDuplicate) {
              continue;
            }

            const promise = PermissionGrantService.createNewPermissionGrant(
              this.state.pickedSubjectType,
              subject,
              PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY,
              costCenterId,
              body,
            );

            promises.push(promise);
          }
        }
      }
    }

    return PromiseUtils.allResolved(promises);
  }

  render() {
    return (
      <>
        <div className="flex-s-c gap-20px mt-20px">
          <div>
            <InputLabel className="text-13px">Berechtigung für</InputLabel>
            <Select
              value={this.state.pickedSubjectType}
              onChange={this.handleChangeSubjectType}
              className="w-300px"
              size="small"
              // If changing permissions for user groups should be possible in the wizard, the whole logic becomes a bit more complex.
              // Thus, we disable it for the moment. It should be possible in the future though.
              disabled
            >
              {PermissionGrant.getPickableSubjectTypes().map((subjectType) => (
                <MenuItem value={subjectType.value} key={subjectType.value}>
                  {subjectType.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <PermissionGrantMultiPicker
            type="permission_for"
            subjectType={this.state.pickedSubjectType}
            pickedIds={this.state.pickedSubjects}
            callbackPickedItems={this.handleChangeSubjects}
            fullWidth
          />
        </div>
        <div className="flex-s-c gap-20px mt-20px">
          <div>
            <InputLabel className="text-13px">Berechtigung auf</InputLabel>
            <Select
              value={this.state.pickedEntityType}
              key={0}
              onChange={this.handleChangeEntityType}
              className="w-300px"
              size="small"
            >
              {PermissionGrant.getPickableEntityTypes().map((entity) => (
                <MenuItem value={entity.value} key={entity.value}>
                  {entity.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <PermissionGrantMultiPicker
            type="permission_to"
            entityType={this.state.pickedEntityType}
            pickedIds={this.state.pickedEntities}
            callbackPickedItems={this.handleChangeEntities}
            fullWidth
            // Needed for the enhanced label
            subjectType={this.state.pickedSubjectType}
            subjects={this.state.pickedSubjects}
            displayPermissionGrantOfCostCentersWithSites={
              this.props.grantPermissionsOnCostCenters
            }
          />
        </div>
        <div className="flex-s-e gap-20px mt-20px">
          <div>
            <InputLabel className="text-13px">Berechtigung als</InputLabel>
            <Select
              value={this.state.permissions.getDefaultRoleName() ?? 'None'} // A random String has to be chosen as fallback value because otherwise renderValue wouldn't catch the case if(!this.state.permissions.permissionGranted())
              key={0}
              onChange={this.handleChangeRole}
              className="w-300px"
              size="small"
              renderValue={(id) => {
                if (!this.state.permissions.permissionGranted()) {
                  return (
                    <span className="text-mui-not-selected-grey">
                      Bitte Berechtigungen vergeben
                    </span>
                  );
                }

                return Permissions.getPickableRoles().find(
                  (option) => option.id === id,
                ).name;
              }}
            >
              {Permissions.getPickableRoles().map((role) => (
                <MenuItem value={role.id} key={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="mb-2px">
            <LightTooltip title="Detaillierte Berechtigungen einsehen">
              <Button
                variant="outlined"
                color="primary"
                onClick={this.openPermissionForm}
              >
                Details
              </Button>
            </LightTooltip>
          </div>
        </div>
        <PermissionForm
          open={this.state.permissionFormOpen}
          formSuccess={this.permissionFormSuccess}
          formAbort={this.permissionFormAbort}
          permissions={this.state.permissions}
        />
        <br />
        <br />
        <br />
        <br />
      </>
    );
  }
}

export default withErrorBoundary(
  UpdatePermissionsWizardPermissionGrantPicker,
  'Daten konnten nicht geladen werden.',
);
