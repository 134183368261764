import Spinner from '~/components/Spinner';
import DataExchanges from './DataExchanges';
import { Suppliers } from '~/components/suppliers/Suppliers';
import { useSelector } from 'react-redux';

import FeatureService from '~/services/feature.service';

const DataExchangePage = () => {
  const companyAccount = useSelector(
    (state) => state?.companyAccount?.companyAccount,
  );

  /**
   * We don't want to show the old data exchange page whilst we are loading the company account to determine the feature flag.
   * This would only happen when you reload on the data exchange page.
   */
  const showDataExchanges = Boolean(companyAccount?.id);
  if (!showDataExchanges) {
    return <Spinner />;
  }

  const showNewKanbanView = FeatureService.supplierKanban();

  return showNewKanbanView ? <Suppliers /> : <DataExchanges />;
};

export default DataExchangePage;
