import React from 'react';

import {
  Add as AddIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { Button } from '@mui/material';

import { connect } from 'react-redux';
import Log from '~/utils/Log';
import Spinner from '~/components/Spinner';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import ChipList from '../../chips/ChipList';
import HeaderAccordion from '../../accordion/HeaderAccordion';
import ArrayUtils from '~/utils/arrayUtils';
import AddItemModal from '~/components/AddItemModal';
import SquaredIconButton from '../../iconButtons/SquaredIconButton';
import FreeTextSearch from '../FreeTextSearch';
import Hint from '../../hint/Hint';
import { LOADING_STATE } from '~/constants/LoadingState';

const mapStateToProps = (state) => ({
  users: state.users,
  sites: state.sites,
  costCenters: state.costCenters,
  vehicles: state.vehicles,
  companies: state.companies,
  organisationalGroups: state.organisationalGroups,
  userGroups: state.userGroups,
});

class ComplexPaginatedEntityMultiPicker extends React.Component {
  constructor(props) {
    super(props);

    this.PAGINATE_BY = 20;

    this.state = {
      textfieldLabel: '',
      fieldName: 'name',
      allItems: [], // All items from the store
      pickedItems: [], // The items that are picked. Determined via this.props.pickedIds.
      displayedItems: [], // The items that are displayed to the user depending on the free text search, the pagination offset and the added and removed items.
      editedItems: [], // The items that are selected. Determined in real-time also in the edit mode. Variable is needed to display the correct items in the edit mode.
      addedItems: [], // The items that have been added by the user. Variable is needed to display added items with the "Neu" tag.
      removedItems: [], // The items that have been removed by the user. Variable is needed to track added items correctly.
      paginationOffset: this.PAGINATE_BY,
      expanded: false,
      inEditMode: false,
      // isLoading: false,
      nameFilter: '',
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.entityType !== prevProps.entityType ||
      this.getLoadingStateFromProps() !==
        this.getLoadingStateFromPrevProps(prevProps)
    ) {
      this.init();
    }

    if (
      JSON.stringify(this.props.pickedIds) !==
        JSON.stringify(prevProps.pickedIds) ||
      JSON.stringify(this.state.allItems) !== JSON.stringify(prevState.allItems)
    ) {
      this.initPickedItems();
    }

    if (
      JSON.stringify(this.state.editedItems) !==
        JSON.stringify(prevState.editedItems) ||
      JSON.stringify(this.state.nameFilter) !==
        JSON.stringify(prevState.nameFilter) ||
      JSON.stringify(this.state.paginationOffset) !==
        JSON.stringify(prevState.paginationOffset)
    ) {
      // If the free text filter has been changed, the pagination chip has been clicked or items have been added or removed,
      // it must be recalculated which items to display.
      this.initDisplayedItems();
    }

    if (
      (this.state.addedItems.length !== prevState.addedItems.length ||
        this.state.removedItems.length !== prevState.removedItems.length) &&
      this.props.onUpdatedItemsChange
    ) {
      this.props.onUpdatedItemsChange([
        ...this.state.addedItems,
        ...this.state.removedItems,
      ]);
    }
  }

  getLoadingStateFromProps() {
    switch (this.props.entityType) {
      case PermissionGrant.ENTITY_TYPE.USER.KEY: {
        return this.props.users.usersLoading;
      }

      case PermissionGrant.ENTITY_TYPE.SITE.KEY: {
        return this.props.sites.sitesLoading;
      }

      case PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY: {
        return this.props.costCenters.costCentersLoading;
      }

      case PermissionGrant.ENTITY_TYPE.VEHICLE.KEY: {
        return this.props.vehicles.vehiclesLoading;
      }

      case PermissionGrant.ENTITY_TYPE.COMPANY.KEY: {
        return this.props.companies.companiesLoading;
      }

      case PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY: {
        return this.props.organisationalGroups.organisationalGroupsLoading;
      }

      case PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY: {
        return this.props.userGroups.userGroupsLoading;
      }

      default: {
        Log.error(
          null,
          new EnumValueNotFoundException(
            'Invalid entity type: ' + this.props.entityType,
          ),
        );
      }
    }
  }

  getLoadingStateFromPrevProps(prevProps) {
    switch (this.props.entityType) {
      case PermissionGrant.ENTITY_TYPE.USER.KEY: {
        return prevProps.users.usersLoading;
      }

      case PermissionGrant.ENTITY_TYPE.SITE.KEY: {
        return prevProps.sites.sitesLoading;
      }

      case PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY: {
        return prevProps.costCenters.costCentersLoading;
      }

      case PermissionGrant.ENTITY_TYPE.VEHICLE.KEY: {
        return prevProps.vehicles.vehiclesLoading;
      }

      case PermissionGrant.ENTITY_TYPE.COMPANY.KEY: {
        return prevProps.companies.companiesLoading;
      }

      case PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY: {
        return prevProps.organisationalGroups.organisationalGroupsLoading;
      }

      case PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY: {
        return prevProps.userGroups.userGroupsLoading;
      }

      default: {
        Log.error(
          null,
          new EnumValueNotFoundException(
            'Invalid entity type: ' + this.props.entityType,
          ),
        );
      }
    }
  }

  init() {
    let textfieldLabel;
    let fieldName;
    let allItems;

    switch (this.props.entityType) {
      case PermissionGrant.ENTITY_TYPE.USER.KEY: {
        textfieldLabel = 'Benutzer';
        fieldName = 'email';
        allItems = this.props.users.users
          .filter((user) => user.active)
          .map((user) => {
            return { ...user, name: user.email };
          }); // Needed to display the user email in the subcomponents correctly
        break;
      }

      case PermissionGrant.ENTITY_TYPE.SITE.KEY: {
        textfieldLabel = 'Standorte';
        fieldName = 'name';
        allItems = this.props.sites.sites.filter((site) => site.active);
        break;
      }

      case PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY: {
        textfieldLabel = 'Kostenstellen';
        fieldName = 'name';
        allItems = this.props.costCenters.costCenters.filter(
          (costCenter) => costCenter.active,
        );
        break;
      }

      case PermissionGrant.ENTITY_TYPE.VEHICLE.KEY: {
        textfieldLabel = 'Fahrzeuge';
        fieldName = 'licensePlateName';
        allItems = this.props.vehicles.vehicles.map((vehicle) => {
          return {
            ...vehicle,
            licensePlateName: vehicle.licensePlate.name,
            name: vehicle.licensePlate.name,
          };
        }); // Needed to display the license plate in the subcomponents correctly
        break;
      }

      case PermissionGrant.ENTITY_TYPE.COMPANY.KEY: {
        textfieldLabel = 'Firmen';
        fieldName = 'name';
        allItems = this.props.companies.companies;
        break;
      }

      case PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY: {
        textfieldLabel = 'Organisations-Gruppen';
        fieldName = 'name';
        allItems = this.props.organisationalGroups.organisationalGroups;
        break;
      }

      case PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY: {
        textfieldLabel = 'Benutzer-Gruppen';
        fieldName = 'name';
        allItems = this.props.userGroups.userGroups;
        break;
      }

      default: {
        Log.error(
          null,
          new EnumValueNotFoundException(
            'Invalid entity type: ' + this.props.entityType,
          ),
        );
        return;
      }
    }

    this.setState({
      textfieldLabel,
      fieldName,
      allItems,
    });
  }

  initPickedItems() {
    let pickedItems = [];

    for (const pickedId of this.props.pickedIds) {
      const pickedItem = this.state.allItems.find(
        (item) => item.id === pickedId,
      );
      if (pickedItem) {
        pickedItems.push(pickedItem);
      }
    }

    pickedItems = ArrayUtils.sortStringArrayByKey(
      pickedItems,
      this.state.fieldName,
    );

    this.setState({
      pickedItems,
      editedItems: pickedItems, // Per default, the items that are displayed in the edit mode, are the same as the picked ones.
    });
  }

  // The whole logic when to display which chips is quite complex and depends on a lot of factors (pagination, free text filter, added and removed items).
  // This should be solved better but hasn't been done yet due to being pragmatic.
  // Additionally, this logic will fundamentally change when the data will really be paginated by the backend.
  initDisplayedItems() {
    let filteredItems = this.state.editedItems;

    // If there is a free text filter, filter the current items respectively.
    if (this.state.nameFilter) {
      filteredItems = filteredItems.filter((item) =>
        item[this.state.fieldName]
          .toLowerCase()
          .includes(this.state.nameFilter.toLowerCase()),
      );
    }

    // If there have been items added, push them to the front of the array.
    // Items that have been added most recently should be pushed to the very front.
    if (this.state.addedItems.length > 0) {
      const addedItems = this.state.addedItems
        .filter((addedItem) =>
          filteredItems.find((item) => item.id === addedItem.id),
        )
        .reverse();
      filteredItems = filteredItems.filter(
        (item) =>
          !this.state.addedItems.find((addedItem) => addedItem.id === item.id),
      );

      filteredItems = [
        ...addedItems.map((addedItem) => {
          return { ...addedItem, new: true };
        }),
        ...filteredItems.map((filteredItem) => {
          return { ...filteredItem, new: false };
        }),
      ];
    }

    // Paginate the items and display the pagination chip if not all items are already displayed.
    const paginatedItems = filteredItems.slice(0, this.state.paginationOffset);

    const hiddenItemCount = filteredItems.length - paginatedItems.length;
    if (hiddenItemCount > 0) {
      paginatedItems.push({
        id: null,
        name: '+' + hiddenItemCount + ' weitere',
        isPaginationChip: true,
      });
    }

    this.setState({
      displayedItems: paginatedItems,
    });
  }

  setNameFilter = (nameFilter) => {
    this.setState({
      nameFilter,
    });
  };
  onChipClick = (entityId) => {
    const entity = this.state.pickedItems.find((item) => item.id === entityId);

    if (!entity) {
      Log.error('Failed to find entity by id: ' + entityId);
      return;
    }

    if (this.props.onChipClick) {
      this.props.onChipClick(entity);
    }
  };
  onPaginationClick = () => {
    Log.info('Load the next paginated set of entities.', {
      cursor: this.state.paginationOffset,
    });
    Log.productAnalyticsEvent(
      'Load paginated entities',
      Log.FEATURE.COMPLEX_PAGINATED_MULTI_PICKER,
    );

    this.setState({
      paginationOffset: this.state.paginationOffset + this.PAGINATE_BY,
    });
  };
  onDelete = (entityId) => {
    Log.info('Remove entity.', { entityId });
    Log.productAnalyticsEvent(
      'Remove entity',
      Log.FEATURE.COMPLEX_PAGINATED_MULTI_PICKER,
    );

    this.setState({
      editedItems: this.state.editedItems.filter(
        (pickedItem) => pickedItem.id !== entityId,
      ),
      paginationOffset: this.state.paginationOffset - 1, // When an item is deleted, also the pagination offset should be reduced to not suddenly display a new item in the list.
    });
  };
  onModeClick = () => {
    Log.info('Change to edit mode.');
    Log.productAnalyticsEvent(
      'Change to edit mode',
      Log.FEATURE.COMPLEX_PAGINATED_MULTI_PICKER,
    );

    this.setState({
      inEditMode: true,
    });
  };
  onAddClick = () => {
    Log.info('Open modal to add items.');
    Log.productAnalyticsEvent(
      'Open Add Item Modal',
      Log.FEATURE.COMPLEX_PAGINATED_MULTI_PICKER,
    );

    this.setState({
      open: true,
    });
  };
  onAddEntityClick = (entityId) => {
    const entity = this.state.allItems.find((item) => item.id === entityId);

    if (!entity) {
      Log.error('Failed to find entity by id: ' + entityId);
      return;
    }

    Log.info('Add entity.', entity);
    Log.productAnalyticsEvent(
      'Add entity',
      Log.FEATURE.COMPLEX_PAGINATED_MULTI_PICKER,
    );

    this.props.callbackPickedItems([...this.state.pickedItems, entity]);

    // If an item is added, it must be determined if the item was previously removed. In this case, the item shouldn't be displayed with a "Neu" tag.
    // Therefore, check if the item is already in the removedItems array and in that case, remove the entry from there.
    // As described above, this logic of determining which items have been added and removed is quite complex and should be simplified.
    if (
      this.state.removedItems.find((removedItem) => removedItem.id === entityId)
    ) {
      this.setState({
        removedItems: this.state.removedItems.filter(
          (removedItem) => removedItem.id !== entityId,
        ),
      });
    } else {
      this.setState({
        addedItems: [...this.state.addedItems, entity],
      });
    }

    this.setState({
      paginationOffset: this.state.paginationOffset + 1, // When an item is added, also the pagination offset should be increased to not suddenly display one item less in the list.
    });
  };
  onSave = () => {
    Log.info('Save edit mode.');
    Log.productAnalyticsEvent(
      'Save edit mode',
      Log.FEATURE.COMPLEX_PAGINATED_MULTI_PICKER,
    );

    this.props.callbackPickedItems(this.state.editedItems);

    this.setState({
      inEditMode: false,
    });

    // The following code is basically the opposite logic of onAddEntityClick.
    // If items should be removed, check if they occur already in the addedItems array and in that case, remove the entries from there.
    // As described above, this logic of determining which items have been added and removed is quite complex and should be simplified.
    const toBeRemovedItems = this.state.pickedItems.filter(
      (pickedItem) =>
        !this.state.editedItems.find(
          (editedItem) => editedItem.id === pickedItem.id,
        ),
    );

    let newAddedItems = [...this.state.addedItems];
    const newRemovedItems = [...this.state.removedItems];

    for (const toBeRemovedItem of toBeRemovedItems) {
      const removeFromAddedItems = this.state.addedItems.find(
        (addedItem) => addedItem.id === toBeRemovedItem.id,
      );

      if (removeFromAddedItems) {
        newAddedItems = newAddedItems.filter(
          (addedItem) => addedItem.id !== toBeRemovedItem.id,
        );
      } else {
        newRemovedItems.push(toBeRemovedItem);
      }
    }

    this.setState({
      addedItems: newAddedItems,
      removedItems: newRemovedItems,
    });
  };
  onCancel = () => {
    Log.info('Cancel edit mode.');
    Log.productAnalyticsEvent(
      'Cancel edit mode',
      Log.FEATURE.COMPLEX_PAGINATED_MULTI_PICKER,
    );

    this.setState({
      editedItems: this.state.pickedItems,
      inEditMode: false,
      // When cancelling the edit mode, ensure that the same chips are displayed as before.
      paginationOffset:
        this.state.paginationOffset -
        this.state.editedItems.length +
        this.state.pickedItems.length,
    });
  };
  closeModal = () => {
    Log.info('Close modal to add items.');
    Log.productAnalyticsEvent(
      'Close Add Item Modal',
      Log.FEATURE.COMPLEX_PAGINATED_MULTI_PICKER,
    );

    this.setState({
      open: false,
    });
  };

  getUpdateWarning() {
    if (
      this.state.addedItems.length === 0 &&
      this.state.removedItems.length === 0
    ) {
      return null;
    }

    const changes = [];
    if (this.state.addedItems.length > 0) {
      changes.push(this.state.addedItems.length + ' hinzugefügt');
    }

    if (this.state.removedItems.length > 0) {
      changes.push(this.state.removedItems.length + ' entfernt');
    }

    return (
      <Hint
        type="error"
        text={'Es gibt ungespeicherte Änderungen (' + changes.join('; ') + ')'}
      />
    );
  }

  render() {
    return (
      <div className="border-grey400 rounded-5px p-16px bg-white">
        <HeaderAccordion
          title={
            <div className="text-18px flex-s-c gap-10px">
              <div className="normal text-line-nowrap">
                {this.state.textfieldLabel}:{' '}
              </div>
              {this.getLoadingStateFromProps() === LOADING_STATE.LOADING ? (
                <Spinner />
              ) : (
                <span className="bold">{this.state.pickedItems.length}</span>
              )}
            </div>
          }
          expanded={this.state.expanded}
          setExpanded={() => this.setState({ expanded: !this.state.expanded })}
          noExpansionText
          productAnalyticsFeature={Log.FEATURE.COMPLEX_PAGINATED_MULTI_PICKER}
        >
          <div className="flexdir-column gap-16px flex">
            <div className="h-1px bg-grey200 w-full" />
            <div className="flex-s-e gap-8px">
              <FreeTextSearch
                id={
                  "ComplexPaginatedEntityMultiPicker_delay_function_'" +
                  this.state.textfieldLabel
                }
                onChange={this.setNameFilter}
                value={this.state.nameFilter}
                withDelay
                productAnalyticsFeature={
                  Log.FEATURE.COMPLEX_PAGINATED_MULTI_PICKER
                }
              />
              {this.state.inEditMode ? (
                <SquaredIconButton
                  onClick={this.onSave}
                  tooltipTitle="Änderungen übernehmen"
                >
                  <SaveIcon className="text-primary500" />
                </SquaredIconButton>
              ) : (
                <SquaredIconButton
                  onClick={this.onModeClick}
                  tooltipTitle={this.state.textfieldLabel + ' bearbeiten'}
                >
                  <EditIcon className="text-primary500" />
                </SquaredIconButton>
              )}
              <SquaredIconButton
                onClick={this.onAddClick}
                disabled={this.state.inEditMode}
                tooltipTitle={this.state.textfieldLabel + ' hinzufügen'}
              >
                <AddIcon
                  className={
                    this.state.inEditMode
                      ? 'text-primary100'
                      : 'text-primary500'
                  }
                />
              </SquaredIconButton>
              <AddItemModal
                title={this.state.textfieldLabel + ' hinzufügen'}
                open={this.state.open}
                items={this.state.allItems}
                pickedIds={this.props.pickedIds}
                onAddItemClick={this.onAddEntityClick}
                closeModal={this.closeModal}
              />
            </div>
            {this.getUpdateWarning()}
            <ChipList
              items={this.state.displayedItems}
              onClick={this.onChipClick}
              onPaginationClick={this.onPaginationClick}
              onDelete={this.onDelete}
              clickable={!this.state.inEditMode}
              deletable={this.state.inEditMode}
            />
            {/* <div className="flex-c-c w-full">
                            <Button
                                color="primary"
                                size="small"
                                onClick={this.onLoadClick}
                                disabled={this.state.isLoading}
                                startIcon={this.state.isLoading ? <Spinner/> : <RefreshIcon/>}
                            >
                                Weitere 20 anzeigen
                            </Button>
                        </div> */}
            {this.state.inEditMode ? (
              <div className="flex-c-c gap-8px w-full">
                <Button
                  className="w-full"
                  color="primary"
                  onClick={this.onCancel}
                  startIcon={<CancelIcon />}
                >
                  Abbrechen
                </Button>
                <Button
                  className="primary-button w-full"
                  onClick={this.onSave}
                  startIcon={<SaveIcon />}
                >
                  Übernehmen
                </Button>
              </div>
            ) : null}
          </div>
        </HeaderAccordion>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ComplexPaginatedEntityMultiPicker);
