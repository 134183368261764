import React from 'react';
import Chip from './Chip';

class ChipList extends React.Component {
  constructor(props) {
    super(props);

    this.INTERNAL_PAGINATE_BY = 10;

    // Internal Pagination can be toggled via this.props.withInternalPagination.
    // The internal pagination helps to not have to implement the pagination logic in the parent component.
    this.state = {
      internalPaginationOffset: this.INTERNAL_PAGINATE_BY,
    };
  }

  onPaginationClick = () => {
    if (!this.props.withInternalPagination) {
      this.props.onPaginationClick();
      return;
    }

    this.setState({
      internalPaginationOffset:
        this.state.internalPaginationOffset + this.INTERNAL_PAGINATE_BY,
    });
  };

  getItems() {
    if (!this.props.withInternalPagination) {
      return this.props.items;
    }

    return this.props.items.slice(0, this.state.internalPaginationOffset);
  }

  render() {
    return (
      <div className="flex-s-c gap-8px flex-wrap">
        {this.getItems().map((item) => (
          <Chip
            {...item}
            name={item[this.props.fieldName ?? 'name']}
            onClick={this.props.onClick}
            onPaginationClick={this.onPaginationClick}
            onDelete={this.props.onDelete}
            clickable={
              this.props.selectedItemIsNotClickable && item.selected
                ? false
                : this.props.clickable
            }
            deletable={this.props.deletable}
            key={item.id}
          />
        ))}
        {this.props.withInternalPagination &&
        this.props.items.length > this.state.internalPaginationOffset ? (
          <Chip
            id={null}
            name={
              '+ ' +
              (this.props.items.length - this.state.internalPaginationOffset) +
              ' weitere Items'
            }
            isPaginationChip
            onPaginationClick={this.onPaginationClick}
            key="pagination-key"
          />
        ) : null}
      </div>
    );
  }
}

export default ChipList;
