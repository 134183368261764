import React from 'react';

import { InputLabel, MenuItem, Select, Button } from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import Log from '~/utils/Log';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import Permissions from '~/models/masterdata/Permissions';
import PermissionGrantMultiPicker from '../../permissionGrant/PermissionGrantMultiPicker';
import { LightTooltip } from '~/utils/componentUtils';
import PermissionForm from '../../permissionGrant/PermissionForm';
import PermissionGrantService from '~/services/permissionGrant.service';
import PromiseUtils from '~/utils/promiseUtils';

class CreateSiteWizardPermissionGrantPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pickedSubjectType: PermissionGrant.SUBJECT_TYPE.USER.KEY,
      pickedSubjects: [],
      permissionFormOpen: false,
      permissions: new Permissions(),
    };
  }

  handleChangeSubjectType = (event) => {
    Log.info(
      'Change form value of subject type',
      { from: this.state.pickedSubjectType, to: event.target.value },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change subject type in create site wizard',
      Log.FEATURE.WIZARD,
    );

    this.setState({
      pickedSubjectType: event.target.value,
      pickedSubjects: [],
    });
  };
  handleChangeSubjects = (event) => {
    const newPickedSubjects = event.map((item) => item.id);

    Log.info(
      'Change form value of subjects',
      { from: this.state.pickedSubjects, to: newPickedSubjects },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change subjects in create site wizard',
      Log.FEATURE.WIZARD,
    );

    this.setState({
      pickedSubjects: newPickedSubjects,
    });
  };
  handleChangeRole = (event) => {
    Log.info(
      'Change form value of role',
      {
        from: this.state.permissions.getDefaultRoleName(),
        to: event.target.value,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change role (' + event.target.value + ') in create site wizard',
      Log.FEATURE.WIZARD,
    );

    const newPermissions = new Permissions();

    if (event.target.value === Permissions.INDIVIDUAL_ROLE) {
      this.setState({
        permissionFormOpen: true,
      });
      return;
    }

    newPermissions.initWithDefaultRole(event.target.value);

    this.setState({
      permissions: newPermissions,
    });
  };
  openPermissionForm = () => {
    Log.productAnalyticsEvent(
      'Open permission form in create site wizard',
      Log.FEATURE.WIZARD,
    );
    this.setState({
      permissionFormOpen: true,
    });
  };
  permissionFormSuccess = (permissions) => {
    this.setState({
      permissionFormOpen: false,
      permissions,
    });
  };
  permissionFormAbort = () => {
    this.setState({
      permissionFormOpen: false,
    });
  };

  async submit(siteId) {
    const promises = [];

    if (!this.state.permissions.permissionGranted()) {
      return;
    }

    for (const subject of this.state.pickedSubjects) {
      const body = {
        permissions: this.state.permissions.getBackendPermissions(),
      };

      Log.info(
        'Submit permission grant form',
        body,
        Log.BREADCRUMB.FORM_SUBMIT.KEY,
      );

      const promise = PermissionGrantService.createNewPermissionGrant(
        this.state.pickedSubjectType,
        subject,
        PermissionGrant.ENTITY_TYPE.SITE.KEY,
        siteId,
        body,
      );

      promises.push(promise);
    }

    return PromiseUtils.allResolved(promises);
  }

  async submitCostCenters(costCenterIds) {
    const promises = [];

    if (!this.state.permissions.permissionGranted()) {
      return;
    }

    for (const costCenterId of costCenterIds) {
      for (const subject of this.state.pickedSubjects) {
        const body = {
          permissions: this.state.permissions.getBackendPermissions(),
        };

        Log.info(
          'Submit permission grant form',
          body,
          Log.BREADCRUMB.FORM_SUBMIT.KEY,
        );

        const promise = PermissionGrantService.createNewPermissionGrant(
          this.state.pickedSubjectType,
          subject,
          PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY,
          costCenterId,
          body,
        );

        promises.push(promise);
      }
    }

    return PromiseUtils.allResolved(promises);
  }

  render() {
    return (
      <>
        <div className="flex-s-c gap-20px mt-20px">
          <div>
            <InputLabel className="text-13px">Berechtigung für</InputLabel>
            <Select
              value={this.state.pickedSubjectType}
              onChange={this.handleChangeSubjectType}
              className="w-300px"
              size="small"
            >
              {PermissionGrant.getPickableSubjectTypes().map((subjectType) => (
                <MenuItem value={subjectType.value} key={subjectType.value}>
                  {subjectType.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <PermissionGrantMultiPicker
            type="permission_for"
            subjectType={this.state.pickedSubjectType}
            pickedIds={this.state.pickedSubjects}
            callbackPickedItems={this.handleChangeSubjects}
            fullWidth
            displayPermissionGrantOfCostCentersWithSites={
              this.props.grantPermissionsOnCostCenters
            }
          />
        </div>
        <div className="flex-s-e gap-20px mt-20px">
          <div>
            <InputLabel className="text-13px">Berechtigung als</InputLabel>
            <Select
              value={this.state.permissions.getDefaultRoleName() ?? 'None'} // A random String has to be chosen as fallback value because otherwise renderValue wouldn't catch the case if(!this.state.permissions.permissionGranted())
              key={0}
              onChange={this.handleChangeRole}
              className="w-300px"
              size="small"
              renderValue={(id) => {
                if (!this.state.permissions.permissionGranted()) {
                  return (
                    <span className="text-mui-not-selected-grey">
                      Bitte Berechtigungen vergeben
                    </span>
                  );
                }

                return Permissions.getPickableRoles().find(
                  (option) => option.id === id,
                ).name;
              }}
            >
              {Permissions.getPickableRoles().map((role) => (
                <MenuItem value={role.id} key={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="mb-2px">
            <LightTooltip title="Detaillierte Berechtigungen einsehen">
              <Button
                variant="outlined"
                color="primary"
                onClick={this.openPermissionForm}
              >
                Details
              </Button>
            </LightTooltip>
          </div>
        </div>
        <PermissionForm
          open={this.state.permissionFormOpen}
          formSuccess={this.permissionFormSuccess}
          formAbort={this.permissionFormAbort}
          permissions={this.state.permissions}
        />
        <br />
        <br />
        <br />
        <br />
      </>
    );
  }
}

export default withErrorBoundary(
  CreateSiteWizardPermissionGrantPicker,
  'Daten konnten nicht geladen werden.',
);
