import { Box, Card } from '@mui/material';
import { closeJsonModal } from '~/redux/devToolsSlice';
import { useSelector, useDispatch } from 'react-redux';
import {
  allExpanded,
  collapseAllNested,
  defaultStyles,
  JsonView,
} from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

import BasicModal from './BasicModal';

export const JsonModal = ({ showAllExpanded = true }) => {
  const devTools = useSelector((state) => state.devTools);
  const dispatch = useDispatch();

  return (
    <BasicModal
      title="JSON"
      open={devTools.jsonModal.open}
      closeModal={() => dispatch(closeJsonModal())}
    >
      <Card variant="outlined">
        <Box p={1}>
          <JsonView
            data={devTools.jsonModal.json ?? {}}
            shouldExpandNode={showAllExpanded ? allExpanded : collapseAllNested}
            style={defaultStyles}
            clickToExpandNode={true}
          />
        </Box>
      </Card>
    </BasicModal>
  );
};
