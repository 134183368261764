export const MASTERDATA = {
  IS_ACTIVE: {
    YES: 'Ja',
    NO: 'Nein',
  },
};

export const COMPANY = {
  ANBS: '98b6312d-70d0-4754-93a3-727add732ebb',
  ANTON_EIREINER_GMBH: '99d9c375-60c7-4c80-a818-112852146f58',
  ARGE_A3: 'edfca2ba-eac3-487f-8d1a-337259b7fea0',
  ARGE_OBERIRDISCHE_WEST: '36be0185-9b75-4829-8b24-5526e4d7d8ac',
  ATH: '143ef55b-4428-4cc0-8dbb-18d0dc06bec7',
  DECHANT: 'a838a898-b6bc-40c6-b3cb-8b4ca166f59c',
  FERTIGBETON_STIEB: 'cc06e3b3-21cf-439d-977b-03ddcb097131',
  GANSER_BETON: '64b8820e-8435-4c10-8697-558524d0917a',
  GROSSMANN: '68587cc0-a792-4c21-a9a5-e8df33da1a73',
  STEINWERKE_ANTON_EIREINER: 'a16fef17-5842-4bcf-bb02-f12bb6c20811',
  STRABAG_AG: 'e8db65d2-79eb-4cb2-9e9a-1f635dd9d9ff',
  STRABAG_BAYERN_NORD: 'c66c66d9-8d6c-493c-9c85-38fa55f0f385',
  STRABAG_NORD_OST: 'bd374d5c-e0a5-457b-af4f-770fc384767b',
  STRABAG_NORD: 'f2af83b2-fa88-46cd-af84-bac57be3e089',
  UNION_BETON: '55fe9894-0189-4b73-9ea7-698097a89003',
  WM: 'd9421c74-1817-4954-91a3-e7ba913a677e',
};
