import React from 'react';

import { Check as CheckIcon } from '@mui/icons-material';

import { Chip as MuiChip } from '@mui/material';

class Chip extends React.Component {
  // There is the possibility to add a chip to load more chips (e.g. pagination functionality).
  // Contrary to the other chips, the pagination chip is always clickable and never deletable independent of the mode.

  isClickable() {
    if (this.props.isPaginationChip) {
      return true;
    }

    return this.props.clickable;
  }

  isDeletable() {
    if (this.props.isPaginationChip) {
      return false;
    }

    return this.props.deletable;
  }

  onClick = () => {
    if (!this.isClickable()) {
      return;
    }

    if (this.props.isPaginationChip) {
      this.props.onPaginationClick();
    } else {
      this.props.onClick(this.props.id);
    }
  };
  onDelete = () => {
    if (!this.isDeletable()) {
      return;
    }

    this.props.onDelete(this.props.id);
  };

  render() {
    return (
      <div onClick={this.onClick} className="relative">
        <MuiChip
          key={this.props.id}
          label={this.props.name}
          onDelete={this.isDeletable() ? this.onDelete : null}
          icon={
            this.props.selected ? <CheckIcon className="text-white" /> : null
          }
          className={
            this.props.selected
              ? 'bg-primary500 text-white'
              : 'text-grey800 bg-white'
          }
          sx={{
            cursor: this.isClickable() ? 'pointer' : 'default',
            backgroundColor: 'white',
            color: this.props.isPaginationChip ? '#173C88' : null,
            border: this.props.isPaginationChip
              ? '#173C88 solid 1px'
              : '#8D94A8 solid 1px', // primary500, grey400
            '&:hover': {
              backgroundColor:
                this.isClickable() && !this.props.selected
                  ? '#E4E6ED !important'
                  : 'white',
            },
          }}
        />
        {this.props.new ? (
          <div className="text-12px bg-grey800 rounded-10px h-20px flex-c-c w-40px r--6px t--8px absolute text-white">
            Neu
          </div>
        ) : null}
      </div>
    );
  }
}

export default Chip;
