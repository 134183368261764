import cloneDeep from 'lodash/cloneDeep';
import React, { createRef, memo } from 'react';
import { DataGridPro, GridPagination } from '@mui/x-data-grid-pro';

import GridToolbar from './interaction/GridToolbar';
import Log from '~/utils/Log';
import LocalStorageService from '~/services/localStorage.service';
import DatagridUtils from '~/utils/datagridUtils';
import ArrayUtils from '~/utils/arrayUtils';
import { gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid';
import { LOADING_STATE } from '~/constants/LoadingState';
import FunctionUtils from '~/utils/functionUtils';
import Spinner from './Spinner';
import { LightTooltipWide } from '~/utils/componentUtils';

class BasicTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columnOrderModel: [],
      columnVisibilityModel: {},
      columnWidthModel: {},
      detailPanelExpandedRowIds: [], // Initialize detailPanelExpandedRowIds to an empty array
      filteredSortedRows: [],
      page: this.props.page ?? 0,
      pageSize: this.props.pageSize ?? 100,
      pinnedColumns: LocalStorageService.getObjectFromLocalStorage(
        this.props.pinnedColumnsCookieId,
      ) ??
        this.props.defaultPinnedColumns ?? { right: [], left: [] },
      rowHeight: DatagridUtils.ROW_HEIGHT.THIN,
    };

    this.apiRef = createRef();

    this.scrolledRowIndex = 0;
  }

  componentDidMount() {
    const cookie = LocalStorageService.getObjectFromLocalStorage(
      this.props.localStorageKey,
    );

    const newCookie = {
      columnVisibilityModel: cookie?.columnVisibilityModel ?? {},
      columnWidthModel: cookie?.columnWidthModel ?? {},
      columnOrderModel: cookie?.columnOrderModel ?? [],
      rowHeight:
        cookie?.rowHeight ??
        this.props.defaultRowHeight ??
        DatagridUtils.ROW_HEIGHT.THIN,
    };

    newCookie.columnVisibilityModel = this.hideDefaultColumns(
      newCookie.columnVisibilityModel,
    );

    this.setState({
      columnVisibilityModel: newCookie.columnVisibilityModel,
      columnWidthModel: newCookie.columnWidthModel,
      columnOrderModel: newCookie.columnOrderModel,
      rowHeight: newCookie.rowHeight,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.defaultHiddenColumns) !==
      JSON.stringify(prevProps.defaultHiddenColumns)
    ) {
      this.setState({
        columnVisibilityModel: this.hideDefaultColumns(
          this.state.columnVisibilityModel,
        ),
      });
    }

    if (
      this.props.triggerScrollToNewRows !== prevProps.triggerScrollToNewRows &&
      this.props.triggerScrollToNewRows
    ) {
      this.scrollToNewRows();
    }
  }

  hideDefaultColumns(columnVisibilityModel) {
    const newColumnVisibilityModel = cloneDeep(columnVisibilityModel);

    if (this.props.defaultHiddenColumns)
      for (const column of this.props.defaultHiddenColumns) {
        if (newColumnVisibilityModel[column] === undefined) {
          newColumnVisibilityModel[column] = false;
        }
      }

    return newColumnVisibilityModel;
  }

  async scrollToNewRows() {
    if (!this.props.scrollToNewRowsTimeout) {
      return;
    }

    await FunctionUtils.timer(this.props.scrollToNewRowsTimeout);

    if (!this.apiRef.current) {
      this.scrollToNewRows();
      return;
    }

    if (this.scrolledRowIndex === this.props.maxScrollToNewRowsIndex) {
      this.scrolledRowIndex = 0;
      this.props.onScrolledRowIndexChange(0);
      this.apiRef.current.scrollToIndexes({ rowIndex: 0 });
      this.scrollToNewRows();
      return;
    }

    let newRowIndex = this.scrolledRowIndex + this.props.scrollToNewRowsSize;
    if (newRowIndex >= this.props.maxScrollToNewRowsIndex) {
      newRowIndex = this.props.maxScrollToNewRowsIndex;
    }

    this.scrolledRowIndex = newRowIndex;
    this.props.onScrolledRowIndexChange(newRowIndex);
    this.apiRef.current.scrollToIndexes({ rowIndex: newRowIndex });
    this.scrollToNewRows();
  }

  onColumnVisibilityModelChange = (event) => {
    Log.info(
      'Change column visibility',
      { from: this.state.columnVisibilityModel, to: event },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change column visibility',
      Log.FEATURE.BASIC_TABLE,
    );

    this.setState({
      columnVisibilityModel: event,
    });

    this.storeLocally(
      event,
      this.state.columnWidthModel,
      this.state.columnOrderModel,
      this.state.rowHeight,
    );
  };
  onColumnWidthChange = (event) => {
    const newColumnWidthModel = cloneDeep(this.state.columnWidthModel);

    newColumnWidthModel[event.colDef.field] = event.colDef.width;

    Log.info(
      'Change column width',
      { from: this.state.columnWidthModel, to: newColumnWidthModel },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change column width', Log.FEATURE.BASIC_TABLE);

    this.setState({
      columnWidthModel: newColumnWidthModel,
    });

    this.storeLocally(
      this.state.columnVisibilityModel,
      newColumnWidthModel,
      this.state.columnOrderModel,
      this.state.rowHeight,
    );
  };
  onColumnOrderChange = (newColumnOrderModel) => {
    const columnOrderModel = LocalStorageService.getObjectFromLocalStorage(
      this.props.localStorageKey,
    )?.columnOrderModel;

    if (
      newColumnOrderModel &&
      JSON.stringify(newColumnOrderModel) === JSON.stringify(columnOrderModel)
    ) {
      return;
    }

    // Needed to fix bug: If column has been added or removed in code, onColumnOrderChange is called before states are initialized.
    // Thus, without this fallback, the cookie would have been filled with empty states
    if (this.state.columnOrderModel.length === 0) {
      this.storeLocally(
        LocalStorageService.getObjectFromLocalStorage(
          this.props.localStorageKey,
        )?.columnVisibilityModel,
        LocalStorageService.getObjectFromLocalStorage(
          this.props.localStorageKey,
        )?.columnWidthModel,
        newColumnOrderModel,
        LocalStorageService.getObjectFromLocalStorage(
          this.props.localStorageKey,
        )?.rowHeight,
      );

      // set state to prevent bug:
      // When changing column order and changing column visibility before reloading, column order was reset.
      // Because no state was updated, still the old order was passed in columns prop to the data grid.
      this.setState({
        columnOrderModel: newColumnOrderModel,
      });

      return;
    }

    Log.info(
      'Change column order',
      { from: columnOrderModel, to: newColumnOrderModel },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change column order', Log.FEATURE.BASIC_TABLE);

    this.storeLocally(
      this.state.columnVisibilityModel,
      this.state.columnWidthModel,
      newColumnOrderModel,
      this.state.rowHeight,
    );
    // set state here also to prevent bug
    this.setState({
      columnOrderModel: newColumnOrderModel,
    });
  };
  onRowHeightChange = (rowHeight) => {
    Log.info(
      'Change row height',
      {
        from: this.state.rowHeight,
        to: rowHeight,
      },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change row height', Log.FEATURE.BASIC_TABLE);

    this.setState({
      rowHeight,
    });

    this.storeLocally(
      this.state.columnVisibilityModel,
      this.state.columnWidthModel,
      this.state.columnOrderModel,
      rowHeight,
    );
  };
  onStateChange = (state) => {
    this.onColumnOrderChange(state.columns.orderedFields);

    if (this.props.onStateChange) {
      this.props.onStateChange(state);
    }

    if (!this.props.excelData) {
      return;
    }

    const newFilteredSortedRows = Array.from(
      new Set(
        gridFilteredSortedRowIdsSelector(state, this.apiRef.current.state),
      ),
    );

    if (
      JSON.stringify(this.state.filteredSortedRows) ===
      JSON.stringify(newFilteredSortedRows)
    ) {
      return;
    }

    this.setState({
      filteredSortedRows: newFilteredSortedRows,
    });
  };
  onRowSelectionModelChange = (event) => {
    if (this.props.onlySingleSelection) {
      this.props.onRowSelectionModelChange(
        ArrayUtils.subtract(event, this.props.rowSelectionModel),
      );
      return;
    }

    // Only the rows from the current page are in the BasicTable
    // and thus the selection model is already limited automatically.
    if (this.props.paginationMode === 'server') {
      this.props.onRowSelectionModelChange(event);
      return;
    }

    // If more than 100 rows are selected, we assume that the "Alle auswählen" checkbox was clicked.
    // In this case, limit the selection model to the current page.
    // However, this has bug potential if we want to select all rows programmatically.
    if (
      event.length > this.state.pageSize &&
      event.length === this.props.rows.length
    ) {
      const from = this.state.page * this.state.pageSize;
      const to = from + this.state.pageSize;
      const limitedSelectionModel = event.slice(from, to);
      this.props.onRowSelectionModelChange(limitedSelectionModel);
      return;
    }

    this.props.onRowSelectionModelChange(event);
  };
  onSelectAllRows = () => {
    Log.info('Select all rows', null, Log.BREADCRUMB.USER_ACTION.KEY);
    Log.productAnalyticsEvent('Select all rows', Log.FEATURE.BASIC_TABLE);

    this.props.onRowSelectionModelChange(this.props.rows.map(({ id }) => id));
  };
  onPageChange = (newPage) => {
    this.setState({
      page: newPage,
    });

    if (this.props.onPageChange) {
      this.props.onPageChange(newPage);
    }
  };
  onPinnedColumnsChange = (event) => {
    Log.info(
      'Change pinned columns',
      { from: this.state.pinnedColumns, to: event },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change pinned columns', Log.FEATURE.BASIC_TABLE);

    this.setState({
      pinnedColumns: event,
    });

    LocalStorageService.setObjectAsLocalStorage(
      this.props.pinnedColumnsCookieId,
      event,
    );
  };

  storeLocally(
    columnVisibilityModel,
    columnWidthModel,
    columnOrderModel,
    rowHeight,
  ) {
    LocalStorageService.setObjectAsLocalStorage(this.props.localStorageKey, {
      columnOrderModel,
      columnVisibilityModel,
      columnWidthModel,
      rowHeight,
    });
  }

  // order the columns according to the user setting in the cookie
  getOrderedColumns() {
    if (this.props.disableColumnReorder) {
      return this.props.columns;
    }

    const data = LocalStorageService.getObjectFromLocalStorage(
      this.props.localStorageKey,
    );

    if (!data?.columnOrderModel) {
      return this.props.columns;
    }

    const indexedColumns = this.props.columns.map((column, index_) => {
      // Get column index from data
      const index = data.columnOrderModel.indexOf(column.field);

      return {
        index: index === -1 ? index_ : index, // Add new columns at their specified position
        ...column,
      };
    });

    return ArrayUtils.sortByKey(indexedColumns, 'index');
  }

  passFilterModelAsProps() {
    if (!this.props.withFilterModel) {
      return null;
    }

    return {
      filterModel: this.getFilterModel(),
    };
  }

  getFilterModel() {
    const filterModel = {
      ...this.props.filterModel,
      logicOperator: this.props.filterModel?.logicOperator ?? 'and',
    };

    return filterModel;

    // The following code was implemented as a bug fix.
    // -> see ticket: https://innovent-consulting.atlassian.net/browse/VGSD-3102
    // However, it was not working with filterable: false in getColumns() of DeliveryList.js
    // -> It lead to infinite loop of rendering. Outweighing both issues, it makes more sense to live
    // with the bug of losing focus on the filter. Anyway, it makes sense to try to fix this bug as well.
    // Thus check this in the future again.
    /* if (this.props.filterModel?.items?.length > 0) return this.props.filterModel;

    // cloneDeep of DatagridUtils.EMPTY_FILTER_MODEL is necessary because otherwise the following error would be thrown:
    // Uncaught TypeError: Cannot add property 0, object is not extensible
    let filterModel = this.props.filterModel
      ? cloneDeep(this.props.filterModel)
      : cloneDeep(DatagridUtils.EMPTY_FILTER_MODEL);

    // Search for a column of type string and add an empty filter for it.
    // This is a workaround for a bug in the data grid: If no filter is set, the focus on the filter is lost when the table data refreshes.
    // Check here: https://github.com/mui/mui-x/issues/8119
    const stringColumn = this.props.columns.find((column) => !column.type || column.type === 'string');

    // Only add the empty filter if there is a column of type string because we apply the operator 'contains' which is only available for string columns.
    // There is a small chance that there is no column of type string. Then the bug of losing the focus will still exist.
    if (stringColumn) {
      filterModel.items.push({
        field: stringColumn.field,
        operator: 'contains',
        value: ''
      });
    }

    return filterModel; */
  }

  // according to cookie, columns are ordered, customized widths are set and the selected row height is applied
  getColumns() {
    const newColumns = cloneDeep(this.getOrderedColumns());

    for (const [index, column] of newColumns.entries()) {
      if (column.resizableText) {
        newColumns[index] = {
          ...newColumns[index],
          ...DatagridUtils.resizeText(this.state.rowHeight, column.renderCell), // apply the selected text size
          width:
            column.width *
            DatagridUtils.getColumnWidthFactor(this.state.rowHeight), // if no custom width is set, the default width is aligned with the row height
        };
      }

      const customWidth = this.state.columnWidthModel[column.field];
      if (customWidth) {
        newColumns[index].width = customWidth;
      }
    }

    return newColumns;
  }

  getPropsToHideHeader() {
    if (!this.props.hideHeader) {
      return null;
    }

    return {
      columnHeaderHeight: 0,
      sx: {
        '& .MuiDataGrid-toolbarContainer': {
          display: 'none !important',
          visibility: 'hidden !important',
          height: '0px !important',
        },
      },
    };
  }

  getPinnedColumns() {
    if (this.state.pinnedColumns === null) {
      return (
        this.props.defaultPinnedColumns ?? {
          left: [],
          right: [],
        }
      );
    }

    return this.state.pinnedColumns;
  }

  getToolbar() {
    if (this.props.hideToolbar) {
      return null;
    }

    return (
      <GridToolbar
        multiplePdfDownload={this.props.multiplePdfDownload}
        onPdfExport={this.props.onPdfExport}
        onMultiPermissionGrantEdit={this.props.onMultiPermissionGrantEdit}
        rowHeight={this.state.rowHeight}
        onRowHeightChange={this.onRowHeightChange}
        excelData={this.getFilteredSortedExcelData()}
        excelColumns={this.props.excelColumns}
        onExcelDlnExport={this.props.onExcelDlnExport}
        onExcelInvoiceExport={this.props.onExcelInvoiceExport}
        productAnalyticsFeature={this.props.productAnalyticsFeature}
        onRequestDeliveryNoteSignature={
          this.props.onRequestDeliveryNoteSignature
        }
        onShareDeliveryNote={this.props.onShareDeliveryNote}
        onMapDirectDeliveryNote={this.props.onMapDirectDeliveryNote}
        onOpenReport={this.props.onOpenReport}
      />
    );
  }

  getFooter = () => {
    if (this.props.hideFooter) {
      return null;
    }

    if (!this.props.footerText) {
      return (
        <div className="flex-sb-c border-top flexdir-row-reverse">
          <GridPagination />
          {this.props.rowSelectionModel?.length > 0 ? (
            <div className="pl-20px">
              <span>
                {this.props.rowSelectionModel.length} Zeile(n) ausgewählt
              </span>
              {this.getSelectAllRowsButton()}
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <div className="flex-sb-c border-top flexdir-row-reverse">
        <GridPagination />
        <div className="pl-20px">{this.props.footerText}</div>
        {this.props.rowSelectionModel?.length > 0 ? (
          <div className="pl-20px">
            <span>
              {this.props.rowSelectionModel.length} Zeile(n) ausgewählt
            </span>
            {this.getSelectAllRowsButton()}
          </div>
        ) : null}
      </div>
    );
  };

  getSelectAllRowsButton() {
    if (this.props.rowSelectionModel.length !== this.state.pageSize) {
      return null;
    }

    if (this.props.disableSelectAllRowsButton) {
      return (
        <LightTooltipWide title={this.props.disableSelectAllRowsButtonMessage}>
          <span className="ml-20px bold disabled-text cursor-not-allowed underline">
            alle {this.props.selectAllRowsCount ?? this.props.rows.length}{' '}
            Zeilen auswählen
          </span>
        </LightTooltipWide>
      );
    }

    return (
      <span
        className="ml-20px bold text-primary500 cursor-pointer underline"
        onClick={this.onSelectAllRows}
      >
        alle {this.props.selectAllRowsCount ?? this.props.rows.length} Zeilen
        auswählen
      </span>
    );
  }

  getCursor() {
    if (this.props.onRowRightClick) {
      return 'context-menu';
    }

    if (this.props.onRowClick) {
      return 'pointer';
    }

    return 'auto';
  }

  getFilteredSortedExcelData() {
    if (!this.props.excelData?.length) {
      return [];
    }

    if (!this.state.filteredSortedRows?.length) {
      return this.props.excelData;
    }

    return ArrayUtils.sortByKeyValues(
      this.props.excelData,
      this.state.filteredSortedRows,
      'id',
    );
  }

  getSlotProps() {
    const slotProps = {
      row: {
        onContextMenu: this.props.onRowRightClick,
        style: {
          cursor: this.getCursor(),
        },
      },
    };

    if (this.props.logicOperators) {
      slotProps.filterPanel = {
        logicOperators: this.props.logicOperators,
      };
    }

    return slotProps;
  }

  render() {
    let slots = {
      toolbar: () => this.getToolbar(),
      NoRowsOverlay: () => (
        <div className="flex-c-c h-full w-full">
          {this.props.loading === LOADING_STATE.FAILED
            ? 'Daten konnten nicht geladen werden.'
            : 'Keine Einträge'}
        </div>
      ),
    };

    slots =
      this.props.paginationLoading === LOADING_STATE.LOADING
        ? {
            ...slots,
            Pagination: () => (
              <div className="pr-20px text-end">
                <Spinner />
              </div>
            ),
          }
        : {
            ...slots,
            Footer: () => this.getFooter(),
          };

    // Ensure detailPanelExpandedRowIds is an array of non-null values (null breaks the DataGridPro component)
    const detailPanelExpandedRowIds =
      this.state.detailPanelExpandedRowIds.filter((id) => id !== null);

    return (
      <DataGridPro
        {...this.props}
        {...this.getPropsToHideHeader()}
        {...this.passFilterModelAsProps()}
        apiRef={this.apiRef}
        columns={this.getColumns()}
        columnVisibilityModel={this.state.columnVisibilityModel}
        slots={slots}
        slotProps={this.getSlotProps()}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        disableRowSelectionOnClick
        initialState={{ pinnedColumns: this.getPinnedColumns() }}
        loading={this.props.loading === LOADING_STATE.LOADING}
        onColumnVisibilityModelChange={this.onColumnVisibilityModelChange}
        onColumnWidthChange={this.onColumnWidthChange}
        onPageChange={this.onPageChange}
        onPinnedColumnsChange={this.onPinnedColumnsChange}
        onRowSelectionModelChange={this.onRowSelectionModelChange}
        onStateChange={this.onStateChange}
        page={this.state.page}
        pageSize={this.state.pageSize}
        pagination={!this.props.infiniteScrolling}
        rowHeight={this.state.rowHeight}
      />
    );
  }
}

export default memo(BasicTable);
