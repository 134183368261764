import { Button, Checkbox, FormControlLabel } from '@mui/material';

import { LightTooltip } from '~/utils/componentUtils';

import BasicForm from '~/components/BasicForm';
import GenericMultiPicker from '~/components/baseComponents/inputs/select/GenericMultiPicker';

import { useSiteReferenceForm } from './useSiteReferenceForm'; // Adjust the path as needed

export const SiteIdentifiersModal = ({ shouldBlacklist = false, siteId }) => {
  const {
    checkboxLabel,
    handleChange,
    handleCheckboxChange,
    handleClose,
    handleOpen,
    handleSubmit,
    isSubmitting,
    open,
    pickedSiteReferenceProposals,
    siteIdentifierOptions,
    updateDlns,
  } = useSiteReferenceForm({
    shouldBlacklist,
    siteId,
  });

  return (
    <div>
      <LightTooltip title="Neue Standortbezeichnung hinzufügen">
        <Button
          variant="outlined"
          color="primary"
          className="mt-10px"
          onClick={handleOpen}
        >
          Bezeichnung hinzufügen
        </Button>
      </LightTooltip>
      <BasicForm
        open={open}
        formSuccess={handleSubmit}
        formAbort={handleClose}
        title={
          shouldBlacklist
            ? `Standortbezeichnung hinzufügen und von Zuordnung ausschließen`
            : `Standortbezeichnung hinzufügen`
        }
        submittingForm={isSubmitting}
        fullWidth
      >
        <GenericMultiPicker
          textfieldLabel="Standortbezeichnung"
          pickedItems={pickedSiteReferenceProposals}
          allItems={siteIdentifierOptions}
          callbackPickedItems={handleChange}
          fieldName="name"
          sortItemsByKey="name"
          sortItems
        />
        {!shouldBlacklist && (
          <FormControlLabel
            control={
              <Checkbox
                checked={updateDlns}
                onChange={handleCheckboxChange}
                name="update_dlns"
              />
            }
            label={checkboxLabel}
            className="mt-20px"
            disabled={pickedSiteReferenceProposals.length === 0}
          />
        )}
      </BasicForm>
    </div>
  );
};
