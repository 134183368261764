import React from 'react';
import BasicForm from '../../BasicForm';
import { connect } from 'react-redux';
import { promiseHandler } from '~/utils/promiseHandler';
import PushService from '~/services/push.service';
import Log from '~/utils/Log';
import ToastService from '~/services/toast.service';
import GenericMultiPicker from '../../baseComponents/inputs/select/GenericMultiPicker';
import ArrayUtils from '~/utils/arrayUtils';
import UserService from '~/services/user.service';
import UserEmailNamePair from '../../UserEmailNamePair';
import MapperService from '~/services/mapper.service';
import { LOADING_STATE } from '~/constants/LoadingState';
import DeliveriesService from '~/services/deliveries.service';
import Spinner from '../../Spinner';
import UserAction from '~/models/userActions/UserAction';
import { saveUserActions } from '~/redux/userinfoSlice';
import { updateDeliveryNotes } from '~/redux/deliveryNotesSlice';
import DeliveryNoteActionMetaData from './DeliveryNoteActionMetaData';

const mapStateToProps = (state) => ({
  users: state.users,
  companies: state.companies.companies,
  userinfo: state.userinfo,
  deliveryNotes: state.deliveryNotes,
});
const mapDispatchToProps = () => ({
  saveUserActions,
  updateDeliveryNotes,
});

class RequestSignatureForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submittingForm: false,
      allUsers: [],
      pickedUsers: [],
      permittedUserIds: [],
      permittedUserIdsLoading: LOADING_STATE.LOADING,
      requestedUsers: [],
    };
  }

  componentDidMount() {
    UserService.loadUsers();
    this.initUsers();
    this.initPermittedUserIds();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this.props.users.users) !==
      JSON.stringify(prevProps.users.users)
    ) {
      this.initUsers();
    }

    if (
      JSON.stringify(this.props.users.users) !==
        JSON.stringify(prevProps.users.users) ||
      JSON.stringify(this.props.permittedUserIds) !==
        JSON.stringify(prevProps.permittedUserIds) ||
      JSON.stringify(this.state.permittedUserIds) !==
        JSON.stringify(prevState.permittedUserIds)
    ) {
      this.initPickedUsers();
    }

    if (
      this.props.permittedUserIds.length === 0 &&
      JSON.stringify(this.props.deliveryNoteIds) !==
        JSON.stringify(prevProps.deliveryNoteIds)
    ) {
      this.initPermittedUserIds();
    }

    if (
      JSON.stringify(this.props.deliveryNoteIds) !==
        JSON.stringify(prevProps.deliveryNoteIds) ||
      JSON.stringify(this.props.userinfo.userActions) !==
        JSON.stringify(prevProps.userinfo.userActions) ||
      JSON.stringify(this.state.allUsers) !== JSON.stringify(prevState.allUsers)
    ) {
      this.initRequestedUsers();
    }
  }

  initUsers() {
    const users = this.props.users.users
      .filter((user) => user.active)
      .map((user) => {
        const newUser = {
          id: user.id,
          active: user.active,
          name: user.getName(),
          date: null,
          count: 0,
          email: user.email,
          nameComponent: <UserEmailNamePair user={user} />,
        };

        return MapperService.addSearchStringWithValues(newUser, [
          user.email,
          user.getName(),
        ]);
      });

    this.setState({
      allUsers: ArrayUtils.sortByKey(users, 'email'),
    });
  }

  initPickedUsers() {
    let pickedUsers = [];

    if (this.state.permittedUserIds.length > 0) {
      pickedUsers = this.state.allUsers.filter((user) =>
        this.state.permittedUserIds.includes(user.id),
      );
    }

    if (this.props.permittedUserIds.length > 0) {
      pickedUsers = this.state.allUsers.filter((user) =>
        this.props.permittedUserIds.includes(user.id),
      );
    }

    this.setState({
      pickedUsers,
    });
  }

  async initPermittedUserIds() {
    this.setState({
      permittedUserIdsLoading: LOADING_STATE.LOADING,
    });

    const deliveryNotes = [];

    for (let index = 0; index < this.props.deliveryNoteIds.length; index++) {
      const [deliveryNote, error] = await promiseHandler(
        DeliveriesService.getDeliveryNoteById(
          this.props.deliveryNoteIds[index],
        ),
      );

      if (error) {
        Log.error(
          'Failed to load delivery note. id: ' +
            this.props.deliveryNoteIds[index],
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to load delivery note',
          Log.FEATURE.NOTIFICATIONS,
          Log.TYPE.ERROR,
        );
        continue;
      }

      const [response2, error2] = await promiseHandler(
        deliveryNote.initPermittedUsers(),
      );

      if (error2) {
        Log.error(
          'Failed to initialize permitted users. delivery note id: ' +
            this.props.deliveryNoteIds[index],
          error2,
        );
        Log.productAnalyticsEvent(
          'Failed initialize permitted users of referenced dln',
          Log.FEATURE.NOTIFICATIONS,
          Log.TYPE.ERROR,
        );
        continue;
      }

      deliveryNotes.push(deliveryNote);
    }

    const permittedUserIds = [];

    for (const deliveryNote of deliveryNotes) {
      for (const permittedUser of deliveryNote.permittedUsers) {
        permittedUserIds.push(permittedUser.id);
      }
    }

    this.setState({
      permittedUserIds: ArrayUtils.removeDuplicates(permittedUserIds),
      permittedUserIdsLoading: LOADING_STATE.SUCCEEDED,
    });
  }

  initRequestedUsers() {
    const userActionsList = [];

    for (const deliveryNoteId of this.props.deliveryNoteIds) {
      if (this.props.userinfo.userActions[deliveryNoteId]) {
        userActionsList.push(this.props.userinfo.userActions[deliveryNoteId]);
      }
    }

    const requestedUsers = UserAction.getRequestedUsers(userActionsList);

    this.setState({
      requestedUsers,
    });
  }

  formSuccess = async (event) => {
    event.preventDefault();

    if (this.state.pickedUsers.length === 0) {
      ToastService.warning([
        'Bitte wähle mindestens einen Benutzer aus, der die Lieferung signieren soll.',
      ]);
      Log.productAnalyticsEvent(
        'Missing user for signature',
        Log.FEATURE.NOTIFICATIONS,
        Log.TYPE.FAILED_VALIDATION,
      );
      return;
    }

    Log.productAnalyticsEvent(
      'Submit request signature form',
      Log.FEATURE.NOTIFICATIONS,
    );

    // Check whether the user has added or removed users from the default list.
    const permittedUserIds = [
      ...this.props.permittedUserIds,
      ...this.state.permittedUserIds,
    ];
    const pickedUserIds = this.state.pickedUsers.map((user) => user.id);

    if (
      permittedUserIds.find(
        (permittedUserId) => !pickedUserIds.includes(permittedUserId),
      )
    ) {
      Log.productAnalyticsEvent(
        'Removed user(s) from default list of permitted users in request signature form',
        Log.FEATURE.NOTIFICATIONS,
      );
    }

    if (
      pickedUserIds.find(
        (pickedUserId) => !permittedUserIds.includes(pickedUserId),
      )
    ) {
      Log.productAnalyticsEvent(
        'Added user(s) to default list of permitted users in request signature form',
        Log.FEATURE.NOTIFICATIONS,
      );
    }

    this.setState({
      submittingForm: true,
    });

    const [response, error] = await promiseHandler(
      PushService.requestSignature(
        this.props.deliveryNoteIds,
        this.state.pickedUsers.map((user) => user.id),
      ),
    );

    if (error) {
      Log.error(
        'Failed to send push to request delivery note signature.',
        error,
      );
      ToastService.httpError(
        [
          'Signaturen konnten nicht angefordert werden wegen eines internen Fehlers.',
        ],
        error.response,
      );
      Log.productAnalyticsEvent(
        'Failed to request signatures',
        Log.FEATURE.NOTIFICATIONS,
        Log.TYPE.ERROR,
      );
      this.closeForm();
      return;
    }

    ToastService.success([
      'Die Signaturen wurden erfolgreich angefordert.',
      'Es wurde eine Benachrichtigung an die ausgewählten Benutzer geschickt.',
    ]);

    const [newUserActions, updatedDeliveryNotes] =
      UserAction.getEnhanceUserActionsAndDeliveryNotes(
        this.props.userinfo.userActions,
        this.state.pickedUsers,
        this.props.deliveryNotes.deliveryNotes,
        this.props.deliveryNoteIds,
        UserAction.TYPE.REQUEST_SIGNATURES,
      );

    this.props.saveUserActions(newUserActions);
    this.props.updateDeliveryNotes(updatedDeliveryNotes);

    const [response2, error2] = await promiseHandler(
      UserService.updateUserActions(newUserActions),
    );

    if (error2) {
      Log.error('Failed to update user actions.', error2);
    }

    this.closeForm();
  };
  formAbort = () => {
    Log.productAnalyticsEvent(
      'Abort request signature form',
      Log.FEATURE.NOTIFICATIONS,
    );

    this.closeForm();
  };

  closeForm() {
    this.setState({
      submittingForm: false,
      pickedUsers: [],
    });
    this.props.closeForm();
  }

  handleChange = (e) => {
    Log.productAnalyticsEvent(
      'Change users of request signature form',
      Log.FEATURE.NOTIFICATIONS,
    );

    this.setState({
      pickedUsers: e,
    });
  };

  getFormBody() {
    if (this.state.permittedUserIdsLoading === LOADING_STATE.LOADING) {
      return (
        <div className="w-800px">
          <Spinner alignLeft />
        </div>
      );
    }

    return (
      <div className="w-800px">
        Bitte gib die Benutzer an, welche die Lieferung(en) signieren sollen.
        <GenericMultiPicker
          textfieldLabel="Benutzer"
          pickedItems={this.state.pickedUsers}
          allItems={this.state.allUsers}
          callbackPickedItems={this.handleChange}
          fieldName="email"
          loading={this.props.users.usersLoading}
        />
        {this.state.requestedUsers.length > 0 ? (
          <>
            <div className="h-20px" />
            <DeliveryNoteActionMetaData
              title="Signatur bereits angefordert von:"
              users={this.state.requestedUsers}
            />
          </>
        ) : null}
      </div>
    );
  }

  render() {
    return (
      <BasicForm
        open={this.props.open ?? false}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        title="Signatur anfordern"
        submitButtonTitle="Signaturen anfordern"
        submittingForm={this.state.submittingForm}
      >
        {this.getFormBody()}
      </BasicForm>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps(),
)(RequestSignatureForm);
