import React from 'react';

import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  PictureAsPdf as PictureAsPdfIcon,
} from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  Grid,
  Slide,
  Menu,
  MenuItem,
} from '@mui/material';

import InvoiceMetaData from './InvoiceMetaData';
import InvoiceService from '~/services/invoices.service';
import DeliveriesService from '~/services/deliveries.service';
import ExportService from '~/services/export.service';
import { connect } from 'react-redux';
import { ROUTE } from '~/constants/Route';
import ToastService from '~/services/toast.service';
import InvoiceModel from '~/models/invoices/Invoice';
import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';
import { withErrorBoundary } from '~/ui/atoms';
import PdfViewer from '../PdfViewer';
import Spinner from '../Spinner';

import LocalStorageService from '~/services/localStorage.service';
import { setPageTitle } from '~/redux/menuSlice';
import InvoiceCheckSummary from './InvoiceCheck/invoiceCheckSummary/InvoiceCheckSummary';
import InvoiceReferencedDeliveryNotes from './InvoiceReferencedDeliveryNotes';

import { LOADING_STATE } from '~/constants/LoadingState';
import InvoiceCheckResult from '~/models/invoices/InvoiceCheckResult';
import InvoiceCheckCategories from './InvoiceCheck/invoiceCheckCategories/InvoiceCheckCategories';
import DocumentLoadingPage from '../DocumentLoadingPage';
import DocumentBrowser from '../DocumentBrowser';
import cloneDeep from 'lodash/cloneDeep';
import UserUtils from '~/utils/userUtils';

import { mapDeliveryRow } from '~/components/deliveries/utils';

const mapStateToProps = (state) => ({
  invoices: state.invoices,
});
const mapDispatchToProps = () => ({
  setPageTitle,
});

class Invoice extends React.Component {
  constructor(props) {
    super(props);

    this.PDF_SLIDE_DURATION = 1000;
    this.PDF_COOKIE = 'display_pdf';

    this.state = {
      invoice: null,
      loading: LOADING_STATE.LOADING,
      pdfBlob: null,
      pdfBase64: null,
      displayPdf: false,
      pdfViewerLoaded: false, // to not display pdf with loading screen
      pdfInScreen: false, // needed to coordinate when and where chart and pdf should be displayed
      referencedDeliveryNotes: [],
      referencedDeliveryNoteRows: [],
      referencedDeliveryNotesLoading: LOADING_STATE.NOT_LOADED,
      toSite: '-',
      menuAnchor: null,
      activeInvoiceCheckCategories: [],
    };

    this.pdfDiv = React.createRef();
    this.container = React.createRef();
  }

  componentDidMount() {
    this.loadDocument_safe();

    this.props.setPageTitle('Rechnung');
    document.title = 'VESTIGAS - Rechnung';
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.loadDocument_safe();
    }

    if (
      JSON.stringify(this.state.invoice?.referencedDeliveryNotes) !==
      JSON.stringify(prevState.invoice?.referencedDeliveryNotes)
    ) {
      this.loadReferencedDeliveryNotes_safe();
    }

    if (
      this.state.invoice?.id !== prevState.invoice?.id &&
      LocalStorageService.getLocalStorage(this.PDF_COOKIE)
    ) {
      this.displayPdf();
    }
  }

  getDirection() {
    if (this.props.match.path.startsWith(ROUTE.INCOMING_INVOICES.ROUTE)) {
      return InvoiceModel.DIRECTION.INCOMING;
    }

    if (this.props.match.path.startsWith(ROUTE.OUTGOING_INVOICES.ROUTE)) {
      return InvoiceModel.DIRECTION.OUTGOING;
    }

    return InvoiceModel.DIRECTION.NA;
  }

  getRoute() {
    let route = null;

    if (this.getDirection() === InvoiceModel.DIRECTION.INCOMING) {
      route = ROUTE.INCOMING_INVOICE.ROUTE;
    }

    if (this.getDirection() === InvoiceModel.DIRECTION.OUTGOING) {
      route = ROUTE.OUTGOING_INVOICE.ROUTE;
    }

    return route;
  }

  loadDocument_safe() {
    this.loadDocument().catch((error) => {
      Log.error(
        'Failed to load invoice. id: ' + this.props.match.params.id,
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to load invoice',
        Log.FEATURE.INVOICE,
        Log.TYPE.ERROR,
      );

      this.setState({
        loading: LOADING_STATE.FAILED,
      });
    });
  }

  async loadDocument() {
    const [invoice, error] = await promiseHandler(
      InvoiceService.getInvoiceById(
        this.props.match.params.id,
        this.getDirection(),
      ),
    );

    if (error) {
      throw error;
    }

    this.setState({
      invoice,
      loading: LOADING_STATE.SUCCEEDED,
      activeInvoiceCheckCategories: Object.keys(invoice.checkCategory)
        .filter(
          (key) =>
            invoice.checkCategory[key].status ===
              InvoiceCheckResult.STATUS.ERROR ||
            invoice.checkCategory[key].status ===
              InvoiceCheckResult.STATUS.WARNING,
        )
        .map((key) => invoice.checkCategory[key].name),
      pdfBlob: null,
    });

    this.props.setPageTitle('Rechnung ' + (invoice.number ?? ''));
    document.title = 'VESTIGAS - Rechnung ' + (invoice.number ?? '');
  }

  loadReferencedDeliveryNotes_safe() {
    this.loadReferencedDeliveryNotes().catch((error) => {
      Log.error(
        'Failed to load referenced dlns of invoice. invoice id: ' +
          this.props.match.params.id,
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to load referenced dlns',
        Log.FEATURE.INVOICE,
        Log.TYPE.ERROR,
      );
    });
  }

  async loadReferencedDeliveryNotes() {
    this.setState({
      referencedDeliveryNotesLoading: LOADING_STATE.LOADING,
    });

    const [newDeliveryNotes, error] = await promiseHandler(
      DeliveriesService.getDeliveryNotesByIds(
        this.state.invoice.referencedDeliveryNotes,
      ),
    );

    if (error) {
      Log.error(
        'Failed to load referenced dlns of invoice. invoice id: ' +
          this.props.match.params.id,
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to load referenced dlns',
        Log.FEATURE.INVOICE,
        Log.TYPE.ERROR,
      );
      return;
    }

    for (let index = 0; index < newDeliveryNotes?.length; index++) {
      const [response2, error2] = await promiseHandler(
        newDeliveryNotes[index].initPermittedUsers(),
      );

      if (error2) {
        Log.error(
          'Failed to initialize permitted users. delivery note id: ' +
            this.state.invoice.referencedDeliveryNotes[index],
          error2,
        );
        Log.productAnalyticsEvent(
          'Failed initialize permitted users of referenced dln',
          Log.FEATURE.INVOICE,
          Log.TYPE.ERROR,
        );
      }
    }

    const newInvoice = cloneDeep(this.state.invoice);
    newInvoice.updateToSite(newDeliveryNotes);
    newInvoice.updateStatus(newDeliveryNotes);

    this.setState({
      invoice: newInvoice,
      referencedDeliveryNotes: newDeliveryNotes,
      referencedDeliveryNoteRows: newDeliveryNotes.map(mapDeliveryRow),
      referencedDeliveryNotesLoading: LOADING_STATE.SUCCEEDED,
      toSite: newInvoice.toSite ?? '-',
    });
  }

  loadPdf() {
    return ExportService.getSpecificInvoiceAsPDF(this.state.invoice?.id)
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });

        this.parseBlobToBase64(blob);

        this.setState({
          pdfBlob: blob,
        });

        return blob;
      })
      .catch((error) => {
        ToastService.httpError(
          [ToastService.MESSAGE.PDF_DOWNLOAD_FAILED],
          error.response,
          ToastService.TYPE.WARNING,
        );
        Log.error(
          'Failed to download PDF of invoice. id: ' + this.state.invoice?.id,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to download invoice PDF',
          Log.FEATURE.PDF_DOWNLOAD,
          Log.TYPE.ERROR,
        );

        throw error;
      });
  }

  exportPdf = async () => {
    Log.info('Download invoice PDF', null, Log.BREADCRUMB.USER_ACTION.KEY);
    Log.productAnalyticsEvent('Download invoice PDF', Log.FEATURE.PDF_DOWNLOAD);

    let blob = this.state.pdfBlob;

    if (!blob) {
      const [newBlob, error] = await promiseHandler(this.loadPdf());

      if (error) {
        return;
      }

      blob = newBlob;
    }

    this.handleMenuClose();

    ExportService.downloadFileWithCustomName(
      blob,
      ExportService.getInvoiceFileName(this.state.invoice?.id, null, 'pdf'),
    );
  };
  exportExcel = () => {
    Log.productAnalyticsEvent(
      'Download invoice as Excel',
      Log.FEATURE.EXCEL_DOWNLOAD,
    );

    ExportService.exportInvoiceAsExcel(this.state.invoice);
  };
  onClickDisplayPdf = () => {
    if (this.state.displayPdf) {
      Log.productAnalyticsEvent('Close PDF', Log.FEATURE.INVOICE);
      this.hidePdf();
    } else {
      Log.productAnalyticsEvent('Open PDF', Log.FEATURE.INVOICE);
      this.displayPdf();
    }
  };
  hidePdf = async () => {
    Log.info('Close invoice PDF', null, Log.BREADCRUMB.USER_ACTION.KEY);

    this.setState({
      displayPdf: false,
    });

    LocalStorageService.setLocalStorage(this.PDF_COOKIE, false);

    setTimeout(() => {
      this.setState({
        pdfInScreen: false,
      });
    }, this.PDF_SLIDE_DURATION);
  };
  displayPdf = async () => {
    Log.info('Open invoice PDF', null, Log.BREADCRUMB.USER_ACTION.KEY);

    this.setState({
      displayPdf: true,
      pdfInScreen: true,
    });

    LocalStorageService.setLocalStorage(this.PDF_COOKIE, true);

    // If the file has already been loaded from the backend, it doesn't need to be loaded again.
    if (this.state.pdfBlob) {
      return;
    }

    const [, error] = await promiseHandler(this.loadPdf());

    if (error) {
      this.setState({
        displayPdf: false,
        pdfInScreen: false,
      });
    }
  };

  parseBlobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      this.setState({
        pdfBase64: reader.result,
      });
    };
  }

  handleMenuOpen = (event) => {
    this.setState({
      menuAnchor: event.currentTarget,
    });
  };
  handleMenuClose = (event) => {
    this.setState({
      menuAnchor: null,
    });
  };
  handlePdfViewerLoaded = () => {
    this.setState({
      pdfViewerLoaded: true,
    });
  };
  onCategoryClick = (categoryName) => {
    let newActiveInvoiceCheckCategories = [];

    if (this.state.activeInvoiceCheckCategories.includes(categoryName)) {
      Log.productAnalyticsEvent(
        'Hide category ' + categoryName,
        Log.FEATURE.INVOICE_CHECK,
      );
      newActiveInvoiceCheckCategories = [
        ...this.state.activeInvoiceCheckCategories,
      ].filter((item) => item !== categoryName);
    } else {
      Log.productAnalyticsEvent(
        'Open category ' + categoryName,
        Log.FEATURE.INVOICE_CHECK,
      );
      newActiveInvoiceCheckCategories = [
        categoryName,
        ...this.state.activeInvoiceCheckCategories,
      ];
    }

    this.setState({
      activeInvoiceCheckCategories: newActiveInvoiceCheckCategories,
    });
  };

  getNoCheckingPossibleDescription() {
    const descriptiveSubStatus = this.state.invoice.getDescriptiveSubStatus();

    if (!descriptiveSubStatus) {
      return <span className="bold">Keine Prüfung möglich.</span>;
    }

    return (
      <>
        <span className="bold">Keine Prüfung möglich -&nbsp;</span>
        {descriptiveSubStatus}
      </>
    );
  }

  render() {
    if (this.state.loading !== LOADING_STATE.SUCCEEDED) {
      return (
        <DocumentLoadingPage
          loading={this.state.loading}
          documentType="Rechnung"
        />
      );
    }

    const menuId = 'pdf-menu';
    const renderMenu = (
      <Menu
        anchorEl={this.state.menuAnchor}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(this.state.menuAnchor)}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.exportPdf}>PDF Download</MenuItem>
        {UserUtils.isInvoiceExcelAllowedUser() ? (
          <MenuItem onClick={this.exportExcel}>Excel Download</MenuItem>
        ) : null}
      </Menu>
    );

    const pdf = (
      <Slide
        direction="left"
        in={this.state.displayPdf && this.state.pdfViewerLoaded}
        timeout={this.PDF_SLIDE_DURATION}
        container={this.container.current}
      >
        <div
          ref={this.pdfDiv}
          className="rounded-5px border-grey p-20px flex-c-c w-full bg-white"
        >
          <PdfViewer
            pdf={this.state.pdfBase64}
            width={this.pdfDiv.current?.offsetWidth}
            onLoadSuccess={this.handlePdfViewerLoaded}
            download={this.exportPdf}
            close={this.onClickDisplayPdf}
          />
        </div>
      </Slide>
    );

    const emptyPdfTile = (
      <div className="rounded-5px border-grey p-20px flex-c-c h-300px w-full bg-white">
        <Button
          variant="outlined"
          color="primary"
          startIcon={
            this.state.displayPdf && !this.state.pdfViewerLoaded ? null : (
              <PictureAsPdfIcon />
            )
          }
          disabled={this.state.displayPdf && !this.state.pdfViewerLoaded}
          onClick={this.onClickDisplayPdf}
        >
          {this.state.displayPdf ? (
            this.state.pdfViewerLoaded ? (
              'PDF Schließen'
            ) : (
              <Spinner white title="Öffnen..." />
            )
          ) : (
            'PDF Anzeigen'
          )}
        </Button>
      </div>
    );

    return (
      <div className="main-padding contain-paint" ref={this.container}>
        <div className="rounded-5px box-shadow-blue p-20px mb-20px w-full bg-white">
          <InvoiceMetaData
            invoice={this.state.invoice}
            toSite={this.state.toSite}
          />
        </div>
        {this.state.invoice.status ===
        InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE ? null : (
          <div className="rounded-5px box-shadow-blue p-20px mb-20px w-full bg-white">
            <InvoiceReferencedDeliveryNotes
              referencedDeliveryNoteRows={this.state.referencedDeliveryNoteRows}
              loading={this.state.referencedDeliveryNotesLoading}
            />
          </div>
        )}
        {this.state.invoice.status ===
        InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE ? (
          <div className="bg-grey500 rounded-5px box-shadow-blue h-50px flex-c-c mb-20px w-full text-white">
            {this.getNoCheckingPossibleDescription()}
          </div>
        ) : null}
        <div className="h-1px mt-30px mb-20px bg-grey400 w-full" />
        <div className="text-24px mb-20px flex-sb-s">
          Rechnungsprüfung
          <div className="flex-e-e pb-1rem">
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <Button
                className="primary-button w-185px"
                startIcon={
                  this.state.displayPdf &&
                  !this.state.pdfViewerLoaded ? null : (
                    <PictureAsPdfIcon />
                  )
                }
                disabled={this.state.displayPdf && !this.state.pdfViewerLoaded}
                onClick={this.onClickDisplayPdf}
              >
                {this.state.displayPdf ? (
                  this.state.pdfViewerLoaded ? (
                    'PDF Schließen'
                  ) : (
                    <Spinner white title="Öffnen..." />
                  )
                ) : (
                  'PDF Anzeigen'
                )}
              </Button>
              <Button
                className="primary-button"
                aria-controls={menuId}
                onClick={this.handleMenuOpen}
              >
                <KeyboardArrowDownIcon />
              </Button>
            </ButtonGroup>
            {renderMenu}
          </div>
        </div>
        <Grid container columnSpacing="20px">
          <Grid item lg={12} xl={6}>
            <div className="flexdir-column gap-20px flex">
              <div className="rounded-5px box-shadow-blue pt-20px pb-20px w-full bg-white">
                <InvoiceCheckSummary
                  invoice={this.state.invoice}
                  referencedDeliveryNotes={this.state.referencedDeliveryNotes}
                  activeInvoiceCheckCategories={
                    this.state.activeInvoiceCheckCategories
                  }
                  onCategoryClick={this.onCategoryClick}
                />
              </div>
              {this.state.pdfInScreen ? (
                <InvoiceCheckCategories
                  invoice={this.state.invoice}
                  activeInvoiceCheckCategories={
                    this.state.activeInvoiceCheckCategories
                  }
                />
              ) : null}
              {/* Don't display chart because certain cases display weird results. E.g. invoices without dln reference in articles are displayed with green amounts in chart. */}
              {/* chart */}
            </div>
          </Grid>
          <Grid item lg={12} xl={6}>
            <div className="flexdir-column flex">
              {this.state.pdfInScreen ? null : (
                <InvoiceCheckCategories
                  invoice={this.state.invoice}
                  activeInvoiceCheckCategories={
                    this.state.activeInvoiceCheckCategories
                  }
                />
              )}
              {this.state.pdfInScreen ? pdf : emptyPdfTile}
            </div>
          </Grid>
        </Grid>
        <div
          className="min-h-2rem"
          /* This is a hacky workaround to get the padding bottom of 2rem. It is applied as child container to all divs with main-padding */
          /* A better solution would be to make the parent container min-h-fit-content so that the padding of main-padding is applied. */
          /* However, min-h-fit-content seems to not work with h-fill or generally with flexbox and flex-1. */
        />
        <DocumentBrowser
          browsableDocuments={this.props.invoices.browsableInvoices}
          documentId={this.props.match.params.id}
          route={this.getRoute()}
          history={this.props.history}
        />
      </div>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(Invoice),
  'Rechnung konnte nicht geladen werden.',
);
