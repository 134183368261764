import { withErrorBoundary } from '~/ui/atoms';
import Spinner from '../Spinner';
import { LOADING_STATE } from '~/constants/LoadingState';
import clsx from 'clsx';

const Tile = ({
  children,
  height,
  icon,
  loading,
  rightAnnotation,
  title,
  width,
}) => {
  const gridHeight = 310;
  const gridWidth = 360;
  const gridGap = 30;
  const tilePadding = 20;
  const columnHeaderHeight = 35;

  const tileHeight = height
    ? height * gridHeight + (height - 1) * gridGap
    : gridHeight;
  const tileWidth = width
    ? width * gridWidth + (width - 1) * gridGap
    : gridWidth;
  const contentHeight = tileHeight - 2 * tilePadding - columnHeaderHeight;

  const content = () => {
    if (loading === LOADING_STATE.LOADING) {
      return (
        <div style={{ height: contentHeight }}>
          <Spinner />
        </div>
      );
    }

    if (loading === LOADING_STATE.FAILED) {
      return (
        <div style={{ height: contentHeight }}>
          <div className="flex-c-c text-grey400 bold h-full">
            {'Daten konnten nicht geladen werden.'}
          </div>
        </div>
      );
    }

    return <div style={{ height: contentHeight }}>{children}</div>;
  };

  return (
    <div
      className="rounded-5px box-shadow-blue p-20px inline-block bg-white"
      style={{
        height: tileHeight,
        width: tileWidth,
      }}
    >
      <div className="flex-sb-c">
        <div className="flex-s-c">
          {icon}
          <div className={clsx(['bold text-20px', { 'ml-20px': icon }])}>
            {title}
          </div>
        </div>
        <div className="text-grey400">{rightAnnotation}</div>
      </div>
      {content()}
    </div>
  );
};

export default withErrorBoundary(Tile, null);
