import React from 'react';

import { InputLabel, MenuItem, Select, Button } from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import Log from '~/utils/Log';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import Permissions from '~/models/masterdata/Permissions';
import PermissionGrantMultiPicker from '../../permissionGrant/PermissionGrantMultiPicker';
import { LightTooltip } from '~/utils/componentUtils';
import PermissionForm from '../../permissionGrant/PermissionForm';
import ToastService from '~/services/toast.service';
import PermissionGrantService from '~/services/permissionGrant.service';
import PromiseUtils from '~/utils/promiseUtils';
import { promiseHandler } from '~/utils/promiseHandler';
import SiteService from '~/services/site.service';

class CreateUserWizardPermissionGrantPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pickedEntityType: PermissionGrant.ENTITY_TYPE.SITE.KEY,
      pickedEntities: [],
      permissionFormOpen: false,
      permissions: new Permissions(),
    };
  }

  handleChangeEntityType = (event) => {
    Log.info(
      'Change form value of entity type',
      { from: this.state.pickedEntityType, to: event.target.value },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change entity type in create user wizard',
      Log.FEATURE.WIZARD,
    );

    this.setState({
      pickedEntityType: event.target.value,
      pickedEntities: [],
    });
  };
  handleChangeEntities = (event) => {
    const newPickedEntities = event.map((item) => item.id);

    Log.info(
      'Change form value of entities',
      { from: this.state.pickedEntities, to: newPickedEntities },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change entities in create user wizard',
      Log.FEATURE.WIZARD,
    );

    this.setState({
      pickedEntities: newPickedEntities,
    });
  };
  handleChangeRole = (event) => {
    Log.info(
      'Change form value of role',
      {
        from: this.state.permissions.getDefaultRoleName(),
        to: event.target.value,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change role (' + event.target.value + ') in create user wizard',
      Log.FEATURE.WIZARD,
    );

    const newPermissions = new Permissions();

    if (event.target.value === Permissions.INDIVIDUAL_ROLE) {
      this.setState({
        permissionFormOpen: true,
      });
      return;
    }

    newPermissions.initWithDefaultRole(event.target.value);

    this.setState({
      permissions: newPermissions,
    });
  };
  openPermissionForm = () => {
    Log.productAnalyticsEvent(
      'Open permission form in create user wizard',
      Log.FEATURE.WIZARD,
    );
    this.setState({
      permissionFormOpen: true,
    });
  };
  permissionFormSuccess = (permissions) => {
    this.setState({
      permissionFormOpen: false,
      permissions,
    });
  };
  permissionFormAbort = () => {
    this.setState({
      permissionFormOpen: false,
    });
  };

  async submit(userId, grantPermissionsOnCostCenters) {
    const promises = [];

    if (!this.state.permissions.permissionGranted()) {
      return;
    }

    for (const entity of this.state.pickedEntities) {
      const body = {
        permissions: this.state.permissions.getBackendPermissions(),
      };

      Log.info(
        'Submit permission grant form',
        body,
        Log.BREADCRUMB.FORM_SUBMIT.KEY,
      );

      const promise = PermissionGrantService.createNewPermissionGrant(
        PermissionGrant.SUBJECT_TYPE.USER.KEY,
        userId,
        this.state.pickedEntityType,
        entity,
        body,
      );

      promises.push(promise);
    }

    // Grant permissions on the connected cost centers of the sites.
    if (
      grantPermissionsOnCostCenters &&
      this.state.pickedEntityType === PermissionGrant.ENTITY_TYPE.SITE.KEY
    ) {
      for (let index = 0; index < this.state.pickedEntities.length; index++) {
        const [site, error] = await promiseHandler(
          SiteService.getSiteById(this.state.pickedEntities[index]),
        );

        if (error) {
          Log.error(
            'Failed to load site. id: ' + this.state.pickedEntities[index],
          );
          ToastService.error([
            'Berechtigungen konnten nicht auf alle Kostenstellen vollständig vergeben werden.',
          ]);
          continue;
        }

        for (const costCenterId of site.costCenters) {
          const body = {
            permissions: this.state.permissions.getBackendPermissions(),
          };

          Log.info(
            'Submit permission grant form',
            body,
            Log.BREADCRUMB.FORM_SUBMIT.KEY,
          );

          const promise = PermissionGrantService.createNewPermissionGrant(
            PermissionGrant.SUBJECT_TYPE.USER.KEY,
            userId,
            PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY,
            costCenterId,
            body,
          );

          promises.push(promise);
        }
      }
    }

    return PromiseUtils.allResolved(promises);
  }

  render() {
    return (
      <>
        <div className="flex-s-c gap-20px mt-20px">
          <div>
            <InputLabel className="text-13px">Berechtigung auf</InputLabel>
            <Select
              value={this.state.pickedEntityType}
              onChange={this.handleChangeEntityType}
              className="w-300px"
              size="small"
            >
              {PermissionGrant.getPickableEntityTypes().map((entity) => (
                <MenuItem value={entity.value} key={entity.value}>
                  {entity.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <PermissionGrantMultiPicker
            type="permission_to"
            entityType={this.state.pickedEntityType}
            pickedIds={this.state.pickedEntities}
            callbackPickedItems={this.handleChangeEntities}
            fullWidth
            // Needed for the enhanced label
            subjectType={PermissionGrant.SUBJECT_TYPE.USER.KEY}
            subjects={[]}
            displayPermissionGrantOfCostCentersWithSites={
              this.props.grantPermissionsOnCostCenters
            }
          />
        </div>
        <div className="flex-s-e gap-20px mt-20px">
          <div>
            <InputLabel className="text-13px">Berechtigung als</InputLabel>
            <Select
              value={this.state.permissions.getDefaultRoleName() ?? 'None'} // A random String has to be chosen as fallback value because otherwise renderValue wouldn't catch the case if(!this.state.permissions.permissionGranted())
              key={0}
              onChange={this.handleChangeRole}
              className="w-300px"
              size="small"
              renderValue={(id) => {
                if (!this.state.permissions.permissionGranted()) {
                  return (
                    <span className="text-mui-not-selected-grey">
                      Bitte Berechtigungen vergeben
                    </span>
                  );
                }

                return Permissions.getPickableRoles().find(
                  (option) => option.id === id,
                ).name;
              }}
            >
              {Permissions.getPickableRoles().map((role) => (
                <MenuItem value={role.id} key={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="mb-2px">
            <LightTooltip title="Detaillierte Berechtigungen einsehen">
              <Button
                variant="outlined"
                color="primary"
                onClick={this.openPermissionForm}
              >
                Details
              </Button>
            </LightTooltip>
          </div>
        </div>
        <PermissionForm
          open={this.state.permissionFormOpen}
          formSuccess={this.permissionFormSuccess}
          formAbort={this.permissionFormAbort}
          permissions={this.state.permissions}
        />
        <br />
        <br />
        <br />
        <br />
      </>
    );
  }
}

export default withErrorBoundary(
  CreateUserWizardPermissionGrantPicker,
  'Daten konnten nicht geladen werden.',
);
