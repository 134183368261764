import React from 'react';

import { Add as AddIcon } from '@mui/icons-material';
import {
  Grid,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import { withErrorBoundary } from '~/ui/atoms';
import Log from '~/utils/Log';
import PromiseUtils from '~/utils/promiseUtils';
import UpdatePermissionsWizardPermissionGrantPicker from './UpdatePermissionsWizardPermissionGrantPicker';

class UpdatePermissionsWizardGrantPermissions extends React.Component {
  constructor() {
    super();

    this.state = {
      permissionGrantPickerList: [],
      grantPermissionsOnCostCenters: true,
    };

    this.permissionGrantChilds = [];
  }

  componentDidMount() {
    this.addNewPermissionGrantPicker();
  }

  handleGrantPermissionsRadioChange = (event) => {
    Log.info(
      'Change form value of grant permissions radio button',
      { from: this.props.grantPermissions, to: event.target.value },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change grant permissions radio button in update permissions wizard',
      Log.FEATURE.WIZARD,
    );
    this.props.setGrantPermissions(
      event.target.value === 'grant_permissions_yes',
    );
  };
  handleGrantPermissionsOnCostCentersRadioChange = (event) => {
    Log.info(
      'Change form value of grant permissions on cost centers radio button',
      {
        from: this.state.grantPermissionsOnCostCenters,
        to: event.target.value,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change grant permissions on cost centers radio button in update permissions wizard',
      Log.FEATURE.WIZARD,
    );
    this.setState({
      grantPermissionsOnCostCenters: event.target.value === 'cost_centers_yes',
    });
  };
  addNewPermissionGrantPicker = () => {
    this.permissionGrantChilds.push(React.createRef());

    const newPermissionGrantPickerList = [
      ...this.state.permissionGrantPickerList,
    ];
    newPermissionGrantPickerList.push({
      pickedSubjects: this.props.pickedSubjects,
      setPickedSubjects: this.props.setPickedSubjects,
      ref: this.permissionGrantChilds[
        this.state.permissionGrantPickerList.length
      ],
      key: this.state.permissionGrantPickerList.length,
    });

    Log.info(
      'Add permission grant picker',
      { values: newPermissionGrantPickerList },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );

    // Initially, a picker is added automatically. We don't want to incorrectly track this as user behavior.
    if (this.state.permissionGrantPickerList.length > 0) {
      Log.productAnalyticsEvent('Add picker', Log.FEATURE.PERMISSIONS);
    }

    this.setState({
      permissionGrantPickerList: newPermissionGrantPickerList,
    });
  };

  async submit() {
    const promises = [];

    for (const child of this.permissionGrantChilds) {
      const promise = child.current.submit(
        this.state.grantPermissionsOnCostCenters,
      );
      promises.push(promise);
    }

    return PromiseUtils.allResolved(promises);
  }

  render() {
    return (
      <Grid container direction="row" spacing={3} space={4}>
        <Grid item xs={12} lg={12}>
          <h3 className="main-text mt-0">
            Sollen neue Berechtigungen vergeben werden?
          </h3>
          <RadioGroup
            onChange={this.handleGrantPermissionsRadioChange}
            value={
              this.props.grantPermissions
                ? 'grant_permissions_yes'
                : 'grant_permissions_no'
            }
            row
          >
            <FormControlLabel
              value="grant_permissions_yes"
              control={<Radio />}
              label="Ja"
              className="mr-50px"
            />
            <FormControlLabel
              value="grant_permissions_no"
              control={<Radio />}
              label="Nein"
            />
          </RadioGroup>
        </Grid>
        {this.props.grantPermissions ? (
          <>
            <Grid item xs={12} lg={12}>
              <h3 className="mt-20px main-text">
                Sollen die Berechtigungen jeweils auf die verbundenen Standorte
                und Kostenstellen vergeben werden?
              </h3>
              <RadioGroup
                onChange={this.handleGrantPermissionsOnCostCentersRadioChange}
                value={
                  this.state.grantPermissionsOnCostCenters
                    ? 'cost_centers_yes'
                    : 'cost_centers_no'
                }
                row
              >
                <FormControlLabel
                  value="cost_centers_yes"
                  control={<Radio />}
                  label="Ja (empfohlen)"
                  className="mr-50px"
                />
                <FormControlLabel
                  value="cost_centers_no"
                  control={<Radio />}
                  label="Nein"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} lg={12}>
              {this.state.permissionGrantPickerList.map(
                (permissionGrantPicker) => (
                  <UpdatePermissionsWizardPermissionGrantPicker
                    pickedSubjects={permissionGrantPicker.pickedSubjects}
                    setPickedSubjects={permissionGrantPicker.setPickedSubjects}
                    grantPermissionsOnCostCenters={
                      this.state.grantPermissionsOnCostCenters
                    }
                    ref={permissionGrantPicker.ref}
                    key={permissionGrantPicker.key}
                  />
                ),
              )}
              <div className="flex-c-c w-full">
                <IconButton
                  className="m-auto"
                  onClick={this.addNewPermissionGrantPicker}
                  size="large"
                >
                  <AddIcon />
                </IconButton>
              </div>
            </Grid>
          </>
        ) : null}
      </Grid>
    );
  }
}

export default withErrorBoundary(
  UpdatePermissionsWizardGrantPermissions,
  'Daten konnten nicht geladen werden.',
);
