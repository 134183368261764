import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import MainMenu from './MainMenu';
import Config from '~/Config';
import userService from '~/services/user.service';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveUserCompany,
  updateUserInfos,
  saveUserPermissions,
  saveUserType,
  saveUserId,
  saveProfilePicture,
  saveArticleColorMapping,
  saveDeliveryTabs,
  setUserinfoLoading,
  setUserPermissionsLoading,
  setUserHasLoggedInViaSSO,
  saveUserActions,
  saveUserFeatureFlags,
} from '~/redux/userinfoSlice';
import { saveCompanyAccount } from '~/redux/companyAccountSlice';
import { replaceCompanies, setCompaniesLoading } from '~/redux/companiesSlice';
import DeliveriesService from '~/services/deliveries.service';
import InvoicesService from '~/services/invoices.service';
import Company from '~/models/masterdata/Company';
import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';
import { withErrorBoundary } from '~/ui/atoms';
import AuthService from '~/services/auth.service';
import {
  replaceDeliveryNoteTemplates,
  saveDeliveryNotes,
  saveDeliveryChanges,
  setDeliveryNotesLoading,
  replaceBrowsableDeliveryNotes,
} from '~/redux/deliveryNotesSlice';
import {
  replaceBrowsableInvoices,
  replaceFilteredIncomingInvoices,
  replaceFilteredOutgoingInvoices,
  replaceIncomingInvoices,
  replaceOutgoingInvoices,
  saveIncomingInvoices,
  saveOutgoingInvoices,
  setIncomingInvoicesLoading,
  setOutgoingInvoicesLoading,
} from '~/redux/invoicesSlice';
import LocalStorageService from '~/services/localStorage.service';
import Spinner from '../Spinner';
import { replaceSites, setSitesLoading } from '~/redux/sitesSlice';
import {
  replaceCostCenters,
  setCostCentersLoading,
} from '~/redux/costCentersSlice';
import { replaceVehicles, setVehiclesLoading } from '~/redux/vehiclesSlice';
import {
  replaceCustomerSubscriptions,
  setCustomerSubscriptionsLoading,
} from '~/redux/customerSubscriptionsSlice';
import {
  replacePdfTemplates,
  setPdfTemplatesLoading,
} from '~/redux/pdfTemplatesSlice';
import { replaceUsers, setUsersLoading } from '~/redux/usersSlice';
import { replaceCustomFields } from '~/redux/customFieldsSlice';
import { LOADING_STATE } from '~/constants/LoadingState';
import { FEATURE_FLAG } from '~/constants/FeatureFlag';
import DeactivatedAccountModal from '../DeactivatedAccountModal';
import CustomFieldService from '~/services/customField.service';
import ThirdPartyService from '~/services/thirdParty.service';
import {
  replaceOrganisationalGroups,
  setOrganisationalGroupsLoading,
} from '~/redux/organisationalGroupsSlice';
import {
  replaceUserGroups,
  setUserGroupsLoading,
} from '~/redux/userGroupsSlice';
import {
  replaceActiveSiteReferences,
  replaceBlacklistedSiteReferences,
  replaceInactiveSiteReferences,
  replaceSiteReferences,
  setSiteReferencesLoading,
} from '~/redux/siteReferencesSlice';
import { es6ClassFactory as ES6ClassFactory } from '~/utils/ES6ClassFactory';
import Invoice from '~/models/invoices/Invoice';
import { setCurrentIssues } from '~/redux/dataExchangesSlice';
import userUtils from '~/utils/userUtils';
import ExternalSupplierOverview from '../dataExchanges/ExternalSupplierOverview';
import UserUtils from '~/utils/userUtils';
import UserFeatureFlags from '~/models/masterdata/UserFeatureFlags';
import {
  setDashboard_filterGroups,
  setDelivery_filterGroups,
  setInvoice_filterGroups,
} from '~/redux/filtersSlice';
import { BayWaScreen } from '../deliveries/bayWaScreen';

export default withErrorBoundary(
  withRouter(function AppInitializer(props) {
    const dispatch = useDispatch();
    const {
      filteredDeliveryNotesVersion,
      filteredDeliveryNotesVersionUpdateByBulkLoad,
    } = useSelector((state) => state.deliveryNotes);
    const {
      incomingInvoices,
      incomingInvoicesFromBackendVersion,
      outgoingInvoices,
      outgoingInvoicesFromBackendVersion,
    } = useSelector((state) => state.invoices);
    const { dataExchangeCompanies, dataExchanges } = useSelector(
      (state) => state.dataExchanges,
    );
    const { selectedCostCenters, selectedSites } = useSelector(
      (state) => state.filters,
    );

    const [displayPage, setDisplayPage] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');

    // query all information that should be in global state
    const loadData = () => {
      loadUserData();
      loadIncomingInvoices();
      loadOutgoingInvoices();
      loadProfilePicture();
      loadCustomFields();
      handlePassedMasterData();
    };

    async function loadUserData() {
      if (
        window.redux_state &&
        window.redux_state.userinfo.userinfoLoading ===
          LOADING_STATE.SUCCEEDED &&
        window.redux_state.userinfo.userPermissionsLoading ===
          LOADING_STATE.SUCCEEDED
      ) {
        dispatch(
          saveCompanyAccount(window.redux_state.companyAccount.companyAccount),
        );
        dispatch(
          saveUserPermissions(window.redux_state.userinfo.userPermissions),
        );
        dispatch(updateUserInfos(window.redux_state.userinfo.userinfo));
        dispatch(saveUserType(window.redux_state.userinfo.userinfo.userType));
        dispatch(
          saveArticleColorMapping({
            mapping: window.redux_state.userinfo.articleColorMapping,
          }),
        );
        dispatch(saveDeliveryTabs(window.redux_state.userinfo.deliveryTabs));
        dispatch(
          setDashboard_filterGroups(
            window.redux_state.filters.dashboard_filterGroups,
          ),
        );
        dispatch(
          setDelivery_filterGroups(
            window.redux_state.filters.delivery_filterGroups,
          ),
        );
        dispatch(
          setInvoice_filterGroups(
            window.redux_state.filters.invoice_filterGroups,
          ),
        );
        dispatch(saveUserActions(window.redux_state.userinfo.userActions));
        dispatch(saveUserCompany(window.redux_state.userinfo.userinfo.company));
        dispatch(
          saveUserFeatureFlags(
            window.redux_state.userinfo.userinfo.userFeatureFlags,
          ),
        );

        ThirdPartyService.initSentryCompany(
          window.redux_state.userinfo.userinfo.company.name,
        );
        ThirdPartyService.initProductAnalyticsTools(
          window.redux_state.companyAccount.companyAccount.featureFlags[
            FEATURE_FLAG.PRODUCT_ANALYTICS_TOOLS
          ],
          window.redux_state.userinfo.userinfo.company.name,
          window.redux_state.userinfo.userinfo.isVestigasSupport,
        );

        Log.info('Userinfo state passed via window.redux_state.');

        loadDeliveryNotes(true);
        loadDeliveryNoteTemplates();

        return;
      }

      dispatch(setUserPermissionsLoading(LOADING_STATE.LOADING));
      dispatch(setUserinfoLoading(LOADING_STATE.LOADING));

      const [response, error] = await promiseHandler(userService.getData());

      if (error) {
        dispatch(setUserPermissionsLoading(LOADING_STATE.FAILED));
        dispatch(setUserinfoLoading(LOADING_STATE.FAILED));
        return;
      }

      handleLoadUserDataSuccess(response);
    }

    async function handleLoadUserDataSuccess(response) {
      dispatch(
        saveCompanyAccount({
          id: response?.company_account?.id,
          name: response?.company_account?.name,
          featureFlags: response?.company_account?.data?.feature_flags,
        }),
      );

      // Store the feature flag sentry_google_analytics_disabled in the cookies and reload the page if a change has been made.
      // Storing in the cookies is needed so that on next page load, the webapp already knows if Google Analytics is allowed even before the user data has been loaded from the backend.
      if (
        response?.company_account?.data?.feature_flags?.[
          FEATURE_FLAG.SENTRY_GOOGLE_ANALYTICS_DISABLED
        ] &&
        !LocalStorageService.sentryGoogleAnalyticsDisabled()
      ) {
        LocalStorageService.disableSentryGoogleAnalytics();
        window.location.reload();
      } else if (
        !response?.company_account?.data?.feature_flags?.[
          FEATURE_FLAG.SENTRY_GOOGLE_ANALYTICS_DISABLED
        ] &&
        LocalStorageService.sentryGoogleAnalyticsDisabled()
      ) {
        LocalStorageService.enableSentryGoogleAnalytics();
        window.location.reload();
      }

      dispatch(saveUserPermissions(response?.total_permissions ?? []));
      dispatch(
        updateUserInfos({
          firstname: response?.first_name,
          lastname: response?.last_name,
          position: response?.settings?.position,
          isVestigasSupport: response?.is_vestigas_support,
          phone: response?.settings?.contact_information,
        }),
      );
      dispatch(saveUserType(response?.settings?.user_type));
      dispatch(
        saveUserFeatureFlags(
          new UserFeatureFlags(response?.settings?.feature_flags),
        ),
      );

      dispatch(
        saveArticleColorMapping({
          mapping:
            response?.settings?.webapp_settings?.articleColorMapping ?? [],
        }),
      );
      dispatch(
        saveDeliveryTabs(response?.settings?.webapp_delivery_tabs ?? []),
      );
      dispatch(
        setDashboard_filterGroups(
          response?.settings?.webapp_dashboard_filter_groups ?? [],
        ),
      );
      dispatch(
        setDelivery_filterGroups(
          response?.settings?.webapp_delivery_filter_groups ?? [],
        ),
      );
      dispatch(
        setInvoice_filterGroups(
          response?.settings?.webapp_invoice_filter_groups ?? [],
        ),
      );
      dispatch(saveUserActions(response?.settings?.webapp_user_actions ?? {}));
      dispatch(
        replaceActiveSiteReferences(
          response?.settings?.webapp_site_references?.active_site_references ??
            [],
        ),
      );
      dispatch(
        replaceInactiveSiteReferences(
          response?.settings?.webapp_site_references
            ?.inactive_site_references ?? [],
        ),
      );
      dispatch(
        replaceBlacklistedSiteReferences(
          response?.settings?.webapp_site_references
            ?.blacklisted_site_references ?? [],
        ),
      );

      const company = new Company(response?.company);
      dispatch(saveUserCompany(company));

      ThirdPartyService.initSentryCompany(company.name);
      ThirdPartyService.initProductAnalyticsTools(
        response?.company_account?.data?.feature_flags?.[
          FEATURE_FLAG.PRODUCT_ANALYTICS_TOOLS
        ],
        company.name,
        response?.is_vestigas_support,
      );

      loadDeliveryNotes(true);

      if (
        response?.company_account?.data?.feature_flags?.[
          FEATURE_FLAG.CREATE_DELIVERY_NOTE
        ]
      ) {
        loadDeliveryNoteTemplates();
      }
    }

    // When initializing dlns, the company of the logged-in user is needed to determine the process role.
    async function loadDeliveryNotes(companyLoaded) {
      // This handles the (edge) case that the data is passed to another tab by copying the redux state into window.
      if (
        window.redux_state &&
        window.redux_state.deliveryNotes.deliveryNotesLoading ===
          LOADING_STATE.SUCCEEDED
      ) {
        dispatch(
          saveDeliveryNotes(window.redux_state.deliveryNotes.deliveryNotes),
        );
        dispatch(
          saveDeliveryChanges(window.redux_state.deliveryNotes.deliveryChanges),
        );
        dispatch(
          replaceBrowsableDeliveryNotes(
            window.redux_state.deliveryNotes.browsableDeliveryNotes,
          ),
        );

        Log.info('Delivery notes state passed via window.redux_state.');

        return;
      }

      if (!companyLoaded) {
        const [company, error] = await promiseHandler(userService.getCompany());

        if (error) {
          Log.error('Failed to load user company.', error);
          Log.productAnalyticsEvent(
            'Failed to load user company',
            Log.FEATURE.OTHER_FEATURE,
            Log.TYPE.ERROR,
          );
        }

        if (company) {
          dispatch(saveUserCompany(company));
        }
      }

      dispatch(setDeliveryNotesLoading(LOADING_STATE.LOADING));
      DeliveriesService.initStoredDlns(
        true,
        selectedSites,
        selectedCostCenters,
      ).catch((error) => {
        dispatch(setDeliveryNotesLoading(LOADING_STATE.FAILED));
      });
    }

    async function loadDeliveryNoteTemplates() {
      if (
        window.redux_state &&
        window.redux_state.deliveryNotes.deliveryNoteTemplatesLoading ===
          LOADING_STATE.SUCCEEDED
      ) {
        dispatch(
          replaceDeliveryNoteTemplates(
            window.redux_state.deliveryNotes.deliveryNoteTemplates,
          ),
        );

        Log.info(
          'Delivery notes templates state passed via window.redux_state.',
        );

        return;
      }

      DeliveriesService.refreshTemplates();
    }

    async function loadIncomingInvoices() {
      if (
        window.redux_state &&
        window.redux_state.invoices.incomingInvoicesLoading ===
          LOADING_STATE.SUCCEEDED
      ) {
        dispatch(
          saveIncomingInvoices(window.redux_state.invoices.incomingInvoices),
        );
        dispatch(
          replaceBrowsableInvoices(
            window.redux_state.invoices.browsableInvoices,
          ),
        );

        Log.info('Invoice state passed via window.redux_state.');

        return;
      }

      dispatch(setIncomingInvoicesLoading(LOADING_STATE.LOADING));
      InvoicesService.getAllIncomingInvoices().catch((error) => {
        dispatch(setIncomingInvoicesLoading(LOADING_STATE.FAILED));
      });
    }

    async function loadOutgoingInvoices() {
      if (
        window.redux_state &&
        window.redux_state.invoices.outgoingInvoicesLoading ===
          LOADING_STATE.SUCCEEDED
      ) {
        dispatch(
          saveOutgoingInvoices(window.redux_state.invoices.outgoingInvoices),
        );
        dispatch(
          replaceBrowsableInvoices(
            window.redux_state.invoices.browsableInvoices,
          ),
        );

        Log.info('Invoice state passed via window.redux_state.');

        return;
      }

      dispatch(setOutgoingInvoicesLoading(LOADING_STATE.LOADING));
      InvoicesService.getAllOutgoingInvoices().catch((error) => {
        dispatch(setOutgoingInvoicesLoading(LOADING_STATE.FAILED));
      });
    }

    async function loadProfilePicture() {
      const [response, error] = await promiseHandler(
        userService.getProfilePicture(),
      );

      if (error) {
        Log.error('Failed to load profile picture.', error);
        Log.productAnalyticsEvent(
          'Failed to load user profile picture',
          Log.FEATURE.OTHER_FEATURE,
          Log.TYPE.ERROR,
        );
        return;
      }

      dispatch(saveProfilePicture(response));
    }

    async function loadCustomFields() {
      if (
        window.redux_state &&
        window.redux_state.customFields.customFieldsLoading ===
          LOADING_STATE.SUCCEEDED
      ) {
        dispatch(
          replaceCustomFields(window.redux_state.customFields.customFields),
        );

        Log.info('Custom fields state passed via window.redux_state.');

        return;
      }

      CustomFieldService.loadCustomFields();
    }

    React.useEffect(() => {
      let currentIssues = false;

      // If there is a data exchange with a company that has current issues, the user should be informed about it.
      for (const dataExchange of dataExchanges) {
        const dataExchangeCompany = dataExchangeCompanies.find(
          (dataExchangeCompany) =>
            dataExchangeCompany.id === dataExchange.senderId,
        );
        if (dataExchangeCompany?.currentIssues) {
          currentIssues = true;
        }
      }

      dispatch(setCurrentIssues(currentIssues));
    }, [JSON.stringify(dataExchanges), JSON.stringify(dataExchangeCompanies)]);

    async function handlePassedMasterData() {
      if (!window.redux_state) {
        return;
      }

      if (window.redux_state.users.usersLoading === LOADING_STATE.SUCCEEDED) {
        Log.info('Users state passed via window.redux_state.');
        dispatch(replaceUsers(window.redux_state.users.users));
      } else {
        dispatch(setUsersLoading(LOADING_STATE.NOT_LOADED));
      }

      if (window.redux_state.sites.sitesLoading === LOADING_STATE.SUCCEEDED) {
        Log.info('Sites state passed via window.redux_state.');
        dispatch(replaceSites(window.redux_state.sites.sites));
      } else {
        dispatch(setSitesLoading(LOADING_STATE.NOT_LOADED));
      }

      if (
        window.redux_state.costCenters.costCentersLoading ===
        LOADING_STATE.SUCCEEDED
      ) {
        Log.info('Cost centers state passed via window.redux_state.');
        dispatch(
          replaceCostCenters(window.redux_state.costCenters.costCenters),
        );
      } else {
        dispatch(setCostCentersLoading(LOADING_STATE.NOT_LOADED));
      }

      if (
        window.redux_state.vehicles.vehiclesLoading === LOADING_STATE.SUCCEEDED
      ) {
        Log.info('Vehicles state passed via window.redux_state.');
        dispatch(replaceVehicles(window.redux_state.vehicles.vehicles));
      } else {
        dispatch(setVehiclesLoading(LOADING_STATE.NOT_LOADED));
      }

      if (
        window.redux_state.companies.companiesLoading ===
        LOADING_STATE.SUCCEEDED
      ) {
        Log.info('Companies state passed via window.redux_state.');
        dispatch(replaceCompanies(window.redux_state.companies.companies));
      } else {
        dispatch(setCompaniesLoading(LOADING_STATE.NOT_LOADED));
      }

      if (
        window.redux_state.organisationalGroups.organisationalGroupsLoading ===
        LOADING_STATE.SUCCEEDED
      ) {
        Log.info('Organisational groups state passed via window.redux_state.');
        dispatch(
          replaceOrganisationalGroups(
            window.redux_state.organisationalGroups.organisationalGroups,
          ),
        );
      } else {
        dispatch(setOrganisationalGroupsLoading(LOADING_STATE.NOT_LOADED));
      }

      if (
        window.redux_state.userGroups.userGroupsLoading ===
        LOADING_STATE.SUCCEEDED
      ) {
        Log.info('User groups state passed via window.redux_state.');
        dispatch(replaceUserGroups(window.redux_state.userGroups.userGroups));
      } else {
        dispatch(setUserGroupsLoading(LOADING_STATE.NOT_LOADED));
      }

      if (
        window.redux_state.siteReferences.siteReferencesLoading ===
        LOADING_STATE.SUCCEEDED
      ) {
        Log.info('Site references state passed via window.redux_state.');
        dispatch(
          replaceSiteReferences(
            window.redux_state.siteReferences.siteReferences,
          ),
        );
        dispatch(
          replaceActiveSiteReferences(
            window.redux_state.siteReferences.activeSiteReferences,
          ),
        );
        dispatch(
          replaceInactiveSiteReferences(
            window.redux_state.siteReferences.inactiveSiteReferences,
          ),
        );
        dispatch(
          replaceBlacklistedSiteReferences(
            window.redux_state.siteReferences.blacklistedSiteReferences,
          ),
        );
      } else {
        dispatch(setSiteReferencesLoading(LOADING_STATE.NOT_LOADED));
      }

      if (
        window.redux_state.customerSubscriptions
          .customerSubscriptionsLoading === LOADING_STATE.SUCCEEDED
      ) {
        Log.info('Customer subscriptions state passed via window.redux_state.');
        dispatch(
          replaceCustomerSubscriptions(
            window.redux_state.customerSubscriptions.customerSubscriptions,
          ),
        );
      } else {
        dispatch(setCustomerSubscriptionsLoading(LOADING_STATE.NOT_LOADED));
      }

      if (
        window.redux_state.pdfTemplates.pdfTemplatesLoading ===
        LOADING_STATE.SUCCEEDED
      ) {
        Log.info('Pdf templates state passed via window.redux_state.');
        dispatch(
          replacePdfTemplates(window.redux_state.pdfTemplates.pdfTemplates),
        );
      } else {
        dispatch(setPdfTemplatesLoading(LOADING_STATE.NOT_LOADED));
      }
    }

    const initIncomingInvoices = () => {
      const newIncomingInvoices = ES6ClassFactory.convertToES6Class(
        incomingInvoices,
        new Invoice(),
      );

      const noFilterApplied =
        selectedSites.length === 0 && selectedCostCenters.length === 0;

      for (const newIncomingInvoice of newIncomingInvoices) {
        // Update the invoice data based on the referenced delivery notes in the following cases:
        // ### For the toSite and status:
        // - If the init of the invoices has been triggered by the bulk load of the delivery notes
        //   (e.g. filteredDeliveryNotesVersionUpdateByBulkLoad)
        //   and the referenced delivery notes of the invoice haven't been loaded yet
        // ### For the containsFilteredDeliveryNote (to determine if invoice should be filtered out):
        // - If the init of the invoices has been triggered by the filter change
        //   (e.g. !filteredDeliveryNotesVersionUpdateByBulkLoad)
        // - If the init of the invoices has been triggered by the bulk load of the delivery notes
        //   (e.g. filteredDeliveryNotesVersionUpdateByBulkLoad)
        //   and the referenced delivery notes of the invoice haven't been loaded yet
        const updateToSiteAndStatus =
          filteredDeliveryNotesVersionUpdateByBulkLoad &&
          !newIncomingInvoice.referencedDeliveryNotesLoaded;
        const updateContainsFilteredDeliveryNote =
          !filteredDeliveryNotesVersionUpdateByBulkLoad ||
          (filteredDeliveryNotesVersionUpdateByBulkLoad &&
            !newIncomingInvoice.referencedDeliveryNotesLoaded);

        if (noFilterApplied) {
          // If there is no filter applied, we want to display all invoices anyway.
          // Therefore, we don't need to check if the invoice should be filtered out.
          newIncomingInvoice.containsFilteredDeliveryNote = true;
          newIncomingInvoice.initWithReferencedDeliveryNotes(
            updateToSiteAndStatus,
            false,
          );
        } else {
          // If there is a filter applied, we want to display only the invoices that are not filtered out.
          newIncomingInvoice.initWithReferencedDeliveryNotes(
            updateToSiteAndStatus,
            updateContainsFilteredDeliveryNote,
          );
        }
      }

      dispatch(replaceIncomingInvoices(newIncomingInvoices));
      dispatch(
        replaceFilteredIncomingInvoices(
          newIncomingInvoices.filter((invoice) => {
            // If a dln is referenced but filtered out, the invoice should be filtered out as well
            if (
              invoice.referencedDeliveryNotes.length > 0 &&
              !invoice.containsFilteredDeliveryNote
            ) {
              return false;
            }

            // If there is a filter applied, invoices without referenced delivery notes should be filtered out (e.g. create invoice)
            if (
              (selectedSites.length > 0 || selectedCostCenters.length > 0) &&
              invoice.referencedDeliveryNotes.length === 0
            ) {
              return false;
            }

            return true;
          }),
        ),
      );
    };

    const initOutgoingInvoices = () => {
      const newOutgoingInvoices = ES6ClassFactory.convertToES6Class(
        outgoingInvoices,
        new Invoice(),
      );

      const noFilterApplied =
        selectedSites.length === 0 && selectedCostCenters.length === 0;

      for (const newOutgoingInvoice of newOutgoingInvoices) {
        // Update the invoice data based on the referenced delivery notes in the following cases:
        // ### For the toSite and status:
        // - If the init of the invoices has been triggered by the bulk load of the delivery notes
        //   (e.g. filteredDeliveryNotesVersionUpdateByBulkLoad)
        //   and the referenced delivery notes of the invoice haven't been loaded yet
        // ### For the containsFilteredDeliveryNote (to determine if invoice should be filtered out):
        // - If the init of the invoices has been triggered by the filter change
        //   (e.g. !filteredDeliveryNotesVersionUpdateByBulkLoad)
        // - If the init of the invoices has been triggered by the bulk load of the delivery notes
        //   (e.g. filteredDeliveryNotesVersionUpdateByBulkLoad)
        //   and the referenced delivery notes of the invoice haven't been loaded yet
        const updateToSiteAndStatus =
          filteredDeliveryNotesVersionUpdateByBulkLoad &&
          !newOutgoingInvoice.referencedDeliveryNotesLoaded;
        const updateContainsFilteredDeliveryNote =
          !filteredDeliveryNotesVersionUpdateByBulkLoad ||
          (filteredDeliveryNotesVersionUpdateByBulkLoad &&
            !newOutgoingInvoice.referencedDeliveryNotesLoaded);

        if (noFilterApplied) {
          // If there is no filter applied, we want to display all invoices anyway.
          // Therefore, we don't need to check if the invoice should be filtered out.
          newOutgoingInvoice.containsFilteredDeliveryNote = true;
          newOutgoingInvoice.initWithReferencedDeliveryNotes(
            updateToSiteAndStatus,
            false,
          );
        } else {
          // If there is a filter applied, we want to display only the invoices that are not filtered out.
          newOutgoingInvoice.initWithReferencedDeliveryNotes(
            updateToSiteAndStatus,
            updateContainsFilteredDeliveryNote,
          );
        }
      }

      dispatch(replaceOutgoingInvoices(newOutgoingInvoices));
      dispatch(
        replaceFilteredOutgoingInvoices(
          newOutgoingInvoices.filter((invoice) => {
            // If a dln is referenced but filtered out, the invoice should be filtered out as well
            if (
              invoice.referencedDeliveryNotes.length > 0 &&
              !invoice.containsFilteredDeliveryNote
            ) {
              return false;
            }

            // If there is a filter applied, invoices without referenced delivery notes should be filtered out (e.g. create invoice)
            if (
              (selectedSites.length > 0 || selectedCostCenters.length > 0) &&
              invoice.referencedDeliveryNotes.length === 0
            ) {
              return false;
            }

            return true;
          }),
        ),
      );
    };

    useEffect(() => {
      // If the user is still logged in as vestigas admin (only usable in Admin Webapp), it makes sense to log out automatically.
      if (userUtils.isVestigasAdminAccount()) {
        AuthService.logout();
        return;
      }

      // If the page is loaded due to the redirection from oidc, an empty page should be displayed.
      // Hence, don't do anything at all here and wait for the reloading in the oidc onSignIn callback.
      if (AuthService.isOidcRedirectUrl(props.history.location.search)) {
        // If redirecting to the home page during login (via oidc onSignIn) takes unexpectedly long (more than 3 seconds), a message is displayed to the user that he is logged in
        setTimeout(() => {
          setLoadingMessage('Du wirst eingeloggt...');
        }, 3000);

        // If redirecting to the home page during login hasn't been done after 10 seconds we restart the login
        // process so that he isn't stuck in that loading screen. We're not calling logout here since there are
        // cases where login fails because of oidc-react and not keycloak login (e.g. if login url with state is
        // bookmarked, authprovider is initialized with invalid state and dies => in this case, keycloak is fine and
        // we will restart the webapp login process.)
        setTimeout(() => {
          window.location.href = Config.redirectUrl;
          // AuthService.logout();
        }, 10_000);
        return;
      }

      // 1. Case: user has a valid access token
      // -> Just regularly load the webapp
      if (AuthService.accessTokenValid()) {
        setDisplayPage(true);
        return;
      }

      // 2. Case: user has an expired token
      // -> If the refresh token is expired or not given at all, the user can directly be logged out
      // -> If the user has a valid refresh token, it is tried to get a new access token. If this succeeds, the webapp is loaded normally. If it fails, the user is logged out.
      if (AuthService.accessTokenExpired()) {
        if (
          !AuthService.getRefreshToken() ||
          AuthService.refreshTokenExpired()
        ) {
          AuthService.logout();
          return;
        }

        AuthService.loadRefreshedTokens()
          .then((response) => {
            AuthService.saveTokens(response.data);
            setDisplayPage(true);
          })
          .catch((error) => {
            Log.error('Failed to refresh tokens.', error);
            Log.productAnalyticsEvent(
              'Failed to refresh access token',
              Log.FEATURE.AUTHENTICATION,
              Log.TYPE.ERROR,
            );
            AuthService.logout();
          });
        return;
      }

      // 3. Case: user has no token at all
      // -> Log out the user.
      if (
        !AuthService.getAccessToken() && // If the page has "logout=true" in the search, the page is reloaded after logout due to signoutRedirect({post_logout_redirect_uri: oidcConfig.redirectUri + '/?logout=true'}).
        // In this case, we don't need to log out the user again as this would result in an infinite loop of logouts and page reloads.
        !AuthService.isLogoutUrl(props.history.location.search)
      ) {
        // The user is logged out automatically because it could happen that the user has no access token but a session in keycloak.
        // In this case, the user wouldn't get a new access token because keycloak doesn't sign in and oidc onSignIn isn't triggered. Thus, the user wouldn't have an access token and wouldn't get a new one.

        // The delay of 3 seconds is built in due to the following reason:
        // If the user opens the webapp and no keycloak session is present, the user is immediately redirected to the login page.
        // In this case, the auto logout would be unnecessary and just cause the webapp to reload every time it is opened and the user doesn't have a keycloak session.
        setTimeout(() => {
          AuthService.logout();
        }, 3000);
      }
    }, []);

    // If the page should be displayed, the data should be loaded as well
    useEffect(() => {
      if (!displayPage) {
        return;
      }

      loadData();
      dispatch(
        setUserHasLoggedInViaSSO(AuthService.accessTokenContainsFicKey()),
      );
    }, [displayPage]);

    useEffect(() => {
      initIncomingInvoices();
    }, [filteredDeliveryNotesVersion, incomingInvoicesFromBackendVersion]);

    useEffect(() => {
      initOutgoingInvoices();
    }, [filteredDeliveryNotesVersion, outgoingInvoicesFromBackendVersion]);

    const userId = AuthService.getUserIdFromAccessToken();

    if (userId) {
      dispatch(saveUserId(userId));
      ThirdPartyService.initSentryUser();
    }

    // We have a user that can only view the supplier overview. That user is provided to potential clients.
    if (userId === UserUtils.SUPPLIER_OVERVIEW_USER) {
      return <ExternalSupplierOverview />;
    }

    if (userId === UserUtils.BAYWA_SCREEN_USER) {
      return <BayWaScreen />;
    }

    return displayPage ? (
      <>
        <MainMenu />
        <DeactivatedAccountModal />
      </>
    ) : (
      <div className="h-100vh flex-c-c">
        <Spinner title={loadingMessage} />
      </div>
    );
  }),
  'Web App konnte nicht geladen werden.',
);
