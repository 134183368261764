import * as Sentry from '@sentry/react';
import * as amplitude from '@amplitude/analytics-browser';
import ObjectUtils from './objectUtils';
import GlobalStorage from './GlobalStorage';
import ConfigUtils from './configUtils';

export default class Log {
  static info(message, data, breadcrumb) {
    if (!ConfigUtils.isProductionEnvironment()) {
      console.info(message, data);
    }

    const breadcrumbConst = Object.keys(Log.BREADCRUMB).find((x) => {
      return breadcrumb === Log.BREADCRUMB[x].KEY;
    });

    Sentry.addBreadcrumb({
      type: Log.BREADCRUMB[breadcrumbConst]?.TYPE ?? 'info',
      category: Log.BREADCRUMB[breadcrumbConst]?.CATEGORY ?? 'info',
      message:
        message ?? Log.BREADCRUMB[breadcrumbConst]?.DEFAULT_MESSAGE ?? '',
      data,
      level: 'info',
    });
  }

  static warn(message, data, breadcrumb) {
    if (!ConfigUtils.isProductionEnvironment()) {
      console.warn(message, data);
    }

    const breadcrumbConst = Object.keys(Log.BREADCRUMB).find((x) => {
      return breadcrumb === Log.BREADCRUMB[x].KEY;
    });

    Sentry.addBreadcrumb({
      type: Log.BREADCRUMB[breadcrumbConst]?.TYPE ?? 'info',
      category: Log.BREADCRUMB[breadcrumbConst]?.CATEGORY ?? 'info',
      message:
        message ?? Log.BREADCRUMB[breadcrumbConst]?.DEFAULT_MESSAGE ?? '',
      data,
      level: 'warning',
    });
  }

  static error(message, error) {
    if (message) {
      console.error(message);
    }

    if (error) {
      console.error(error);
    }

    if (ConfigUtils.isDevelopmentEnvironment()) {
      return;
    }

    // It seems that somehow, an err was provided that was not instance of JS Error.
    // To investigate further, this case is caught and the variable is sent to Sentry as an exception.
    // https://sentry.io/organizations/vestigas/issues/3805048262/?environment=production&project=6410247&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=90d
    // https://github.com/getsentry/sentry-javascript/issues/2292
    if (error && !ObjectUtils.isError(error)) {
      Sentry.captureException(
        new Error('Provided variable is not instance of JS Error.'),
        {
          extra: { message, err: error },
        },
      );
    }

    Sentry.captureException(error ?? new Error(message), {
      extra: { message },
    });
  }

  static productAnalyticsEvent(event, feature, type) {
    if (!GlobalStorage.productAnalyticsToolsInitialized) {
      return;
    }

    if (type) {
      amplitude.track(feature + ' - (' + type + ') ' + event);
      return;
    }

    amplitude.track(feature + ' - ' + event);
  }

  // https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
  // types: navigation, user, info
  // categories: form, modal, click, state change, props change, filter, selection
  // level: fatal, critical, error, warning, log, info, debug
  static BREADCRUMB = {
    MODAL_OPEN: {
      KEY: 'modal-open',
      TYPE: 'navigation',
      CATEGORY: 'modal',
      DEFAULT_MESSAGE: 'Open modal',
    },
    FORM_OPEN: {
      KEY: 'form-open',
      TYPE: 'navigation',
      CATEGORY: 'form',
      DEFAULT_MESSAGE: 'Open form',
    },
    FORM_CLOSE: {
      KEY: 'form-close',
      TYPE: 'navigation',
      CATEGORY: 'form',
      DEFAULT_MESSAGE: 'Close form',
    },
    FORM_SUBMIT: {
      KEY: 'form-submit',
      TYPE: 'user',
      CATEGORY: 'form',
      DEFAULT_MESSAGE: 'Submit form',
    },
    FORM_CHANGE: {
      KEY: 'form-change',
      TYPE: 'user',
      CATEGORY: 'form',
      DEFAULT_MESSAGE: 'Change form value',
    },
    FILTER_CHANGE: {
      KEY: 'filter-change',
      TYPE: 'user',
      CATEGORY: 'filter',
      DEFAULT_MESSAGE: 'Change filter value',
    },
    SELECTION_CHANGE: {
      KEY: 'selection-change',
      TYPE: 'user',
      CATEGORY: 'selection',
      DEFAULT_MESSAGE: 'Change selection value',
    },
    USER_ACTION: {
      KEY: 'user-action',
      TYPE: 'user',
      CATEGORY: 'action',
      DEFAULT_MESSAGE: null,
    },
    REDUX_CHANGE: {
      KEY: 'redux-change',
      TYPE: 'info',
      CATEGORY: 'redux',
      DEFAULT_MESSAGE: 'Change redux state',
    },
    HTTP_NOT_200: {
      KEY: 'http-not-200',
      TYPE: 'http',
      CATEGORY: 'xhr',
      DEFAULT_MESSAGE: 'Response status is not 200',
    },
    INIT_MODEL: {
      KEY: 'init-model',
      TYPE: 'info',
      CATEGORY: 'model',
      DEFAULT_MESSAGE: 'Initialize model',
    },
  };
  static FEATURE = {
    NAVIGATION: 'Navigation',
    MENU: 'Menu',
    HOME: 'Home',
    DASHBOARD: 'Dashboard',
    REPORT: 'Report',
    USER_METRICS: 'User metrics',
    DELIVERY_OVERVIEW: 'Delivery overview',
    DELIVERY_LIST: 'Delivery list',
    DELIVERY_NOTE: 'Delivery note',
    CREATE_DELIVERY_NOTE: 'Create delivery note',
    SHARE_DELIVERY_NOTE: 'Share delivery note',
    DELIVERY_CHANGES: 'Delivery changes',
    FILTER_GROUPS: 'Filter groups',
    CONCRETE_DIARY: 'Concrete diary',
    INVOICE_LIST: 'Invoice list',
    INVOICE: 'Invoice',
    INVOICE_CHECK: 'Invoice check',
    SETTINGS_TABLE: 'Settings table',
    USER: 'User',
    SITE: 'Site',
    COST_CENTER: 'Cost center',
    VEHICLE: 'Vehicle',
    COMPANY: 'Company',
    ORGANISATIONAL_GROUP: 'Organisational group',
    USER_GROUP: 'User group',
    CUSTOM_FIELD: 'Custom field',
    SIGNATURE_FIELD: 'Signature field',
    DATA_SUBSCRIPTION: 'Data subscription',
    ARTICLE_MASTER: 'Article master',
    PERMISSIONS: 'Permissions',
    PERMISSION_GRANT_DIALOG: 'Permission grant dialog',
    PDF_SETTINGS: 'PDF settings',
    PDF_TEMPLATE: 'PDF template',
    SUBSCRIPTION: 'Subscription',
    DATA_PROTECTION: 'Data protection',
    SUPPLIER_OVERVIEW: 'Supplier overview',
    KANBAN_SUPPLIER_OVERVIEW: 'Kanban Supplier Overview',
    SUPPLIER_CONTACT_PAGE: 'Supplier contact page',
    CURRENT_SITE_AND_COST_CENTER: 'Current site and cost center',
    PROFILE: 'Profile',
    SITE_MAPPING: 'Site mapping',
    ACCORDION: 'Accordion',
    NOTIFICATIONS: 'Notifications',
    PDF_DOWNLOAD: 'PDF download',
    EXCEL_DOWNLOAD: 'Excel download',
    IMAGE_UPLOAD: 'Image upload',
    BASIC_TABLE: 'Basic table',
    PDF_VIEWER: 'PDF viewer',
    ADD_ITEM_MODAL: 'Add item modal',
    COMPLEX_PAGINATED_MULTI_PICKER: 'Complex paginated multi picker',
    WIZARD: 'Wizard',
    FORM: 'Form',
    AUTHENTICATION: 'Authentication',
    IDS: 'IDS',
    BAYWA_SCREEN: 'BayWa screen',
    ARCHIVE_MODE: 'Archive mode',
    OTHER_FEATURE: 'Other feature',
  };
  static TYPE = {
    ERROR: 'Error',
    FAILED_VALIDATION: 'Failed Validation',
  };
}
