import React from 'react';

import {
  AddLocation as AddLocationIcon,
  BackupTable as BackupTableIcon,
  LockOpenOutlined as LockOpenOutlinedIcon,
  PersonAdd as PersonAddIcon,
  PictureAsPdf as PictureAsPdfIcon,
  TableRows as TableRowsIcon,
} from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';

import {
  PieChartOutlinedIcon,
  SignatureIcon,
  UserSignatureIcon,
} from '~/assets/icons';

import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';
import Log from '~/utils/Log';
import Spinner from '../Spinner';

export default function GridToolbarCustomComponent(props) {
  const [anchorElement, setAnchorElement] = React.useState(null);

  const handleClick = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const getIcon = () => {
    switch (props.icon) {
      case 'pdf': {
        return (
          <PictureAsPdfIcon fontSize="small" style={{ marginRight: '5px' }} />
        );
      }

      case 'excel': {
        return (
          <BackupTableIcon fontSize="small" style={{ marginRight: '5px' }} />
        );
      }

      case 'permissionGrant': {
        return (
          <LockOpenOutlinedIcon
            fontSize="small"
            style={{ marginRight: '5px' }}
          />
        );
      }

      case 'rowHeight': {
        return (
          <TableRowsIcon fontSize="small" style={{ marginRight: '5px' }} />
        );
      }

      case 'shareDeliveryNote': {
        return (
          <PersonAddIcon fontSize="small" style={{ marginRight: '5px' }} />
        );
      }

      case 'mapDirectDeliveryNote': {
        return (
          <AddLocationIcon fontSize="small" style={{ marginRight: '5px' }} />
        );
      }

      case 'signature': {
        return <SignatureIcon className="icon-extra-small mr-5px" />;
      }

      case 'requestSignature': {
        return <UserSignatureIcon className="icon-extra-small mr-5px" />;
      }

      case 'report': {
        return <PieChartOutlinedIcon className="icon-extra-small mr-5px" />;
      }

      default: {
        Log.error(
          null,
          new EnumValueNotFoundException('Invalid icon: ' + props.icon),
        );
        return null;
      }
    }
  };

  return (
    <div>
      <Button
        className="datagrid-toolbar-button"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        {getIcon()}
        {props.title}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {props.menuItems.map((menuItem, index) => (
          <MenuItem
            selected={menuItem.selected}
            disabled={menuItem.isLoading}
            key={index}
            onClick={menuItem.onClick ? menuItem.onClick : handleClose}
            className="flex-s-c gap-10px"
          >
            {menuItem.isLoading ? <Spinner /> : null}
            {menuItem.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
