import { COMPANY } from '~/constants/MasterdataConsts';
import Article from '~/models/articles/Article';
import Concrete from '~/models/articles/Concrete';

export default class DeliveryNoteParser {
  static parseAssetMain(assetMain) {
    if (
      [
        COMPANY.UNION_BETON,
        COMPANY.GANSER_BETON,
        COMPANY.FERTIGBETON_STIEB,
      ].includes(assetMain?.asset_state?.body?.header?.issuer?.id) && // issuer
      [
        COMPANY.UNION_BETON,
        COMPANY.GANSER_BETON,
        COMPANY.FERTIGBETON_STIEB,
      ].includes(
        assetMain?.asset_state?.body?.transaction?.delivery?.ship_from
          ?.legal_organization?.id,
      ) // supplier
    ) {
      DeliveryNoteParser.parseRohrdorfer(assetMain);
    }
  }

  static parseRohrdorfer(assetMain) {
    // concrete always has an 8 digit article number
    if (
      assetMain?.asset_state?.body?.transaction?.logistics_package?.[0]
        ?.line_item?.[0]?.product?.seller_assigned_id?.length !== 8
    ) {
      return;
    }

    // replace EA with MTQ for concrete
    if (
      assetMain?.asset_state?.body?.transaction?.logistics_package?.[0]
        ?.line_item?.[0]?.delivery?.product_unit?.quantity_type ===
      Article.UNIT.EA.STANDARDISED
    ) {
      assetMain.asset_state.body.transaction.logistics_package[0].line_item[0].delivery.product_unit.quantity_type =
        Article.UNIT.MTQ.STANDARDISED;
    }

    if (
      !assetMain.asset_state.body.transaction.logistics_package[0].line_item[0]
        .product.name
    ) {
      return;
    }

    let [strengthClass, exposureClass, consistency, largestGrain] =
      assetMain.asset_state.body.transaction.logistics_package[0].line_item[0].product.name.split(
        ' ',
      );

    // only map concrete (e.g. Sandbeton is filtered out here)
    if (!Concrete.STRENGTH_CLASSES.includes(strengthClass)) {
      return;
    }

    // handle the case if the corrosion class is mapped to the largest grain
    if (!Concrete.LARGEST_GRAINS.includes(largestGrain)) {
      largestGrain = '';
    }

    assetMain.asset_state.body.transaction.logistics_package[0].line_item[0].product =
      {
        concrete: {
          strength_class: strengthClass,
          exposure_class: exposureClass,
          consistency,
          largest_grain: largestGrain,
        },
        ...assetMain.asset_state.body.transaction.logistics_package[0]
          .line_item[0].product,
      };
  }
}
