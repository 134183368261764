import React from 'react';

import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';

import BasicModal from './BasicModal';
import Spinner from './Spinner';

import {
  LightTooltip,
  MissingPermissionsTooltip,
} from '~/utils/componentUtils';
import UserUtils from '~/utils/userUtils';
import Log from '~/utils/Log';

class BasicForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteFormOpen: false,
      abortFormOpen: false,
    };
  }

  closeModal = (e) => {
    e.stopPropagation();

    if (this.props.unsavedChanges?.length > 0) {
      this.setState({
        abortFormOpen: true,
      });
      return;
    }

    this.props.formAbort();
  };
  closeAbortForm = () => {
    this.setState({
      abortFormOpen: false,
    });
  };
  abortFormSuccess = (e) => {
    e.stopPropagation();
    e.preventDefault();

    Log.productAnalyticsEvent('Submit abort form', Log.FEATURE.FORM);

    this.props.formAbort();
    this.closeAbortForm();
  };
  abortFormAbort = () => {
    Log.productAnalyticsEvent('Abort abort form', Log.FEATURE.FORM);
    this.closeAbortForm();
  };
  deleteFormSuccess = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      deleteFormOpen: false,
    });

    this.props.formDelete(event);
  };
  deleteFormAbort = () => {
    this.setState({
      deleteFormOpen: false,
    });
  };

  getTitle() {
    return (
      <div>
        <div className="flex-c-c gap-10px">
          {this.props.title}
          {this.props.id && UserUtils.isVestigasAccount() ? (
            <LightTooltip title="ID in die Zwischenablage kopieren">
              <IconButton
                size="medium"
                onClick={() => navigator.clipboard.writeText(this.props.id)}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </LightTooltip>
          ) : null}
        </div>
        {this.props.unsavedChanges?.length > 0 ? (
          <div className="text-12px normal text-warningBase t-60px absolute">
            * Ungespeicherte Änderungen: {this.props.unsavedChanges.join(', ')}
          </div>
        ) : null}
      </div>
    );
  }

  getSubmitButton() {
    const submitButtonTitle = this.props.submitButtonTitle ?? 'Speichern';

    const button = (
      <Button
        variant="contained"
        color="primary"
        className={
          this.props.missingPermissionsToSubmit
            ? 'disabled-primary-button'
            : 'primary-button'
        }
        type="submit"
        disabled={
          this.props.submittingForm ||
          this.props.disableSubmitButton ||
          this.props.missingPermissionsToSubmit
        }
      >
        {this.props.submittingForm ? (
          <Spinner white title={submitButtonTitle + '...'} />
        ) : (
          submitButtonTitle
        )}
      </Button>
    );

    if (this.props.missingPermissionsToSubmit) {
      return <MissingPermissionsTooltip>{button}</MissingPermissionsTooltip>;
    }

    return button;
  }

  getDeleteButton() {
    const deleteButtonTitle = this.props.deleteButtonTitle ?? 'Löschen';

    const button = (
      <Button
        variant="outlined"
        color="secondary"
        onClick={(event) =>
          this.props.noDeleteForm
            ? this.deleteFormSuccess(event)
            : this.setState({ deleteFormOpen: true })
        }
        disabled={
          this.props.deletingForm || this.props.missingPermissionsToDelete
        }
      >
        {this.props.deletingForm ? (
          <Spinner title={deleteButtonTitle + '...'} />
        ) : (
          deleteButtonTitle
        )}
      </Button>
    );

    if (this.props.missingPermissionsToDelete) {
      return <MissingPermissionsTooltip>{button}</MissingPermissionsTooltip>;
    }

    return button;
  }

  getDeleteForm() {
    return (
      <BasicForm
        open={this.state.deleteFormOpen}
        formSuccess={this.deleteFormSuccess}
        formAbort={this.deleteFormAbort}
        title={this.props.title + ' löschen'}
        submitButtonTitle="Löschen"
      >
        Willst du mit dem Löschen fortfahren?
      </BasicForm>
    );
  }

  render() {
    return (
      <BasicModal
        title={this.getTitle()}
        open={this.props.open}
        closeModal={this.closeModal}
        fullWidth={this.props.fullWidth}
        opacity={this.props.opacity}
      >
        <form onSubmit={this.props.formSuccess}>
          <div className="pb-80px min-w-300px">
            {' '}
            {/* Min width to prevent that footer buttons don't fit the whole row */}
            {this.props.children}
          </div>
          <div
            className="flex-sb-c sticky-dialog-footer"
            style={{ width: `calc(100% - 48px)` }}
          >
            {this.props.formDelete ? (
              <>
                {this.getDeleteButton()}
                {this.getDeleteForm()}
              </>
            ) : (
              <div />
            )}
            <div>
              <Button
                variant="text"
                className="mr-30px"
                onClick={this.closeModal}
              >
                Abbrechen
              </Button>
              <BasicForm
                open={this.state.abortFormOpen}
                formSuccess={this.abortFormSuccess}
                formAbort={this.abortFormAbort}
                title="Ungespeicherte Änderungen"
                submitButtonTitle="Fortfahren"
              >
                Ungespeicherte Änderungen gehen verloren. Willst du wirklich
                fortfahren?
              </BasicForm>
              {this.getSubmitButton()}
            </div>
          </div>
        </form>
      </BasicModal>
    );
  }
}

export default BasicForm;
