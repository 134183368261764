import React from 'react';
import UniversalCommunication from '~/models/masterdata/UniversalCommunication';

function SupportContact(props) {
  const { email, phone } = UniversalCommunication.getSupportContact();

  return (
    <div className="box-shadow-blue rounded-5px p-20px h-full bg-white">
      <div className="bold mb-20px">Support kontaktieren</div>
      <div className="flexdir-column gap-20px flex">
        <div className="flex-s-s">
          <span className="bold w-80px">Hotline:</span>
          <span className="word-break">{phone}</span>
        </div>
        <div className="flex-s-s">
          <span className="bold w-80px">E-Mail:</span>
          <span className="word-break">{email}</span>
        </div>
      </div>
    </div>
  );
}

export default SupportContact;
