import { Button, Grid } from '@mui/material';

import { LightTooltip } from '~/utils/componentUtils';
import DatagridUtils from '~/utils/datagridUtils';

import BasicTable from '~/components/BasicTable';
import Spinner from '~/components/Spinner';

import { SiteIdentifiersModal } from './SiteIdentifiersModal';

const defineColumn = (field, headerName, width, options = {}) => ({
  field,
  headerName,
  renderCell: DatagridUtils.displayCellTooltip,
  resizableText: true,
  sortable: true,
  width,
  ...options,
});

const columns = [
  defineColumn('identifier', 'Standortbezeichnung des Lieferanten', 400),
  defineColumn('issuerName', 'Lieferant', 500),
  defineColumn('ignoreAddressInfo', 'Ohne Adress-Info', 80, {
    resizableText: false,
  }),
  defineColumn('addressString', 'Adresse', 120, { resizableText: false }),
  defineColumn('issuerId', 'ID des Lieferanten', 400, {
    renderCell: undefined,
  }),
];

const i18nKeys = {
  blacklisted: {
    buttonBlacklistStateToggle: {
      title: 'Ausgewählte Standortbezeichnungen diesem Standort zuordnen',
      inProgressText: 'Aktualisiere Status...',
      text: 'Dem Standort zuordnen',
    },
    buttonDelete: {
      inProgressText: 'Lösche Bezeichnungen...',
      text: 'Bezeichnungen löschen',
      title: 'Ausgewählte Standortbezeichnungen löschen',
    },
    heading: 'Von der Zuordnung ausgeschlossene Standortbezeichnungen',
  },
  whitelisted: {
    buttonBlacklistStateToggle: {
      title:
        'Ausgewählte Standortbezeichnungen von der Zuordnung zu diesem Standort ausschließen',
      inProgressText: 'Aktualisiere Status...',
      text: 'Von Zuordnung ausschließen',
    },
    buttonDelete: {
      inProgressText: 'Lösche Bezeichnungen...',
      text: 'Bezeichnungen löschen',
      title: 'Ausgewählte Standortbezeichnungen löschen',
    },
    heading: 'Zugeordnete Standortbezeichnungen',
  },
};

/**
 * Renders a table of site identifiers with options to blacklist/un-blacklist and delete identifiers.
 *
 * @param {Object} props - The component props.
 * @param {string} props.blacklistStatus - The status of the identifiers ('whitelisted' / 'blacklisted').
 * @param {boolean} props.isInProgress - Indicates if an action is in progress.
 * @param {boolean} props.isLoading - Indicates if data is being loaded.
 * @param {Function} props.onBlacklistSite - The callback function to blacklist/un-blacklist identifiers.
 * @param {Function} props.onDeleteMappings - The callback function to delete identifiers.
 * @param {Function} props.onSelectSite - The callback function to select identifiers.
 * @param {Array} props.selectedSites - The selected identifiers.
 * @param {string} props.siteId - The ID of the site.
 * @param {Array} props.sites - The list of site identifiers.
 * @return {JSX.Element} The rendered table of site identifiers.
 */
export const SiteIdentifiersTableComponent = ({
  blacklistStatus = 'whitelisted',
  isInProgress,
  isLoading,
  onBlacklistSite,
  onDeleteMappings,
  onSelectSite,
  selectedSites,
  siteId,
  sites,
}) => {
  const i18n = i18nKeys[blacklistStatus];
  const sitesType = `${blacklistStatus}Sites`;

  return (
    <div>
      <h3 className="main-text">{i18n.heading}</h3>
      <div className="border">
        <BasicTable
          rows={sites}
          columns={columns}
          onRowSelectionModelChange={(event) => onSelectSite(event, sitesType)}
          rowSelectionModel={selectedSites}
          rowHeight={DatagridUtils.ROW_HEIGHT.THIN}
          pageSize={20}
          rowsPerPageOptions={[20]}
          loading={isLoading}
          disableRowSelectionOnClick
          checkboxSelection
          autoHeight
        />
      </div>
      <Grid container>
        <Grid item xs={6}>
          <LightTooltip title={i18n.buttonDelete.title}>
            <Button
              className="mt-10px"
              variant="outlined"
              color="secondary"
              disabled={selectedSites.length === 0 || isInProgress}
              onClick={() => onDeleteMappings(sitesType)}
            >
              {isInProgress ? (
                <Spinner title={i18n.buttonDelete.inProgressText} />
              ) : (
                i18n.buttonDelete.text
              )}
            </Button>
          </LightTooltip>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <LightTooltip title={i18n.buttonBlacklistStateToggle.title}>
              <Button
                className="mt-10px mr-10px"
                variant="outlined"
                color="primary"
                disabled={selectedSites.length === 0 || isInProgress}
                onClick={onBlacklistSite}
              >
                {isInProgress ? (
                  <Spinner
                    title={i18n.buttonBlacklistStateToggle.inProgressText}
                  />
                ) : (
                  i18n.buttonBlacklistStateToggle.text
                )}
              </Button>
            </LightTooltip>
            <SiteIdentifiersModal
              siteId={siteId}
              shouldBlacklist={blacklistStatus === 'blacklisted'}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
