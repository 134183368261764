import React from 'react';
import { withErrorBoundary } from '~/ui/atoms';
import { LOADING_STATE } from '~/constants/LoadingState';
import { promiseHandler } from '~/utils/promiseHandler';
import CompanyService from '~/services/company.service';
import Log from '~/utils/Log';
import Spinner from '../Spinner';
import DataExchangeCompany from '~/models/dataExchanges/DataExchangeCompany';
import DataExchangeStatusIcon from './DataExchangeStatusIcon';
import DataExchangeStatusTooltip from './DataExchangeStatusTooltip';
import { LightTooltipWide } from '~/utils/componentUtils';
import { Checkbox } from '@mui/material';

class DataExchangeTile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logo: null,
      logoLoading: LOADING_STATE.NOT_LOADED,
    };
  }

  componentDidMount() {
    this.loadCompanyLogo();
  }

  async loadCompanyLogo() {
    this.setState({
      logoLoading: LOADING_STATE.LOADING,
    });

    const [logo, error] = await promiseHandler(
      CompanyService.getCompanyLogo(this.props.dataExchange.senderId),
    );

    if (error) {
      Log.error(
        'Failed to load logo. company id: ' + this.props.dataExchange.senderId,
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to load logo',
        Log.FEATURE.SUPPLIER_OVERVIEW,
        Log.TYPE.ERROR,
      );
      this.setState({
        logoLoading: LOADING_STATE.FAILED,
      });
      return;
    }

    this.setState({
      logo,
      logoLoading: LOADING_STATE.SUCCEEDED,
    });
  }

  getLogo() {
    if (
      this.state.logoLoading === LOADING_STATE.NOT_LOADED ||
      this.state.logo === null ||
      this.state.logo.size === 0
    ) {
      return null;
    }

    if (this.state.logoLoading === LOADING_STATE.LOADING) {
      return <Spinner />;
    }

    if (this.state.logoLoading === LOADING_STATE.FAILED) {
      return 'Logo konnte nicht geladen werden';
    }

    return (
      <img
        className="max-h-48px max-w-200px"
        src={URL.createObjectURL(this.state.logo)}
      />
    );
  }

  getFlag() {
    if (!this.props.dataExchange.currentIssues) {
      return null;
    }

    return (
      <DataExchangeStatusTooltip
        status={DataExchangeCompany.STATUS.CURRENT_ISSUES.KEY}
      >
        <div className="flex-c-c t--16px r-0 h-35px w-24px flag-error absolute">
          <DataExchangeStatusIcon
            status={DataExchangeCompany.STATUS.CURRENT_ISSUES.KEY}
          />
        </div>
      </DataExchangeStatusTooltip>
    );
  }

  getFurtherInformation() {
    if (!this.props.dataExchange.furtherInformation) {
      return null;
    }

    return (
      <div className="mt-16px text-grey400">
        {this.props.dataExchange.furtherInformation}
      </div>
    );
  }

  getAdditionalData() {
    const rows = [];

    if (this.props.dataExchange.activeCustomerIds) {
      rows.push({
        title: 'Kundennummern:',
        value: this.props.dataExchange.activeCustomerIds,
      });
    }

    if (this.props.dataExchange.knownIssues) {
      rows.push({
        title: 'Bekannte Probleme:',
        value: this.props.dataExchange.knownIssues,
      });
    }

    if (rows.length === 0) {
      return null;
    }

    return rows.map((row) => (
      <div key={row.value} className="pt-16px mt-16px border-top">
        <div className="bold mb-8px">{row.title}</div>
        <div>{row.value}</div>
      </div>
    ));
  }

  getDocumentStatusColorClass(status) {
    if (status === DataExchangeCompany.STATUS.ACTIVATED.KEY) {
      return 'text-successDark';
    }

    if (status === DataExchangeCompany.STATUS.LIVE.KEY) {
      return 'text-successDark';
    }

    if (status === DataExchangeCompany.STATUS.QR_CODE.KEY) {
      return 'text-successDark';
    }

    return null;
  }

  render() {
    return (
      <div className="relative">
        {this.getFlag()}
        <div className="flex-sb-s">
          <div className="mb-16px h-48px pr-30px flex-s-c">
            {this.getLogo()}
          </div>
          {this.props.requestInformationSelection && (
            <Checkbox
              checked={this.props.requestInformationSelectionList.includes(
                this.props.dataExchange.senderId,
              )}
              onChange={() =>
                this.props.onChangeRequestInformationSelection(
                  this.props.dataExchange.senderId,
                )
              }
            />
          )}
        </div>
        <LightTooltipWide
          title={
            <div>
              <div className="bold">{this.props.dataExchange.name}</div>
              <div>{this.props.dataExchange.address}</div>
              <div className="mt-10px">VESTIGAS-ID:</div>
              <div className="bold">{this.props.dataExchange.senderId}</div>
            </div>
          }
          enterDelay={1000}
          enterNextDelay={1000}
        >
          <div className="mb-8px text-grey400 text-overflow">
            {this.props.dataExchange.name}
          </div>
        </LightTooltipWide>
        <div className="mb-16px h-1px bg-grey100" />
        <div className="mb-8px flex-sb-c bg-grey100 p-12px rounded-8px">
          <span>Lieferungen:</span>
          <DataExchangeStatusTooltip
            status={this.props.dataExchange.deliveryNoteStatus}
          >
            <span
              className={
                'bold ' +
                this.getDocumentStatusColorClass(
                  this.props.dataExchange.deliveryNoteStatus,
                )
              }
            >
              {DataExchangeCompany.getStatusAbbreviatedLabel(
                this.props.dataExchange.deliveryNoteStatus,
              )}
            </span>
          </DataExchangeStatusTooltip>
        </div>
        <div className="flex-sb-c bg-grey100 p-12px rounded-8px">
          <span>Rechnungen:</span>
          <DataExchangeStatusTooltip
            status={this.props.dataExchange.invoiceStatus}
          >
            <span
              className={
                'bold ' +
                this.getDocumentStatusColorClass(
                  this.props.dataExchange.invoiceStatus,
                )
              }
            >
              {DataExchangeCompany.getStatusAbbreviatedLabel(
                this.props.dataExchange.invoiceStatus,
              )}
            </span>
          </DataExchangeStatusTooltip>
        </div>
        {this.getFurtherInformation()}
        {this.getAdditionalData()}
      </div>
    );
  }
}

export default withErrorBoundary(
  DataExchangeTile,
  'Daten konnte nicht geladen werden.',
);
