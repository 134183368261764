import React from 'react';

import { Logout as LogoutIcon } from '@mui/icons-material';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useAuth } from 'oidc-react';
import Log from '~/utils/Log';
import Config from '~/Config';
import DataExchanges from './DataExchanges';

function ExternalSupplierOverview(props) {
  const useStyles = makeStyles((theme) => ({
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${props.drawerWidth}px)`,
        marginLeft: props.drawerWidth,
        transition: 'width 1s ease-in-out',
      },
      backgroundColor: '#ffffff',
      color: '#343A4D',
      toolbar: theme.mixins.toolbar,
      // This fixed a bug. Without it, the burger menu would move to the left when the date range select
      // in the home screen is opened (in case of a screen width of around 729px).
      paddingRight: '0px !important',
    },
  }));

  const classes = useStyles();
  const auth = useAuth();

  const logout = () => {
    Log.productAnalyticsEvent('Logout', Log.FEATURE.AUTHENTICATION);
    auth.signOutRedirect({ post_logout_redirect_uri: Config.redirectUrl });
  };

  return (
    <div>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div className="flex-sb-c w-full">
            <span className="text-32px">Lieferantenübersicht</span>
            <IconButton onClick={logout} size="large">
              <LogoutIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <div className="mt-65px">
        <DataExchanges externalSupplierOverview />
      </div>
    </div>
  );
}

export default ExternalSupplierOverview;
