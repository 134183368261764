import { CircularProgress } from '@mui/material';

export default function Spinner(props) {
  return (
    <div
      className={
        'h-full ' +
        (props.white ? 'text-white' : '') +
        (props.alignLeft ? '' : 'flex-c-c w-full')
      }
    >
      <CircularProgress
        size={props.size ?? 20}
        style={props.white ? { color: 'white' } : { color: 'currentColor' }}
      />
      {props.title ? <div className="ml-10px">{props.title}</div> : null}
    </div>
  );
}
