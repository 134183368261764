import React, { useEffect } from 'react';

import { Backdrop, CircularProgress, CssBaseline } from '@mui/material';

import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import RouteUtils from '~/utils/routeUtils';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import Log from '~/utils/Log';
import { usePrevious } from '~/utils/customHooks';
import Drawer from './Drawer';
import LocalStorageService from '~/services/localStorage.service';
import TopBar from './TopBar';
import { JsonModal } from '../JsonModal';
import FeatureService from '~/services/feature.service';

function MainMenu(props) {
  const [hideDrawer, setHideDrawer] = React.useState(
    LocalStorageService.getBooleanFromLocalStorage(
      LocalStorageService.HIDE_DRAWER,
    ),
  );

  const drawerWidthOpen = FeatureService.serviceNotes() ? 280 : 240;
  const drawerWidthClosed = 110;
  const drawerWidth = hideDrawer ? drawerWidthClosed : drawerWidthOpen;

  const dispatch = useDispatch();
  const location = useLocation();
  const previousLocation = usePrevious(location);
  const backdrop = useSelector((state) => state.backdrop);
  const devTools = useSelector((state) => state.devTools);

  if (location.pathname !== previousLocation?.pathname) {
    try {
      Log.productAnalyticsEvent(
        RouteUtils.getBaseUrl(location.pathname),
        Log.FEATURE.NAVIGATION,
      );
    } catch (error) {
      Log.error('Failed to send page view to product analytics tool', error);
    }
  }

  // enforce limit of toasts
  const { toasts } = useToasterStore();
  useEffect(() => {
    for (const t of toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, index) => index >= 5))
      toast.dismiss(t.id); // Dismiss – Use toast.remove(t.id) removal without animation
  }, [toasts]);

  return (
    <div className="h-100vh w-100vw flex">
      <Backdrop
        style={{
          zIndex: 10_000,
          backgroundColor: '#000000',
          opacity: 0.8,
          color: '#FFFFFF',
          fontSize: '25px',
        }}
        open={backdrop.open}
      >
        <CircularProgress color="inherit" style={{ marginRight: '20px' }} />
        {backdrop.message}
      </Backdrop>
      <Toaster
        toastOptions={{
          className: '',
          style: {
            borderRadius: '5px',
            padding: '5px 0px 5px 5px',
            maxWidth: '60vw',
          },
        }}
      />
      <CssBaseline />
      <TopBar drawerWidth={drawerWidth} />
      <Drawer
        hideDrawer={hideDrawer}
        setHideDrawer={setHideDrawer}
        drawerWidth={drawerWidth}
      />
      <JsonModal />
    </div>
  );
}

MainMenu.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MainMenu;
