import { createSlice } from '@reduxjs/toolkit';

import { LOADING_STATE } from '~/constants/LoadingState';

const siteReferencesSlice = createSlice({
  name: 'siteReferences',
  initialState: {
    activeSiteReferences: [],
    assignedSiteReferences: [],
    assignedSiteReferencesLoading: LOADING_STATE.NOT_LOADED,
    blacklistedSiteReferences: [],
    inactiveSiteReferences: [],
    siteReferences: [],
    siteReferencesLoading: LOADING_STATE.NOT_LOADED,
  },
  reducers: {
    replaceSiteReferences(state, parameter) {
      const { payload } = parameter;

      state.siteReferences = payload;
      state.siteReferencesLoading = LOADING_STATE.SUCCEEDED;
    },
    replaceAssignedSiteReferences(state, parameter) {
      const { payload } = parameter;

      state.assignedSiteReferences = payload;
      state.assignedSiteReferencesLoading = LOADING_STATE.SUCCEEDED;
    },
    setSiteReferencesLoading(state, parameter) {
      const { payload } = parameter;

      state.siteReferencesLoading = payload;
    },
    setAssignedSiteReferencesLoading(state, parameter) {
      const { payload } = parameter;

      state.assignedSiteReferencesLoading = payload;
    },
    replaceActiveSiteReferences(state, parameter) {
      const { payload } = parameter;

      state.activeSiteReferences = payload;
    },
    replaceInactiveSiteReferences(state, parameter) {
      const { payload } = parameter;

      state.inactiveSiteReferences = payload;
    },
    replaceBlacklistedSiteReferences(state, parameter) {
      const { payload } = parameter;

      state.blacklistedSiteReferences = payload;
    },
  },
});

const { actions, reducer } = siteReferencesSlice;

export const {
  replaceActiveSiteReferences,
  replaceAssignedSiteReferences,
  replaceBlacklistedSiteReferences,
  replaceInactiveSiteReferences,
  replaceSiteReferences,
  setAssignedSiteReferencesLoading,
  setSiteReferencesLoading,
} = actions;

export default reducer;
