import React from 'react';
import { IconButton } from '@mui/material';
import { LightTooltip } from '~/utils/componentUtils';

// The way this component is cut isn't optimal because the color and size of the icon needs to be passed inside the icon in the parent component.
// This should be solved better but hasn't been done yet due to being pragmatic.
export default class SquaredIconButton extends React.Component {
  // Currently there are two sizes for the SquaredIconButton: "small" and default.
  render() {
    const styles = {
      width: this.props.size === 'small' ? 30 : 40,
      height: this.props.size === 'small' ? 30 : 40,
      borderRadius: 1,
      border: '1px solid ' + (this.props.disabled ? '#C5C9D6' : '#8D94A8'), // grey200 / grey400
    };

    return (
      <LightTooltip title={this.props.tooltipTitle}>
        <IconButton
          onClick={this.props.onClick}
          disabled={this.props.disabled}
          sx={styles}
          size="large"
        >
          {this.props.children}
        </IconButton>
      </LightTooltip>
    );
  }
}
