import React from 'react';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import GridToolbarCustomComponent from './GridToolbarCustomComponent';
import DatagridUtils from '~/utils/datagridUtils';
import ExportService from '~/services/export.service';
import Log from '~/utils/Log';
import ToastService from '~/services/toast.service';

// TODO: If possible by the plugin, we need to integrate the download for CSV and PDF in one dropdown button and translate them accordingly to German
export default function GridToolbar(props) {
  const onRowHeightChange = (rowHeight) => {
    props.onRowHeightChange(rowHeight);
  };

  const rowHeightMenuItem = (name, height) => {
    return {
      name,
      onClick: () => onRowHeightChange(height),
      selected: props.rowHeight === height,
    };
  };

  const onExcelExport = (withProductAnalyticsEvent) => {
    if (withProductAnalyticsEvent) {
      Log.productAnalyticsEvent(
        'Download Excel',
        props.productAnalyticsFeature ?? Log.FEATURE.EXCEL_DOWNLOAD,
      );
    }

    if (props.excelData.length === 0) {
      ToastService.info([
        'Bitte wähle für den Excel-Download mindestens einen Eintrag aus der Tabelle aus.',
      ]);
      Log.productAnalyticsEvent(
        'No row selected in Excel-Download',
        props.productAnalyticsFeature ?? Log.FEATURE.EXCEL_DOWNLOAD,
        Log.TYPE.FAILED_VALIDATION,
      );
      return;
    }

    const rows = [];

    rows.push(props.excelColumns.map((column) => column.headerName));

    rows.push(
      ...props.excelData.map((data) => {
        return props.excelColumns.map((column) => data[column.field]);
      }),
    );

    ExportService.exportExcel(rows);
  };

  const onExcelDlnExport = (downloadOption) => {
    if (ExportService.DOWNLOAD_OPTION.ZIP) {
      Log.productAnalyticsEvent(
        'Download Excel with multiple PDFs as .zip file',
        props.productAnalyticsFeature ?? Log.FEATURE.EXCEL_DOWNLOAD,
      );
    }

    if (ExportService.DOWNLOAD_OPTION.MERGE) {
      Log.productAnalyticsEvent(
        'Download Excel with multiple PDFs as one merged document',
        props.productAnalyticsFeature ?? Log.FEATURE.EXCEL_DOWNLOAD,
      );
    }

    onExcelExport();

    props.onExcelDlnExport(downloadOption);
  };

  const onExcelInvoiceExport = () => {
    Log.productAnalyticsEvent(
      'Download invoice as Excel',
      Log.FEATURE.EXCEL_DOWNLOAD,
    );
    props.onExcelInvoiceExport();
  };

  const excelExportMenuItems = [
    {
      name: 'Download als Excel',
      onClick: () => onExcelExport(true),
    },
  ];

  if (props.onExcelDlnExport) {
    if (props.multiplePdfDownload) {
      excelExportMenuItems.push(
        {
          name: 'Download als Excel mit PDF-Lieferungen (.zip-Datei)',
          onClick: () => onExcelDlnExport(ExportService.DOWNLOAD_OPTION.ZIP),
        },
        {
          name: 'Download als Excel mit PDF-Lieferungen (ein Dokument)',
          onClick: () => onExcelDlnExport(ExportService.DOWNLOAD_OPTION.MERGE),
        },
      );
    } else {
      excelExportMenuItems.push({
        name: 'Download als Excel mit PDF-Lieferungen',
        onClick: () => onExcelDlnExport(),
      });
    }
  }

  if (props.onExcelInvoiceExport) {
    excelExportMenuItems.push({
      name: 'Download einzelne Rechnungen als Excel',
      onClick: () => onExcelInvoiceExport(),
    });
  }

  let pdfExportMenuItems = [];

  if (props.multiplePdfDownload) {
    pdfExportMenuItems = [
      {
        name: 'Download als PDF (.zip-Datei)',
        onClick: () => props.onPdfExport(ExportService.DOWNLOAD_OPTION.ZIP),
      },
      {
        name: 'Download als PDF (ein Dokument)',
        onClick: () => props.onPdfExport(ExportService.DOWNLOAD_OPTION.MERGE),
      },
    ];
  } else {
    pdfExportMenuItems = [
      {
        name: 'Download als PDF',
        onClick: () => props.onPdfExport(),
      },
    ];
  }

  return (
    <GridToolbarContainer>
      {props.noColumnsButton ? null : <GridToolbarColumnsButton />}
      {props.rowHeight ? (
        <GridToolbarCustomComponent
          title="Zeilenhöhe"
          icon="rowHeight"
          menuItems={[
            rowHeightMenuItem('Sehr Dünn', DatagridUtils.ROW_HEIGHT.VERY_THIN),
            rowHeightMenuItem('Dünn', DatagridUtils.ROW_HEIGHT.THIN),
            rowHeightMenuItem('Standard', DatagridUtils.ROW_HEIGHT.STANDARD),
            rowHeightMenuItem('Breit', DatagridUtils.ROW_HEIGHT.WIDE),
          ]}
        />
      ) : null}
      {props.noFilterButton ? null : <GridToolbarFilterButton />}
      {props.noExportButton ? null : (
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      )}
      {props.onPdfExport ? (
        <GridToolbarCustomComponent
          title="PDF Download"
          icon="pdf"
          menuItems={pdfExportMenuItems}
        />
      ) : null}
      {props.excelData && props.excelColumns ? (
        <GridToolbarCustomComponent
          title="Excel Download"
          icon="excel"
          menuItems={excelExportMenuItems}
        />
      ) : null}
      {props.onMultiPermissionGrantEdit ? (
        <GridToolbarCustomComponent
          title="Berechtigungen vergeben"
          icon="permissionGrant"
          menuItems={[
            {
              name: 'Berechtigungen vergeben',
              onClick: props.onMultiPermissionGrantEdit,
            },
          ]}
        />
      ) : null}
      {/* { props.onDeliveryNoteSignature ?
                <GridToolbarCustomComponent
                    title="Lieferungen signieren"
                    icon="signature"
                    menuItems={[{
                        name: "Lieferungen signieren",
                        onClick: props.onDeliveryNoteSignature,
                        isLoading: props.deliveryNoteSignaturePushIsLoading
                    }]}
                /> : null } */}
      {props.onRequestDeliveryNoteSignature ? (
        <GridToolbarCustomComponent
          title="Signaturen anfordern"
          icon="requestSignature"
          menuItems={[
            {
              name: 'Signaturen anfordern',
              onClick: props.onRequestDeliveryNoteSignature,
            },
          ]}
        />
      ) : null}
      {props.onShareDeliveryNote ? (
        <GridToolbarCustomComponent
          title="Lieferungen teilen"
          icon="shareDeliveryNote"
          menuItems={[
            {
              name: 'Lieferungen teilen',
              onClick: props.onShareDeliveryNote,
            },
          ]}
        />
      ) : null}
      {props.onMapDirectDeliveryNote ? (
        <GridToolbarCustomComponent
          title="Standort zuweisen"
          icon="mapDirectDeliveryNote"
          menuItems={[
            {
              name: 'Standort zuweisen',
              onClick: props.onMapDirectDeliveryNote,
            },
          ]}
        />
      ) : null}
      {props.onOpenReport ? (
        <GridToolbarCustomComponent
          title="Statistiken"
          icon="report"
          menuItems={[
            {
              name: 'Statistiken',
              onClick: props.onOpenReport,
            },
          ]}
        />
      ) : null}
    </GridToolbarContainer>
  );
}
