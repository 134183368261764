import {
  Box,
  Button,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';

import {
  AddOutlined as AddOutlinedIcon,
  Create as CreateIcon,
  DateRange as DateRangeIcon,
} from '@mui/icons-material';

import { LOADING_STATE } from '~/constants/LoadingState';
import { dateUtils } from '~/utils/dateUtils';

import { CONTACT_CHANNEL_NAME, CONTACT_CHANNEL_ICON } from '../../constants';
import { useContactAttempt } from './useContactAttempt';

const TIMESPAN_EDIT_LIMIT = 24;

export const ContactAttempt = ({
  contactAttempts,
  setContactAttempts,
  supplier,
}) => {
  const {
    disableButton,
    formValues,
    handleFormOnChange,
    handleSubmit,
    loadingState,
    maxDate,
    setFormValues,
    setShowForm,
    showContactAttempts,
    showForm,
    showNewContactButton,
    showNoContactedPersonErrorMessage,
    showNewForm,
  } = useContactAttempt({
    contactAttempts,
    setContactAttempts,
    supplier,
  });

  return (
    <Box
      flexDirection="column"
      className="contact-attempts-container"
      display="flex"
      gap={1}
    >
      <Typography variant="h6" className="content-heading">
        Kontaktversuche
      </Typography>
      <Box marginTop={1}>
        <Typography>
          Hier siehst du eine Übersicht deiner Kontaktversuche
        </Typography>
      </Box>
      <Box marginTop={2}>
        {showNoContactedPersonErrorMessage && (
          <Box
            marginTop={18}
            alignItems="center"
            justifyContent="center"
            display="flex"
            height="100%"
            width="100%"
          >
            <Typography color="textSecondary">
              Für diesen Lieferanten sind keine Kontaktversuche gespeichert
            </Typography>
          </Box>
        )}
        {showContactAttempts && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            width="100%"
            gap={1}
          >
            {contactAttempts.map((attempt, index_) => {
              const allowEdit =
                dateUtils.getDifferenceInHours(attempt.timestamp, new Date()) <
                TIMESPAN_EDIT_LIMIT;

              return (
                <Box
                  key={`contact-attempt-${index_}`}
                  className="active-form-data"
                  width="100%"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom={1.5}
                    gap={2}
                  >
                    <Typography className="flex-1">
                      <b>
                        {CONTACT_CHANNEL_NAME[attempt.channel] ??
                          CONTACT_CHANNEL_NAME[attempt.channel]}
                      </b>
                    </Typography>
                    <Typography>
                      <DateRangeIcon fontSize="10px" />
                      {dateUtils.getFormattedDate(
                        attempt.timestamp,
                        dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
                      )}
                    </Typography>
                    {allowEdit && (
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setFormValues({
                            ...attempt,
                            timestamp: dateUtils.getFormattedDate(
                              attempt.timestamp,
                              dateUtils.DATE_FORMAT.DATE_TIME_INPUT_FORMAT,
                            ),
                          });
                          setShowForm(true);
                        }}
                        size="large"
                      >
                        <CreateIcon htmlColor="#2453B2" />
                      </IconButton>
                    )}
                  </Box>
                  <Typography color="textSecondary" variant="body2">
                    {attempt.summary}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        )}
        {showNewContactButton && (
          <Box marginTop={2}>
            <Button onClick={showNewForm} variant="outlined" color="primary">
              <AddOutlinedIcon />
              Neuer Kontaktversuch
            </Button>
          </Box>
        )}
        {showForm && (
          <Box className="form-container">
            <Box marginBottom={2}>
              <Typography className="semibold">Neuer Kontaktversuch</Typography>
            </Box>
            <Grid container flexDirection="row" alignItems="center" spacing={1}>
              <Grid item xs={6}>
                <InputLabel>Art des Kontakts</InputLabel>
                <Select
                  value={formValues.channel}
                  name="channel"
                  onChange={handleFormOnChange}
                  startAdornment={CONTACT_CHANNEL_ICON[formValues.channel]}
                  variant="outlined"
                  color="primary"
                  fullWidth
                >
                  <MenuItem value="phone">Telefon</MenuItem>
                  <MenuItem value="email">E-Mail</MenuItem>
                  <MenuItem value="personal">Persönlich</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>Datum des Kontakts</InputLabel>
                <TextField
                  value={formValues.timestamp}
                  type="datetime-local"
                  name="timestamp"
                  onChange={handleFormOnChange}
                  fullWidth
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    maxDate,
                    max: maxDate,
                  }}
                  maxDate={maxDate}
                />
              </Grid>
            </Grid>
            <Box marginTop={1} className="textarea-container">
              <FormLabel>Zusammenfassung</FormLabel>
              <TextareaAutosize
                value={formValues.summary}
                name="summary"
                onChange={handleFormOnChange}
                minLength={4}
                placeholder="Schreibe auf, was besprochen wurde"
                autoFocus
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={2}
              marginTop={2}
            >
              <Button
                loading={loadingState === LOADING_STATE.LOADING}
                disabled={disableButton}
                onClick={handleSubmit}
                size="small"
                color="primary"
                variant="contained"
              >
                Speichern
              </Button>
              <Button
                loading={loadingState === LOADING_STATE.LOADING}
                size="small"
                color="primary"
                variant="outlined"
                onClick={() => setShowForm(false)}
              >
                Abbrechen
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
