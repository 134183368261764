import React, { useEffect, useState } from 'react';

import {
  AccountCircle as AccountCircleIcon,
  ArrowBack as ArrowBackIcon,
  FactCheck as FactCheckIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import {
  Button,
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Link, useHistory } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import { useSelector } from 'react-redux';
import userService from '~/services/user.service';
import { ROUTE } from '~/constants/Route';
import { promiseHandler } from '~/utils/promiseHandler';
import CompanyService from '~/services/company.service';
import Log from '~/utils/Log';
import User from '~/models/masterdata/User';

import Config, { environmentInfo } from '~/Config';

import BrowserUtils from '~/utils/browserUtils';
import { LightTooltip } from '~/utils/componentUtils';
import RouteUtils from '~/utils/routeUtils';
import ConfigUtils from '~/utils/configUtils';
import { SitesCostCentersSelection } from './SitesCostCentersSelection';

function TopBar(props) {
  const useStyles = makeStyles((theme) => ({
    appBar: {
      width: `calc(100% - ${props.drawerWidth}px)`,
      marginLeft: props.drawerWidth,
      transition: 'width 1s ease-in-out',
      backgroundColor: '#ffffff',
      color: '#343A4D',
      // This fixed a bug. Without it, the burger menu would move to the left when the date range select
      // in the home screen is opened (in case of a screen width of around 729px).
      paddingRight: '0 !important',
    },
  }));

  const classes = useStyles();
  const [anchorElement, setAnchorElement] = React.useState(null);
  const isMenuOpen = Boolean(anchorElement);
  const auth = useAuth();
  const history = useHistory();
  const userinfo = useSelector((state) => state.userinfo);
  const menu = useSelector((state) => state.menu);

  const logout = () => {
    Log.productAnalyticsEvent('Logout', Log.FEATURE.AUTHENTICATION);
    auth.signOutRedirect({ post_logout_redirect_uri: Config.redirectUrl });
  };

  // load company logo
  const [companyLogo, setCompanyLogo] = useState(null);

  useEffect(() => {
    loadCompanyLogo();
  }, [userinfo.userinfo?.company?.id]);

  const loadCompanyLogo = async () => {
    if (!userinfo.userinfo?.company?.id) {
      return;
    }

    const [companyLogo, error] = await promiseHandler(
      CompanyService.getCompanyLogoById(userinfo.userinfo?.company?.id),
    );

    if (error) {
      Log.error('Failed to load logo of user company', error);
      Log.productAnalyticsEvent(
        'Failed to load logo of user company',
        Log.FEATURE.MENU,
        Log.TYPE.ERROR,
      );
      return;
    }

    setCompanyLogo(companyLogo);
  };

  const handleMenuClose = (event) => {
    Log.productAnalyticsEvent('Close burger menu', Log.FEATURE.MENU);
    setAnchorElement(null);
  };

  const handleMenuOpen = (event) => {
    Log.productAnalyticsEvent('Open burger menu', Log.FEATURE.MENU);
    setAnchorElement(event.currentTarget);
  };

  const handleNavigateBack = () => {
    Log.productAnalyticsEvent('Navigate back', Log.FEATURE.MENU);
    history.goBack();
  };

  const handleClickDeliveryDataQuality = () => {
    Log.productAnalyticsEvent(
      'Open delivery note data quality',
      Log.FEATURE.DELIVERY_NOTE,
    );
    history.push(
      ROUTE.DELIVERY_NOTE_DATA_QUALITY.ROUTE +
        '/' +
        RouteUtils.getUuid(history.location.pathname),
    );
  };

  const menuId = 'account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorElement}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <div style={{ padding: '0px 16px 16px 16px', maxWidth: 200 }}>
        <div
          className="flex-s-c flexdir-column gap-10px text-center"
          style={{ paddingBottom: '20px' }}
        >
          {userinfo.userinfo?.profilePicture?.size > 0 ? (
            <img
              width="50px"
              src={URL.createObjectURL(userinfo.userinfo.profilePicture)}
              className="circle mt-20px mb-1rem"
            />
          ) : (
            <AccountCircleIcon
              fontSize="large"
              className="h-50px w-50px mt-20px mb-1rem"
            />
          )}
          {userinfo.userinfo?.firstname || userinfo.userinfo?.lastname ? (
            <div className="bold">
              {User.formatName(
                userinfo.userinfo?.firstname,
                userinfo.userinfo?.lastname,
              )}
            </div>
          ) : null}
          {userinfo.userinfo?.position ? (
            <div>{userinfo.userinfo?.position}</div>
          ) : null}
          {userinfo.userinfo?.company?.name ? (
            <div>{userinfo.userinfo?.company?.name}</div>
          ) : null}
        </div>
        <div style={{ borderBottom: '1px solid #E4E6ED' }} />
      </div>
      {userService.userIsAuthorizedForPage(
        userinfo.userPermissions,
        '/dashboard',
      ) ? (
        <MenuItem
          onClick={handleMenuClose}
          style={{ paddingTop: 11, paddingBottom: 11 }}
          component={Link}
          to="/dashboard"
        >
          Statistiken
        </MenuItem>
      ) : null}
      {/* <MenuItem onClick={handleMenuClose} component={Link} to="/messages">Nachrichten</MenuItem> */}
      {/* workaround time: MenuItem with link to external site didn't work. Hence this beautiful solution of an a tag, styled the same as a MenuItem */}
      {userinfo.userinfo.userHasLoggedInViaSSO ? null : (
        <MenuItem>
          <a
            className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters"
            target="_blank"
            rel="noopener noreferrer"
            href="https://login.vestigas.com/auth/realms/vestigas/account/password"
          >
            Passwort ändern
          </a>
        </MenuItem>
      )}
      <MenuItem
        onClick={handleMenuClose}
        style={{ paddingTop: 11, paddingBottom: 11 }}
        component={Link}
        to={ROUTE.SETTINGS.ROUTE}
      >
        Benutzerdaten ändern
      </MenuItem>
      <MenuItem>
        <a
          className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters"
          target="_blank"
          rel="noopener noreferrer"
          href="https://vestigas.notion.site/Herzlich-Willkommen-in-einer-Welt-ohne-Lieferscheine-c4db904d5ff4405fa1feb1f5cf8bd0c2"
        >
          Zur Schulung
        </a>
      </MenuItem>
      <MenuItem
        onClick={handleMenuClose}
        style={{ paddingTop: 11, paddingBottom: 11 }}
        component={Link}
        to={ROUTE.SETTINGS.ROUTE}
      >
        Einstellungen/Admin
      </MenuItem>
      <MenuItem
        onClick={handleMenuClose}
        style={{ paddingTop: 11, paddingBottom: 11 }}
        component={Link}
        to={ROUTE.SETTINGS_IMPRESSUM.ROUTE}
      >
        Impressum
      </MenuItem>
      <MenuItem
        className="border-top"
        onClick={logout}
        style={{ paddingTop: 11, borderTop: '#E0E0E0 solid 1px' }}
      >
        Abmelden
      </MenuItem>
    </Menu>
  );

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton onClick={handleNavigateBack} size="large">
          <ArrowBackIcon />
        </IconButton>
        <div className="flex-s-c gap-20px pl-20px flex-1">
          <span className="text-32px truncate">{menu.pageTitle}</span>
          {RouteUtils.getBaseUrl(history.location.pathname) ===
            ROUTE.DELIVERY_NOTE.ROUTE &&
          ConfigUtils.isDevelopmentStagingEnvironment() ? (
            <div>
              <LightTooltip title="Öffne die Datenansicht der Lieferung und überprüfe, ob die Informationen der Lieferung der gewünschten Datenqualität entsprechen.">
                <Button
                  variant="outlined"
                  startIcon={<FactCheckIcon />}
                  component="span"
                  onClick={handleClickDeliveryDataQuality}
                >
                  Datenqualität prüfen
                </Button>
              </LightTooltip>
            </div>
          ) : null}
        </div>
        <SitesCostCentersSelection className="mr-30px" />
        {ConfigUtils.isProductionEnvironment() ? null : (
          <>
            <div className="mr-30px bold text-14px text-successBase">
              {environmentInfo}
            </div>
            <div className="h-45px w-1px bg-grey400 mr-30px" />
          </>
        )}
        {companyLogo?.size > 0 && BrowserUtils.isWideScreen() ? (
          <>
            <img
              className="toolbar-logo mr-30px"
              src={URL.createObjectURL(companyLogo)}
            />
            <div className="h-45px w-1px bg-grey400 mr-30px" />
          </>
        ) : null}
        <Button
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleMenuOpen}
          color="inherit"
          startIcon={<AccountCircleIcon fontSize="large" />}
          endIcon={<KeyboardArrowDownIcon fontSize="large" />}
        >
          {User.formatName(
            userinfo.userinfo.firstname,
            userinfo.userinfo.lastname,
          )
            ? User.formatName(
                userinfo.userinfo.firstname,
                userinfo.userinfo.lastname,
              )
            : 'Menü'}
        </Button>
        {renderMenu}
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
