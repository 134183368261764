import cloneDeep from 'lodash/cloneDeep';

import FilterGroupFilter from '~/models/filters/FilterGroupFilter';
import Log from '~/utils/Log';

/**
 * Handles the change of filter values based on the type provided.
 * All of this code goes away with refactoring the filtersSlice.
 * The function currently takes the full props object of DeliveryOverview as an argument, because it contains a huge list of filter getters and setters that should not exist in the first place.
 *
 * @param {string} type - The type of filter being changed.
 * @param {object} customField - The custom field object.
 * @param {string} filterValue - The new filter value.
 * @param {object} props - The full props object of DeliveryOverview.
 */
export const handleFilterChange = (type, customField, filterValue, props) => {
  const {
    selectedAcceptState,
    selectedArticle,
    selectedArticleNumber,
    selectedCostCenter,
    selectedCustomFields,
    selectedFromSite,
    selectedNumber,
    selectedPermittedCostCenters,
    selectedPermittedToSites,
    selectedProcessState,
    selectedRecipient,
    selectedSettledStatus,
    selectedSupplier,
    selectedToSiteRecipient,
    selectedToSiteSupplier,
    setDelivery_selectedAcceptState,
    setDelivery_selectedArticle,
    setDelivery_selectedArticleNumber,
    setDelivery_selectedCostCenter,
    setDelivery_selectedCustomFields,
    setDelivery_selectedFromSite,
    setDelivery_selectedNumber,
    setDelivery_selectedPermittedCostCenters,
    setDelivery_selectedPermittedToSites,
    setDelivery_selectedProcessState,
    setDelivery_selectedRecipient,
    setDelivery_selectedSettledStatus,
    setDelivery_selectedSupplier,
    setDelivery_selectedToSiteRecipient,
    setDelivery_selectedToSiteSupplier,
    // setFilterValue,
  } = props;

  // setFilterValue({
  //   page: 'deliveries',
  //   filterKey: mapDeliveriesFilterName(type),
  //   filterValue,
  // });

  switch (type) {
    case FilterGroupFilter.FILTER.SELECTED_TO_SITE_RECIPIENT: {
      Log.info(
        `Change filter value of ${type}`,
        {
          from: selectedToSiteRecipient,
          to: filterValue,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Filter to site recipient',
        Log.FEATURE.DELIVERY_OVERVIEW,
      );

      setDelivery_selectedToSiteRecipient(filterValue);
      break;
    }

    case FilterGroupFilter.FILTER.SELECTED_TO_SITE_SUPPLIER: {
      Log.info(
        `Change filter value of ${type}`,
        {
          from: selectedToSiteSupplier,
          to: filterValue,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Filter to site supplier',
        Log.FEATURE.DELIVERY_OVERVIEW,
      );

      setDelivery_selectedToSiteSupplier(filterValue);
      break;
    }

    case FilterGroupFilter.FILTER.SELECTED_COST_CENTER: {
      Log.info(
        `Change filter value of ${type}`,
        {
          from: selectedCostCenter,
          to: filterValue,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Filter cost center',
        Log.FEATURE.DELIVERY_OVERVIEW,
      );

      setDelivery_selectedCostCenter(filterValue);
      break;
    }

    case FilterGroupFilter.FILTER.SELECTED_NUMBER: {
      Log.info(
        `Change filter value of ${type}`,
        {
          from: selectedNumber,
          to: filterValue,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Filter DLN number',
        Log.FEATURE.DELIVERY_OVERVIEW,
      );

      setDelivery_selectedNumber(filterValue);
      break;
    }

    case FilterGroupFilter.FILTER.SELECTED_ARTICLE_NUMBER: {
      Log.info(
        `Change filter value of ${type}`,
        {
          from: selectedArticleNumber,
          to: filterValue,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Filter article number',
        Log.FEATURE.DELIVERY_OVERVIEW,
      );

      setDelivery_selectedArticleNumber(filterValue);
      break;
    }

    case FilterGroupFilter.FILTER.SELECTED_ARTICLE: {
      Log.info(
        `Change filter value of ${type}`,
        {
          from: selectedArticle,
          to: filterValue,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Filter article',
        Log.FEATURE.DELIVERY_OVERVIEW,
      );

      setDelivery_selectedArticle(filterValue);
      break;
    }

    case FilterGroupFilter.FILTER.SELECTED_SUPPLIER: {
      Log.info(
        `Change filter value of ${type}`,
        {
          from: selectedSupplier,
          to: filterValue,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Filter supplier',
        Log.FEATURE.DELIVERY_OVERVIEW,
      );

      setDelivery_selectedSupplier(filterValue);
      break;
    }

    case FilterGroupFilter.FILTER.SELECTED_RECIPIENT: {
      Log.info(
        `Change filter value of ${type}`,
        {
          from: selectedRecipient,
          to: filterValue,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Filter recipient',
        Log.FEATURE.DELIVERY_OVERVIEW,
      );

      setDelivery_selectedRecipient(filterValue);
      break;
    }

    case FilterGroupFilter.FILTER.SELECTED_PROCESS_STATE: {
      Log.info(
        `Change filter value of ${type}`,
        {
          from: selectedProcessState,
          to: filterValue,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Filter process state',
        Log.FEATURE.DELIVERY_OVERVIEW,
      );

      setDelivery_selectedProcessState(filterValue);
      break;
    }

    case FilterGroupFilter.FILTER.SELECTED_ACCEPT_STATE: {
      Log.info(
        `Change filter value of ${type}`,
        {
          from: selectedAcceptState,
          to: filterValue,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Filter accept state',
        Log.FEATURE.DELIVERY_OVERVIEW,
      );

      setDelivery_selectedAcceptState(filterValue);
      break;
    }

    case FilterGroupFilter.FILTER.SELECTED_SETTLED_STATUS: {
      Log.info(
        `Change filter value of ${type}`,
        {
          from: selectedSettledStatus,
          to: filterValue,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Filter settled status',
        Log.FEATURE.DELIVERY_OVERVIEW,
      );

      setDelivery_selectedSettledStatus(filterValue);
      break;
    }

    case FilterGroupFilter.FILTER.SELECTED_FROM_SITE: {
      Log.info(
        `Change filter value of ${type}`,
        {
          from: selectedFromSite,
          to: filterValue,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Filter from site',
        Log.FEATURE.DELIVERY_OVERVIEW,
      );

      setDelivery_selectedFromSite(filterValue);
      break;
    }

    case FilterGroupFilter.FILTER.SELECTED_PERMITTED_TO_SITES: {
      Log.info(
        `Change filter value of ${type}`,
        {
          from: selectedPermittedToSites,
          to: filterValue,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Filter permitted to sites',
        Log.FEATURE.DELIVERY_OVERVIEW,
      );

      setDelivery_selectedPermittedToSites(filterValue);
      break;
    }

    case FilterGroupFilter.FILTER.SELECTED_PERMITTED_COST_CENTERS: {
      Log.info(
        `Change filter value of ${type}`,
        {
          from: selectedPermittedCostCenters,
          to: filterValue,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Filter permitted cost centers',
        Log.FEATURE.DELIVERY_OVERVIEW,
      );

      setDelivery_selectedPermittedCostCenters(filterValue);
      break;
    }

    default: {
      break;
    }
  }

  if (!customField) {
    return;
  }

  const newSelectedCustomFields = cloneDeep(selectedCustomFields);
  const selectedCustomFieldIndex = selectedCustomFields.findIndex(
    ({ key }) => key === customField.key,
  );

  Log.info(
    `Change filter value of ${customField.key}`,
    {
      from: selectedCustomFields[selectedCustomFieldIndex]?.filterValue,
      to: filterValue,
    },
    Log.BREADCRUMB.FILTER_CHANGE.KEY,
  );
  Log.productAnalyticsEvent(
    'Filter custom field',
    Log.FEATURE.DELIVERY_OVERVIEW,
  );

  if (selectedCustomFieldIndex === -1) {
    // If the custom field has not filter applied yet, push it to the selectedCustomFields filter.
    newSelectedCustomFields.push({
      key: customField.key,
      filterValue,
    });
  } else if (filterValue.length > 0) {
    // If a filter value has been added, overwrite the custom field filter
    newSelectedCustomFields[selectedCustomFieldIndex].filterValue = filterValue;
  } else {
    // If all filter values have been removed, remove the whole custom field entry from the selectedCustomFields variable
    // because we don't want empty custom field entries in selectedCustomFields.
    newSelectedCustomFields.splice(selectedCustomFieldIndex, 1);
  }

  setDelivery_selectedCustomFields(newSelectedCustomFields);
};
