import React from 'react';

import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import {
  Checkbox,
  MenuItem,
  Select,
  Button,
  InputLabel,
  TextField,
  Autocomplete,
} from '@mui/material';

import { connect } from 'react-redux';
import { withErrorBoundary } from '~/ui/atoms';
import ConcreteDiaryModel from '~/models/concreteDiary/ConcreteDiary';
import BasicTable from '../BasicTable';
import LocalStorageService from '~/services/localStorage.service';
import UnitUtils from '~/utils/unitUtils';
import { dateUtils, parseDate } from '~/utils/dateUtils';
import Log from '~/utils/Log';

import ToastService from '~/services/toast.service';
import {
  setConcreteDiary_concreteIds,
  setConcreteDiary_site,
  setConcreteDiary_supplier,
  setConcreteDiary_sortModel,
} from '~/redux/filtersSlice';
import { DeliveryNoteLink } from '~/utils/componentUtils';
import DeliveryStatus from '../deliveries/DeliveryStatus';
import Spinner from '../Spinner';
import ArrayUtils from '~/utils/arrayUtils';
import DeliveryNote from '~/models/deliveries/DeliveryNote';
import cloneDeep from 'lodash/cloneDeep';
import { LOADING_STATE } from '~/constants/LoadingState';
import { setPageTitle } from '~/redux/menuSlice';

const mapStateToProps = (state) => ({
  deliveryNotes: state.deliveryNotes,
  concreteIds: state.filters.concreteDiary_concreteIds,
  site: state.filters.concreteDiary_site,
  supplier: state.filters.concreteDiary_supplier,
  sortModel: state.filters.concreteDiary_sortModel,
});
const mapDispatchToProps = () => ({
  setConcreteDiary_concreteIds,
  setConcreteDiary_site,
  setConcreteDiary_supplier,
  setConcreteDiary_sortModel,
  setPageTitle,
});

class ConcreteDiary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      concreteDiary: null,
      rows: [],
      selectableConcreteIds: [],
      selectableSites: [],
      selectableSuppliers: [],
      rowSelectionModel: [],
      expandedRow: null,
    };
  }

  componentDidMount() {
    this.loadData();
    this.initSelects();

    this.props.setPageTitle('Betontagebuch');
    document.title = 'VESTIGAS - Betontagebuch';
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.deliveryNotes.filteredDeliveryNotesVersion !==
        prevProps.deliveryNotes.filteredDeliveryNotesVersion ||
      JSON.stringify(this.props.concreteIds) !==
        JSON.stringify(prevProps.concreteIds) ||
      this.props.site !== prevProps.site ||
      this.props.supplier !== prevProps.supplier
    ) {
      this.loadData();
    }

    if (
      this.props.deliveryNotes.filteredDeliveryNotesVersion !==
      prevProps.deliveryNotes.filteredDeliveryNotesVersion
    ) {
      this.initSelects();
    }
  }

  loadData() {
    if (this.props.deliveryNotes.filteredDeliveryNotes.length === 0) {
      return;
    }

    const concreteDiary = new ConcreteDiaryModel(
      this.props.deliveryNotes.filteredDeliveryNotes,
      this.props.concreteIds,
      this.props.site,
      this.props.supplier,
    );

    const rows = concreteDiary.records.map((record, index) => {
      return {
        ...record,
        id: index,
        numberTo: record.numberFrom === record.numberTo ? '-' : record.numberTo,
        deliveryCount: record.deliveryNotes.length,
        selectedNumber: record.deliveryNotes[0].number,
        cement: record.cement.join(', '),
        concretingTime: record.getConcretingTime(),
      };
    });

    this.setState({
      concreteDiary,
      rows,
    });

    const sortedDeliveryNotes = ArrayUtils.sortByKey(
      this.props.deliveryNotes.filteredDeliveryNotes,
      DeliveryNote.PROPERTY.CREATION_DATE.KEY,
      true,
    );
    const filteredDeliveryNotes =
      this.props.deliveryNotes.filteredDeliveryNotes.filter(
        (deliveryNote) =>
          !(
            deliveryNote.creationDate <=
            LocalStorageService.getLocalStorage(
              LocalStorageService.CONCRETE_DIARY_LATEST_DLN_TS,
            )
          ),
      );

    const concreteIds = [];

    for (const deliveryNote of filteredDeliveryNotes) {
      for (const article of deliveryNote.articles) {
        if (
          article.isConcrete() &&
          article.concrete.isMonitoringConcrete() &&
          !concreteIds.includes(article.concrete.concreteId)
        ) {
          concreteIds.push(article.concrete.concreteId);
        }
      }
    }

    this.props.setConcreteDiary_concreteIds([
      ...concreteIds,
      ...this.props.concreteIds,
    ]);
    LocalStorageService.setLocalStorage(
      LocalStorageService.CONCRETE_DIARY_LATEST_DLN_TS,
      sortedDeliveryNotes[0].creationDate,
    );
  }

  initSelects() {
    let selectableConcreteIds = [];
    let selectableSites = [];
    let selectableSuppliers = [];

    for (const deliveryNote of this.props.deliveryNotes.filteredDeliveryNotes) {
      const article = deliveryNote.articles.find((article) =>
        article.isConcrete(),
      );

      if (!article) {
        continue;
      }

      if (
        article.concrete.concreteId &&
        !selectableConcreteIds.includes(article.concrete.concreteId)
      ) {
        selectableConcreteIds.push(article.concrete.concreteId);
      }

      if (
        deliveryNote.toSiteRecipient.id &&
        !selectableSites.find(
          (item) => item.id === deliveryNote.toSiteRecipient.id,
        )
      ) {
        selectableSites.push(deliveryNote.toSiteRecipient);
      }

      if (
        deliveryNote.supplier.id &&
        !selectableSuppliers.find(
          (item) => item.id === deliveryNote.supplier.id,
        )
      ) {
        selectableSuppliers.push(deliveryNote.supplier);
      }
    }

    selectableConcreteIds = selectableConcreteIds.sort();
    selectableSites = ArrayUtils.sortByKey(selectableSites, 'name');
    selectableSuppliers = ArrayUtils.sortByKey(selectableSuppliers, 'name');

    this.setState({
      selectableConcreteIds,
      selectableSites,
      selectableSuppliers,
    });
  }

  getColumns() {
    return [
      {
        field: 'copy',
        headerName: 'Daten kopieren',
        width: 150,
        renderCell: (params) => {
          return (
            <Button
              variant="text"
              onClick={(e) => this.copyRow(e, params.id)}
              startIcon={<ContentCopyIcon fontSize="small" />}
            >
              Kopieren
            </Button>
          );
        },
      },
      {
        field: 'date',
        headerName: 'Betoniertag',
        type: 'date',
        width: 150,
        sortable: true,
        resizableText: true,
        valueGetter: parseDate,
        renderCell: (params) =>
          dateUtils.getFormattedDate_safe(
            params.value,
            dateUtils.DATE_FORMAT.DD_MM_YYYY,
          ),
      },
      {
        field: 'concreteId',
        headerName: 'Sortennummer',
        width: 150,
        sortable: true,
        resizableText: true,
      },
      {
        field: 'numberFrom',
        headerName: 'LFS-Nr. (von)',
        width: 250,
        sortable: true,
        resizableText: true,
      },
      {
        field: 'numberTo',
        headerName: 'LFS-Nr. (bis)',
        width: 250,
        sortable: true,
        resizableText: true,
      },
      {
        field: 'deliveryCount',
        headerName: 'Anz. Lieferungen',
        width: 100,
        sortable: true,
        resizableText: true,
      },
      {
        field: 'totalAmount',
        headerName: 'Gesamtmenge',
        width: 100,
        sortable: true,
        type: 'number',
        resizableText: true,
        renderCell: (params) => UnitUtils.formatDe_safe(params.value),
      },
      {
        field: 'strengthClass',
        headerName: 'Festigkeitsklasse',
        width: 120,
        sortable: true,
        resizableText: true,
      },
      {
        field: 'exposureClass',
        headerName: 'Expositionsklasse',
        width: 150,
        sortable: true,
        resizableText: true,
      },
      {
        field: 'consistency',
        headerName: 'Konsistenzklasse',
        width: 120,
        sortable: true,
        resizableText: true,
      },
      {
        field: 'cement',
        headerName: 'Zement',
        width: 200,
        sortable: true,
        resizableText: true,
      },
      {
        field: 'largestGrain',
        headerName: 'Größtkorn',
        width: 120,
        sortable: true,
        resizableText: true,
      },
      {
        field: 'waterCementRatio',
        headerName: 'W/Z',
        width: 120,
        sortable: true,
        resizableText: true,
        renderCell: (params) => UnitUtils.formatDe_safe(params.value),
      },
      {
        field: 'concretingTime',
        headerName: 'Betonier-Beginn/Ende',
        width: 180,
        sortable: true,
        resizableText: true,
        renderCell: (params) => params.value,
      },
    ];
  }

  copyRow = (e, id) => {
    e.stopPropagation();
    Log.productAnalyticsEvent('Copy record', Log.FEATURE.CONCRETE_DIARY);

    if (
      this.state.rowSelectionModel.length > 0 &&
      !this.state.rowSelectionModel.includes(id)
    ) {
      ToastService.warning([
        'Bitte wähle nur die Einträge aus, die du kopieren möchtest.',
      ]);
      return;
    }

    let selectedRows = [];

    if (this.state.rowSelectionModel.length > 0) {
      selectedRows = this.state.rows.filter((row) =>
        this.state.rowSelectionModel.includes(row.id),
      );
    } else {
      selectedRows = this.state.rows.filter((row) => row.id === id);
    }

    try {
      const string = this.state.concreteDiary.getExcelString(selectedRows);

      navigator.clipboard
        .writeText(string)
        .then((response) => {
          ToastService.success(['In die Zwischenablage kopiert.']);
        })
        .catch((error) => {
          ToastService.error([
            'Eintrag konnte nicht in die Zwischenablage kopiert werden.',
          ]);
          Log.productAnalyticsEvent(
            'Failed to copy record',
            Log.FEATURE.CONCRETE_DIARY,
            Log.TYPE.ERROR,
          );
        });
    } catch (error) {
      Log.error('Failed to copy concrete diary record to clipboard.', error);
      ToastService.error([
        'Eintrag konnte nicht in die Zwischenablage kopiert werden. Bitte kontaktiere den Support, damit dein Betontagebuch registriert werden kann.',
      ]);
      Log.productAnalyticsEvent(
        'Failed to copy record',
        Log.FEATURE.CONCRETE_DIARY,
        Log.TYPE.ERROR,
      );
    }

    /* const row = this.state.rows.find((row) => row.id === id);

    try {
      const string = this.state.concreteDiary.getExcelString(row.concreteId, row.date, row.selectedNumber);

      navigator.clipboard
        .writeText(string)
        .then((response) => {
          ToastService.success(['In die Zwischenablage kopiert.']);
        })
        .catch((err) => {
          ToastService.error(['Eintrag konnte nicht in die Zwischenablage kopiert werden.']);
          Log.productAnalyticsEvent('Failed to copy record', Log.FEATURE.CONCRETE_DIARY, Log.TYPE.ERROR);
        });
    } catch (e) {
      Log.error('Failed to copy concrete diary record to clipboard.', e);
      ToastService.error([
        'Eintrag konnte nicht in die Zwischenablage kopiert werden. Bitte kontaktiere den Support, damit dein Betontagebuch registriert werden kann.'
      ]);
      Log.productAnalyticsEvent('Failed to copy record', Log.FEATURE.CONCRETE_DIARY, Log.TYPE.ERROR);
    } */
  };
  handleChangeConcreteId = (value) => {
    Log.info(
      'Change filter value of concrete ids',
      { from: this.props.concreteIds, to: value },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Filter concrete id', Log.FEATURE.CONCRETE_DIARY);

    this.props.setConcreteDiary_concreteIds(value);
  };
  handleChangeSite = (event) => {
    Log.info(
      'Change filter value of site',
      { from: this.props.site, to: event.target.value },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Filter site', Log.FEATURE.CONCRETE_DIARY);

    this.props.setConcreteDiary_site(event.target.value);
  };
  handleChangeSupplier = (event) => {
    Log.info(
      'Change filter value of supplier',
      { from: this.props.supplier, to: event.target.value },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Filter supplier', Log.FEATURE.CONCRETE_DIARY);

    this.props.setConcreteDiary_supplier(event.target.value);
  };
  handleCheckboxChange = (rowId, deliveryNoteNumber) => {
    const newRows = cloneDeep(this.state.rows);

    const index = newRows.findIndex(({ id }) => id === rowId);
    newRows[index].selectedNumber = deliveryNoteNumber;

    this.setState({
      rows: newRows ?? [],
    });
  };
  onRowSelectionModelChange = (event) => {
    Log.info(
      'Change selection value of selected concrete diary records',
      { from: this.state.rowSelectionModel, to: event },
      Log.BREADCRUMB.SELECTION_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      '(De)select concrete diary record',
      Log.FEATURE.CONCRETE_DIARY,
    );

    this.setState({
      rowSelectionModel: event,
    });
  };
  onSortModelChange = (event) => {
    Log.productAnalyticsEvent('Sort', Log.FEATURE.CONCRETE_DIARY);
    this.props.setConcreteDiary_sortModel(event);
  };
  onRowClick = (rowData) => {
    if (this.state.expandedRow === rowData.id) {
      Log.productAnalyticsEvent('Collapse record', Log.FEATURE.CONCRETE_DIARY);
      this.setState({
        expandedRow: null,
      });
    } else {
      Log.productAnalyticsEvent('Expand record', Log.FEATURE.CONCRETE_DIARY);
      this.setState({
        expandedRow: rowData.id,
      });
    }
  };
  getDetailPanelContent = (rowData) => {
    const sortedDeliveryNotes = rowData.row.deliveryNotes.sort();

    return (
      <div className="pl-100px pt-30px flexdir-column gap-15px border-bottom flex h-full w-full">
        {sortedDeliveryNotes.map((deliveryNote) => {
          return (
            <div className="flex-s-c gap-30px text-14px h-20px">
              <Checkbox
                checked={deliveryNote.number === rowData.row.selectedNumber}
                onChange={() =>
                  this.handleCheckboxChange(rowData.id, deliveryNote.number)
                }
                size="small"
              />
              <div className="w-140px h-full">
                <DeliveryStatus
                  processState={deliveryNote.processState}
                  combinedState={deliveryNote.combinedState}
                  settledStatus={deliveryNote.settledStatus}
                  whiteBackground
                />
              </div>
              <DeliveryNoteLink {...deliveryNote} />
              <span className="w-100px text-overflow text-end">
                {UnitUtils.formatValueUnitPair_safe(
                  deliveryNote.mainArticle.amount.value,
                  deliveryNote.mainArticle.amount.unit,
                  UnitUtils.getAbbreviatedUnit,
                ) ?? '-'}
              </span>
              <span className="w-80px">{deliveryNote.carrierLicensePlate}</span>
              <span className="">{deliveryNote.fromSite.name}</span>
            </div>
          );
        })}
      </div>
    );
  };
  getDetailPanelHeight = (rowData) => {
    return 40 + rowData.row.deliveryNotes.length * 40;
  };

  render() {
    if (
      this.props.deliveryNotes.deliveryNotesLoading === LOADING_STATE.LOADING
    ) {
      return <Spinner title="Betontagebuch wird geladen..." />;
    }

    return (
      <div className="main-padding flexdir-column flex h-full">
        <div className="flex-s-e gap-20px">
          <Autocomplete
            className="max-w-800px w-full"
            size="small"
            multiple
            noOptionsText="Keine Optionen"
            options={this.state.selectableConcreteIds}
            getOptionLabel={(option) => option}
            value={this.props.concreteIds}
            onChange={(event, value) => this.handleChangeConcreteId(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Sortennummern"
                placeholder="Weitere"
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <div className="text-12px pt-5px pb-5px w-full">{option}</div>
              </li>
            )}
          />
          <div className="w-200px">
            <InputLabel className="text-13px" id="demo-multiple-name-label">
              Lieferort
            </InputLabel>
            <Select
              labelId="site-select"
              id="site-select"
              value={this.props.site}
              fullWidth
              key="site-select-0"
              onChange={this.handleChangeSite}
              size="small"
              className="bg-white"
            >
              {this.state.selectableSites.map((entity) => (
                <MenuItem value={entity.id} key={entity.id}>
                  {entity.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="w-200px">
            <InputLabel className="text-13px" id="demo-multiple-name-label">
              Lieferant
            </InputLabel>
            <Select
              labelId="supplier-select"
              id="supplier-select"
              value={this.props.supplier}
              fullWidth
              key="supplier-select-0"
              onChange={this.handleChangeSupplier}
              size="small"
              className="bg-white"
            >
              {this.state.selectableSuppliers.map((entity) => (
                <MenuItem value={entity.id} key={entity.id}>
                  {entity.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className="min-h-500px mt-20px box-shadow-blue rounded-5px flex-1 bg-white">
          <BasicTable
            rows={this.state.rows}
            columns={this.getColumns()}
            disableRowSelectionOnClick
            checkboxSelection
            onRowSelectionModelChange={this.onRowSelectionModelChange}
            rowSelectionModel={this.state.rowSelectionModel}
            onSortModelChange={this.onSortModelChange}
            sortModel={this.props.sortModel}
            getDetailPanelContent={this.getDetailPanelContent}
            getDetailPanelHeight={this.getDetailPanelHeight}
            detailPanelExpandedRowIds={[this.state.expandedRow]}
            onRowClick={this.onRowClick}
            defaultHiddenColumns={['__detail_panel_toggle__']}
            loading={this.props.deliveryNotes.deliveryNotesLoading}
            localStorageKey={LocalStorageService.CONCRETE_DIARY}
          />
        </div>
        <div
          className="min-h-2rem"
          /* This is a hacky workaround to get the padding bottom of 2rem. It is applied as child container to all divs with main-padding */
          /* A better solution would be to make the parent container min-h-fit-content so that the padding of main-padding is applied. */
          /* However, min-h-fit-content seems to not work with h-fill or generally with flexbox and flex-1. */
        />
      </div>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(ConcreteDiary),
  'Betontagebuch konnte nicht geladen werden.',
);
