import React from 'react';

import { Button } from '@mui/material';

import { connect } from 'react-redux';
import { withErrorBoundary } from '~/ui/atoms';
import BasicTable from '~/components/BasicTable';
import LocalStorageService from '~/services/localStorage.service';
import DatagridUtils from '~/utils/datagridUtils';
import ArrayUtils from '~/utils/arrayUtils';
import {
  replaceActiveSiteReferences,
  replaceBlacklistedSiteReferences,
  replaceInactiveSiteReferences,
} from '~/redux/siteReferencesSlice';
import { promiseHandler } from '~/utils/promiseHandler';
import UserService from '~/services/user.service';
import ToastService from '~/services/toast.service';
import Log from '~/utils/Log';
import SiteReferenceModal from './SiteReferenceModal';
import SiteService from '~/services/site.service';
import HeaderAccordion from '~/components/baseComponents/accordion/HeaderAccordion';
import Spinner from '~/components/Spinner';
import { LightTooltipSpan } from '~/utils/componentUtils';

const mapStateToProps = ({ siteReferences }) => ({
  activeSiteReferences: siteReferences.activeSiteReferences,
  blacklistedSiteReferences: siteReferences.blacklistedSiteReferences,
  inactiveSiteReferences: siteReferences.inactiveSiteReferences,
  siteReferences,
});
const mapDispatchToProps = () => ({
  replaceActiveSiteReferences,
  replaceBlacklistedSiteReferences,
  replaceInactiveSiteReferences,
});

const columns = [
  {
    field: 'identifier',
    headerName: 'Standortbezeichnung des Lieferanten',
    width: 400,
    sortable: true,
    resizableText: true,
    renderCell: DatagridUtils.displayCellTooltip,
  },
  {
    field: 'issuerName',
    headerName: 'Lieferant',
    width: 500,
    sortable: true,
    resizableText: true,
    renderCell: DatagridUtils.displayCellTooltip,
  },
  {
    field: 'ignoreAddressInfo',
    headerName: 'Ohne Adress-Info',
    width: 120,
    sortable: true,
    resizableText: false,
    renderCell: DatagridUtils.displayCellTooltip,
  },
  {
    field: 'issuerId',
    headerName: 'ID des Lieferanten',
    width: 400,
    sortable: true,
    resizableText: true,
  },
];

const AssignedReferenceColumns = [
  {
    field: 'assignedSiteName',
    headerName: 'Standort',
    width: 400,
    sortable: true,
    resizableText: true,
    renderCell: DatagridUtils.displayCellTooltip,
  },
  ...columns,
];

class SiteReferences extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAssignedSiteReference: null,
      selectedActiveSiteReference: null,
      selectedInactiveSiteReference: null,
      selectedBlacklistedSiteReference: null,
      deleteAssignedSiteReference: false,
      open: false,
      issuerId: null,
      identifier: null,
      assignedSiteId: null,
      identifierId: null,
    };
  }

  onAssignedSiteReferencesSelectionModelChange = (event) => {
    Log.info(
      'Change selection value of selected assigned site references',
      { from: this.state.selectedAssignedSiteReference, to: event },
      Log.BREADCRUMB.SELECTION_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      '(De)select assigned site reference',
      Log.FEATURE.SITE_MAPPING,
    );
    this.setState({
      selectedAssignedSiteReference: event[0],
    });
  };
  onActiveSiteReferencesSelectionModelChange = (event) => {
    Log.info(
      'Change selection value of selected active site references',
      { from: this.state.selectedActiveSiteReference, to: event },
      Log.BREADCRUMB.SELECTION_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      '(De)select active site reference',
      Log.FEATURE.SITE_MAPPING,
    );
    this.setState({
      selectedActiveSiteReference: event[0],
    });
  };
  onInactiveSiteReferencesSelectionModelChange = (event) => {
    Log.info(
      'Change selection value of selected inactive site references',
      { from: this.state.selectedInactiveSiteReference, to: event },
      Log.BREADCRUMB.SELECTION_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      '(De)select inactive site reference',
      Log.FEATURE.SITE_MAPPING,
    );
    this.setState({
      selectedInactiveSiteReference: event[0],
    });
  };
  onBlacklistedSiteReferencesSelectionModelChange = (event) => {
    Log.info(
      'Change selection value of selected blacklisted site references',
      { from: this.state.selectedBlacklistedSiteReference, to: event },
      Log.BREADCRUMB.SELECTION_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      '(De)select blacklisted site reference',
      Log.FEATURE.SITE_MAPPING,
    );
    this.setState({
      selectedBlacklistedSiteReference: event[0],
    });
  };
  deleteSiteReferenceAssignment = async () => {
    Log.info(
      'Delete site reference assignment',
      { value: this.state.selectedAssignedSiteReference },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent(
      'Delete site reference assignment',
      Log.FEATURE.SITE_MAPPING,
    );

    this.setState({
      deleteAssignedSiteReference: true,
    });

    const assignedSiteReference =
      this.props.siteReferences.assignedSiteReferences.find(
        (assignedSiteReference) =>
          assignedSiteReference.id === this.state.selectedAssignedSiteReference,
      );

    const [response, error] = await promiseHandler(
      SiteService.deleteSiteReference(
        assignedSiteReference.assignedSiteId,
        assignedSiteReference.identifierId,
      ),
    );

    if (error) {
      ToastService.httpError(
        [ToastService.MESSAGE.SITE_REFERENCE_DELETION_FAILED],
        error.response,
      );
      Log.productAnalyticsEvent(
        'Failed to delete site reference assignment',
        Log.FEATURE.SITE_MAPPING,
        Log.TYPE.ERROR,
      );
      this.setState({
        deleteAssignedSiteReference: false,
      });
      return;
    }

    this.setState({
      deleteAssignedSiteReference: false,
    });

    SiteService.refreshAssignedSiteReferences();
    SiteService.refreshSiteReferences();
  };
  reassignSiteReference = async () => {
    Log.info(
      'Reassign site reference',
      { value: this.state.selectedAssignedSiteReference },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent(
      'Open form to reassign site reference',
      Log.FEATURE.SITE_MAPPING,
    );

    const assignedSiteReference =
      this.props.siteReferences.assignedSiteReferences.find(
        (assignedSiteReference) =>
          assignedSiteReference.id === this.state.selectedAssignedSiteReference,
      );

    this.setState({
      open: true,
      issuerId: assignedSiteReference.issuerId,
      identifier: assignedSiteReference.identifier,
      assignedSiteId: assignedSiteReference.assignedSiteId,
      identifierId: assignedSiteReference.identifierId,
    });
  };
  deactivateActiveSiteReference = async () => {
    Log.info(
      'Deactivate active site reference',
      { value: this.state.selectedActiveSiteReference },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent(
      'Deactivate site reference',
      Log.FEATURE.SITE_MAPPING,
    );

    const [activeSiteReferences, inactiveSiteReferences] =
      ArrayUtils.moveToNewArrayById(
        this.props.activeSiteReferences,
        this.props.inactiveSiteReferences,
        this.state.selectedActiveSiteReference,
      );

    const [response, error] = await promiseHandler(
      UserService.updateSiteReferences(
        activeSiteReferences,
        inactiveSiteReferences,
        this.props.blacklistedSiteReferences,
      ),
    );

    if (error) {
      Log.error('Failed to update site references.', error);
      ToastService.httpError(
        'Die Lieferortbezeichnungen konnten nicht aktualisiert werden.',
        error.response,
      );
      Log.productAnalyticsEvent(
        'Failed to deactivate site reference',
        Log.FEATURE.SITE_MAPPING,
        Log.TYPE.ERROR,
      );
      return;
    }

    this.props.replaceActiveSiteReferences(activeSiteReferences);
    this.props.replaceInactiveSiteReferences(inactiveSiteReferences);
  };
  blacklistActiveSiteReference = async () => {
    Log.info(
      'Blacklist active site reference',
      { value: this.state.selectedActiveSiteReference },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent(
      'Blacklist site reference',
      Log.FEATURE.SITE_MAPPING,
    );

    const [activeSiteReferences, blacklistedSiteReferences] =
      ArrayUtils.moveToNewArrayById(
        this.props.activeSiteReferences,
        this.props.blacklistedSiteReferences,
        this.state.selectedActiveSiteReference,
      );

    const [response, error] = await promiseHandler(
      UserService.updateSiteReferences(
        activeSiteReferences,
        this.props.inactiveSiteReferences,
        blacklistedSiteReferences,
      ),
    );

    if (error) {
      Log.error('Failed to update site references.', error);
      ToastService.httpError(
        'Die Lieferortbezeichnungen konnten nicht aktualisiert werden.',
        error.response,
      );
      Log.productAnalyticsEvent(
        'Failed to blacklist site reference',
        Log.FEATURE.SITE_MAPPING,
        Log.TYPE.ERROR,
      );
      return;
    }

    this.props.replaceActiveSiteReferences(activeSiteReferences);
    this.props.replaceBlacklistedSiteReferences(blacklistedSiteReferences);
  };
  assignActiveSiteReference = () => {
    Log.info(
      'Assign active site reference',
      { value: this.state.selectedActiveSiteReference },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent('Open form', Log.FEATURE.SITE_MAPPING);

    const siteReference = this.props.activeSiteReferences.find(
      (activeSiteReference) =>
        activeSiteReference.id === this.state.selectedActiveSiteReference,
    );

    this.setState({
      open: true,
      issuerId: siteReference.issuerId,
      identifier: siteReference.identifier,
      assignedSiteId: null,
      identifierId: null,
    });
  };
  blacklistInactiveSiteReference = async () => {
    Log.info(
      'Blacklist inactive site reference',
      { value: this.state.selectedInactiveSiteReference },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent(
      'Blacklist site reference',
      Log.FEATURE.SITE_MAPPING,
    );

    const [inactiveSiteReferences, blacklistedSiteReferences] =
      ArrayUtils.moveToNewArrayById(
        this.props.inactiveSiteReferences,
        this.props.blacklistedSiteReferences,
        this.state.selectedInactiveSiteReference,
      );

    const [response, error] = await promiseHandler(
      UserService.updateSiteReferences(
        this.props.activeSiteReferences,
        inactiveSiteReferences,
        blacklistedSiteReferences,
      ),
    );

    if (error) {
      Log.error('Failed to update site references.', error);
      ToastService.httpError(
        'Die Lieferortbezeichnungen konnten nicht aktualisiert werden.',
        error.response,
      );
      Log.productAnalyticsEvent(
        'Failed to blacklist site reference',
        Log.FEATURE.SITE_MAPPING,
        Log.TYPE.ERROR,
      );
      return;
    }

    this.props.replaceInactiveSiteReferences(inactiveSiteReferences);
    this.props.replaceBlacklistedSiteReferences(blacklistedSiteReferences);
  };
  assignInactiveSiteReference = () => {
    Log.info(
      'Assign inactive site reference',
      { value: this.state.selectedInactiveSiteReference },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent('Open form', Log.FEATURE.SITE_MAPPING);

    const siteReference = this.props.inactiveSiteReferences.find(
      (inactiveSiteReference) =>
        inactiveSiteReference.id === this.state.selectedInactiveSiteReference,
    );

    this.setState({
      open: true,
      issuerId: siteReference.issuerId,
      identifier: siteReference.identifier,
      assignedSiteId: null,
      identifierId: null,
    });
  };
  deactivateBlacklistedSiteReference = async () => {
    Log.info(
      'Deactivate blacklisted site reference',
      { value: this.state.selectedBlacklistedSiteReference },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent(
      'Deactivate site reference',
      Log.FEATURE.SITE_MAPPING,
    );

    const [blacklistedSiteReferences, inactiveSiteReferences] =
      ArrayUtils.moveToNewArrayById(
        this.props.blacklistedSiteReferences,
        this.props.inactiveSiteReferences,
        this.state.selectedBlacklistedSiteReference,
      );

    const [response, error] = await promiseHandler(
      UserService.updateSiteReferences(
        this.props.activeSiteReferences,
        inactiveSiteReferences,
        blacklistedSiteReferences,
      ),
    );

    if (error) {
      Log.error('Failed to update site references.', error);
      ToastService.httpError(
        'Die Lieferortbezeichnungen konnten nicht aktualisiert werden.',
        error.response,
      );
      Log.productAnalyticsEvent(
        'Failed to deactivate site reference',
        Log.FEATURE.SITE_MAPPING,
        Log.TYPE.ERROR,
      );
      return;
    }

    this.props.replaceInactiveSiteReferences(inactiveSiteReferences);
    this.props.replaceBlacklistedSiteReferences(blacklistedSiteReferences);
  };
  closeForm = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <div className="ml-2rem mr-2rem">
        <div className="main-header">Lieferortbezeichnungen</div>
        <div className="mt-20px box-shadow-blue rounded-5px p-10px bg-white">
          <div className="bold text-16px mb-20px">
            Zugeordnete Lieferortbezeichnungen
          </div>
          <div className="h-450px">
            <BasicTable
              rows={this.props.siteReferences.assignedSiteReferences}
              columns={AssignedReferenceColumns}
              rowSelectionModel={
                this.state.selectedAssignedSiteReference
                  ? [this.state.selectedAssignedSiteReference]
                  : []
              }
              onRowSelectionModelChange={
                this.onAssignedSiteReferencesSelectionModelChange
              }
              loading={this.props.siteReferences.assignedSiteReferencesLoading}
              localStorageKey={
                LocalStorageService.ASSIGNED_SITE_REFERENCES_TABLE
              }
              defaultHiddenColumns={['id']}
              disableRowSelectionOnClick
              checkboxSelection
              onlySingleSelection
            />
          </div>
          <div className="flex-sb-c">
            <LightTooltipSpan title='Die Lieferortbezeichnung nicht mehr zu dem aktuellen Standort zuordnen. Diese kann anschließend als "Nicht zugeordnete Lieferortbezeichnung" einem anderen Standort wieder zugeordnet werden.'>
              <Button
                variant="outlined"
                color="secondary"
                className="mt-10px"
                disabled={
                  this.state.deleteAssignedSiteReference ||
                  !this.state.selectedAssignedSiteReference
                }
                onClick={this.deleteSiteReferenceAssignment}
              >
                {this.state.deleteAssignedSiteReference ? (
                  <Spinner title="Zuordnung auflösen..." />
                ) : (
                  'Zuordnung auflösen'
                )}
              </Button>
            </LightTooltipSpan>
            <LightTooltipSpan title="Die Lieferortbezeichnung einem anderen Standort zuordnen.">
              <Button
                variant="outlined"
                color="primary"
                className="mt-10px"
                disabled={
                  this.state.deleteAssignedSiteReference ||
                  !this.state.selectedAssignedSiteReference
                }
                onClick={this.reassignSiteReference}
              >
                Neu zuordnen
              </Button>
            </LightTooltipSpan>
          </div>
        </div>
        <div className="mt-20px box-shadow-blue rounded-5px p-10px bg-white">
          <div className="bold text-16px mb-20px">
            Nicht zugeordnete Lieferortbezeichnungen
          </div>
          <div className="h-450px">
            <BasicTable
              rows={this.props.activeSiteReferences}
              columns={columns}
              disableRowSelectionOnClick
              checkboxSelection
              onlySingleSelection
              rowSelectionModel={
                this.state.selectedActiveSiteReference
                  ? [this.state.selectedActiveSiteReference]
                  : []
              }
              onRowSelectionModelChange={
                this.onActiveSiteReferencesSelectionModelChange
              }
              loading={this.props.siteReferences.siteReferencesLoading}
              localStorageKey={LocalStorageService.SITE_REFERENCES_TABLE}
              defaultHiddenColumns={['id']}
            />
          </div>
          <div className="flex-sb-c">
            <div className="flex-s-c gap-20px">
              <LightTooltipSpan title='Die Lieferortbezeichnung deaktivieren. Lieferortbezeichnungen können als "inaktiv" markiert werden, um hervorzuheben, dass diese aktuell keinem Standort zugeordnet werden müssen.'>
                <Button
                  variant="outlined"
                  className="mt-10px"
                  disabled={!this.state.selectedActiveSiteReference}
                  onClick={this.deactivateActiveSiteReference}
                >
                  Deaktivieren
                </Button>
              </LightTooltipSpan>
              <LightTooltipSpan title='Die Lieferortbezeichnung der Blacklist hinzufügen. Lieferortbezeichnungen können der Blacklist hinzugefügt werden, um hervorzuheben, dass diese keinem Standort zugeordnet werden dürfen. Dies ist vor allem bei so genannten "generischen" oder "allgemeinen" Lieferortbezeichnungen relevant - z.B. Firmenanschriften als Lieferortbezeichnungen.'>
                <Button
                  variant="outlined"
                  color="secondary"
                  className="mt-10px"
                  disabled={!this.state.selectedActiveSiteReference}
                  onClick={this.blacklistActiveSiteReference}
                >
                  Blacklisten
                </Button>
              </LightTooltipSpan>
            </div>
            <LightTooltipSpan title="Die Lieferortbezeichnung einem Standort zuordnen.">
              <Button
                variant="outlined"
                color="primary"
                className="mt-10px"
                disabled={!this.state.selectedActiveSiteReference}
                onClick={this.assignActiveSiteReference}
              >
                Zuordnen
              </Button>
            </LightTooltipSpan>
          </div>
        </div>
        <div className="h-30px" />
        <div className="box-shadow-blue rounded-5px p-10px bg-white">
          <HeaderAccordion
            title={<h3>Deaktivierte Lieferortbezeichnungen</h3>}
            productAnalyticsFeature="inactive site references"
          >
            <div className="h-450px">
              <BasicTable
                rows={this.props.inactiveSiteReferences}
                columns={columns}
                disableRowSelectionOnClick
                checkboxSelection
                onlySingleSelection
                rowSelectionModel={
                  this.state.selectedInactiveSiteReference
                    ? [this.state.selectedInactiveSiteReference]
                    : []
                }
                onRowSelectionModelChange={
                  this.onInactiveSiteReferencesSelectionModelChange
                }
                loading={this.props.siteReferences.siteReferencesLoading}
                localStorageKey={LocalStorageService.SITE_REFERENCES_TABLE}
                defaultHiddenColumns={['id']}
              />
            </div>
            <div className="flex-sb-c">
              <LightTooltipSpan title='Die Lieferortbezeichnung der Blacklist hinzufügen. Lieferortbezeichnungen können der Blacklist hinzugefügt werden, um hervorzuheben, dass diese keinem Standort zugeordnet werden dürfen. Dies ist vor allem bei so genannten "generischen" oder "allgemeinen" Lieferortbezeichnungen relevant - z.B. Firmenanschriften als Lieferortbezeichnungen.'>
                <Button
                  variant="outlined"
                  color="secondary"
                  className="mt-10px"
                  disabled={!this.state.selectedInactiveSiteReference}
                  onClick={this.blacklistInactiveSiteReference}
                >
                  Blacklisten
                </Button>
              </LightTooltipSpan>
              <LightTooltipSpan title="Die Lieferortbezeichnung einem Standort zuordnen.">
                <Button
                  variant="outlined"
                  color="primary"
                  className="mt-10px"
                  disabled={!this.state.selectedInactiveSiteReference}
                  onClick={this.assignInactiveSiteReference}
                >
                  Zuordnen
                </Button>
              </LightTooltipSpan>
            </div>
          </HeaderAccordion>
        </div>
        <div className="h-30px" />
        <div className="box-shadow-blue rounded-5px p-10px bg-white">
          <HeaderAccordion
            title={<h3>Blacklist Lieferortbezeichnungen</h3>}
            productAnalyticsFeature="blacklisted site references"
          >
            <div className="h-450px">
              <BasicTable
                rows={this.props.blacklistedSiteReferences}
                columns={columns}
                disableRowSelectionOnClick
                checkboxSelection
                onlySingleSelection
                rowSelectionModel={
                  this.state.selectedBlacklistedSiteReference
                    ? [this.state.selectedBlacklistedSiteReference]
                    : []
                }
                onRowSelectionModelChange={
                  this.onBlacklistedSiteReferencesSelectionModelChange
                }
                loading={this.props.siteReferences.siteReferencesLoading}
                localStorageKey={LocalStorageService.SITE_REFERENCES_TABLE}
                defaultHiddenColumns={['id']}
              />
            </div>
            <div className="flex-e-c">
              <LightTooltipSpan title="Die Lieferortbezeichnung von der Blacklist entfernen, damit diese wieder einem Standort zugeordnet werden kann.">
                <Button
                  variant="outlined"
                  className="mt-10px"
                  disabled={!this.state.selectedBlacklistedSiteReference}
                  onClick={this.deactivateBlacklistedSiteReference}
                >
                  Nach "Deaktiv" verschieben
                </Button>
              </LightTooltipSpan>
            </div>
          </HeaderAccordion>
        </div>
        <SiteReferenceModal
          open={this.state.open}
          issuerId={this.state.issuerId}
          identifier={this.state.identifier}
          assignedSiteId={this.state.assignedSiteId}
          identifierId={this.state.identifierId}
          closeForm={this.closeForm}
          refreshSiteReferences={SiteService.refreshSiteReferences}
          refreshAssignedSiteReferences={
            SiteService.refreshAssignedSiteReferences
          }
        />
      </div>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(SiteReferences),
  'Lieferortbezeichnungen konnten nicht geladen werden.',
);
