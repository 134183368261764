export const TAB = {
  DASHBOARD: {
    CHARTS: {
      NAME: 'Diagramme',
      INDEX: 0,
    },
    REPORT: {
      NAME: 'Berichte',
      INDEX: 1,
    },
  },
  DELIVERY: {
    LIST: {
      NAME: 'Alle Lieferungen',
      INDEX: 0,
    },
    CHANGES: {
      NAME: 'Änderungen',
      INDEX: 1,
    },
    UNASSIGNED_DELIVERY_NOTES: {
      NAME: 'Nicht zugeordnet',
      INDEX: 2,
    },
  },
};
