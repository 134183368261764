import { useSelector } from 'react-redux';

import { useQueryCompanyLogo } from '~/data/company';

import DataExchangeCompany from '~/models/dataExchanges/DataExchangeCompany';
import UniversalCommunication from '~/models/masterdata/UniversalCommunication';

import UserUtils from '~/utils/userUtils';
import { dateUtils } from '~/utils/dateUtils';

import { useStyles } from './styles';
import { generateMailToLink } from './utils';

const isActive = (status) =>
  [
    DataExchangeCompany.STATUS.ACTIVATED.KEY,
    DataExchangeCompany.STATUS.LIVE.KEY,
    DataExchangeCompany.STATUS.QR_CODE.KEY,
  ].includes(status);

export const useSupplierCard = ({ supplier }) => {
  const dataExchange = supplier?.dataExchange;
  const lastContactAttempt = dataExchange?.lastContactAttemptTimestamp;
  const contactLink = dataExchange?.contactingLink;

  const userinfo = useSelector(({ userinfo }) => userinfo);
  const shouldDisplayInvoicesStatus = !UserUtils.isStrabagAccount(
    userinfo?.userinfo?.company?.companyAccount,
  );

  const classes = useStyles();

  const logo = useQueryCompanyLogo(supplier.id);

  const requestMoreInfo = () => {
    const mailLink = generateMailToLink({
      emailAddress: UniversalCommunication.getSupportContact().email,
      subject: 'Information Lieferanten Status',
      body: `
Bitte informieren Sie mich über den aktuellen Stand der Lieferanten:

${supplier.name} ${supplier?.address?.getConcatenatedAddress?.()}
      `,
    });

    window.location.href = mailLink;
  };

  const contacts = dataExchange?.activeCustomerIds?.length ?? 0;

  const responsiblePerson =
    [
      dataExchange?.responsiblePerson?.first_name,
      dataExchange?.responsiblePerson?.last_name,
    ]
      .filter(Boolean)
      .join(' ') || dataExchange?.responsiblePerson?.email;

  const lastContact = lastContactAttempt
    ? dateUtils.getFormattedDate(
        lastContactAttempt,
        dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
      )
    : null;

  return {
    classes,
    contacts,
    contactLink,
    isActive,
    lastContact,
    logo,
    requestMoreInfo,
    responsiblePerson,
    shouldDisplayInvoicesStatus,
  };
};
