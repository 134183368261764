import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { connect } from 'react-redux';

import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';

import { LOADING_STATE } from '~/constants/LoadingState';

import FeatureService from '~/services/feature.service';
import MasterDataService from '~/services/masterData.service';
import OrganisationalGroupService from '~/services/organisationalGroup.service';
import PushService from '~/services/push.service';
import ToastService from '~/services/toast.service';
import UserService from '~/services/user.service';

import Company from '~/models/masterdata/Company';
import CostCenter from '~/models/masterdata/CostCenter';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import SignatureRoles from '~/models/masterdata/SignatureRoles';
import Site from '~/models/masterdata/Site';
import User from '~/models/masterdata/User';

import { es6ClassFactory as ES6ClassFactory } from '~/utils/ES6ClassFactory';
import { LightTooltipWide } from '~/utils/componentUtils';
import { promiseHandler } from '~/utils/promiseHandler';
import ArrayUtils from '~/utils/arrayUtils';
import FunctionUtils from '~/utils/functionUtils';
import Log from '~/utils/Log';
import ObjectUtils from '~/utils/objectUtils';
import UserUtils from '~/utils/userUtils';

import BasicForm from '~/components/BasicForm';
import ComplexPaginatedEntityMultiPicker from '~/components/baseComponents/inputs/select/ComplexPaginatedEntityMultiPicker';
import GenericMultiPicker from '~/components/baseComponents/inputs/select/GenericMultiPicker';
import MapperService from '~/services/mapper.service';
import PackageBasicRestrictionTooltip from '~/components/salesPackages/packageBasicRestriction/packageBasicRestrictionTooltip';
import PasswordTextField from '~/components/baseComponents/inputs/PasswordTextField';
import SearchableSelect from '~/components/baseComponents/inputs/select/SearchableSelect';
import Select from '~/components/baseComponents/inputs/select/Select';
import Spinner from '~/components/Spinner';

import { OrganisationalGroupPaths } from '../paths/OrganisationalGroupPaths';
import PermissionGrantSubjectTable from '../permissionGrant/PermissionGrantSubjectTable';
import UserGroupPaths from '../paths/UserGroupPaths';

const mapStateToProps = (state) => ({
  companies: state.companies,
  sites: state.sites,
  costCenters: state.costCenters,
  userinfo: state.userinfo,
});
const mapDispatchToProps = () => ({});

const SIGNATURE_ROLES = ['supplier', 'carrier', 'recipient'];

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: this.getDefaultUser(),
      userLoading: LOADING_STATE.NOT_LOADED,
      origSignatureRoles: null,
      origFeatureFlags: null,
      origDefaultSignatureRole: null,
      origPushNotificationSettings: null,
      signatureRolesLoading: LOADING_STATE.NOT_LOADED,
      featureFlagsLoading: LOADING_STATE.NOT_LOADED,
      defaultSignatureRoleLoading: LOADING_STATE.NOT_LOADED,
      pushNotificationSettingsLoading: LOADING_STATE.NOT_LOADED,
      submittingForm: false,
      sortedCompanies: [],
    };
  }

  componentDidMount() {
    this.resetForm(true);
    this.initCompanies();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.user) !== JSON.stringify(this.props.user)) {
      this.resetForm(
        ObjectUtils.JSONstringifyDiffIgnoringProperty(
          this.props.user,
          prevProps.user,
          'permissionGrantsOn',
        ),
      );
    }

    if (
      JSON.stringify(this.props.companies.companies) !==
      JSON.stringify(prevProps.companies.companies)
    ) {
      this.resetDefaultValues();
    }

    if (
      JSON.stringify(this.state.user?.signatureRoles) !==
        JSON.stringify(prevState.user?.signatureRoles) &&
      this.state.defaultSignatureRoleLoading === LOADING_STATE.SUCCEEDED
    ) {
      // If the signature roles have been changed, we might also have to update the default one
      this.resetDefaultSignatureRole();
    }

    if (
      JSON.stringify(this.props.companies.companies) !==
      JSON.stringify(prevProps.companies.companies)
    ) {
      this.initCompanies();
    }
  }

  async resetForm(resetGeneralUserInformation) {
    if (!resetGeneralUserInformation) {
      // Only reset the granted permissions because this is the only thing that has been changed.
      const newUser = cloneDeep(this.state.user);
      newUser.permissionGrantsOn = this.props.user
        ? this.props.user.permissionGrantsOn
        : this.getDefaultUser().permissionGrantsOn;

      this.setState({
        user: newUser,
      });

      return;
    }

    // Need to convert user to ES6 class because otherwise the following error would be thrown when clicking from org group form into user:
    // Uncaught TypeError: this.state.user.getDlnPermittedSites is not a function.
    const newUser = this.props.user
      ? ES6ClassFactory.convertToES6Class([this.props.user], new User())[0]
      : this.getDefaultUser();

    this.setState({
      user: newUser,
      origSignatureRoles: null,
      origFeatureFlags: null,
      origDefaultSignatureRole: null,
      origPushNotificationSettings: null,
    });

    if (this.renderForCreate()) {
      return;
    }

    // Set state of loading already here, because otherwise, feature flags would wait to show loading spinner until signature roles have been loading due to the await logic.
    this.setState({
      signatureRolesLoading: LOADING_STATE.LOADING,
      featureFlagsLoading: LOADING_STATE.LOADING,
      pushNotificationSettingsLoading: LOADING_STATE.LOADING,
    });

    // Loading the data synchronously is not optimal. Should be refactored.
    await this.loadSignatureRoles();
    if (UserUtils.isVestigasAccount()) {
      await this.loadFeatureFlags();
    }

    if (UserUtils.isVestigasAccount()) {
      await this.loadDefaultSignatureRole();
    }

    if (UserUtils.isVestigasAccount() || UserUtils.isUserWriteAllowedUser()) {
      await this.loadPushNotificationSettings();
    }
  }

  resetDefaultValues() {
    const newUser = cloneDeep(this.state.user);

    if (!newUser.company.id) {
      newUser.company =
        this.props.companies.companies.find(
          (company) => company.id === this.props.userinfo.userinfo.company?.id,
        ) ?? new Company({ id: this.props.userinfo.userinfo.company?.id });
    }

    this.setState({
      user: newUser,
    });
  }

  resetDefaultSignatureRole() {
    const newUser = cloneDeep(this.state.user);

    if (newUser.signatureRoles.noSignatureRoleIsSelected()) {
      // If there is no signature role selected, the default signature role should be null.
      newUser.signatureRoles.defaultSignatureRole = null;
    } else if (
      !this.getDefaultSignatureRoleOptions().find(
        (option) => option.id === newUser.signatureRoles.defaultSignatureRole,
      )
    ) {
      // If the default signature role is not in the list of signature roles, set it to the first one.
      newUser.signatureRoles.defaultSignatureRole =
        this.getDefaultSignatureRoleOptions()[0].id;
    }

    this.setState({
      user: newUser,
    });
  }

  initCompanies() {
    const companies = this.props.companies.companies.map((company) => {
      const newCompany = {
        id: company.id,
        name: company.name,
      };

      return MapperService.addSearchStringWithValues(newCompany, [
        company.name,
      ]);
    });

    const sortedCompanies = ArrayUtils.sortByKey(companies, 'name');

    this.setState({
      sortedCompanies,
    });
  }

  formSuccess = async (event) => {
    event.preventDefault();

    this.setState({
      submittingForm: true,
    });

    let body = {
      username: this.state.user.email,
      email: this.state.user.email,
      first_name: this.state.user.firstName,
      last_name: this.state.user.lastName,
      position: this.state.user.position,
      company_id: this.state.user.company.id,
      is_active: this.state.user.active,
      user_type: this.state.user.signatureRoles?.getUserType() ?? null,
    };

    // Only send password to backend if it is set as empty string can make problems.
    if (this.state.user.password) {
      body.password = this.state.user.password;
    }

    if (this.renderForCreate()) {
      body = {
        ...body,
        // Pass signature role explicitly when creating user
        permitted_signature_types:
          this.state.user.signatureRoles.getSignatureRoles(),
        org_units: this.state.user.organisationalGroups,
      };
    }

    if (
      MasterDataService.propertiesAreMissing(
        body,
        ['company_id'],
        Log.FEATURE.USER,
      )
    ) {
      this.setState({
        submittingForm: false,
      });
      return;
    }

    Log.info('Submit user form', body, Log.BREADCRUMB.FORM_SUBMIT.KEY);
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.USER);

    if (this.renderForCreate()) {
      const [userId, error] = await promiseHandler(
        UserService.createUser(body),
      );

      if (error) {
        Log.error('Failed to create user.', error);

        if (error.response?.status === 409) {
          ToastService.httpError(
            [ToastService.MESSAGE.USER_CREATION_FAILED_DUPLICATE],
            error.response,
          );
          Log.productAnalyticsEvent(
            'Failed to create due to duplicate user',
            Log.FEATURE.USER,
            Log.TYPE.ERROR,
          );
        } else {
          ToastService.httpError(
            [ToastService.MESSAGE.USER_CREATION_FAILED],
            error.response,
          );
          Log.productAnalyticsEvent(
            'Failed to create',
            Log.FEATURE.USER,
            Log.TYPE.ERROR,
          );
        }

        this.setState({
          submittingForm: false,
        });

        return;
      }

      await this.updateFeatureFlags(userId);
      await this.updateDefaultSignatureRole(userId);
      await this.updatePushNotificationSettings(userId);
    } else {
      const [data, error] = await promiseHandler(
        UserService.updateUser(this.state.user?.id, body),
      );

      if (error) {
        ToastService.httpError(
          [ToastService.MESSAGE.USER_UPDATE_FAILED],
          error.response,
        );
        Log.error('Failed to update user. id:' + this.state.user?.id, error);
        Log.productAnalyticsEvent(
          'Failed to update',
          Log.FEATURE.USER,
          Log.TYPE.ERROR,
        );
        this.setState({
          submittingForm: false,
        });
        return;
      }

      const [response2, error2] = await promiseHandler(
        OrganisationalGroupService.updateParentOrganisationalGroups(
          this.props.user.id,
          PermissionGrant.ENTITY_TYPE.USER.KEY,
          this.props.user.organisationalGroups,
          this.state.user.organisationalGroups,
        ),
      );

      if (error2) {
        ToastService.httpError(
          ['Organisations-Gruppen konnten nicht geändert werden.'],
          error2.response,
        );
        Log.productAnalyticsEvent(
          'Failed to update organisational groups',
          Log.FEATURE.USER,
          Log.TYPE.ERROR,
        );
      }

      await this.updateSignatureRoles(this.state.user.id);
      await this.updateFeatureFlags(this.state.user.id);
      await this.updateDefaultSignatureRole(this.state.user.id);
      await this.updatePushNotificationSettings(this.state.user.id);
    }

    this.setState({
      submittingForm: false,
    });

    this.props.closeForm();
    this.resetForm(true);
    UserService.refreshUsers();
  };
  formAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.USER);
    this.props.closeForm();
    this.resetForm(true);
  };
  renderForCreate = () => {
    return this.props.type === 'create';
  };

  getDefaultUser() {
    const user = new User();

    user.company =
      this.props.companies.companies.find(
        (company) => company.id === this.props.userinfo.userinfo.company?.id,
      ) ?? new Company({ id: this.props.userinfo.userinfo.company?.id });

    return user;
  }

  async loadSignatureRoles() {
    this.setState({
      signatureRolesLoading: LOADING_STATE.LOADING,
    });

    const [signatureRoles, error] = await promiseHandler(
      UserService.getUserSignatureRoles(this.props.user?.id),
    );

    if (error) {
      Log.error(
        'Failed to load user signature roles. user id: ' + this.props.user?.id,
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to load signature roles',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
      this.setState({
        signatureRolesLoading: LOADING_STATE.FAILED,
      });
      return;
    }

    const newUser = cloneDeep(this.state.user);

    newUser.signatureRoles = signatureRoles;

    this.setState({
      user: newUser,
      origSignatureRoles: signatureRoles,
      signatureRolesLoading: LOADING_STATE.SUCCEEDED,
    });
  }

  async loadFeatureFlags() {
    this.setState({
      featureFlagsLoading: LOADING_STATE.LOADING,
    });

    const [featureFlags, error] = await promiseHandler(
      UserService.getUserFeatureFlags(this.props.user?.id),
    );

    if (error) {
      Log.error(
        'Failed to load user feature flags. user id: ' + this.props.user?.id,
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to load feature flags',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
      this.setState({
        featureFlagsLoading: LOADING_STATE.FAILED,
      });
      return;
    }

    const newUser = cloneDeep(this.state.user);

    newUser.featureFlags = featureFlags;

    this.setState({
      user: newUser,
      origFeatureFlags: featureFlags,
      featureFlagsLoading: LOADING_STATE.SUCCEEDED,
    });
  }

  async loadDefaultSignatureRole() {
    this.setState({
      defaultSignatureRoleLoading: LOADING_STATE.LOADING,
    });

    const [defaultSignatureRole, error] = await promiseHandler(
      UserService.getUserDefaultSignatureRole(this.props.user?.id),
    );

    if (error) {
      Log.error(
        'Failed to load user default signature role. user id: ' +
          this.props.user?.id,
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to load default signature role',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
      this.setState({
        defaultSignatureRoleLoading: LOADING_STATE.FAILED,
      });
      return;
    }

    const newUser = cloneDeep(this.state.user);

    newUser.signatureRoles.defaultSignatureRole = defaultSignatureRole;

    this.setState({
      user: newUser,
      origDefaultSignatureRole: defaultSignatureRole,
      defaultSignatureRoleLoading: LOADING_STATE.SUCCEEDED,
    });
  }

  async loadPushNotificationSettings() {
    this.setState({
      pushNotificationSettingsLoading: LOADING_STATE.LOADING,
    });

    const [pushNotificationSettings, error] = await promiseHandler(
      PushService.getSettings(this.props.user?.id),
    );

    if (error) {
      Log.error(
        'Failed to load push notification settings. user id: ' +
          this.props.user?.id,
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to load push notification settings',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
      this.setState({
        pushNotificationSettingsLoading: LOADING_STATE.FAILED,
      });
      return;
    }

    const newUser = cloneDeep(this.state.user);

    newUser.pushNotificationSettings = pushNotificationSettings;

    this.setState({
      user: newUser,
      origPushNotificationSettings: pushNotificationSettings,
      pushNotificationSettingsLoading: LOADING_STATE.SUCCEEDED,
    });
  }

  async updateSignatureRoles(userId) {
    // If the signature roles haven't been updated, it is not necessary to write them to the backend.
    if (
      JSON.stringify(this.state.user.signatureRoles) ===
      JSON.stringify(this.state.origSignatureRoles)
    ) {
      return;
    }

    Log.productAnalyticsEvent('Update signature roles', Log.FEATURE.USER);

    const [response, error] = await promiseHandler(
      UserService.updateUserSignatureRoles(
        userId,
        this.state.user.signatureRoles,
      ),
    );

    if (error) {
      const lines = [
        ToastService.MESSAGE.USER_SIGNATURE_ROLE_UPDATE_FAILED,
        UserService.getUserEmailFromStore(userId),
      ];
      ToastService.httpError(lines, error.response);
      Log.productAnalyticsEvent(
        'Failed to update signature roles',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    }
  }

  async updateFeatureFlags(userId) {
    if (!UserUtils.isVestigasAccount()) {
      return;
    }

    // If the feature flags haven't been updated, it is not necessary to write them to the backend.
    if (
      JSON.stringify(this.state.user.featureFlags) ===
      JSON.stringify(this.state.origFeatureFlags)
    ) {
      return;
    }

    Log.productAnalyticsEvent('Update feature flags', Log.FEATURE.USER);
    const {
      accessPermittedSites,
      concreteDiary,
      concreteEditingEnabled,
      lineItemDetailsEditingDisabled,
      ocrEnabled,
      recipientSetArrived,
      showOrderAtPreview,
      userCannotSetAccepted,
      userCannotSetDeclined,
      userCannotSetDelivered,
      userMetrics,
    } = this.state.user.featureFlags;

    const [, error] = await promiseHandler(
      UserService.updateUserFeatureFlags(userId, {
        access_permitted_sites: accessPermittedSites,
        concrete_diary: concreteDiary,
        concrete_editing_enabled: concreteEditingEnabled,
        line_item_details_editing_disabled: lineItemDetailsEditingDisabled,
        ocr_enabled: ocrEnabled,
        recipient_set_arrived: recipientSetArrived,
        show_order_at_preview: showOrderAtPreview,
        user_cannot_set_accepted: userCannotSetAccepted,
        user_cannot_set_declined: userCannotSetDeclined,
        user_cannot_set_delivered: userCannotSetDelivered,
        user_metrics: userMetrics,
      }),
    );

    if (error) {
      const lines = [
        ToastService.MESSAGE.USER_FEATURE_FLAGS_UPDATE_FAILED,
        UserService.getUserEmailFromStore(userId),
      ];
      ToastService.httpError(lines, error.response);
      Log.productAnalyticsEvent(
        'Failed to update feature flags',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    }
  }

  async updateDefaultSignatureRole(userId) {
    if (!UserUtils.isVestigasAccount()) {
      return;
    }

    // If the default signature role hasn't been updated, it is not necessary to write it to the backend.
    if (
      this.state.user.signatureRoles.defaultSignatureRole ===
      this.state.origDefaultSignatureRole
    ) {
      return;
    }

    Log.productAnalyticsEvent(
      'Update default signature role',
      Log.FEATURE.USER,
    );

    const [response, error] = await promiseHandler(
      UserService.updateUserDefaultSignatureRole(
        userId,
        this.state.user.signatureRoles.defaultSignatureRole,
      ),
    );

    if (error) {
      const lines = [
        ToastService.MESSAGE.USER_DEFAULT_SIGNATURE_ROLE_UPDATE_FAILED,
        UserService.getUserEmailFromStore(userId),
      ];
      ToastService.httpError(lines, error.response);
      Log.productAnalyticsEvent(
        'Failed to update default signature role',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    }
  }

  async updatePushNotificationSettings(userId) {
    if (!UserUtils.isVestigasAccount() && !UserUtils.isUserWriteAllowedUser()) {
      return;
    }

    // If the push notification settings haven't been updated, it is not necessary to write them to the backend.
    if (
      JSON.stringify(this.state.user.pushNotificationSettings) ===
      JSON.stringify(this.state.origPushNotificationSettings)
    ) {
      return;
    }

    Log.productAnalyticsEvent(
      'Update push notification settings',
      Log.FEATURE.USER,
    );

    const [response, error] = await promiseHandler(
      PushService.updateSettings(
        userId,
        this.state.user.pushNotificationSettings.getBackendFormat(),
      ),
    );

    if (error) {
      const lines = [
        ToastService.MESSAGE.USER_PUSH_NOTIFICATIONS_UPDATE_FAILED,
        UserService.getUserEmailFromStore(userId),
      ];
      ToastService.httpError(lines, error.response);
      Log.productAnalyticsEvent(
        'Failed to update push notification settings',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    }
  }

  handleInputChange = (event) => {
    const newUser = cloneDeep(this.state.user);

    switch (event.target.name) {
      case 'email': {
        newUser.email = event.target.value;
        Log.info(
          'Change form value of email',
          { from: this.state.user.email, to: newUser.email },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'user_change_email',
          Log.productAnalyticsEvent,
          ['Change email', Log.FEATURE.USER],
        );
        break;
      }

      case 'password': {
        newUser.password = event.target.value;
        Log.info(
          'Change form value of password',
          { from: this.state.user.password, to: newUser.password },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'user_change_password',
          Log.productAnalyticsEvent,
          ['Change password', Log.FEATURE.USER],
        );
        break;
      }

      case 'first_name': {
        newUser.firstName = event.target.value;
        Log.info(
          'Change form value of first name',
          { from: this.state.user.firstName, to: newUser.firstName },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'user_change_first_name',
          Log.productAnalyticsEvent,
          ['Change first name', Log.FEATURE.USER],
        );
        break;
      }

      case 'last_name': {
        newUser.lastName = event.target.value;
        Log.info(
          'Change form value of last name',
          { from: this.state.user.lastName, to: newUser.lastName },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'user_change_last_name',
          Log.productAnalyticsEvent,
          ['Change last name', Log.FEATURE.USER],
        );
        break;
      }

      case 'position': {
        newUser.position = event.target.value;
        Log.info(
          'Change form value of position',
          { from: this.state.user.position, to: newUser.position },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        FunctionUtils.delayFunction(
          'user_change_position',
          Log.productAnalyticsEvent,
          ['Change position', Log.FEATURE.USER],
        );
        break;
      }
    }

    this.setState({
      user: newUser,
    });
  };
  handleCheckboxChange = (event) => {
    const newUser = cloneDeep(this.state.user);

    switch (event.target.name) {
      case 'active': {
        newUser.active = event.target.checked;
        Log.info(
          'Change form value of active',
          { from: this.state.user.active, to: newUser.active },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent('Change active checkbox', Log.FEATURE.USER);
        break;
      }

      case 'signature_role_supplier': {
        newUser.signatureRoles.supplier = event.target.checked;
        Log.info(
          'Change form value of supplier signature role',
          {
            from: this.state.user.signatureRoles.supplier,
            to: newUser.signatureRoles.supplier,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change supplier signature role checkbox',
          Log.FEATURE.USER,
        );
        break;
      }

      case 'signature_role_carrier': {
        newUser.signatureRoles.carrier = event.target.checked;
        Log.info(
          'Change form value of carrier signature role',
          {
            from: this.state.user.signatureRoles.carrier,
            to: newUser.signatureRoles.carrier,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change carrier signature role checkbox',
          Log.FEATURE.USER,
        );
        break;
      }

      case 'signature_role_recipient': {
        newUser.signatureRoles.recipient = event.target.checked;
        Log.info(
          'Change form value of recipient signature role',
          {
            from: this.state.user.signatureRoles.recipient,
            to: newUser.signatureRoles.recipient,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change recipient signature role checkbox',
          Log.FEATURE.USER,
        );
        break;
      }

      case 'signature_role_on_behalf_supplier': {
        newUser.signatureRoles.onBehalfSupplier = event.target.checked;
        Log.info(
          'Change form value of on behalf supplier signature role',
          {
            from: this.state.user.signatureRoles.onBehalfSupplier,
            to: newUser.signatureRoles.onBehalfSupplier,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change on behalf supplier signature role checkbox',
          Log.FEATURE.USER,
        );
        break;
      }

      case 'signature_role_on_behalf_carrier': {
        newUser.signatureRoles.onBehalfCarrier = event.target.checked;
        Log.info(
          'Change form value of on behalf carrier signature role',
          {
            from: this.state.user.signatureRoles.onBehalfCarrier,
            to: newUser.signatureRoles.onBehalfCarrier,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change on behalf carrier signature role checkbox',
          Log.FEATURE.USER,
        );
        break;
      }

      case 'signature_role_on_behalf_recipient': {
        newUser.signatureRoles.onBehalfRecipient = event.target.checked;
        Log.info(
          'Change form value of on behalf recipient signature role',
          {
            from: this.state.user.signatureRoles.onBehalfRecipient,
            to: newUser.signatureRoles.onBehalfRecipient,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change on behalf recipient signature role checkbox',
          Log.FEATURE.USER,
        );
        break;
      }

      case 'push_notifications_dln_created': {
        newUser.pushNotificationSettings.dlnCreated.all = event.target.checked;
        Log.info(
          'Change form value of push notifications',
          {
            from: this.state.user.pushNotificationSettings.dlnCreated.all,
            to: newUser.pushNotificationSettings.dlnCreated.all,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change push notifications checkbox',
          Log.FEATURE.USER,
        );
        break;
      }

      case 'push_notifications_dln_sign_reminder': {
        newUser.pushNotificationSettings.dlnSignReminder.all =
          event.target.checked;
        Log.info(
          'Change form value of push notifications',
          {
            from: this.state.user.pushNotificationSettings.dlnSignReminder.all,
            to: newUser.pushNotificationSettings.dlnSignReminder.all,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change push notifications checkbox',
          Log.FEATURE.USER,
        );
        break;
      }

      case 'feature_flag_access_permitted': {
        newUser.featureFlags.accessPermittedSites = event.target.checked;
        Log.info(
          'Change form value of feature flags access permitted sites',
          {
            from: this.state.user.featureFlags.accessPermittedSites,
            to: newUser.featureFlags.accessPermittedSites,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change feature flags access permitted sites checkbox',
          Log.FEATURE.USER,
        );
        break;
      }

      case 'feature_flag_user_metrics': {
        newUser.featureFlags.userMetrics = event.target.checked;
        Log.info(
          'Change form value of feature flags user metrics',
          {
            from: this.state.user.featureFlags.userMetrics,
            to: newUser.featureFlags.userMetrics,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change feature flags user metrics checkbox',
          Log.FEATURE.USER,
        );
        break;
      }

      case 'feature_flag_concrete_editing_enabled': {
        newUser.featureFlags.concreteEditingEnabled = event.target.checked;
        Log.info(
          'Change form value of feature flags concrete editing enabled',
          {
            from: this.state.user.featureFlags.concreteEditingEnabled,
            to: newUser.featureFlags.concreteEditingEnabled,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change feature flags concrete editing enabled checkbox',
          Log.FEATURE.USER,
        );
        break;
      }

      case 'feature_flag_concrete_diary': {
        newUser.featureFlags.concreteDiary = event.target.checked;
        Log.info(
          'Change form value of feature flags concrete diary',
          {
            from: this.state.user.featureFlags.concreteDiary,
            to: newUser.featureFlags.concreteDiary,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change feature flags concrete diary checkbox',
          Log.FEATURE.USER,
        );
        break;
      }

      case 'feature_flag_line_item_details_editing_disabled': {
        newUser.featureFlags.lineItemDetailsEditingDisabled =
          event.target.checked;
        Log.info(
          'Change form value of feature flags line item details editing disabled',
          {
            from: this.state.user.featureFlags.lineItemDetailsEditingDisabled,
            to: newUser.featureFlags.lineItemDetailsEditingDisabled,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change feature flags line item details editing disabled checkbox',
          Log.FEATURE.USER,
        );
        break;
      }

      case 'feature_flag_recipient_set_arrived': {
        newUser.featureFlags.recipientSetArrived = event.target.checked;
        Log.info(
          'Change form value of feature flags recipient set arrived',
          {
            from: this.state.user.featureFlags.recipientSetArrived,
            to: newUser.featureFlags.recipientSetArrived,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change feature flags recipient set arrived checkbox',
          Log.FEATURE.USER,
        );
        break;
      }

      case 'feature_flag_user_cannot_set_accepted': {
        newUser.featureFlags.userCannotSetAccepted = event.target.checked;
        Log.info(
          'Change form value of feature flags user cannot set accepted',
          {
            from: this.state.user.featureFlags.userCannotSetAccepted,
            to: newUser.featureFlags.userCannotSetAccepted,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change feature flags user cannot set accepted checkbox',
          Log.FEATURE.USER,
        );
        break;
      }

      case 'feature_flag_user_cannot_set_delivered': {
        newUser.featureFlags.userCannotSetDelivered = event.target.checked;
        Log.info(
          'Change form value of feature flags user cannot set delivered',
          {
            from: this.state.user.featureFlags.userCannotSetDelivered,
            to: newUser.featureFlags.userCannotSetDelivered,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change feature flags user cannot set delivered checkbox',
          Log.FEATURE.USER,
        );
        break;
      }

      case 'feature_flag_user_cannot_set_declined': {
        newUser.featureFlags.userCannotSetDeclined = event.target.checked;
        Log.info(
          'Change form value of feature flags user cannot set declined',
          {
            from: this.state.user.featureFlags.userCannotSetDeclined,
            to: newUser.featureFlags.userCannotSetDeclined,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change feature flags user cannot set declined checkbox',
          Log.FEATURE.USER,
        );
        break;
      }

      case 'feature_flag_show_order_at_preview': {
        newUser.featureFlags.showOrderAtPreview = event.target.checked;
        Log.info(
          'Change form value of feature flags show order at preview',
          {
            from: this.state.user.featureFlags.showOrderAtPreview,
            to: newUser.featureFlags.showOrderAtPreview,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change feature flags show order at preview checkbox',
          Log.FEATURE.USER,
        );
        break;
      }

      case 'feature_flag_ocr_enabled': {
        newUser.featureFlags.ocrEnabled = event.target.checked;
        Log.info(
          'Change form value of feature flags OCR enabled',
          {
            from: this.state.user.featureFlags.ocrEnabled,
            to: newUser.featureFlags.ocrEnabled,
          },
          Log.BREADCRUMB.FORM_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change feature flags OCR enabled checkbox',
          Log.FEATURE.USER,
        );
        break;
      }
    }

    this.setState({
      user: newUser,
    });
  };
  handleChangeCompany = (event) => {
    const newUser = cloneDeep(this.state.user);

    newUser.company = new Company({ id: event.target.value });

    Log.info(
      'Change form value of company',
      { from: this.state.user.company, to: newUser.company },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change company', Log.FEATURE.USER);

    this.setState({
      user: newUser,
    });
  };
  handleChangeDefaultSignatureRole = (event) => {
    const newUser = cloneDeep(this.state.user);

    newUser.signatureRoles.defaultSignatureRole = event.target.value;

    Log.info(
      'Change form value of default signature role',
      {
        from: this.state.user.signatureRoles.defaultSignatureRole,
        to: newUser.signatureRoles.defaultSignatureRole,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change default signature role',
      Log.FEATURE.USER,
    );

    this.setState({
      user: newUser,
    });
  };
  handleChangeOrganisationalGroups = (organisationalGroups) => {
    const newUser = cloneDeep(this.state.user);

    newUser.organisationalGroups = organisationalGroups.map(
      (organisationalGroup) => organisationalGroup.id,
    );

    Log.info(
      'Change form value of organisational groups',
      {
        from: this.state.user.organisationalGroups,
        to: newUser.organisationalGroups,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change organisational groups', Log.FEATURE.USER);

    this.setState({
      user: newUser,
    });
  };
  handleChangeSitesDlnCreatedPush = (sites) => {
    const newUser = cloneDeep(this.state.user);

    newUser.pushNotificationSettings.dlnCreated.siteIds = sites.map(
      (site) => site.id,
    );

    Log.info(
      'Change form value of sites',
      {
        from: this.state.user.pushNotificationSettings.dlnCreated.siteIds,
        to: newUser.pushNotificationSettings.dlnCreated.siteIds,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change push notifications sites',
      Log.FEATURE.USER,
    );

    this.setState({
      user: newUser,
    });
  };
  handleChangeCostCentersDlnCreatedPush = (costCenters) => {
    const newUser = cloneDeep(this.state.user);

    newUser.pushNotificationSettings.dlnCreated.costCenterIds = costCenters.map(
      (costCenter) => costCenter.id,
    );

    Log.info(
      'Change form value of cost centers',
      {
        from: this.state.user.pushNotificationSettings.dlnCreated.costCenterIds,
        to: newUser.pushNotificationSettings.dlnCreated.costCenterIds,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change push notifications cost centers',
      Log.FEATURE.USER,
    );

    this.setState({
      user: newUser,
    });
  };
  handleChangeSitesDlnSignReminderPush = (sites) => {
    const newUser = cloneDeep(this.state.user);

    newUser.pushNotificationSettings.dlnSignReminder.siteIds = sites.map(
      (site) => site.id,
    );

    Log.info(
      'Change form value of sites',
      {
        from: this.state.user.pushNotificationSettings.dlnSignReminder.siteIds,
        to: newUser.pushNotificationSettings.dlnSignReminder.siteIds,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change push notifications sites',
      Log.FEATURE.USER,
    );

    this.setState({
      user: newUser,
    });
  };
  handleChangeCostCentersDlnSignReminderPush = (costCenters) => {
    const newUser = cloneDeep(this.state.user);

    newUser.pushNotificationSettings.dlnSignReminder.costCenterIds =
      costCenters.map(({ id }) => id);

    Log.info(
      'Change form value of cost centers',
      {
        from: this.state.user.pushNotificationSettings.dlnSignReminder
          .costCenterIds,
        to: newUser.pushNotificationSettings.dlnSignReminder.costCenterIds,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change push notifications cost centers',
      Log.FEATURE.USER,
    );

    this.setState({
      user: newUser,
    });
  };

  getActiveSitesDlnCreatedPush() {
    return [...this.props.sites?.sites].filter(
      (site) =>
        site.active ||
        this.state.user?.pushNotificationSettings?.dlnCreated?.siteIds?.includes(
          site.id,
        ),
    );
  }

  getPickedSitesDlnCreatedPush() {
    return this.state.user?.pushNotificationSettings?.dlnCreated?.siteIds?.map(
      (id) =>
        this.props.sites?.sites?.find((site) => site.id === id) ??
        new Site({ id }),
    );
  }

  getActiveCostCentersDlnCreatedPush() {
    return [...this.props.costCenters?.costCenters].filter(
      (costCenter) =>
        costCenter.active ||
        this.state.user?.pushNotificationSettings?.dlnCreated?.costCenterIds?.includes(
          costCenter.id,
        ),
    );
  }

  getPickedCostCentersDlnCreatedPush() {
    return this.state.user?.pushNotificationSettings?.dlnCreated?.costCenterIds?.map(
      (id) =>
        this.props.costCenters?.costCenters?.find(
          (costCenter) => costCenter.id === id,
        ) ?? new CostCenter({ id }),
    );
  }

  getActiveSitesDlnSignReminderPush() {
    return [...this.props.sites?.sites].filter(
      (site) =>
        site.active ||
        this.state.user?.pushNotificationSettings?.dlnSignReminder?.siteIds?.includes(
          site.id,
        ),
    );
  }

  getPickedSitesDlnSignReminderPush() {
    return this.state.user?.pushNotificationSettings?.dlnSignReminder?.siteIds?.map(
      (id) =>
        this.props.sites?.sites?.find((site) => site.id === id) ??
        new Site({ id }),
    );
  }

  getActiveCostCentersDlnSignReminderPush() {
    return [...this.props.costCenters?.costCenters].filter(
      (costCenter) =>
        costCenter.active ||
        this.state.user?.pushNotificationSettings?.dlnSignReminder?.costCenterIds?.includes(
          costCenter.id,
        ),
    );
  }

  getPickedCostCentersDlnSignReminderPush() {
    return this.state.user?.pushNotificationSettings?.dlnSignReminder?.costCenterIds?.map(
      (id) =>
        this.props.costCenters?.costCenters?.find(
          (costCenter) => costCenter.id === id,
        ) ?? new CostCenter({ id }),
    );
  }

  getSignatureRolesCheckboxes() {
    if (this.state.signatureRolesLoading === LOADING_STATE.LOADING) {
      return (
        <div className="w-480px h-42px">
          <Spinner />
        </div>
      );
    }

    if (this.state.signatureRolesLoading === LOADING_STATE.FAILED) {
      return (
        <div className="w-480px h-42px">
          Daten konnten nicht geladen werden.
        </div>
      );
    }

    return (
      <>
        {SIGNATURE_ROLES.map((role) => (
          <FormControlLabel
            key={role}
            control={
              <Checkbox
                checked={this.state.user?.signatureRoles?.[role]}
                onChange={this.handleCheckboxChange}
                name={`signature_role_${role}`}
              />
            }
            label={`Als ${SignatureRoles.SIGNATURE_ROLE[role.toUpperCase()].STRING}`}
            className="mr-50px"
          />
        ))}
      </>
    );
  }

  getOnBehalfSignatureRolesCheckboxes() {
    if (this.state.signatureRolesLoading === LOADING_STATE.LOADING) {
      return (
        <div className="w-480px h-42px">
          <Spinner />
        </div>
      );
    }

    if (this.state.signatureRolesLoading === LOADING_STATE.FAILED) {
      return (
        <div className="w-480px h-42px">
          Daten konnten nicht geladen werden.
        </div>
      );
    }

    const ON_BEHALF_SIGNATURE_ROLES = SIGNATURE_ROLES.map(
      (role) => `on_behalf_${role}`,
    );

    return (
      <>
        {ON_BEHALF_SIGNATURE_ROLES.map((role) => {
          const roleName =
            SignatureRoles.SIGNATURE_ROLE[role.toUpperCase()].STRING;

          return (
            <FormControlLabel
              key={role}
              control={
                <Checkbox
                  checked={this.state.user?.signatureRoles?.[role]}
                  onChange={this.handleCheckboxChange}
                  name={`signature_role_${role}`}
                />
              }
              label={`Als ${roleName}`}
              className="mr-50px"
            />
          );
        })}
      </>
    );
  }

  getFeaturesFlagsCheckboxes() {
    if (this.state.featureFlagsLoading === LOADING_STATE.LOADING) {
      return (
        <div className="w-480px h-42px">
          <Spinner />
        </div>
      );
    }

    if (this.state.featureFlagsLoading === LOADING_STATE.FAILED) {
      return (
        <div className="w-480px h-42px">
          Daten konnten nicht geladen werden.
        </div>
      );
    }

    return (
      <div className="flexdir-column w-fit-content flex">
        <LightTooltipWide
          title={
            <div>
              <div>
                Benutzer, bei denen das Feature{' '}
                <span className="bold">
                  Berechtigte Lieferorte und Kostenstellen einsehen
                </span>{' '}
                freigeschaltet ist:
              </div>
              <div>
                - können berechtigte Lieferorte und berechtigte Kostenstelle in
                der Lieferungsliste und -detailansicht einsehen.
              </div>
              <div>
                - Zudem wird der Tab mit nicht zugeordneten Lieferungen in der
                Lieferungsliste angezeigt.
              </div>
            </div>
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.user?.featureFlags?.accessPermittedSites}
                onChange={this.handleCheckboxChange}
                name="feature_flag_access_permitted"
              />
            }
            label={'Berechtigte Lieferorte und Kostenstellen einsehen'}
          />
        </LightTooltipWide>
        <LightTooltipWide
          title={
            <div>
              Benutzer, bei denen das Feature{' '}
              <span className="bold">Benutzerstatistiken einsehen</span>{' '}
              freigeschaltet ist, können diese unter "Statistiken" einsehen.
            </div>
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.user?.featureFlags?.userMetrics}
                onChange={this.handleCheckboxChange}
                name="feature_flag_user_metrics"
              />
            }
            label={'Benutzerstatistiken einsehen'}
          />
        </LightTooltipWide>
        <LightTooltipWide
          title={
            <div>
              Benutzer, bei denen das Feature{' '}
              <span className="bold">Betondaten bearbeiten</span> freigeschaltet
              ist, können in der mobilen App Betondaten einer Lieferung
              verändern.
            </div>
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.user?.featureFlags?.concreteEditingEnabled}
                onChange={this.handleCheckboxChange}
                name="feature_flag_concrete_editing_enabled"
              />
            }
            label={'Betondaten bearbeiten'}
          />
        </LightTooltipWide>
        <LightTooltipWide
          title={
            <div>
              Benutzer, bei denen das Feature{' '}
              <span className="bold">Betontagebuch</span> freigeschaltet ist,
              können in der Webapp das Betontagebuch einsehen.
            </div>
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.user?.featureFlags?.concreteDiary}
                onChange={this.handleCheckboxChange}
                name="feature_flag_concrete_diary"
              />
            }
            label={'Betontagebuch'}
          />
        </LightTooltipWide>
        <LightTooltipWide
          title={
            <div>
              Benutzer, bei denen das Feature{' '}
              <span className="bold">Artikeldetails nicht bearbeiten</span>{' '}
              freigeschaltet ist, können in der mobilen App Artikeldetails nicht
              verändern.
            </div>
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  this.state.user?.featureFlags?.lineItemDetailsEditingDisabled
                }
                onChange={this.handleCheckboxChange}
                name="feature_flag_line_item_details_editing_disabled"
              />
            }
            label={'Artikeldetails nicht bearbeiten'}
          />
        </LightTooltipWide>
        <LightTooltipWide
          title={
            <div>
              Benutzer, bei denen das Feature{' '}
              <span className="bold">
                Empfänger kann das Angekommen Event setzen
              </span>{' '}
              freigeschaltet ist, können in der mobilen App eine Lieferung als
              angekommen markieren.
            </div>
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.user?.featureFlags?.recipientSetArrived}
                onChange={this.handleCheckboxChange}
                name="feature_flag_recipient_set_arrived"
              />
            }
            label={'Empfänger kann das "Angekommen" Event setzen'}
          />
        </LightTooltipWide>
        <LightTooltipWide
          title={
            <div>
              Benutzer, bei denen das Feature{' '}
              <span className="bold">
                Nutzer kann das "Geliefert" Event nicht setzen
              </span>{' '}
              freigeschaltet ist, können in der mobilen App eine Lieferung nicht
              als geliefert markieren.
            </div>
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.user?.featureFlags?.userCannotSetDelivered}
                onChange={this.handleCheckboxChange}
                name="feature_flag_user_cannot_set_delivered"
              />
            }
            label={'Nutzer kann das "Geliefert" Event nicht setzen'}
          />
        </LightTooltipWide>
        <LightTooltipWide
          title={
            <div>
              Benutzer, bei denen das Feature{' '}
              <span className="bold">
                Nutzer kann das "Angenommen" Event nicht setzen
              </span>{' '}
              freigeschaltet ist, können in der mobilen App eine Lieferung nicht
              als angenommen markieren.
            </div>
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.user?.featureFlags?.userCannotSetAccepted}
                onChange={this.handleCheckboxChange}
                name="feature_flag_user_cannot_set_accepted"
              />
            }
            label={'Nutzer kann das "Angenommen" Event nicht setzen'}
          />
        </LightTooltipWide>
        <LightTooltipWide
          title={
            <div>
              Benutzer, bei denen das Feature{' '}
              <span className="bold">
                Nutzer kann das "Abgelehnt" Event nicht setzen
              </span>{' '}
              freigeschaltet ist, können in der mobilen App eine Lieferung nicht
              als abgelehnt markieren.
            </div>
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.user?.featureFlags?.userCannotSetDeclined}
                onChange={this.handleCheckboxChange}
                name="feature_flag_user_cannot_set_declined"
              />
            }
            label={'Nutzer kann das "Abgelehnt" Event nicht setzen'}
          />
        </LightTooltipWide>
        <LightTooltipWide
          title={
            <div>
              Benutzer, bei denen das Feature{' '}
              <span className="bold">
                Bestellreferenz in Vorschau anzeigen (mobile App)
              </span>{' '}
              freigeschaltet ist, können in der mobilen App die Bestellreferenz
              einer Lieferung in der Vorschau einsehen.
            </div>
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.user?.featureFlags?.showOrderAtPreview}
                onChange={this.handleCheckboxChange}
                name="feature_flag_show_order_at_preview"
              />
            }
            label={'Bestellreferenz in Vorschau anzeigen (mobile App)'}
          />
        </LightTooltipWide>
        <LightTooltipWide
          title={
            <div>
              Benutzer, bei denen das Feature{' '}
              <span className="bold">Lieferschein-OCR</span> freigeschaltet ist,
              können Lieferscheine in der mobilen App über OCR einlesen.
            </div>
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.user?.featureFlags?.ocrEnabled}
                onChange={this.handleCheckboxChange}
                name="feature_flag_ocr_enabled"
              />
            }
            label={'Lieferschein-OCR'}
          />
        </LightTooltipWide>
      </div>
    );
  }

  refreshUser = async () => {
    this.setState({
      userLoading: LOADING_STATE.LOADING,
    });

    const [, error] = await promiseHandler(
      UserService.refreshUser(this.props.user.id),
    );

    if (error) {
      this.setState({
        userLoading: LOADING_STATE.FAILED,
      });
      return;
    }

    this.setState({
      userLoading: LOADING_STATE.SUCCEEDED,
    });
  };

  getPaths() {
    if (this.renderForCreate()) {
      return null;
    }

    return (
      <div className="flexdir-column gap-10px flex">
        <OrganisationalGroupPaths
          id={this.props.user.id}
          organisationalGroupPaths={this.props.user.organisationalGroupPaths}
          onOpenOrganisationalGroup={(organisationalGroup) =>
            this.props.onOpenOrganisationalGroup(
              organisationalGroup,
              this.getUnsavedChanges(),
            )
          }
        />
        <UserGroupPaths
          id={this.props.user.id}
          userGroupPaths={this.props.user.userGroupPaths}
          onOpenUserGroup={(userGroup) =>
            this.props.onOpenUserGroup(userGroup, this.getUnsavedChanges())
          }
        />
      </div>
    );
  }

  getUnsavedChanges() {
    // Unsaved changes logic is a bit tricky with create forms because this.props.user is null and some form values are set per default.
    if (this.renderForCreate()) {
      return [];
    }

    // We need to overwrite the signature roles and push notification settings with the original ones
    // because otherwise the unsaved changes would be determined incorrectly.
    // If the original ones haven't been set yet, no changes should be displayed. Thus, the current state is compared with itself.
    const newPropsUser = cloneDeep(this.props.user);
    newPropsUser.signatureRoles =
      this.state.origSignatureRoles ?? this.state.user.signatureRoles;
    newPropsUser.signatureRoles.defaultSignatureRole =
      this.state.origDefaultSignatureRole ??
      this.state.user.signatureRoles.defaultSignatureRole;
    newPropsUser.featureFlags =
      this.state.origFeatureFlags ?? this.state.user.featureFlags;
    newPropsUser.pushNotificationSettings =
      this.state.origPushNotificationSettings ??
      this.state.user.pushNotificationSettings;

    return User.getDifferentValues(newPropsUser, this.state.user);
  }

  getPushNotificationSettingsComponents() {
    if (!UserUtils.isVestigasAccount() && !UserUtils.isUserWriteAllowedUser()) {
      return null;
    }

    if (this.state.pushNotificationSettingsLoading === LOADING_STATE.LOADING) {
      return (
        <>
          <Grid item xs={12} lg={12}>
            <h3 className="main-text">
              Push-Benachrichtigungen, wenn Lieferung erstellt wurde
            </h3>
            <div className="w-480px h-42px">
              <Spinner />
            </div>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="main-text">
              Push-Benachrichtigungen am Ende des Tages
            </h3>
            <div className="w-480px h-42px">
              <Spinner />
            </div>
          </Grid>
        </>
      );
    }

    if (this.state.pushNotificationSettingsLoading === LOADING_STATE.FAILED) {
      return (
        <>
          <Grid item xs={12} lg={12}>
            <h3 className="main-text">
              Push-Benachrichtigungen, wenn Lieferung erstellt wurde
            </h3>
            <div className="w-480px h-42px">
              Daten konnten nicht geladen werden.
            </div>
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="main-text">
              Push-Benachrichtigungen am Ende des Tages
            </h3>
            <div className="w-480px h-42px">
              Daten konnten nicht geladen werden.
            </div>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid item xs={12} lg={12}>
          <h3 className="main-text">
            Push-Benachrichtigungen, wenn Lieferung erstellt wurde
          </h3>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <PackageBasicRestrictionTooltip>
                <div className="flex-s-c gap-20px w-full">
                  <FormControlLabel
                    className="mr-50px"
                    control={
                      <Checkbox
                        checked={
                          this.state.user?.pushNotificationSettings?.dlnCreated
                            ?.all
                        }
                        onChange={this.handleCheckboxChange}
                        name="push_notifications_dln_created"
                        disabled={FeatureService.packageBasicRestriction()}
                      />
                    }
                    label="Alle"
                  />
                  <div className="flex-s-c gap-20px w-full">
                    <div className="w-half">
                      <GenericMultiPicker
                        textfieldLabel="Standorte"
                        pickedItems={this.getPickedSitesDlnCreatedPush()}
                        allItems={this.getActiveSitesDlnCreatedPush()}
                        prioritizedItems={this.state.user.getDlnPermittedSites()}
                        callbackPickedItems={
                          this.handleChangeSitesDlnCreatedPush
                        }
                        fieldName="name"
                        disabled={
                          this.state.user?.pushNotificationSettings?.dlnCreated
                            ?.all || FeatureService.packageBasicRestriction()
                        }
                        loading={this.props.sites.sitesLoading}
                        errorText="Standorte konnten nicht geladen werden."
                        sortItems
                        sortItemsByKey="name"
                      />
                    </div>
                    <div className="w-half">
                      <GenericMultiPicker
                        textfieldLabel="Kostenstellen"
                        pickedItems={this.getPickedCostCentersDlnCreatedPush()}
                        allItems={this.getActiveCostCentersDlnCreatedPush()}
                        prioritizedItems={this.state.user.getDlnPermittedCostCenters()}
                        callbackPickedItems={
                          this.handleChangeCostCentersDlnCreatedPush
                        }
                        fieldName="name"
                        disabled={
                          this.state.user?.pushNotificationSettings?.dlnCreated
                            ?.all || FeatureService.packageBasicRestriction()
                        }
                        loading={this.props.costCenters.costCentersLoading}
                        errorText="Kostenstellen konnten nicht geladen werden."
                        sortItems
                        sortItemsByKey="name"
                      />
                    </div>
                  </div>
                </div>
              </PackageBasicRestrictionTooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <h3 className="main-text">
            Push-Benachrichtigungen am Ende des Tages
          </h3>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <PackageBasicRestrictionTooltip>
                <div className="flex-s-c gap-20px w-full">
                  <FormControlLabel
                    className="mr-50px"
                    control={
                      <Checkbox
                        checked={
                          this.state.user?.pushNotificationSettings
                            ?.dlnSignReminder?.all
                        }
                        onChange={this.handleCheckboxChange}
                        name="push_notifications_dln_sign_reminder"
                        disabled={FeatureService.packageBasicRestriction()}
                      />
                    }
                    label="Alle"
                  />
                  <div className="flex-s-c gap-20px w-full">
                    <div className="w-half">
                      <GenericMultiPicker
                        textfieldLabel="Standorte"
                        pickedItems={this.getPickedSitesDlnSignReminderPush()}
                        allItems={this.getActiveSitesDlnSignReminderPush()}
                        prioritizedItems={this.state.user.getDlnPermittedSites()}
                        callbackPickedItems={
                          this.handleChangeSitesDlnSignReminderPush
                        }
                        fieldName="name"
                        disabled={
                          this.state.user?.pushNotificationSettings
                            ?.dlnSignReminder?.all ||
                          FeatureService.packageBasicRestriction()
                        }
                        loading={this.props.sites.sitesLoading}
                        errorText="Standorte konnten nicht geladen werden."
                        sortItems
                        sortItemsByKey="name"
                      />
                    </div>
                    <div className="w-half">
                      <GenericMultiPicker
                        textfieldLabel="Kostenstellen"
                        pickedItems={this.getPickedCostCentersDlnSignReminderPush()}
                        allItems={this.getActiveCostCentersDlnSignReminderPush()}
                        prioritizedItems={this.state.user.getDlnPermittedCostCenters()}
                        callbackPickedItems={
                          this.handleChangeCostCentersDlnSignReminderPush
                        }
                        fieldName="name"
                        disabled={
                          this.state.user?.pushNotificationSettings
                            ?.dlnSignReminder?.all ||
                          FeatureService.packageBasicRestriction()
                        }
                        loading={this.props.costCenters.costCentersLoading}
                        errorText="Kostenstellen konnten nicht geladen werden."
                        sortItems
                        sortItemsByKey="name"
                      />
                    </div>
                  </div>
                </div>
              </PackageBasicRestrictionTooltip>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  getDefaultSignatureRoleOptions() {
    const defaultSignatureRoleOptions = [];

    if (this.state.user?.signatureRoles?.supplier) {
      defaultSignatureRoleOptions.push({
        id: SignatureRoles.SIGNATURE_ROLE.SUPPLIER.KEY,
        name: SignatureRoles.SIGNATURE_ROLE.SUPPLIER.STRING,
      });
    }

    if (this.state.user?.signatureRoles?.carrier) {
      defaultSignatureRoleOptions.push({
        id: SignatureRoles.SIGNATURE_ROLE.CARRIER.KEY,
        name: SignatureRoles.SIGNATURE_ROLE.CARRIER.STRING,
      });
    }

    if (this.state.user?.signatureRoles?.recipient) {
      defaultSignatureRoleOptions.push({
        id: SignatureRoles.SIGNATURE_ROLE.RECIPIENT.KEY,
        name: SignatureRoles.SIGNATURE_ROLE.RECIPIENT.STRING,
      });
    }

    return defaultSignatureRoleOptions;
  }

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        title={
          'Benutzer ' +
          (this.renderForCreate() ? 'Erstellen' : this.props.user.email)
        }
        fullWidth
        submittingForm={this.state.submittingForm}
        id={this.props.user?.id}
        unsavedChanges={this.getUnsavedChanges()}
        missingPermissionsToSubmit={
          this.renderForCreate()
            ? !UserUtils.isUserCreateAllowedUser()
            : !UserUtils.isUserWriteAllowedUser()
        }
      >
        <Grid container direction="row" spacing={3} space={4}>
          {this.props.user?.organisationalGroupPaths?.length > 0 ||
          this.props.user?.userGroupPaths?.length > 0 ? (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  {this.getPaths()}
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid item xs={12} lg={12}>
            <h3 className="main-text mt-0">Benutzer</h3>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="email-input"
                  name="email"
                  label="E-Mail"
                  type="text"
                  fullWidth
                  required
                  value={this.state.user?.email}
                  onChange={this.handleInputChange}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <PasswordTextField
                  label={
                    this.renderForCreate()
                      ? 'Passwort'
                      : 'Passwort zurücksetzen'
                  }
                  fullWidth
                  required={this.renderForCreate()}
                  value={this.state.user?.password}
                  onChange={this.handleInputChange}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <FormControlLabel
                  disabled={this.renderForCreate()}
                  control={
                    <Checkbox
                      checked={this.state.user?.active}
                      onChange={this.handleCheckboxChange}
                      name="active"
                    />
                  }
                  label="Aktiv"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="first_name-input"
                  name="first_name"
                  label="Vorname"
                  type="text"
                  fullWidth
                  required
                  value={this.state.user?.firstName}
                  onChange={this.handleInputChange}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="last_name-input"
                  name="last_name"
                  label="Nachname"
                  type="text"
                  fullWidth
                  required
                  value={this.state.user?.lastName}
                  onChange={this.handleInputChange}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="position-input"
                  name="position"
                  label="Position"
                  type="text"
                  fullWidth
                  value={this.state.user?.position}
                  onChange={this.handleInputChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} lg={4}>
            <InputLabel className="text-13px" id="demo-multiple-name-label">
              Firma
            </InputLabel>
            <SearchableSelect
              value={this.state.user?.company?.id}
              fullWidth
              onChange={(e) => this.handleChangeCompany(e)}
              size="small"
              options={this.state.sortedCompanies}
              loading={this.props.companies.companiesLoading}
              errorText="Firmen konnten nicht geladen werden."
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">
              Rechte zum Signieren und Bearbeiten von Lieferungen in der App
            </h3>
            {this.getSignatureRolesCheckboxes()}
          </Grid>
          <Grid item xs={12} lg={12}>
            <h3 className="mt-20px main-text">
              Bildschirmunterschrift von Lieferungen in der App
            </h3>
            {this.getOnBehalfSignatureRolesCheckboxes()}
          </Grid>
          {this.state.user?.signatureRoles?.multipleSignatureRolesAreSelected() && (
            <Grid item xs={6} lg={4}>
              <h3 className="mt-20px main-text">
                Default-Signaturberechtigung
              </h3>
              <Select
                value={this.state.user?.signatureRoles?.defaultSignatureRole}
                fullWidth
                onChange={this.handleChangeDefaultSignatureRole}
                size="small"
                options={this.getDefaultSignatureRoleOptions()}
              />
            </Grid>
          )}
          {UserUtils.isVestigasAccount() ? (
            <Grid item xs={12} lg={12}>
              <h3 className="mt-20px main-text">Feature Flags</h3>
              {this.getFeaturesFlagsCheckboxes()}
            </Grid>
          ) : null}
          {this.getPushNotificationSettingsComponents()}
          <Grid item xs={12}>
            <h3 className="mt-20px main-text">Ist Teil von...</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <ComplexPaginatedEntityMultiPicker
                  entityType={
                    PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY
                  }
                  pickedIds={this.state.user.organisationalGroups}
                  callbackPickedItems={this.handleChangeOrganisationalGroups}
                  onChipClick={(organisationalGroup) =>
                    this.props.onOpenOrganisationalGroup(
                      organisationalGroup,
                      this.getUnsavedChanges(),
                    )
                  }
                  onUpdatedItemsChange={
                    this.props.onUpdatedOrganisationalGroupsChange
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          {this.renderForCreate() ? null : (
            <Grid item xs={12}>
              <PermissionGrantSubjectTable
                title="Worauf ist dieser Benutzer berechtigt?"
                permissionGrantsOn={this.state.user.permissionGrantsOn}
                defaultSubjects={[this.props.user?.id]}
                defaultSubjectType={PermissionGrant.SUBJECT_TYPE.USER.KEY}
                fixedPicker={PermissionGrant.TYPE.SUBJECT}
                refreshData={this.refreshUser}
                loading={this.state.userLoading}
              />
            </Grid>
          )}
        </Grid>
      </BasicForm>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(UserForm);
