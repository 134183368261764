import React from 'react';
import { connect } from 'react-redux';
import { SettingsTable } from '../../SettingsTable';
import { MASTERDATA } from '~/constants/MasterdataConsts';
import { withErrorBoundary } from '~/ui/atoms';
import LocalStorageService from '~/services/localStorage.service';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import UserService from '~/services/user.service';
import MapperService from '~/services/mapper.service';

const columns = [
  {
    field: 'email',
    headerName: 'E-Mail',
    width: 400,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'company_name',
    headerName: 'Firma',
    width: 300,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'active',
    headerName: 'Aktiv',
    width: 100,
    sortable: true,
    resizableText: true,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 400,
    sortable: true,
    resizableText: true,
  },
];

const mapStateToProps = (state) => ({
  companies: state.companies,
  users: state.users,
});

class UsersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadData();
    }
  }

  loadData() {
    this.setState({
      rows: this.props.users.users?.map((user) => {
        const row = {
          id: user.id,
          email: user.email,
          company_name: this.props.companies.companies?.find(
            (company) => company.id === user.company?.id,
          )?.name,
          active: user.active
            ? MASTERDATA.IS_ACTIVE.YES
            : MASTERDATA.IS_ACTIVE.NO,
        };

        return MapperService.addSearchString(row, [
          'id',
          'email',
          'company_name',
        ]);
      }),
    });
  }

  render() {
    return (
      <SettingsTable
        entity="user"
        title="Benutzer"
        rows={this.state.rows}
        columns={columns}
        items={this.props.users.users}
        activeFilter
        sortBy="email"
        loading={this.props.users.usersLoading}
        refreshData={UserService.refreshUsers}
        multiPermissionGrantEdit
        multiPermissionGrantDefaultSubjectType={
          PermissionGrant.SUBJECT_TYPE.USER.KEY
        }
        multiPermissionGrantFixedPicker={PermissionGrant.TYPE.SUBJECT}
        localStorageKey={LocalStorageService.USERS_TABLE}
      />
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(UsersTable),
  'Benutzer konnten nicht geladen werden.',
);
