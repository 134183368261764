import Permissions from './Permissions';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

export default class PermissionGrant {
  constructor(permissionGrant) {
    this.id = permissionGrant?.id?.toString() ?? null;
    this.permissions = new Permissions(permissionGrant?.permissions);
    this.subjectType = permissionGrant?.subject_type ?? null;
    this.subjectId = permissionGrant?.subject_id ?? null;
    this.entityType = permissionGrant?.target_type ?? null;
    this.entityId = permissionGrant?.target_id ?? null;
  }

  getEntityTypeString() {
    const entityType = Object.keys(PermissionGrant.ENTITY_TYPE).find(
      (x) => PermissionGrant.ENTITY_TYPE[x].KEY === this.entityType,
    );

    if (!entityType) {
      throw new EnumValueNotFoundException(
        'Invalid entity type: ' + this.entityType,
      );
    }

    return PermissionGrant.ENTITY_TYPE[entityType].STRING;
  }

  getSubjectTypeString() {
    const subjectType = Object.keys(PermissionGrant.SUBJECT_TYPE).find(
      (x) => PermissionGrant.SUBJECT_TYPE[x].KEY === this.subjectType,
    );

    if (!subjectType) {
      throw new EnumValueNotFoundException(
        'Invalid subject type: ' + this.subjectType,
      );
    }

    return PermissionGrant.SUBJECT_TYPE[subjectType].STRING;
  }

  static permissionGrantsAreMatching(permissionGrantA, permissionGrantB) {
    return (
      permissionGrantA.subjectId === permissionGrantB.subjectId &&
      permissionGrantA.entityId === permissionGrantB.entityId &&
      JSON.stringify(permissionGrantA.permissions) ===
        JSON.stringify(permissionGrantB.permissions)
    );
  }

  static getPickableSubjectTypes = () => {
    return Object.keys(PermissionGrant.SUBJECT_TYPE).map((x) => {
      return {
        value: PermissionGrant.SUBJECT_TYPE[x].KEY,
        name: PermissionGrant.SUBJECT_TYPE[x].STRING,
      };
    });
  };
  static getPickableEntityTypes = () => {
    return Object.keys(PermissionGrant.ENTITY_TYPE).map((x) => {
      return {
        value: PermissionGrant.ENTITY_TYPE[x].KEY,
        name: PermissionGrant.ENTITY_TYPE[x].STRING,
      };
    });
  };

  canReadDeliveryNotes() {
    return this.permissions.canReadDeliveryNotes();
  }

  getDefaultRoleName() {
    return this.permissions.getDefaultRoleName();
  }

  static TYPE = {
    SUBJECT: 'subject',
    ENTITY: 'entity',
  };
  static SUBJECT_TYPE = {
    USER: {
      KEY: 'user',
      STRING: 'Benutzer',
    },
    USER_GROUP: {
      KEY: 'user_group',
      STRING: 'Benutzer-Gruppe',
    },
  };
  static ENTITY_TYPE = {
    USER: {
      KEY: 'user',
      STRING: 'Benutzer',
    },
    SITE: {
      KEY: 'site',
      STRING: 'Standort',
    },
    COST_CENTER: {
      KEY: 'accounting_reference',
      STRING: 'Kostenstelle',
    },
    VEHICLE: {
      KEY: 'vehicle',
      STRING: 'Fahrzeug',
    },
    COMPANY: {
      KEY: 'company',
      STRING: 'Firma',
    },
    ORGANISATIONAL_GROUP: {
      KEY: 'org_unit',
      STRING: 'Organisations-Gruppe',
    },
    USER_GROUP: {
      KEY: 'user_group',
      STRING: 'Benutzer-Gruppe',
    },
  };
}
