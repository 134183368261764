import { setUseWhatChange } from '@simbathesailor/use-what-changed';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import store from '~/redux/store';
import ThirdPartyService from '~/services/thirdParty.service';

import { isLocalhost } from '~/utils/env';

import App from './App';

setUseWhatChange(process.env.NODE_ENV === 'development');

if (!isLocalhost) {
  ThirdPartyService.initSentry();
}

ThirdPartyService.initMuiPro();

const root = createRoot(document.querySelector('#root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
