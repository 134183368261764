import cloneDeep from 'lodash/cloneDeep';
import max from 'lodash/max';
import InvalidFunctionParameterException from '~/errors/InvalidFunctionParameterException';
import Log from './Log';

class ArrayUtils {
  constructor() {
    this.EMPTY_DROPDOWN_OPTION = '(leer)';
    this.EMPTY_DROPDOWN_OPTION_RECIPIENT_SITE = '(unbestätigter Lieferort)';
  }

  // returns all values of array1 that are not in array2 (return = array1 - array2)
  subtract(array1, array2) {
    if (!array1 || !array2) {
      return [];
    }

    return array1.filter((x) => !array2.includes(x));
  }

  // returns all rows if value is empty string
  filterByKey(rows, key, value) {
    if (!key || !value) {
      return rows;
    }

    return rows.filter((row) => row[key] === value);
  }

  // filters an array of objects by checking whether the key of each object has one of the allowed values

  filterByKeyValues(
    array,
    key,
    values,
    emptyDropdownOption = this.EMPTY_DROPDOWN_OPTION,
  ) {
    if (values.length === 0) {
      return [...array];
    }

    return array.filter((item) => {
      if (
        (item[key] === undefined || item[key] === '') &&
        values.includes(emptyDropdownOption)
      ) {
        return true;
      }

      return values.includes(item[key]);
    });
  }

  filterByKeyValuesFromArray(
    array,
    key,
    values,
    emptyDropdownOption = this.EMPTY_DROPDOWN_OPTION,
  ) {
    if (values.length === 0) {
      return [...array];
    }

    return array.filter((item) => {
      if (
        (item[key] === undefined || item[key].length === 0) &&
        values.includes(emptyDropdownOption)
      ) {
        return true;
      }

      return this.getOverlappingValues(item[key], values).length > 0;
    });
  }

  sortByKey(array, key, desc) {
    try {
      return [...array].sort((a, b) => {
        const x = a[key];
        const y = b[key];

        if (x === y) {
          return 0;
        }

        return desc ? (x > y ? -1 : 1) : x < y ? -1 : 1;
      });
    } catch (error) {
      Log.error('Failed to sort array by key.', error);
      return array;
    }
  }

  sortByNestedKey(array, nestedKey, desc) {
    try {
      return [...array].sort((a, b) => {
        const x = nestedKey.reduce((object, key) => object[key], a);
        const y = nestedKey.reduce((object, key) => object[key], b);
        if (desc) {
          return x > y ? -1 : x < y ? 1 : 0;
        }

        return x < y ? -1 : x > y ? 1 : 0;
      });
    } catch (error) {
      Log.error('Failed to sort array by key.', error);
      return array;
    }
  }

  sortStringArrayByKey(stringArray, key, desc) {
    try {
      return [...stringArray].sort((a, b) => {
        const x = a[key].toLowerCase();
        const y = b[key].toLowerCase();
        if (desc) {
          return x > y ? -1 : x < y ? 1 : 0;
        }

        return x < y ? -1 : x > y ? 1 : 0;
      });
    } catch (error) {
      Log.error('Failed to sort string array by key.', error);
      return stringArray;
    }
  }

  // sorts an array by a key based on a list of values
  // array = [{name: 'Moritz', id: 1}, ...]
  // sortedValues = [1, 5, 8, 3, 2]
  sortByKeyValues(array, sortedValues, key) {
    if (sortedValues.length !== this.removeDuplicates(sortedValues).length) {
      throw new InvalidFunctionParameterException(
        'Invalid array sortedValues. sortedValues must not contain duplicates.',
      );
    }

    const sortedArray = [];

    for (const sortedValue of sortedValues) {
      const items = array.filter((item) => item[key] === sortedValue);

      sortedArray.push(...items);
    }

    return sortedArray;
  }

  pushToFrontByKeyValues(array, sortedValues, key) {
    const newArray = cloneDeep(array);

    for (const [index, sortedValue] of sortedValues.entries()) {
      const index = array.findIndex((item) => item[key] === sortedValue);
      if (!index) {
        continue;
      }

      this.pushToFront(newArray, index);
    }

    return newArray;
  }

  getOverlappingValues(array1, array2) {
    const set1 = new Set(array1);
    const set2 = new Set(array2);

    // Choose the smaller set to iterate through
    const [smallerSet, largerSet] =
      set1.size < set2.size ? [set1, set2] : [set2, set1];

    return Array.from(smallerSet).filter((value) => largerSet.has(value));
  }

  getDifference(oldArray, newArray) {
    const removedValues = this.subtract(oldArray, newArray);
    const addedValues = this.subtract(newArray, oldArray);

    return [removedValues, addedValues];
  }

  removeDuplicates(array) {
    return [...new Set(array)];
  }

  removeDuplicatesByKey(array, key) {
    return array.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t[key] === value[key]),
    );
  }

  // removes a specific value from an array
  remove(array, value) {
    return array.filter((item) => item !== value);
  }

  removeByKey(array, key, value) {
    const index = array.findIndex((item) => item[key] === value);
    if (index !== -1) {
      return [...array.slice(0, index), ...array.slice(index + 1)];
    }

    return array;
  }

  updateByKey(array, key, value, updatedValue) {
    const index = array.findIndex((item) => item[key] === value);

    const newArray = [...array];
    newArray[index] = updatedValue;

    return newArray;
  }

  /**
   * Sum up an array of objects by a given key.
   * Returns the sum as a number starting at 0.
   */
  sumByKey(array, key) {
    if (key) {
      array = array.map((item) => item[key]);
    }

    return array.reduce((sum, value) => sum + (value ?? 0), 0);
  }

  // Count occurrences in flat array.
  count(array) {
    const accumulator = {};
    for (const current of array) {
      accumulator[current] = (accumulator[current] || 0) + 1;
    }

    return accumulator;
  }

  // Get value with most frequent occurence in flat array.
  getMostFrequentValue(array) {
    const countedArray = this.count(array);

    const mostFrequentValue = max(
      Object.keys(countedArray),
      (o) => countedArray[o],
    );

    if (mostFrequentValue === undefined) {
      return null;
    }

    return mostFrequentValue;
  }

  /**
   * Return an object where the keys are the distinct items from key input variable.
   * The values are the objects with the respective item from the key input variable.
   * @returns [{Mindermenge: [{...}, {...}]}, {Normalbeton: [{...}]}]
   */
  assignByKey(array, key) {
    return array.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  // sums up all values of sumKey for all array items when the groupKeys are the same
  // similar to SQL GROUP BY clause -> SELECT (sumKey) GROUP BY groupKeys
  groupByKeys(array, groupKeys, sumKey) {
    const helper = {};

    return array.reduce(function (r, o) {
      const columnString = groupKeys.map((key) => o[key]).join('-');

      if (helper[columnString]) {
        helper[columnString][sumKey] += o[sumKey];
      } else {
        helper[columnString] = Object.assign({}, o);
        r.push(helper[columnString]);
      }

      return r;
    }, []);
  }

  /**
   * Get an array containing the distinct values of a specific key from an array of objects
   * @param array e.g. [{baustelle: "Baustelle Stuttgart", kostenstelle: "X5 038"},{baustelle: "Baustelle Hannover", kostenstelle: "X5 038"},{baustelle: "Baustelle Hannover", kostenstelle: "A1 003"}]
   * @param key "baustelle"
   * @param emptyDropdownOption
   * @param sortedArray
   * @returns {[]} ["Baustelle Stuttgart","Baustelle Hannover"]
   */

  getDistinctValuesByKey(
    array,
    key,
    emptyDropdownOption = this.EMPTY_DROPDOWN_OPTION,
    sortedArray,
  ) {
    const options = [];

    for (const item of array) {
      let value =
        item[key] !== '' && item[key] !== undefined
          ? item[key]
          : emptyDropdownOption;

      // Handle booleans in dashboard filter.
      if (typeof value === 'boolean' && value) {
        value = 'Ja';
      }

      if (typeof value === 'boolean' && !value) {
        value = 'Nein';
      }

      if (!options.includes(value)) {
        options.push(value);
      }
    }

    if (sortedArray) {
      return sortedArray.filter((item) => options.includes(item));
    }

    return options.sort((a, b) =>
      String(a).toLowerCase().localeCompare(String(b).toLowerCase()),
    );
  }

  /**
   * Get an array containing the distinct values of a specific key from an array of objects
   * @param array e.g. [{baustelle: "Baustelle Stuttgart", kostenstelle: "X5 038"},{baustelle: "Baustelle Hannover", kostenstelle: "X5 038"},{baustelle: "Baustelle Hannover", kostenstelle: "A1 003"}]
   * @param key "baustelle"
   * @param emptyDropdownOption
   * @param sortedArray
   * @returns {[]} ["Baustelle Stuttgart","Baustelle Hannover"]
   */

  getDistinctValuesByKey_safe(
    array,
    key,
    emptyDropdownOption = this.EMPTY_DROPDOWN_OPTION,
    sortedArray,
  ) {
    const options = [];

    for (const item of array) {
      // If the key is not a string, don't extract any value from it.
      if (typeof item[key] !== 'string') {
        continue;
      }

      let value =
        item[key] !== '' && item[key] !== undefined
          ? item[key]
          : emptyDropdownOption;

      // Handle booleans in dashboard filter.
      if (typeof value === 'boolean' && value) {
        value = 'Ja';
      }

      if (typeof value === 'boolean' && !value) {
        value = 'Nein';
      }

      if (!options.includes(value)) {
        options.push(value);
      }
    }

    if (sortedArray) {
      return sortedArray.filter((item) => options.includes(item));
    }

    return options.sort((a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase()),
    );
  }

  /**
   * Get an array containing the distinct values of a specific key from an array of objects.
   * The key inside the object is an array itself.
   *
   * @param {Array} array - e.g. [{baustelle: "Baustelle Stuttgart", kostenstellen: ["X5 038", "A7 012"]},{baustelle: "Baustelle Hannover", kostenstelle: ["X5 038"]},{baustelle: "Baustelle Hannover", kostenstelle: ["A1 003", "FF K78"]}]
   * @param {string} key - "kostenstellen"
   * @param {string} [emptyDropdownOption] - Optional value for empty dropdown.
   * @param {Array} [sortedArray] - Optional sorted array to filter the distinct values.
   * @returns {Array} - ["X5 038","A7 012","A1 003","FF K78"]
   */
  getDistinctValuesFromArrayByKey(
    array,
    key,
    emptyDropdownOption = this.EMPTY_DROPDOWN_OPTION,
    sortedArray,
  ) {
    const optionsSet = new Set();

    for (const item of array) {
      if (item[key].length === 0) {
        // Add the "is empty" message if the array is empty.
        optionsSet.add(emptyDropdownOption);
      }

      for (const value of item[key]) {
        let formattedValue =
          value !== '' && value !== undefined ? value : emptyDropdownOption;

        // Handle booleans in dashboard filter.
        if (typeof formattedValue === 'boolean') {
          formattedValue = formattedValue ? 'Ja' : 'Nein';
        }

        optionsSet.add(formattedValue);
      }
    }

    const options = Array.from(optionsSet);

    if (sortedArray) {
      return sortedArray.filter((item) => optionsSet.has(item));
    }

    return options.sort((a, b) =>
      String(a).toLowerCase().localeCompare(String(b).toLowerCase()),
    );
  }

  /**
   * Get an array containing the distinct values of a specific key from an array of objects.
   * The key inside the object is an array itself.
   *
   * @param array e.g. [{baustelle: "Baustelle Stuttgart", kostenstellen: ["X5 038", "A7 012"]},{baustelle: "Baustelle Hannover", kostenstelle: ["X5 038"]},{baustelle: "Baustelle Hannover", kostenstelle: ["A1 003", "FF K78"]}]
   * @param key "kostenstellen"
   * @param emptyDropdownOption
   * @param sortedArray
   * @returns {[]} ["X5 038","A7 012","A1 003","FF K78"]
   */
  getDistinctValuesFromArrayByKey_safe(
    array,
    key,
    emptyDropdownOption = this.EMPTY_DROPDOWN_OPTION,
    sortedArray,
  ) {
    const optionsSet = new Set();

    for (const item of array) {
      // If the key is not an array, don't extract any value from it.
      if (!Array.isArray(item[key])) {
        continue;
      }

      if (item[key].length === 0) {
        optionsSet.add(emptyDropdownOption);
      }

      for (const value of item[key]) {
        let formattedValue =
          value !== '' && value !== undefined ? value : emptyDropdownOption;

        // Handle booleans in dashboard filter.
        if (typeof formattedValue === 'boolean') {
          formattedValue = formattedValue ? 'Ja' : 'Nein';
        }

        optionsSet.add(formattedValue);
      }
    }

    const options = Array.from(optionsSet);

    if (sortedArray) {
      return sortedArray.filter((item) => optionsSet.has(item));
    }

    return options.sort((a, b) =>
      String(a).toLowerCase().localeCompare(String(b).toLowerCase()),
    );
  }

  // check if subsetArray is a subset of array
  // iterate through every item in subsetArray and check if it occurs in array
  isSubset(array, subsetArray) {
    const base = new Set(array);
    const subset = new Set(subsetArray);

    return subset.isSubsetOf(base);
  }

  joinGermanWords(words) {
    return words.reduce((previous, current, index) => {
      if (words.length === index + 1) {
        return [previous, ' und ', current];
      }

      return [previous, ', ', current];
    });
  }

  // split array in parts with equal length
  split(array, numberOfSplits) {
    const newArray = cloneDeep(array);

    const split = Math.ceil(newArray.length / numberOfSplits);

    const response = [];

    for (let index = 0; index < numberOfSplits; index++) {
      response.push(newArray.splice(0, split));
    }

    return response;
  }

  joinComponents(array) {
    if (array.length === 0) {
      return null;
    }

    return array.reduce((previous, current) => [previous, ', ', current]);
  }

  matchArrays(array1, array2, condition) {
    const matches = [];
    const unmatched1 = [];
    const unmatched2 = [];

    // Iterate over the first array
    for (const element of array1) {
      let foundMatch = false;

      // Iterate over the second array
      for (const element2 of array2) {
        if (condition(element, element2)) {
          // Condition is satisfied, add the match and mark it as found
          matches.push([element, element2]);
          foundMatch = true;
          break;
        }
      }

      // If no match was found, add the item to the unmatched array
      if (!foundMatch) {
        unmatched1.push(element);
      }
    }

    // Find unmatched items from the second array
    for (const element of array2) {
      let foundMatch = false;

      // Iterate over the matched items to avoid duplicates
      for (const match of matches) {
        if (element === match[1]) {
          foundMatch = true;
          break;
        }
      }

      // If no match was found, add the item to the unmatched array
      if (!foundMatch) {
        unmatched2.push(element);
      }
    }

    // Return an object containing matches and unmatched items
    return {
      matches,
      unmatched1,
      unmatched2,
    };
  }

  moveItem(array, fromIndex, toIndex) {
    // First, remove the item from its current position using splice()
    const itemToMove = array.splice(fromIndex, 1)[0];

    // Then, insert the item at the new position using splice()
    array.splice(toIndex, 0, itemToMove);

    return array;
  }

  pushToFront(array, fromIndex) {
    return this.moveItem(array, fromIndex, 0);
  }

  // Move a value from one array to another by id
  // oldArray: [{id: '123', mame: 'Neubau Brücke'}, {id: 'abc', mame: 'Altbau'}]
  // newArray: []
  // id: '123'
  // -> [[{id: 'abc', mame: 'Altbau'}], [{id: '123', mame: 'Neubau Brücke'}]]
  moveToNewArrayById(oldArray, newArray, id) {
    const clonedOldArray = cloneDeep(oldArray);
    const clonedNewArray = cloneDeep(newArray);

    const itemToBeMoved = clonedOldArray.find((item) => item.id === id);

    return [
      this.removeByKey(clonedOldArray, 'id', id), // Array where item has been removed
      [...clonedNewArray, itemToBeMoved], // Array where item has been pushed to
    ];
  }

  removeLastNElements(array, n) {
    return array.slice(0, array.length - n);
  }

  concat(array1, array2) {
    return this.removeDuplicates([...array1, ...array2]);
  }

  concatByKey(newArray, oldArray, key) {
    return newArray.concat(
      oldArray.filter(
        (itemOld) => !newArray.some((itemNew) => itemNew[key] === itemOld[key]),
      ),
    );
  }
}

export default new ArrayUtils();
