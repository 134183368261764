import React from 'react';

import { Info as InfoIcon } from '@mui/icons-material';

import Log from '~/utils/Log';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

export default class Hint extends React.Component {
  getColorClass() {
    switch (this.props.type) {
      case 'error': {
        return 'text-error500';
      }

      default: {
        Log.error(
          null,
          new EnumValueNotFoundException('Invalid type: ' + this.props.type),
        );
      }
    }
  }

  render() {
    return (
      <div className={'text-12px flex-s-c ' + this.getColorClass()}>
        <InfoIcon className={'mr-6px text-18px ' + this.getColorClass()} />
        {this.props.text}
      </div>
    );
  }
}
