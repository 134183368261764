import * as React from 'react';

import { Close as CloseIcon } from '@mui/icons-material';

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';

const BasicModal = ({
  children,
  className,
  closeModal,
  fullWidth,
  opacity,
  open = false,
  title,
}) => (
  <Dialog
    open={open}
    onClose={closeModal}
    className={className}
    fullWidth={fullWidth}
    maxWidth="lg"
    scroll="paper"
    BackdropProps={{
      style: { backgroundColor: 'black', opacity: opacity ?? 0.3 },
    }}
    aria-describedby="modal-modal-description"
    aria-labelledby="modal-modal-title"
  >
    <div className="flex-sb-s">
      <div className="flex-wrap">
        <DialogTitle className="dialog-title">{title}</DialogTitle>
      </div>
      <IconButton
        className="dialog-close-button"
        onClick={closeModal}
        size="large"
      >
        <CloseIcon />
      </IconButton>
    </div>
    <DialogContent>
      <Box>{children}</Box>
    </DialogContent>
  </Dialog>
);

export default BasicModal;
