import React from 'react';
import { connect } from 'react-redux';
import BasicForm from '~/components/BasicForm';
import SearchableSelect from '~/components/baseComponents/inputs/select/SearchableSelect';
import { withErrorBoundary } from '~/ui/atoms';
import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';
import SiteService from '~/services/site.service';
import { InputLabel, Checkbox, FormControlLabel } from '@mui/material';
import { LOADING_STATE } from '~/constants/LoadingState';
import ValueGroup from '~/models/deliveries/ValueGroup';
import ToastService from '~/services/toast.service';
import { SiteNameAddressPair } from '~/components/SiteNameAddressPair';
import MapperService from '~/services/mapper.service';
import Spinner from '~/components/Spinner';

const mapStateToProps = (state) => ({
  sites: state.sites,
  costCenters: state.costCenters,
});

class SiteReferenceModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortedSites: [],
      selectedSite: null,
      submittingForm: false,
      affectedDlnsCount: 0,
      affectedDlnsCountIsLoading: false,
      updateDlns: true,
    };
  }

  componentDidMount() {
    this.init();
    this.loadAffectedDlnsCount();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.sites.sitesLoading === LOADING_STATE.SUCCEEDED &&
        prevProps.sites.sitesLoading !== LOADING_STATE.SUCCEEDED) ||
      (this.props.costCenters.costCentersLoading === LOADING_STATE.SUCCEEDED &&
        prevProps.costCenters.costCentersLoading !== LOADING_STATE.SUCCEEDED)
    ) {
      this.init();
    }

    if (
      this.props.issuerId !== prevProps.issuerId ||
      this.props.identifier !== prevProps.identifier
    ) {
      this.loadAffectedDlnsCount();
    }
  }

  async init() {
    const sites = this.props.sites.sites.map((site) => {
      const costCenters =
        this.props.costCenters.costCenters
          .filter((costCenter) => site.costCenters.includes(costCenter.id))
          ?.map((costCenter) => costCenter.name)
          ?.join(', ') ?? null;

      const newSite = {
        id: site.id,
        active: site.active,
        name: site.name,
        nameComponent: (
          <SiteNameAddressPair site={site} costCenters={costCenters} />
        ),
      };

      return MapperService.addSearchStringWithValues(newSite, [
        site.name,
        site.address.getConcatenatedAddress(),
        costCenters,
      ]);
    });

    const sortedSites = ArrayUtils.sortByKey(sites, 'name').filter(
      (site) => site.active,
    );

    this.setState({
      sortedSites,
      selectedSite: sortedSites[0]?.id,
    });
  }

  loadAffectedDlnsCount = async () => {
    if (!this.props.issuerId || !this.props.identifier) {
      return;
    }

    this.setState({
      affectedDlnsCount: 0,
      affectedDlnsCountIsLoading: true,
    });

    const [response, error] = await promiseHandler(
      SiteService.countAffectedDlnsOfSiteReference(this.props.identifier),
    );

    if (error) {
      Log.error('Failed to load count of affected dlns.', error);
      Log.productAnalyticsEvent(
        'Failed to load count of affected dlns',
        Log.FEATURE.SITE_MAPPING,
        Log.TYPE.ERROR,
      );
      return;
    }

    this.setState({
      affectedDlnsCount: response.data.total_count,
      affectedDlnsCountIsLoading: false,
    });
  };
  formSuccess = async (event) => {
    event.preventDefault();

    this.setState({
      submittingForm: true,
    });

    Log.info(
      'Submit site reference form',
      { value: this.state.selectedSite },
      Log.BREADCRUMB.FORM_SUBMIT.KEY,
    );
    Log.productAnalyticsEvent(
      'Map site reference to site (from site references)',
      Log.FEATURE.SITE_MAPPING,
    );

    // If both assignedSiteId and identifierId are set, the already assigned identifier has to be deleted from the already assigned site.
    const reassignSiteReference =
      this.props.assignedSiteId && this.props.identifierId;

    if (reassignSiteReference) {
      const [response, error] = await promiseHandler(
        SiteService.deleteSiteReference(
          this.props.assignedSiteId,
          this.props.identifierId,
        ),
      );

      if (error) {
        ToastService.httpError(
          ['Die Lieferortbezeichnung konnte nicht neu zugeordnet werden.'],
          error.response,
        );
        Log.productAnalyticsEvent(
          'Failed to delete site reference assignment',
          Log.FEATURE.SITE_MAPPING,
          Log.TYPE.ERROR,
        );
        return;
      }
    }

    const [identifierId, error2] = await promiseHandler(
      SiteService.postSiteIdentifier(
        this.props.issuerId,
        ValueGroup.getCurrentValue(this.props.identifier),
        true,
      ),
    );

    if (error2) {
      Log.error('Failed to post site reference.', error2);
      ToastService.httpError(
        [
          'Die Lieferortbezeichnung konnte nicht für den Standort hinterlegt werden.',
        ],
        error2.response,
      );
      Log.productAnalyticsEvent(
        'Failed to map site reference to site',
        Log.FEATURE.SITE_MAPPING,
        Log.TYPE.ERROR,
      );
      this.setState({
        submittingForm: false,
      });
      return;
    }

    this.setState({
      submittingForm: false,
    });

    this.props.closeForm();
    this.props.refreshAssignedSiteReferences();
    if (!reassignSiteReference) {
      this.props.refreshSiteReferences();
    }
  };
  formAbort = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.SITE_MAPPING);
    this.props.closeForm();
  };
  handleChange = (event) => {
    Log.productAnalyticsEvent('Change site', Log.FEATURE.SITE_MAPPING);

    this.setState({
      selectedSite: event.target.value,
    });
  };
  handleCheckboxChange = (event) => {
    Log.productAnalyticsEvent(
      'Change update dlns checkbox',
      Log.FEATURE.SITE_MAPPING,
    );
    Log.info(
      'Change form value of update dlns checkbox',
      { from: this.state.updateDlns, to: event.target.checked },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );

    this.setState({
      updateDlns: !this.state.updateDlns,
    });
  };
  getCheckboxLabel = () => {
    if (this.state.affectedDlnsCountIsLoading) {
      return <Spinner />;
    }

    if (this.state.affectedDlnsCount === 0) {
      return 'Nicht zugeordnete Lieferungen dem Standort zuordnen.';
    }

    if (this.state.affectedDlnsCount > 100) {
      return 'Die letzten 100 nicht zugeordneten Lieferungen dem Standort zuordnen.';
    }

    return (
      this.state.affectedDlnsCount +
      ' nicht zugeordnete Lieferungen dem Standort zuordnen.'
    );
  };

  render() {
    return (
      <BasicForm
        open={this.props.open}
        formSuccess={this.formSuccess}
        formAbort={this.formAbort}
        title="Lieferort einem Standort zuweisen"
        submitButtonTitle="Zuweisen"
        submittingForm={this.state.submittingForm}
      >
        <div className="text-13px text-grey600">
          Lieferort (Lieferantenbezeichnung) zuweisen:
        </div>
        <div className="bold max-w-800px">
          {ValueGroup.getCurrentValue(this.props.identifier)}
        </div>
        <div className="mt-10px text-13px text-grey600">
          Bitte gib an, zu welchem Standort Lieferungen mit diesem Lieferort
          zugewiesen werden sollen.
        </div>
        <InputLabel className="text-13px mt-20px">Standort</InputLabel>
        <div className="w-600px">
          <SearchableSelect
            options={this.state.sortedSites}
            value={this.state.selectedSite}
            onChange={this.handleChange}
            size="small"
            loading={this.props.sites.sitesLoading}
          />
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.updateDlns}
              onChange={this.handleCheckboxChange}
              name="update_dlns"
            />
          }
          label={this.getCheckboxLabel()}
          className="mt-20px"
        />
      </BasicForm>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(SiteReferenceModal),
  null,
);
