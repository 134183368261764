import { Button, ButtonGroup } from '@mui/material';

import { MASTERDATA } from '~/constants/MasterdataConsts';

import DatagridUtils from '~/utils/datagridUtils';
import UserUtils from '~/utils/userUtils';

import FreeTextSearch from '~/components/baseComponents/inputs/FreeTextSearch';
import BasicTable from '~/components/BasicTable';

export const SettingsTableComponent = ({
  activeFilter,
  buttonGroupFilter,
  columns,
  localStorageKey,
  entity,
  filteredRows,
  freeTextFilter,
  getButton,
  getForm,
  getPermissionGrantDialog,
  loading,
  onMultiPermissionGrantEdit,
  onRowClick,
  onRowSelectionModelChange,
  onSortModelChange,
  rowSelectionModel,
  setButtonGroupFilter,
  setFreeTextFilter,
  setShowMultiPermissionGrantEdit,
  sortModel,
  title,
}) => {
  const handleMultiPermissionGrantEdit = () =>
    onMultiPermissionGrantEdit && UserUtils.isPermissionGrantAllowedUser()
      ? () => setShowMultiPermissionGrantEdit(true)
      : null;

  const excelData = filteredRows.filter(({ id }) =>
    rowSelectionModel.includes(id),
  );

  return (
    <div className="mx-4">
      <div className="main-header">{title}</div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          {activeFilter ? (
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
              orientation={`${
                window.innerWidth > 600 ? `horizontal` : `vertical`
              }`}
            >
              <Button
                className={
                  buttonGroupFilter === ''
                    ? 'primary-button'
                    : 'secondary-button'
                }
                onClick={() => setButtonGroupFilter('')}
              >
                Alle
              </Button>
              <Button
                className={
                  buttonGroupFilter === MASTERDATA.IS_ACTIVE.YES
                    ? 'primary-button'
                    : 'secondary-button'
                }
                onClick={() => setButtonGroupFilter(MASTERDATA.IS_ACTIVE.YES)}
              >
                Aktiv
              </Button>
              <Button
                className={
                  buttonGroupFilter === MASTERDATA.IS_ACTIVE.NO
                    ? 'primary-button'
                    : 'secondary-button'
                }
                onClick={() => setButtonGroupFilter(MASTERDATA.IS_ACTIVE.NO)}
              >
                Inaktiv
              </Button>
            </ButtonGroup>
          ) : null}
          <div className="w-80">
            <FreeTextSearch
              id={`${entity}settings_table_delay_function`}
              onChange={setFreeTextFilter}
              value={freeTextFilter}
              withDelay
              autoFocus
            />
          </div>
        </div>
        <div>
          {getButton()}
          {getForm()}
        </div>
      </div>
      <div
        className="box-shadow-blue mt-4 rounded-md bg-white"
        style={{
          height: DatagridUtils.TABLE_HEIGHT_10_ROWS,
        }}
      >
        <BasicTable
          rows={filteredRows}
          columns={columns}
          checkboxSelection={onMultiPermissionGrantEdit}
          onRowClick={(rowData) => onRowClick(rowData.row)}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={onRowSelectionModelChange}
          onSortModelChange={onSortModelChange}
          sortModel={sortModel}
          excelData={excelData}
          excelColumns={columns}
          onMultiPermissionGrantEdit={handleMultiPermissionGrantEdit}
          loading={loading}
          localStorageKey={localStorageKey}
          defaultHiddenColumns={['id']}
          disableRowSelectionOnClick
        />
      </div>
      {getPermissionGrantDialog()}
    </div>
  );
};
