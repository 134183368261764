import React from 'react';
import { connect } from 'react-redux';

import { DataGrid } from '@mui/x-data-grid';

import ArticleModal from '../../articles/ArticleModal';
import UnitUtils from '~/utils/unitUtils';
import DatagridUtils from '~/utils/datagridUtils';

import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';

import { withErrorBoundary } from '~/ui/atoms';
import Log from '~/utils/Log';
import ToastService from '~/services/toast.service';
import DeliveryNote from '~/models/deliveries/DeliveryNote';

import { LightTooltip, LightTooltipWide } from '~/utils/componentUtils';
import ValueGroup from '~/models/deliveries/ValueGroup';
import { EditingHistorySafe as EditingHistory } from './EditingHistorySafe';

import Article from '~/models/articles/Article';
import BilledItem from '~/models/billingState/BilledItem';

import {
  DeclinedIconLight,
  DoneIconLight,
  EditedIconLight,
  NotSettledIcon,
  PartlySettledIcon,
  SettledIcon,
  UnsignedIconLight,
} from '~/assets/icons';

import DeliveryNoteArticleSummary from './DeliveryNoteArticleSummary';

const mapStateToProps = (state) => ({
  companyAccount: state.companyAccount,
});

class DeliveryNoteArticleList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      article: null,
      hoveredRow: null,
      open: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.deliveryNote?.articles) !==
      JSON.stringify(prevProps.deliveryNote?.articles)
    ) {
      this.setState({
        article: this.props.deliveryNote?.articles.find(
          (article) =>
            ValueGroup.getCurrentValue(article.logisticsPackage) ===
              ValueGroup.getCurrentValue(
                this.state.article?.logisticsPackage,
              ) &&
            ValueGroup.getCurrentValue(article.position) ===
              ValueGroup.getCurrentValue(this.state.article?.position),
        ),
      });
    }
  }

  handleClose = () => {
    Log.productAnalyticsEvent('Close article', Log.FEATURE.DELIVERY_NOTE);
    this.setState({
      open: false,
    });
  };
  onRowClick = (row) => {
    const rowId = row.id?.split(';');

    const article = this.props.deliveryNote?.articles?.find((article) => {
      return (
        ValueGroup.getCurrentValue(article.logisticsPackage) === rowId[0] &&
        ValueGroup.getCurrentValue(article.position) === rowId[1]
      );
    });

    if (!article) {
      ToastService.error([ToastService.MESSAGE.ARTICLE_OPEN_FAILED]);
      Log.error(
        'Failed to find article: package=' +
          rowId[0] +
          ',  position=' +
          rowId[1],
      );
      Log.productAnalyticsEvent(
        'Failed to open article',
        Log.FEATURE.DELIVERY_NOTE,
        Log.TYPE.ERROR,
      );
      return;
    }

    Log.info(
      'Open article modal',
      {
        from: 'Delivery Note',
        to:
          'Article | Package: ' +
          ValueGroup.getCurrentValue(article.logisticsPackage) +
          ' | Position: ' +
          ValueGroup.getCurrentValue(article.position),
      },
      Log.BREADCRUMB.MODAL_OPEN.KEY,
    );
    Log.productAnalyticsEvent('Open article', Log.FEATURE.DELIVERY_NOTE);

    this.setState({
      article,
      open: true,
    });
  };
  getColumns = () => {
    const columns = [];

    // don't display "Menge" column if all articles are only displayed with weight as this would be redundant information
    const displayAmountColumn = this.props.deliveryNote?.articles?.some(
      (article) => article.displayAmount(),
    );
    const displayWeightColumn = this.props.deliveryNote?.articles?.some(
      (article) => article.displayWeight(),
    );

    if (
      this.props.deliveryNote?.settledStatus !==
      BilledItem.SETTLED_STATUS.NOT_SETTLED.KEY
    ) {
      columns.push({
        field: 'settledStatus',
        headerName: '',
        width: 70,
        sortable: true,
        renderCell(params) {
          switch (params.value) {
            case BilledItem.SETTLED_STATUS.FULLY_SETTLED.KEY: {
              return (
                <LightTooltip
                  title={BilledItem.SETTLED_STATUS.FULLY_SETTLED.DESCRIPTION}
                >
                  <div className="flex-c-c h-40px w-40px rounded-10px border-successBase">
                    <SettledIcon className="text-successBase" />
                  </div>
                </LightTooltip>
              );
            }

            case BilledItem.SETTLED_STATUS.PARTLY_SETTLED.KEY: {
              return (
                <LightTooltip
                  title={BilledItem.SETTLED_STATUS.PARTLY_SETTLED.DESCRIPTION}
                >
                  <div className="flex-c-c h-40px w-40px rounded-10px border-warningBase">
                    <PartlySettledIcon className="text-warningBase" />
                  </div>
                </LightTooltip>
              );
            }

            case BilledItem.SETTLED_STATUS.NOT_SETTLED.KEY: {
              return (
                <LightTooltip
                  title={BilledItem.SETTLED_STATUS.NOT_SETTLED.DESCRIPTION}
                >
                  <div className="flex-c-c h-40px w-40px rounded-10px border-grey600">
                    <NotSettledIcon className="text-grey600" />
                  </div>
                </LightTooltip>
              );
            }

            default: {
              return null;
            }
          }
        },
      });
    }

    if (
      this.props.deliveryNote?.acceptState ===
      AcceptStateCalculator.ACCEPT_STATE.DECLINED
    ) {
      columns.push({
        field: 'acceptState',
        headerName: '',
        width: 70,
        sortable: true,
        renderCell(params) {
          switch (params.value.acceptState) {
            case AcceptStateCalculator.ACCEPT_STATE.DECLINED: {
              return (
                <LightTooltip
                  title={'Artikel wurde abgelehnt (' + params.value.by + ').'}
                >
                  <DeclinedIconLight className="ml-10px h-20px" />
                </LightTooltip>
              );
            }

            case AcceptStateCalculator.ACCEPT_STATE.APPROVED: {
              return (
                <LightTooltip title={'Artikel wurde bestätigt.'}>
                  <DoneIconLight className="ml-10px h-20px" />
                </LightTooltip>
              );
            }

            case AcceptStateCalculator.ACCEPT_STATE.OPEN: {
              return (
                <LightTooltip
                  title={
                    'Artikel wurde nicht signiert (' + params.value.by + ').'
                  }
                >
                  <UnsignedIconLight className="ml-10px h-20px" />
                </LightTooltip>
              );
            }

            default: {
              return null;
            }
          }
        },
      });
    }

    columns.push(
      { field: 'position', headerName: 'Position', flex: 1, sortable: true },
      {
        field: 'number',
        headerName: 'Artikel-Nr.',
        flex: 1,
        sortable: true,
        renderCell: (params) =>
          DatagridUtils.displayCellTooltipControlled(
            <EditingHistory value={params.value} fallback="" />,
            ValueGroup.getCurrentValue(params.value),
          ),
      },
    );

    if (
      this.props.deliveryNote?.articles?.some(
        (article) =>
          article.isConcrete() &&
          article.concrete?.concreteId &&
          ValueGroup.getCurrentValue(article.concrete?.concreteId) !==
            ValueGroup.getCurrentValue(article.number),
      )
    ) {
      columns.push({
        field: 'concreteId',
        headerName: 'Sorten-Nr.',
        flex: 1,
        sortable: true,
        renderCell: (params) =>
          DatagridUtils.displayCellTooltipControlled(
            <EditingHistory value={params.value} fallback="" />,
            ValueGroup.getCurrentValue(params.value),
          ),
      });
    }

    columns.push({
      field: 'type',
      headerName: 'Artikel',
      flex: 3,
      sortable: true,
      renderCell(params) {
        return DatagridUtils.displayCellTooltipControlled(
          params.value.edited ? (
            <div className="flex-s-c">
              {ValueGroup.getCurrentValue(params.value.type)}
              <LightTooltipWide title="Artikel wurde bearbeitet (mehr Infos in der Detailansicht).">
                <EditedIconLight className="ml-10px h-20px w-20px" />
              </LightTooltipWide>
            </div>
          ) : (
            <EditingHistory value={params.value.type} fallback="" />
          ),
          ValueGroup.getCurrentValue(params.value.type),
        );
      },
    });

    if (
      this.props.deliveryNote?.acceptState ===
      AcceptStateCalculator.ACCEPT_STATE.DECLINED
    ) {
      columns.push({
        field: 'partialAcceptStatus',
        headerName: 'Abgelehnt',
        flex: 2,
        sortable: true,
        renderCell: DatagridUtils.displayCellTooltip,
      });
    }

    if (displayAmountColumn) {
      columns.push(
        {
          field: 'amountValue',
          headerName: 'Menge',
          flex: 1,
          sortable: true,
          type: 'number',
          renderCell(params) {
            return DatagridUtils.displayCellTooltipControlled(
              <EditingHistory
                value={params.value}
                callback={UnitUtils.formatDe_safe}
                fallback=""
              />,
              UnitUtils.formatDe_safe(ValueGroup.getCurrentValue(params.value)),
            );
          },
        },
        {
          field: 'amountUnit',
          headerName: 'Einheit',
          width: 70,
          sortable: true,
          renderCell(params) {
            return (
              <EditingHistory
                value={params.value}
                callback={UnitUtils.getAbbreviatedUnit}
                fallback=""
              />
            );
          },
        },
      );
    }

    if (displayWeightColumn) {
      columns.push(
        {
          field: 'weightValue',
          headerName: 'Gewicht',
          flex: 1,
          sortable: true,
          type: 'number',
          renderCell(params) {
            return DatagridUtils.displayCellTooltipControlled(
              <EditingHistory
                value={params.value}
                callback={UnitUtils.formatDe_safe}
                fallback=""
              />,
              UnitUtils.formatDe_safe(ValueGroup.getCurrentValue(params.value)),
            );
          },
        },
        {
          field: 'weightUnit',
          headerName: 'Einheit',
          width: 70,
          sortable: true,
          renderCell(params) {
            return (
              <EditingHistory
                value={params.value}
                callback={UnitUtils.getAbbreviatedUnit}
                fallback=""
              />
            );
          },
        },
      );
    }

    return columns;
  };

  getRowBackgroundClass(row) {
    if (row.id === this.state.hoveredRow) {
      return 'bg-light-grey';
    }

    if (
      row.acceptState.acceptState ===
      AcceptStateCalculator.ACCEPT_STATE.DECLINED
    ) {
      return 'bg-declined';
    }

    return '';
  }

  handleMouseEnter = (event) => {
    this.setState({
      hoveredRow: event.currentTarget.dataset.id,
    });
  };
  handleMouseLeave = () => {
    this.setState({
      hoveredRow: null,
    });
  };

  shouldDisplaySummary() {
    if (
      this.props.deliveryDirection ===
      Article.DELIVERY_DIRECTION.RETURNED_ARTICLES.KEY
    ) {
      return true;
    }

    // Display the summary of the delivered articles if there are more than one or none at all or if there are
    // returned articles.
    return (
      this.props.deliveryNote?.deliveredArticles?.length > 1 ||
      this.props.deliveryNote?.deliveredArticles?.length === 0 ||
      this.props.deliveryNote?.returnedArticles?.length > 1
    );
  }

  render() {
    const articles =
      this.props.deliveryDirection ===
      Article.DELIVERY_DIRECTION.DELIVERED_ARTICLES.KEY
        ? this.props.deliveryNote?.deliveredArticles
        : this.props.deliveryNote?.returnedArticles;

    const rows = articles?.map((article) => {
      const position = this.props.deliveryNote?.hasMultipleLogisticsPackages()
        ? '(' +
          ValueGroup.getCurrentValue(article.logisticsPackage) +
          ') ' +
          ValueGroup.getCurrentValue(article.position)
        : ValueGroup.getCurrentValue(article.position);

      return {
        id:
          ValueGroup.getCurrentValue(article.logisticsPackage) +
          ';' +
          ValueGroup.getCurrentValue(article.position),
        position,
        number: article.number,
        concreteId: article.concrete?.concreteId,
        type: { type: article.type, edited: article.hasBeenEdited() },
        partialAcceptStatus: article.getPartialAcceptStatusString(),
        amountValue: article.amount?.value,
        amountUnit: article.amount?.unit,
        weightValue: article.weight?.value,
        weightUnit: article.weight?.unit,
        settledStatus: article.billedItem?.settledStatus,
        acceptState: {
          acceptState: article.acceptState,
          by: [
            // determine the parties that have approved, declined or not signed to display them in the tooltip
            ...(article.acceptArticleSupplier.acceptState ===
            article.acceptState
              ? [DeliveryNote.PROCESS_ROLE.SUPPLIER.STRING]
              : []),
            ...(article.acceptArticleCarrier.acceptState === article.acceptState
              ? [DeliveryNote.PROCESS_ROLE.CARRIER.STRING]
              : []),
            ...(article.acceptArticleRecipient.acceptState ===
            article.acceptState
              ? [DeliveryNote.PROCESS_ROLE.RECIPIENT.STRING]
              : []),
            ...(article.acceptArticleOnBehalfSupplier.acceptState ===
            article.acceptState
              ? [DeliveryNote.PROCESS_ROLE.SUPPLIER.STRING]
              : []),
            ...(article.acceptArticleOnBehalfCarrier.acceptState ===
            article.acceptState
              ? [DeliveryNote.PROCESS_ROLE.CARRIER.STRING]
              : []),
            ...(article.acceptArticleOnBehalfRecipient.acceptState ===
            article.acceptState
              ? [DeliveryNote.PROCESS_ROLE.RECIPIENT.STRING]
              : []),
          ].join(', '),
        },
      };
    });

    return (
      <>
        {this.shouldDisplaySummary() && (
          <DeliveryNoteArticleSummary
            deliveryNote={this.props.deliveryNote}
            deliveryDirection={this.props.deliveryDirection}
          />
        )}
        <ArticleModal
          open={this.state.open}
          handleClose={this.handleClose}
          article={this.state.article}
          deliveryNote={this.props.deliveryNote}
          deliveryDirection={this.props.deliveryDirection}
        />
        <DataGrid
          rows={rows}
          columns={this.getColumns()}
          pageSizeOptions={[100]}
          onRowClick={(rowData) => this.onRowClick(rowData.row)}
          getRowClassName={(params) =>
            `${this.getRowBackgroundClass(params.row)}`
          }
          disableRowSelectionOnClick
          autoHeight
          slotProps={{
            row: {
              onMouseEnter: this.handleMouseEnter,
              onMouseLeave: this.handleMouseLeave,
              style: { cursor: 'pointer' },
            },
          }}
        />
      </>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps)(DeliveryNoteArticleList),
  'Artikel konnten nicht geladen werden.',
);
