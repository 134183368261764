import { array, bool, func, object, string } from 'prop-types';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import { Button, Chip } from '@mui/material';
import {
  Check as CheckIcon,
  Add as AddIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Save as SaveIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import FilterGroupFilter from '~/models/filters/FilterGroupFilter';
import FilterNew from '~/models/filters/FilterNew';

import FeatureService from '~/services/feature.service';

import Log from '~/utils/Log';
import ObjectUtils from '~/utils/objectUtils';
import { withErrorBoundary } from '~/ui/atoms';

import TextField from '../deliveries/deliveryNoteForm/TextField';
import BasicForm from '../BasicForm';
import { AccordionBody } from '../baseComponents/accordion/AccordionBody';
import ClientPortalTooltip from '../salesPackages/clientPortal/ClientPortalTooltip';
import PackageBasicRestrictionTooltip from '../salesPackages/packageBasicRestriction/packageBasicRestrictionTooltip';
import { LightTooltip } from '~/utils/componentUtils';

import { FilterRow } from './FilterRow';

class FilterGroups extends React.Component {
  static propTypes = {
    currentFilterGroupObject: object.isRequired,
    deleteFilterGroup: func.isRequired,
    disableDeleteButton: bool,
    disableUpdateButton: bool,
    filterGroupMarks: array,
    filterGroupOpen: bool,
    filterGroups: array.isRequired,
    filterRows: array.isRequired,
    hideAccordion: bool,
    hideEditNameIcon: bool,
    hideUpdateDeleteButton: bool,
    onChangeSearchValue: func,
    onChangeValue: func.isRequired,
    onClickExpandFilterGroup: func,
    onClickFilterGroup: func.isRequired,
    onOpenFilterRow: func,
    onScrollToBottom: func,
    originalFilterGroupObject: object,
    resetSelectedFilterGroup: func.isRequired,
    saveNewFilterGroup: func.isRequired,
    selectableFilters: array.isRequired,
    selectedFilterGroup: string,
    updateFilterGroup: func.isRequired,
    updateFilterGroupName: func.isRequired,
    updateFilterRows: func.isRequired,
    withExpandableFilterGroup: bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      deleteFormOpen: false,
      formOpen: false,
      formType: 'create',
      height: '',
      newFilterGroupName: '',
      selectedFilterGroup: null,
    };
  }

  componentDidMount() {
    const selectedFilterGroupReset = this.resetSelectedFilterGroup();
    if (selectedFilterGroupReset) {
      return;
    }

    this.initFilterRows();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      // this.props.selectedFilterGroup !== prevProps.selectedFilterGroup ||
      // JSON.stringify(this.props.currentFilterGroupObject) !== JSON.stringify(prevProps.currentFilterGroupObject) ||
      this.props.currentFilterGroupObject.id !==
        prevProps.currentFilterGroupObject.id ||
      // Catch case: selectable filters are empty on mount
      (this.props.selectableFilters.length > 0 &&
        prevProps.selectableFilters.length === 0)
    ) {
      this.initFilterRows();
    }

    if (
      // When the filter rows have not been initialized yet, removing would lead to an empty result.
      this.props.filterRows.length > 0 &&
      // JSON.stringify(this.props.selectableFilters) might be bad performance.
      // When having time, this could be analysed and if needed, refactored.
      JSON.stringify(this.props.selectableFilters) !==
        JSON.stringify(prevProps.selectableFilters)
    ) {
      this.removeDisabledFilterRows();
    }
  }

  initFilterRows() {
    let newFilterRows = this.getFilterRowsFromFilterGroup(
      this.props.currentFilterGroupObject,
    );

    newFilterRows = newFilterRows.filter((filterRow) => {
      const selectableFilter = this.props.selectableFilters.find(
        ({ id }) => id === filterRow,
      );

      return selectableFilter && !selectableFilter.disabled;
    });

    if (newFilterRows.length === 0) {
      // If there are no filter rows, we want to at least display one row.
      const selectableFilter = this.props.selectableFilters.find(
        ({ disabled }) => !disabled,
      );
      if (selectableFilter) {
        newFilterRows.push(selectableFilter.id);
      }
    }

    this.props.updateFilterRows(newFilterRows);
  }

  removeDisabledFilterRows() {
    const newFilterRows = [...this.props.filterRows].filter((filterRow) => {
      const selectableFilter = this.props.selectableFilters.find(
        ({ id }) => id === filterRow,
      );

      if (!selectableFilter) {
        return true;
      }

      if (!selectableFilter.disabled) {
        return true;
      }

      return false;
    });

    this.props.updateFilterRows(newFilterRows);
  }

  resetSelectedFilterGroup() {
    const { filterGroups, resetSelectedFilterGroup, selectedFilterGroup } =
      this.props;

    if (!selectedFilterGroup) {
      return false;
    }

    if (filterGroups.find(({ id }) => id === selectedFilterGroup)) {
      return false;
    }

    resetSelectedFilterGroup();

    return true;
  }

  getFilterRowsFromFilterGroup = (filterGroup) => {
    const filterRows = [...filterGroup.filterRows];

    // Extend the filter rows by the props that are filtered on.
    for (const entry of ObjectUtils.entries(filterGroup.filters)) {
      // If no filter is applied or the filter is already in the list of filter rows, there is no use in extending the filter row list.
      if (entry.value.length === 0 || filterRows.includes(entry.key)) {
        continue;
      }

      // If the filter is not the custom fields filter, we can handle it normally.
      if (entry.key !== FilterGroupFilter.FILTER.SELECTED_CUSTOM_FIELDS) {
        filterRows.push(entry.key);
        continue;
      }

      // If the filter is the custom fields filter, we have to handle it differently because custom fields filters are stored with a different format.
      for (const customField of entry.value) {
        // Prevent duplicate filter rows.
        if (filterRows.includes(customField.key)) {
          continue;
        }

        filterRows.push(customField.key);
      }
    }

    return filterRows;
  };
  onClickFilterGroup = (id) => {
    if (this.props.selectedFilterGroup === id) {
      Log.info('Deselect filter group', id, Log.BREADCRUMB.USER_ACTION.KEY);
      Log.productAnalyticsEvent(
        'Deselect filter group',
        Log.FEATURE.FILTER_GROUPS,
      );
    } else {
      Log.info('Select filter group', id, Log.BREADCRUMB.USER_ACTION.KEY);
      Log.productAnalyticsEvent(
        'Select filter group',
        Log.FEATURE.FILTER_GROUPS,
      );
    }

    this.props.onClickFilterGroup(id);
  };
  onClickNewFilterGroup = (event) => {
    event.currentTarget.blur();

    this.setState({
      formOpen: true,
      formType: 'create',
    });
  };
  onClickChangeFilterGroupName = (event) => {
    event.stopPropagation();
    event.currentTarget.blur();

    this.setState({
      formOpen: true,
      formType: 'edit',
      newFilterGroupName: this.props.filterGroups.find(
        (filterGroup) => filterGroup.id === this.props.selectedFilterGroup,
      ).name,
    });
  };
  onClickExpandFilterGroup = (event) => {
    event.stopPropagation();

    if (this.props.filterGroupOpen) {
      Log.info(
        'Hide filters',
        this.props.selectedFilterGroup,
        Log.BREADCRUMB.USER_ACTION.KEY,
      );
      Log.productAnalyticsEvent('Hide filters', Log.FEATURE.FILTER_GROUPS);
    } else {
      Log.info(
        'Expand filters',
        this.props.selectedFilterGroup,
        Log.BREADCRUMB.USER_ACTION.KEY,
      );
      Log.productAnalyticsEvent('Expand filters', Log.FEATURE.FILTER_GROUPS);
    }

    this.props.onClickExpandFilterGroup();
  };

  renderForCreateNewFilterGroup() {
    return this.state.formType === 'create';
  }

  saveNewFilterGroup = (event) => {
    event.preventDefault();

    Log.info(
      'Save filter',
      this.state.newFilterGroupName,
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent('Submit form', Log.FEATURE.FILTER_GROUPS);

    if (this.renderForCreateNewFilterGroup()) {
      // If the filters haven't been changed and the user wants to create a new filter, the new filter should be empty.
      this.props.saveNewFilterGroup(
        uuidv4(),
        this.state.newFilterGroupName,
        this.props.filterRows,
        !this.filterGroupHasBeenUpdated(),
      );
    } else {
      this.props.updateFilterGroupName(
        this.props.selectedFilterGroup,
        this.state.newFilterGroupName,
      );
    }

    this.setState({
      formOpen: false,
      newFilterGroupName: '',
    });
  };
  abortNewFilterGroup = () => {
    Log.productAnalyticsEvent('Abort form', Log.FEATURE.FILTER_GROUPS);

    this.setState({
      formOpen: false,
      newFilterGroupName: '',
    });
  };
  updateFilterGroup = () => {
    Log.info(
      'Update filter group',
      this.props.selectedFilterGroup,
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent('Update filter group', Log.FEATURE.FILTER_GROUPS);

    this.props.updateFilterGroup(this.props.filterRows);
  };
  onChangeProperty = (oldFilterRow, newFilterRow) => {
    Log.info(
      'Update property of filter',
      oldFilterRow,
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent(
      'Update property of filter',
      Log.FEATURE.FILTER_GROUPS,
    );

    const oldSelectedFilter = this.props.selectableFilters.find(
      ({ id }) => id === oldFilterRow,
    );

    this.props.onChangeValue(oldFilterRow, oldSelectedFilter.customField, []); // Feature FILTER_OPERATOR

    const filterRowIndex = this.props.filterRows.indexOf(oldFilterRow);
    const newFilterRows = [...this.props.filterRows];
    newFilterRows[filterRowIndex] = newFilterRow;

    this.props.updateFilterRows(newFilterRows);
  };
  onChangeOperator = () => {
    // Feature FILTER_OPERATOR
  };
  onDeleteRow = (deletedFilterRow) => {
    Log.info('Delete filter', deletedFilterRow, Log.BREADCRUMB.USER_ACTION.KEY);
    Log.productAnalyticsEvent('Delete filter', Log.FEATURE.FILTER_GROUPS);

    const oldSelectedFilter = this.props.selectableFilters.find(
      (selectableFilter) => selectableFilter.id === deletedFilterRow,
    );

    this.props.onChangeValue(
      deletedFilterRow,
      oldSelectedFilter.customField,
      [],
    ); // Feature FILTER_OPERATOR

    // If there is only one filter left, just reset it, but don't remove it so that the list of filters isn't empty.
    if (this.props.filterRows.length === 1) {
      return;
    }

    const filterRowIndex = this.props.filterRows.indexOf(deletedFilterRow);
    const newFilterRows = [...this.props.filterRows];
    newFilterRows.splice(filterRowIndex, 1);

    this.props.updateFilterRows(newFilterRows);
  };
  onOpenFilterRow = (filterId) => {
    Log.info('Open filter row', filterId, Log.BREADCRUMB.USER_ACTION.KEY);
    Log.productAnalyticsEvent('Open filter row', Log.FEATURE.FILTER_GROUPS);

    if (this.props.onOpenFilterRow) {
      this.props.onOpenFilterRow(filterId);
    }
  };
  onChangeSearchValue = (filterId, searchValue) => {
    Log.info(
      'Change search value of filter row',
      filterId,
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent(
      'Change search value of filter row',
      Log.FEATURE.FILTER_GROUPS,
    );

    if (this.props.onChangeSearchValue) {
      this.props.onChangeSearchValue(filterId, searchValue);
    }
  };
  onScrollToBottom = (filterId) => {
    Log.info(
      'Scroll to bottom of filter row',
      filterId,
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent(
      'Scroll to bottom of filter row',
      Log.FEATURE.FILTER_GROUPS,
    );

    if (this.props.onScrollToBottom) {
      this.props.onScrollToBottom(filterId);
    }
  };

  getLabel(filterGroup) {
    const filterGroupMark = this.props.filterGroupMarks?.find(
      (filterGroupMark) => filterGroupMark.id === filterGroup.id,
    );

    return (
      <div>
        <div className="flex-s-c gap-16px">
          <div className="flex-s-c gap-8px">
            <div>{filterGroup.name}</div>
            {filterGroup.id === this.props.selectedFilterGroup &&
            !this.props.hideEditNameIcon ? (
              <EditIcon
                className="text-17px"
                onClick={this.onClickChangeFilterGroupName}
              />
            ) : null}
          </div>
          {filterGroup.id === this.props.selectedFilterGroup &&
          this.props.withExpandableFilterGroup &&
          !this.props.hideAccordion ? (
            <>
              {/* Apply fixed width so that width of chip doesn't change when open/closing filters. */}
              <div className="h-14px w-1px bg-white" />
              <div className="flex-s-c" onClick={this.onClickExpandFilterGroup}>
                <div className="bold w-115px underline">
                  {this.props.filterGroupOpen
                    ? 'Filter ausblenden'
                    : 'Filter einblenden'}
                </div>
                {this.props.filterGroupOpen ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </div>
            </>
          ) : null}
        </div>
        {this.getFilterGroupMark(filterGroupMark)}
      </div>
    );
  }

  getFilterGroupMark(filterGroupMark) {
    if (!filterGroupMark) {
      return null;
    }

    if (filterGroupMark.tooltipTitle) {
      return (
        <LightTooltip title={filterGroupMark.tooltipTitle}>
          <div className="rounded-10px h-14px w-26px r--6px t--8px bg-error500 text-10px flex-c-c box-shadow-light absolute">
            <span className="bold text-white">{filterGroupMark.mark}</span>
          </div>
        </LightTooltip>
      );
    }

    return (
      <div className="rounded-10px h-14px w-26px r--6px t--8px bg-error500 text-10px flex-c-c box-shadow-light absolute">
        <span className="bold text-white">{filterGroupMark.mark}</span>
      </div>
    );
  }

  getButtons() {
    // If there is no filter group given, we need to hide the buttons because is no filter group that could be updated or deleted.
    if (
      !this.props.selectedFilterGroup ||
      this.props.filterGroups.length === 0 ||
      this.props.hideUpdateDeleteButton
    ) {
      return null;
    }

    return (
      <div className="flex-sb-c gap-16px mt-16px">
        {this.getUpdateButton()}
        {this.getDeleteButton()}
        {this.getDeleteForm()}
      </div>
    );
  }

  getUpdateButton() {
    return (
      <Button
        variant="outlined"
        color="primary"
        onClick={this.updateFilterGroup}
        disabled={
          !this.filterGroupHasBeenUpdated() || this.props.disableUpdateButton
        }
        startIcon={<SaveIcon />}
        className="normal-case"
      >
        {!this.filterGroupHasBeenUpdated() || this.props.disableUpdateButton
          ? 'Filtergruppe gespeichert'
          : 'Filtergruppe speichern'}
      </Button>
    );
  }

  getDeleteButton() {
    return (
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => this.setState({ deleteFormOpen: true })}
        disabled={this.props.disableDeleteButton}
        startIcon={<DeleteOutlinedIcon />}
        className="normal-case"
      >
        Filtergruppe löschen
      </Button>
    );
  }

  getDeleteForm() {
    return (
      <BasicForm
        open={this.state.deleteFormOpen}
        formSuccess={this.deleteFormSuccess}
        formAbort={this.deleteFormAbort}
        title="Filtergruppe löschen"
        submitButtonTitle="Löschen"
      >
        Willst du mit dem Löschen fortfahren?
      </BasicForm>
    );
  }

  deleteFormSuccess = (event) => {
    event.preventDefault();
    event.stopPropagation();

    Log.info(
      'Delete filter group',
      this.props.selectedFilterGroup,
      Log.BREADCRUMB.USER_ACTION.KEY,
    );
    Log.productAnalyticsEvent('Delete filter group', Log.FEATURE.FILTER_GROUPS);

    this.props.deleteFilterGroup();

    this.setState({
      deleteFormOpen: false,
    });
  };
  deleteFormAbort = () => {
    this.setState({
      deleteFormOpen: false,
    });
  };

  getFilterRows() {
    const {
      currentFilterGroupObject,
      filterRows,
      onChangeValue,
      selectableFilters,
    } = this.props;

    return (
      <div className="flexdir-column gap-16px flex">
        {filterRows.map((filterRow) => {
          const newSelectedFilter = cloneDeep(
            selectableFilters.find(({ id }) => id === filterRow),
          );

          if (!newSelectedFilter) {
            return null;
          }

          const newSelectedValues =
            (newSelectedFilter.customField
              ? currentFilterGroupObject.filters.selectedCustomFields.find(
                  ({ key }) => key === newSelectedFilter.customField.key,
                )?.filterValue
              : cloneDeep(currentFilterGroupObject.filters[filterRow])) ?? [];

          return (
            <FilterRow
              key={newSelectedFilter.id}
              selectedValues={newSelectedValues}
              selectedFilter={newSelectedFilter}
              selectableFilters={selectableFilters.filter(
                ({ id }) => id === filterRow || !filterRows.includes(id),
              )}
              operator={FilterNew.OPERATOR.IS_ANY_OF}
              onChangeProperty={(event) =>
                this.onChangeProperty(filterRow, event.target.value)
              }
              onChangeOperator={this.onChangeOperator}
              onChangeValue={(event, filterValue) =>
                onChangeValue(
                  filterRow,
                  newSelectedFilter.customField,
                  filterValue,
                )
              }
              onDeleteRow={() => this.onDeleteRow(filterRow)}
              hideDeleteButton={filterRows.length === 1}
              disabled={newSelectedFilter.disabled}
              onOpen={() => this.onOpenFilterRow(newSelectedFilter.id)}
              onChangeSearchValue={(searchValue) =>
                this.onChangeSearchValue(filterRow, searchValue)
              }
              onScrollToBottom={() => this.onScrollToBottom(filterRow)}
            />
          );
        })}
      </div>
    );
  }

  addFilterRow = () => {
    Log.info('Add filter', null, Log.BREADCRUMB.USER_ACTION.KEY);
    Log.productAnalyticsEvent('Add filter', Log.FEATURE.FILTER_GROUPS);

    const selectableFilters = this.props.selectableFilters.filter(
      (selectableFilter) =>
        !this.props.filterRows.includes(selectableFilter.id) &&
        !selectableFilter.disabled,
    );

    if (selectableFilters.length === 0) {
      return;
    }

    this.props.updateFilterRows([
      ...this.props.filterRows,
      selectableFilters[0].id,
    ]);

    /* // If all selectable filters have already been added to the filter rows, do nothing.
    if (this.props.selectableFilters.length === this.state.filterRows.length) return;

    for (let i = 0; i < this.props.selectableFilters.length; i++) {
      if (this.state.filterRows.includes(this.props.selectableFilters[i].id)) continue;

      this.setState({
        filterRows: [...this.state.filterRows, this.props.selectableFilters[i].id]
      });

      // The global filter rows should only be applied if no filter group is selected.
      if (!this.props.selectedFilterGroup)
        this.props.updateFilterRows([...this.state.filterRows, this.props.selectableFilters[i].id]);

      return;
    } */
  };

  /**
   * Sort object keys of a filter and their values
   */
  sortFilterValues(filters) {
    const sortedFilters = {};

    // Get all keys, sort them, and then process each key
    const sortedKeys = Object.keys(filters).sort();

    for (const key of sortedKeys) {
      // Sort the array values; return as is if not an array
      sortedFilters[key] = Array.isArray(filters[key])
        ? [...filters[key]].sort()
        : filters[key];
    }

    return sortedFilters;
  }

  filterValuesAreDifferent(currentFilters, originalFilters) {
    const sortedCurrentFilters = this.sortFilterValues(currentFilters);
    const sortedOriginalFilters = this.sortFilterValues(originalFilters);

    // Convert sorted filter objects to JSON strings for safe comparison
    const isDifferent =
      JSON.stringify(sortedCurrentFilters) !==
      JSON.stringify(sortedOriginalFilters);

    return isDifferent;
  }

  filterNamesAreDifferent(currentFilterNames, originalFilterNames) {
    // Convert sorted filter names to JSON strings for safe comparison
    const isDifferent =
      JSON.stringify(currentFilterNames) !==
      JSON.stringify(originalFilterNames);

    return isDifferent;
  }

  filterGroupHasBeenUpdated() {
    if (
      this.filterNamesAreDifferent(
        this.props.filterRows,
        this.props.originalFilterGroupObject?.filterRows,
      )
    ) {
      return true;
    }

    if (
      this.filterValuesAreDifferent(
        this.props.currentFilterGroupObject.filters,
        this.props.originalFilterGroupObject.filters,
      )
    ) {
      return true;
    }

    return false;
  }

  isAccordionBodyOpen() {
    if (this.props.hideAccordion) {
      return false;
    }

    if (this.props.filterGroupOpen) {
      return true;
    }

    // If there is no filter group given, we need to display the filter rows. Otherwise, the user can't filter.
    if (this.props.filterGroups.length === 0) {
      return true;
    }

    return false;
  }

  render() {
    return (
      <>
        <div className="flex-s-c gap-10px flex-wrap">
          {this.props.filterGroups.map((filterGroup, index) => (
            <Chip
              key={index}
              label={this.getLabel(filterGroup)}
              onClick={() => this.onClickFilterGroup(filterGroup.id)}
              icon={
                filterGroup.id === this.props.selectedFilterGroup ? (
                  <CheckIcon className="text-white" />
                ) : null
              }
              disabled={filterGroup.disabled}
              className={
                filterGroup.id === this.props.selectedFilterGroup
                  ? 'bg-primary500 text-white'
                  : 'text-grey800 bg-white'
              }
              sx={{
                cursor: 'pointer',
                border: '#8D94A8 solid 1px',
                '&:hover': {
                  backgroundColor:
                    filterGroup.id === this.props.selectedFilterGroup
                      ? null
                      : '#E4E6ED !important',
                },
              }}
            />
          ))}
          <PackageBasicRestrictionTooltip>
            <ClientPortalTooltip>
              <Chip
                label={
                  this.filterGroupHasBeenUpdated()
                    ? 'Als neue Filtergruppe speichern'
                    : 'Neue Filtergruppe'
                }
                icon={<AddIcon className="text-primary500" />}
                onClick={this.onClickNewFilterGroup}
                disabled={
                  FeatureService.clientPortal() ||
                  FeatureService.packageBasicRestriction()
                }
                sx={{
                  cursor: 'pointer',
                  backgroundColor: 'rgba(255, 255, 255, 0)', // Transparent background
                  color: '#173C88',
                  border: this.filterGroupHasBeenUpdated()
                    ? '#8D94A8 solid 1px'
                    : null,
                  '&:hover': {
                    backgroundColor: '#E4E6ED',
                  },
                }}
              />
            </ClientPortalTooltip>
          </PackageBasicRestrictionTooltip>
          <BasicForm
            title={
              this.renderForCreateNewFilterGroup()
                ? 'Filtergruppe speichern'
                : 'Filtergruppe umbenennen'
            }
            open={this.state.formOpen}
            formAbort={this.abortNewFilterGroup}
            formSuccess={this.saveNewFilterGroup}
            submittingForm={this.state.submittingTabForm}
          >
            <div className="w-350px">
              <TextField
                title="Name"
                value={this.state.newFilterGroupName}
                onChange={(event) =>
                  this.setState({ newFilterGroupName: event.target.value })
                }
                placeholder="Bitte eingeben"
                autoComplete="off"
                autoFocus
              />
            </div>
          </BasicForm>
        </div>
        <AccordionBody
          id="filter_accordion"
          open={this.isAccordionBodyOpen()}
          className="flex-none"
        >
          <div className="h-2" />
          {this.getFilterRows()}
          <PackageBasicRestrictionTooltip>
            <ClientPortalTooltip>
              <Button
                variant="outlined"
                color="primary"
                onClick={this.addFilterRow}
                className="mt-4 w-80"
                disabled={
                  FeatureService.clientPortal() ||
                  FeatureService.packageBasicRestriction()
                }
              >
                <AddIcon />
              </Button>
            </ClientPortalTooltip>
          </PackageBasicRestrictionTooltip>
          {this.getButtons()}
        </AccordionBody>
      </>
    );
  }
}

export default withErrorBoundary(
  FilterGroups,
  'Filter konnten nicht geladen werden.',
  true,
);
