import React from 'react';

import { DeliveryNoteMetaDataItem } from '../deliveries/deliveryNote/deliveryNoteMetaData/DeliveryNoteMetaDataItem';

import UnitUtils from '~/utils/unitUtils';
import { withErrorBoundary } from '~/ui/atoms';
import { EditingHistorySafe as EditingHistory } from '../deliveries/deliveryNote/EditingHistorySafe';
import ValueGroup from '~/models/deliveries/ValueGroup';
import Article from '~/models/articles/Article';

import ArrayUtils from '~/utils/arrayUtils';
import ShippingMark from '~/models/deliveries/ShippingMark';

import { ReturnedGoodsIcon } from '~/assets/icons';

function ArticleMetaData(props) {
  const categoryValues = [];

  const formatValueUnitPair = (pair) => {
    return (
      UnitUtils.formatValueUnitPair_safe(
        pair.value,
        pair.unit,
        UnitUtils.getDescriptiveUnit,
      ) ?? '-'
    );
  };

  if (
    props.deliveryDirection === Article.DELIVERY_DIRECTION.RETURNED_ARTICLES.KEY
  ) {
    categoryValues.push({
      category: 'Richtung',
      value: (
        <div className="flex-s-c">
          Retoure
          <ReturnedGoodsIcon className="ml-10px h-20px w-20px" />
        </div>
      ),
    });
  }

  if (props.deliveryNote?.hasMultipleLogisticsPackages()) {
    categoryValues.push({
      category: 'Paket',
      value: <EditingHistory value={props.article?.logisticsPackage} />,
    });
  }

  categoryValues.push(
    {
      category: 'Position',
      value: <EditingHistory value={props.article?.position} />,
    },
    {
      category: 'Artikelnummer',
      value: <EditingHistory value={props.article?.number} />,
    },
  );

  if (
    props.article.isConcrete() &&
    ValueGroup.getCurrentValue(props.article?.concrete?.concreteId)
  ) {
    categoryValues.push({
      category: 'Sortennummer',
      value: <EditingHistory value={props.article?.concrete?.concreteId} />,
    });
  }

  categoryValues.push({
    category: 'Artikel',
    value: <EditingHistory value={props.article?.type} />,
  });

  if (ValueGroup.getCurrentValue(props.article?.ean)) {
    categoryValues.push({
      category: 'EAN',
      value: <EditingHistory value={props.article?.ean} />,
    });
  }

  if (props.article?.displayAmount()) {
    categoryValues.push({
      category: 'Menge',
      value: (
        <EditingHistory
          value={ValueGroup.applyFunction(
            {
              value: props.article?.amount?.value,
              unit: props.article?.amount?.unit,
            },
            formatValueUnitPair,
          )}
        />
      ),
    });
  }

  if (props.article?.displayWeight()) {
    categoryValues.push({
      category: 'Gewicht',
      value: (
        <EditingHistory
          value={ValueGroup.applyFunction(
            {
              value: props.article?.weight?.value,
              unit: props.article?.weight?.unit,
            },
            formatValueUnitPair,
          )}
        />
      ),
    });
  }

  categoryValues.push({
    category: 'Kostenstelle',
    value: <EditingHistory value={props.article?.costCenter} />,
  });

  // Group shipping marks by code and sort values alphabetically
  const uniqueShippingMarkCodes = ArrayUtils.removeDuplicates(
    ValueGroup.getCurrentValue(props?.article?.shippingMarks)?.map(
      (shippingMark) => ValueGroup.getCurrentValue(shippingMark.code),
    ) ?? [],
  );

  for (const shippingMarkCode of uniqueShippingMarkCodes) {
    let categoryHeader = ShippingMark.getShippingMarkString(shippingMarkCode);

    categoryHeader ||= ShippingMark.FALLBACK_STRING;

    const values = [];

    ValueGroup.getCurrentValue(props?.article?.shippingMarks)?.forEach(
      (shippingMark) => {
        if (
          ValueGroup.getCurrentValue(shippingMark.code) !== shippingMarkCode
        ) {
          return;
        }

        values.push(ValueGroup.getCurrentValue(shippingMark.value));
      },
    );

    values.sort((a, b) => a.localeCompare(b));

    categoryValues.push({
      category: categoryHeader,
      value: (
        <EditingHistory
          value={ValueGroup.applyFunction(values, () => values.join(', '))}
        />
      ),
    });
  }

  if (ValueGroup.getCurrentValue(props.article?.constructionPlan)) {
    categoryValues.push({
      category: 'Plan',
      value: <EditingHistory value={props.article?.constructionPlan} />,
    });
  }

  if (ValueGroup.getCurrentValue(props.article?.constructionComponent)) {
    categoryValues.push({
      category: 'Bauteil',
      value: <EditingHistory value={props.article?.constructionComponent} />,
    });
  }

  return (
    <div className="flexdir-column gap-20px flex">
      {categoryValues.map((dataSet, index) => (
        <DeliveryNoteMetaDataItem
          key={index}
          category={dataSet.category}
          value={dataSet.value}
        />
      ))}
    </div>
  );
}

export default withErrorBoundary(
  ArticleMetaData,
  'Artikeldaten konnten nicht geladen werden.',
);
