import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { promiseHandler } from '~/utils/promiseHandler';
import CompanyService from '~/services/company.service';
import ImageUpload from '../../ImageUpload';
import UserService from '~/services/user.service';
import { saveProfilePicture } from '~/redux/userinfoSlice';
import ToastService from '~/services/toast.service';
import Log from '~/utils/Log';
import { withErrorBoundary } from '~/ui/atoms';
import User from '~/models/masterdata/User';

function ProfileBanner(props) {
  const dispatch = useDispatch();
  const { companyAccount } = useSelector((state) => state.companyAccount);
  const { userinfo } = useSelector((state) => state.userinfo);
  const [companyLogo, setCompanyLogo] = useState(null);

  useEffect(() => {
    loadCompanyLogo();
  }, [userinfo.company?.id]);

  const loadCompanyLogo = async () => {
    if (!userinfo.company?.id) {
      return;
    }

    const [companyLogo, error] = await promiseHandler(
      CompanyService.getCompanyLogoById(userinfo.company?.id),
    );

    if (error) {
      Log.error(
        'Failed to load company logo. company id: ' + userinfo.company?.id,
        error,
      );
      Log.productAnalyticsEvent(
        'Failed to load company logo',
        Log.FEATURE.PROFILE,
        Log.TYPE.ERROR,
      );
      return;
    }

    setCompanyLogo(companyLogo);
  };

  const uploadProfilePicture = async (picture) => {
    const url = URL.createObjectURL(picture);
    const img = new Image();
    img.src = url;
    img.addEventListener('load', async function () {
      if (img.width / img.height === 1) {
        Log.productAnalyticsEvent(
          'Upload profile picture',
          Log.FEATURE.PROFILE,
        );

        const [response, error] = await promiseHandler(
          UserService.uploadProfilePicture(picture),
        );
        if (error) {
          ToastService.httpError(
            [ToastService.MESSAGE.USER_PROFILE_PICTURE_UPDATE_FAILED],
            error.response,
          );
          Log.error('Failed to upload profile picture.', error);
          Log.productAnalyticsEvent(
            'Failed to upload profile picture',
            Log.FEATURE.PROFILE,
            Log.TYPE.ERROR,
          );
          return;
        }

        dispatch(saveProfilePicture(picture));
      } else {
        ToastService.warning([
          'Bitte lade ein Bild mit gleicher Höhe und Breite hoch.',
        ]);
        Log.productAnalyticsEvent(
          "Image doesn't have equal height and width",
          Log.FEATURE.PROFILE,
          Log.TYPE.FAILED_VALIDATION,
        );
      }
    });
  };

  const handleDelete = () => {
    Log.productAnalyticsEvent('Delete profile picture', Log.FEATURE.PROFILE);

    UserService.deleteProfilePicture()
      .then((response) => {
        dispatch(saveProfilePicture(null));
      })
      .catch((error) => {
        ToastService.httpError(
          [ToastService.MESSAGE.USER_PROFILE_PICTURE_DELETION_FAILED],
          error.response,
        );
        Log.error('Failed to delete profile picture.', error);
        Log.productAnalyticsEvent(
          'Failed to delete profile picture',
          Log.FEATURE.PROFILE,
          Log.TYPE.ERROR,
        );
      });
  };

  return (
    <div className="box-shadow-blue rounded-5px p-20px bg-white text-center">
      {companyLogo?.size > 0 ? (
        <img className="profile-logo" src={URL.createObjectURL(companyLogo)} />
      ) : null}
      <div className="profile-picture">
        {props.uploadablePicture ? (
          <ImageUpload
            image={userinfo.profilePicture}
            setImage={uploadProfilePicture}
            onDelete={handleDelete}
            profilePicture
            withCrop
            uploadText="Lade hier dein Profilbild hoch"
          />
        ) : userinfo.profilePicture?.size > 0 ? (
          <img
            width="15%"
            src={URL.createObjectURL(userinfo.profilePicture)}
            className="circle mt-20px"
          />
        ) : null}
      </div>
      <div className="mt-10px bold">
        {User.formatName(userinfo.firstname, userinfo.lastname)}
      </div>
      {userinfo.position ? (
        <div className="mt-10px">{userinfo.position}</div>
      ) : null}
      <div className="mt-10px">
        {userinfo.company?.name ?? companyAccount.name ?? ''}
      </div>
    </div>
  );
}

export default withErrorBoundary(
  ProfileBanner,
  'Profilbild konnte nicht geladen werden.',
);
