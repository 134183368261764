import { Clear as ClearIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export default function EndAdornmentClear(props) {
  return (
    <div className="flex-s-c">
      <div className="h-20px w-1px bg-grey200" />
      <IconButton onClick={props.onClick} edge="end" size="large">
        <ClearIcon fontSize="small" />
      </IconButton>
    </div>
  );
}
